import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store';

interface NotificationCallbacks {
    hideCallback?: Function;
    actionCallback?: Function;
}

export interface NotificationData {
    content?: string;
    htmlContent?: string;
    timeout?: number;
    type?: 'error' | 'warning' | 'info' | 'success';
    actionText?: string;
    removeCloseButton?: boolean;
}

interface Notification extends NotificationData {
    key: number;
    timeoutId?: NodeJS.Timeout | string | number;
    dismissOnNavigate: boolean;
}

let notificationId = 0;
const generateNotificationId = () => notificationId++;
const notificationCallbacks: Record<number, NotificationCallbacks> = {};

const initialState: Notification[] = [];
export const platformNotificationsSlice = createSlice({
    name: 'platformNotifications',
    initialState,
    reducers: {
        addNotification: (state, action: PayloadAction<Notification>) => {
            state.push(action.payload);
        },
        removeNotification: (state, action: PayloadAction<number>) => {
            const notificationToCloseIndex = state.findIndex(n => n.key === action.payload);
            if (notificationToCloseIndex === -1) return;

            state.splice(notificationToCloseIndex, 1);
        },
        clearNotifications: state => {
            return [];
        }
    }
});

export const addNotification = (notificationData: NotificationData, actionCallback?: Function, hideCallback?: Function, dismissOnNavigate = false) => (
    dispatch: AppDispatch,
    getState: () => RootState
) => {
    const notificationId = generateNotificationId();
    if (hideCallback || actionCallback) {
        notificationCallbacks[notificationId] = {
            hideCallback: hideCallback,
            actionCallback: actionCallback
        };
    }

    const notification: Notification = {
        ...notificationData,
        key: notificationId,
        dismissOnNavigate: dismissOnNavigate,
        timeoutId:
            notificationData.timeout !== 0
                ? setTimeout(() => {
                      dispatch(removeNotification(notificationId, false));
                  }, notificationData.timeout || 10000)
                : undefined
    };
    dispatch(platformNotificationsSlice.actions.addNotification(notification));

    return notificationId;
};

export const addErrorNotification = (content: string) => addNotification({ content: content, timeout: 0, type: 'error' });

export const removeNotification = (notificationId: number, triggerAction: boolean) => (dispatch: AppDispatch, getState: () => RootState) => {
    const notifications = getState().platformNotifications;
    const notificationToCloseIndex = notifications.findIndex(n => n.key === notificationId);
    if (notificationToCloseIndex === -1) return;
    const notificationToClose = notifications[notificationToCloseIndex];
    if (notificationToClose.timeoutId) clearTimeout(notificationToClose.timeoutId);
    const callbacks = notificationCallbacks[notificationId];
    if (callbacks) {
        if (triggerAction && callbacks.actionCallback) {
            callbacks.actionCallback();
        }

        callbacks.hideCallback?.();

        delete notificationCallbacks[notificationId];
    }

    dispatch(platformNotificationsSlice.actions.removeNotification(notificationId));
};

const clearNotificationsWithFilter = (dispatch: AppDispatch, getState: () => RootState, predicate?: (notification: Notification) => unknown) => {
    const notifications = getState().platformNotifications;
    const notificationsToClear = predicate ? notifications.filter(predicate) : notifications;
    notificationsToClear.forEach(n => {
        dispatch(removeNotification(n.key, false));
    });
};

export const clearNotifications = (navigateOnly: boolean) => (dispatch: AppDispatch, getState: () => RootState) => {
    clearNotificationsWithFilter(dispatch, getState, navigateOnly ? n => n.dismissOnNavigate : undefined);
};

export const clearErrorNotifications = () => (dispatch: AppDispatch, getState: () => RootState) => {
    clearNotificationsWithFilter(dispatch, getState, n => n.type === 'error');
};

export default platformNotificationsSlice.reducer;
