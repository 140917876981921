import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { useInvisibleReCaptcha } from '../../components/common/invisibleReCaptcha';
import { emailValidator, maxLengthValidator, requiredValidator, ValidatedInput } from '../../components/ui/inputs';
import { H1, H2, H3 } from '../../components/ui/typography';
import { ResponsiveGroup, useResponsiveLayout, useSingleClickCallback } from '../../hooks/commonHooks';
import { ReactComponent as ClockIcon } from '../../icons/clock.svg';
import { ReactComponent as IcanpreneurLogo } from '../../images/logo.svg';
import { dateTimeService } from '../../services/dateTimeService';
import { NotFoundException } from '../../services/httpServiceBase';
import { CreatePublicInterviewData, publicInterviewsService } from '../../services/interviewsV2Service';
import { PublicResearch, publicResearchService } from '../../services/researchV2Service';

const firstNameValidators = [requiredValidator('First name'), maxLengthValidator('First name', 50)];
const lastNameValidators = [requiredValidator('Last name'), maxLengthValidator('Last name', 50)];
const emailValidators = [requiredValidator('Email'), emailValidator, maxLengthValidator('Email', 320)];
export function StartResearchAILedInterviewPage() {
    const { publicCode } = useParams();
    if (!publicCode) throw new NotFoundException();

    const responsiveGroup = useResponsiveLayout();
    const isMobile = responsiveGroup === ResponsiveGroup.xs;

    const [research, setResearch] = useState<PublicResearch>();

    useEffect(() => {
        publicResearchService.getResearch(publicCode).then(setResearch);
    }, [publicCode]);

    const navigate = useNavigate();
    const getReCaptchaToken = useInvisibleReCaptcha();
    async function startInterview(data: Record<string, any>) {
        const createInterviewData: CreatePublicInterviewData = {
            firstName: data.firstName,
            lastName: data.lastName,
            emailAddress: data.emailAddress,
            researchCode: publicCode!
        };

        const reCaptchaToken = await getReCaptchaToken?.();
        const newInterview = await publicInterviewsService.createInterview(createInterviewData, reCaptchaToken);
        navigate(`/interviews/${newInterview.publicCode}/ai`);
    }
    const [isSubmitting, startInterviewCallback] = useSingleClickCallback(startInterview);

    const title = research ? research.ideaTitle : <Skeleton shape="text" style={{ width: '30%' }} className="!k-display-inline-block" />;
    const subTitle = research ? research.name : <Skeleton shape="text" style={{ width: '50%' }} className="!k-display-inline-block" />;
    const duration = research ? (
        typeof research.interviewScriptDurationInMinutes === 'number' ? (
            dateTimeService.stringifyMinutesToHours(research.interviewScriptDurationInMinutes)
        ) : null
    ) : (
        <Skeleton style={{ width: 50 }} className="!k-display-inline-block" />
    );

    return (
        <div className="page">
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=1.0, minimum-scale=1.0, maximum-scale=1.0" />
            </Helmet>
            <div className="page-content page-content-section ai-background">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'middle' }} className="k-gap-8 k-min-h-full page-content-middle">
                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2 k-text-center">
                        {isMobile ? <H1>{title}</H1> : <div className="k-display-4">{title}</div>}
                        {isMobile ? <H3>{subTitle}</H3> : <H2>{subTitle}</H2>}
                        {duration !== null && (
                            <div>
                                <ClockIcon className="!k-display-inline k-align-middle k-icp-icon k-icp-icon-size-4 k-mr-1.5" />
                                <span className="k-align-middle k-icp-line-height-inline-md">
                                    Approximate interview time:
                                    <span className="k-ml-1.5">{duration}</span>
                                </span>
                            </div>
                        )}
                    </StackLayout>

                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                        <div className="k-p-8 k-rounded-xl k-border-2 k-border-solid k-border-white bg-ghost-gradient">
                            <Form
                                ignoreModified={true}
                                onSubmit={startInterviewCallback}
                                render={formRenderProps => (
                                    <FormElement noValidate={true}>
                                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-6">
                                            <H3>Enter your details below:</H3>

                                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-8">
                                                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                                                    <StackLayout
                                                        orientation={isMobile ? 'vertical' : 'horizontal'}
                                                        align={{ horizontal: 'stretch', vertical: 'top' }}
                                                        className="k-gap-4"
                                                    >
                                                        <Field
                                                            name="firstName"
                                                            component={ValidatedInput}
                                                            label="First name"
                                                            validator={firstNameValidators}
                                                            maxLength={50}
                                                            disabled={isSubmitting || !research}
                                                        />
                                                        <Field
                                                            name="lastName"
                                                            component={ValidatedInput}
                                                            label="Last name"
                                                            validator={lastNameValidators}
                                                            maxLength={50}
                                                            disabled={isSubmitting || !research}
                                                        />
                                                    </StackLayout>
                                                    <Field
                                                        name="emailAddress"
                                                        component={ValidatedInput}
                                                        label="Email"
                                                        validator={emailValidators}
                                                        maxLength={320}
                                                        disabled={isSubmitting || !research}
                                                    />
                                                </StackLayout>

                                                <StackLayout orientation="horizontal" align={{ horizontal: 'center' }} className="k-align-self-center">
                                                    <Button
                                                        type="submit"
                                                        themeColor="primary"
                                                        size="large"
                                                        disabled={!formRenderProps.allowSubmit || isSubmitting || !research}
                                                    >
                                                        Start interview
                                                    </Button>
                                                </StackLayout>
                                            </StackLayout>
                                        </StackLayout>
                                    </FormElement>
                                )}
                            />
                        </div>

                        <StackLayout align={{ horizontal: 'end', vertical: 'middle' }} className="k-gap-2.5">
                            <span>Powered by:</span>
                            <IcanpreneurLogo className="k-mr-4" />
                        </StackLayout>
                    </StackLayout>
                </StackLayout>
            </div>
        </div>
    );
}
