import { Interview2Type, InterviewInsight, InterviewQualityAssessmentRecommendation } from '../services/interviewsV2Service';
import { ErrorWithOperationDisplayName } from './common';
import { dateTimeService } from './dateTimeService';
import { HttpException, HttpServiceBase, PagedResponse, RequestMethod } from './httpServiceBase';
import { ReducedUser } from './usersService';

export enum ResearchTypeV2 {
    ProblemDiscoveryAndValidation = 'ProblemDiscoveryAndValidation',
    WinLoss = 'WinLoss',
    BuyerPersona = 'BuyerPersona',
    Other = 'Other'
}
export type CreateResearchV2Data = {
    name: string;
    type: ResearchTypeV2;
    customerSegmentId: number;
    interviewScriptId: number;
};

export enum ResearchV2Status {
    NotStarted = 'NotStarted',
    InProgress = 'InProgress',
    Complete = 'Complete'
}

export type ResearchV2 = CreateResearchV2Data & {
    id: number;
    status: ResearchV2Status;
    publicCode: string;
    aiLedInterviewsEnabled: boolean;
    contributors: ReducedUser[];
    lastActivity: Date;
    notStartedCount: number;
    inProgressCount: number;
    completeCount: number;
    interviewCount: number;
};

export type ReducedResearchV2 = {
    id: number;
    name: string;
};

export type UpdateResearchV2Data = Pick<ResearchV2, 'name' | 'interviewScriptId' | 'aiLedInterviewsEnabled'>;

export type PublicResearch = {
    name: string;
    type: ResearchTypeV2;
    ideaTitle: string;
    interviewScriptDurationInMinutes: number | null;
};

export enum ResearchAnalysisStatusV2 {
    NotStarted = 'NotStarted',
    Pending = 'Pending',
    Complete = 'Complete',
    Error = 'Error'
}

export type ResearchAnalysisV2 = {
    interviewCount: number;
    status: ResearchAnalysisStatusV2;
    insightSummaryItems: ResearchV2InsightSummaryItem[];
    qualitySummary: ResearchV2QualitySummary | null;
    scriptCoverage: ResearchV2ScriptCoverage | null;
    pinnedInsightCategories: string[];
};

export type ResearchV2InsightSummaryItem = {
    title: string;
    content: string;
};

export type ResearchV2QualitySummary = {
    score: number;
    text: string;
};

export type ResearchV2ScriptCoverage = {
    coverage: number;
    text: string;
};

export type ResearchV2InsightCategory = {
    id: number;
    name: string;
    description: string;
    insights: ResearchV2Insight[];
};

export type ResearchV2Insight = {
    id: number;
    title: string;
    insight: string;
    contributingInsights: ResearchV2ContributingInsight[];
    contributingInterviewCount: number;
};

export type ResearchV2ContributingInsight = {
    interviewId: number;
    interviewTitle: string;
    insights: InterviewInsight[];
};

export type ResearchV2QualityAssessmentArea = {
    id: number;
    title: string;
    text: string;
    percentageImprovement: number;
    recommendations: ResearchV2QualityAssessmentRecommendation[];
};

export type ResearchV2QualityAssessmentRecommendation = {
    id: number;
    title: string;
    recommendation: string;
    contributingRecommendations: ResearchV2ContributingRecommendation[];
    contributingInterviewCount: number;
};

export type ResearchV2ContributingRecommendation = {
    interviewId: number;
    interviewTitle: string;
    recommendations: InterviewQualityAssessmentRecommendation[];
};

export function mapResearchTypeToInterviewType(researchType: ResearchTypeV2): Interview2Type {
    switch (researchType) {
        case ResearchTypeV2.ProblemDiscoveryAndValidation:
            return Interview2Type.ProblemDiscoveryAndValidation;
        case ResearchTypeV2.WinLoss:
            return Interview2Type.WinLoss;
        case ResearchTypeV2.BuyerPersona:
            return Interview2Type.BuyerPersona;
        case ResearchTypeV2.Other:
            return Interview2Type.Other;
    }
}

class ResearchV2Service extends HttpServiceBase {
    constructor() {
        super('/api/research2');
    }

    private static ensureResearchDateFields(research: ResearchV2): ResearchV2 {
        dateTimeService.ensureDateField(research, 'lastActivity');
        return research;
    }

    @ErrorWithOperationDisplayName('Get research list')
    getResearchList(ideaId: string, afterId?: number, skip?: number, take?: number): Promise<PagedResponse<'research', ResearchV2>> {
        const queryParams: URLSearchParams = new URLSearchParams();
        this.addQueryParamIfPresent(queryParams, 'afterId', afterId?.toString());
        this.addQueryParamIfPresent(queryParams, 'skip', skip?.toString());
        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());

        return this.performRequest<PagedResponse<'research', ResearchV2>>({
            path: `/${ideaId}/research`
        }).then(r => {
            r.research.forEach(ResearchV2Service.ensureResearchDateFields);
            return r;
        });
    }

    @ErrorWithOperationDisplayName('Get full research list')
    async getFullResearchList(ideaId: string): Promise<ResearchV2[]> {
        const researchList: ResearchV2[] = [];
        let afterId: number | undefined;
        while (true) {
            const currentPageResearch = await this.getResearchList(ideaId, afterId);
            researchList.push(...currentPageResearch.research);
            if (!currentPageResearch.research.length || researchList.length === currentPageResearch.totalCount) return researchList;
            afterId = currentPageResearch.research.at(-1)!.id;
        }
    }

    @ErrorWithOperationDisplayName('Get research')
    getResearch(ideaId: string, researchId: number): Promise<ResearchV2> {
        return this.performRequest<ResearchV2>({
            path: `/${ideaId}/research/${researchId}`
        }).then(ResearchV2Service.ensureResearchDateFields);
    }

    @ErrorWithOperationDisplayName('Create research')
    createResearch(ideaId: string, data: CreateResearchV2Data): Promise<ResearchV2> {
        return this.performRequest<ResearchV2>({
            path: `/${ideaId}/research`,
            method: RequestMethod.POST,
            body: data
        }).then(ResearchV2Service.ensureResearchDateFields);
    }

    @ErrorWithOperationDisplayName('Update research')
    partiallyUpdateResearch(ideaId: string, id: number, data: Partial<UpdateResearchV2Data>): Promise<ResearchV2> {
        return this.performRequest<ResearchV2>({
            path: `/${ideaId}/research/${id}`,
            method: RequestMethod.PATCH,
            body: data
        }).then(ResearchV2Service.ensureResearchDateFields);
    }

    @ErrorWithOperationDisplayName('Delete research')
    deleteResearch(ideaId: string, researchId: number): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/research/${researchId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Update interviews in research')
    updateIncludedInterviewsInResearch(
        ideaId: string,
        researchId: number,
        interviews: { interviewsToAdd?: number[]; interviewsToRemove?: number[] }
    ): Promise<number[]> {
        return this.performRequest({
            path: `/${ideaId}/research/${researchId}/interviews`,
            method: RequestMethod.POST,
            body: {
                idsToAdd: interviews.interviewsToAdd ?? [],
                idsToRemove: interviews.interviewsToRemove ?? []
            }
        });
    }

    @ErrorWithOperationDisplayName('Get research analysis overview')
    getResearchOverviewAnalysis(ideaId: string, researchId: number): Promise<ResearchAnalysisV2 | null> {
        return this.performRequest<ResearchAnalysisV2>({
            path: `/${ideaId}/research/${researchId}/analysis/overview`
        }).catch(e => {
            if (e instanceof HttpException && e.status === 404) return null;

            return Promise.reject(e);
        });
    }

    @ErrorWithOperationDisplayName('Get research analysis insights')
    getResearchInsights(ideaId: string, researchId: number): Promise<ResearchV2InsightCategory[]> {
        return this.performRequest({
            path: `/${ideaId}/research/${researchId}/analysis/insights`
        });
    }

    @ErrorWithOperationDisplayName('Get research analysis quality assessment')
    getResearchQualityAssessment(ideaId: string, researchId: number): Promise<ResearchV2QualityAssessmentArea[]> {
        return this.performRequest({
            path: `/${ideaId}/research/${researchId}/analysis/quality`
        });
    }

    @ErrorWithOperationDisplayName('Pin insight category')
    pinResearchInsightCategory(ideaId: string, researchId: number, categoryName: string): Promise<string[]> {
        return this.performRequest<{ pinnedInsightCategories: string[] }>({
            path: `/${ideaId}/research/${researchId}/analysis/pinned-insight-categories`,
            method: RequestMethod.POST,
            body: { text: categoryName }
        }).then(r => r.pinnedInsightCategories);
    }

    @ErrorWithOperationDisplayName('Start research analysis')
    runAnalysis(ideaId: string, researchId: number): Promise<ResearchAnalysisV2> {
        return this.performRequest({
            path: `/${ideaId}/research/${researchId}/analysis`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Get research for interview')
    getInterviewCompatibleResearch(
        ideaId: string,
        interviewId: number,
        filter?: string,
        afterId?: number,
        skip?: number,
        take?: number
    ): Promise<ResearchV2[]> {
        const queryParams = new URLSearchParams();
        this.addQueryParamIfPresent(queryParams, 'filter', filter);
        this.addQueryParamIfPresent(queryParams, 'afterId', afterId?.toString());
        this.addQueryParamIfPresent(queryParams, 'skip', skip?.toString());
        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());

        return this.performRequest<PagedResponse<'research', ResearchV2>>({
            path: `/api/interviews3/${ideaId}/interviews/${interviewId}/compatible-research`,
            queryParams,
            ignoreBaseUrl: true
        }).then(r => {
            r.research.forEach(ResearchV2Service.ensureResearchDateFields);
            return r.research;
        });
    }

    getResearchInterviewPublicUrl(publicCode: string) {
        return `/interviews/${publicCode}/start`;
    }
}

class PublicResearchService extends HttpServiceBase {
    constructor() {
        super('/api/research2');
    }

    getResearch(publicCode: string): Promise<PublicResearch> {
        return this.performRequest({
            path: `/${publicCode}`
        });
    }
}

export const researchV2Service = new ResearchV2Service();
export const publicResearchService = new PublicResearchService();
