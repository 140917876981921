import { pdf } from '@progress/kendo-drawing';
import { changeTextBlock } from '@progress/kendo-editor-common';
import { Button } from '@progress/kendo-react-buttons';
import { EditorTools, EditorToolsSettings } from '@progress/kendo-react-editor';
import { PageTemplateProps, PDFExportProps, savePDF } from '@progress/kendo-react-pdf';
import { Fragment, Node } from 'prosemirror-model';
import { NodeSelection, Selection, TextSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { useMemo } from 'react';
import { ReactComponent as Logo } from '../../../images/logo.svg';

const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    Link,
    Unlink,
    InsertTable
} = EditorTools;

export interface BookmarkTitle {
    title: string;
    indentation?: number;
}

export function nodeAtSelection(selection: Selection) {
    let selectedNode: Node | null = null;
    if (selection instanceof NodeSelection) {
        selectedNode = selection.node;
    } else {
        selectedNode = selection.$anchor.parent;
    }
    return selectedNode;
}

export function getDocLevelSectionIdxOfSelection(doc: Node, selection: Selection) {
    const selectedNode = nodeAtSelection(selection);
    let resultHeadingIdx: number = -1;
    let curHeadingIdx = -1;

    doc.nodesBetween(0, doc.content.size, (node, pos, parent, index) => {
        if (node === selectedNode && node.type.name === 'heading' && parent?.type.name === 'doc') {
            resultHeadingIdx = curHeadingIdx + 1;
            return false;
        }

        if (node === selectedNode) {
            resultHeadingIdx = curHeadingIdx;
            return false;
        }

        if (node.type.name === 'heading' && parent?.type.name === 'doc') {
            curHeadingIdx++;
        }
        return true;
    });
    return resultHeadingIdx;
}

export function switchHeadingAndQuestionAtSelection(toHeading: boolean, view: EditorView) {
    const newTransaciton = view.state.tr;
    const selectedNode = nodeAtSelection(view.state.selection);
    if (!selectedNode) return;
    const schema = view.state.schema;
    const switchType = toHeading ? schema.nodes.heading : schema.nodes.paragraph;
    const switchAttrs = toHeading ? { ...selectedNode.attrs, level: 2 } : {};
    changeTextBlock(newTransaciton, selectedNode, switchType, switchAttrs);
    if (newTransaciton.docChanged) {
        view.dispatch(newTransaciton);
        view.focus();
    }
}

export function duplicateNodeAtSelection(view: EditorView) {
    const selection = view.state.selection;

    const selectedNode = nodeAtSelection(selection);

    //COPY fragment to generate node with different reference
    let copiedContent: Fragment | null = Fragment.fromJSON(view.state.schema, selectedNode.content.toJSON());
    if (copiedContent.size === 0) {
        copiedContent = null;
    }

    let baseNodePosition = selection instanceof NodeSelection ? selection.$anchor.pos : selection.$anchor.pos - selection.$anchor.parentOffset - 1;

    const newNode = selectedNode.copy(copiedContent);
    const tr = view.state.tr.insert(baseNodePosition + selectedNode.nodeSize, newNode);
    view.dispatch(tr);
    view.focus();
}

export function deleteNodeAtSelection(view: EditorView) {
    const tr = view.state.tr.deleteSelection();
    view.dispatch(tr);
    view.focus();
}

export function scrollToHeading(editorView: EditorView, headingIdx: number) {
    if (!editorView) return;
    editorView.focus();
    const newTransaction = editorView.state.tr;
    const state = editorView.state;
    const hNodePosition = sectionIndexToDocPos(state.doc, headingIdx);
    const selection = TextSelection.create(state.doc, hNodePosition + 1);
    const tr = newTransaction.setSelection(selection).scrollIntoView();
    editorView.dispatch(tr);
    editorView.focus();

    const coords = editorView.coordsAtPos(hNodePosition);
    const toolbar = document.querySelector('.k-editor-toolbar');
    const marginPx = 12;
    if (toolbar) {
        const toolbarOffset = toolbar.getBoundingClientRect().bottom;
        editorView.dom.scrollBy({
            top: coords.top - toolbarOffset - marginPx,
            behavior: 'smooth'
        });
    } else {
        editorView.dom.scrollBy({
            top: coords.top - marginPx,
            behavior: 'smooth'
        });
    }
}

function sectionIndexToDocPos(doc: Node, sectionIdx: number) {
    let resultNodePosition = -1;
    let curHeadingIdx = 0;
    let found = false;
    doc.nodesBetween(0, doc.content.size, (node, pos, parent, index) => {
        if (node.type.name === 'heading' && curHeadingIdx === sectionIdx && !found) {
            resultNodePosition = pos;
            found = true;
            return false;
        }

        if (node.type.name === 'heading') {
            curHeadingIdx++;
        }
        return false;
    });

    return resultNodePosition;
}

export function usePdfExportTool(scriptTitle: string | null | undefined) {
    return useMemo(() => PdfExportTool.bind(undefined, scriptTitle), [scriptTitle]);
}

export const PdfExportTool = (scriptTitle: string | null | undefined, props: any) => {
    const { view }: { view: EditorView } = props;

    scriptTitle = scriptTitle || 'Interview Script';

    const pdfExportProps: PDFExportProps = {
        fileName: `${scriptTitle.toLocaleLowerCase().replaceAll(' ', '-')}.pdf`,
        title: scriptTitle,
        creator: 'Icanpreneur (https://www.icanpreneur.com)',
        margin: '1in',
        paperSize: 'A4',
        date: new Date(),
        pageTemplate: (props: PageTemplateProps) => {
            return (
                <>
                    {/* Header */}
                    <div
                        className="k-display-flex k-justify-content-between k-fs-xs k-align-items-center k-pos-absolute"
                        style={{ top: '20px', left: '70px', right: '70px' }}
                    >
                        <div>
                            <Logo className="top-nav-logo-image" />
                        </div>
                        <div className="k-text-disabled">
                            <a href="https://www.icanpreneur.com">www.icanpreneur.com</a>
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="k-display-flex k-justify-content-center k-text-disabled k-fs-xs k-mt-5">
                        {props.pageNum} of {props.totalPages}
                    </div>
                </>
            );
        }
    };

    const onClick = () => {
        view.dom.classList.add('k-pdf-export');

        pdf.defineFont({
            'sans-serif': process.env.PUBLIC_URL + '/fonts/Roboto/Roboto-Regular.ttf',
            'sans-serif|Bold': process.env.PUBLIC_URL + '/fonts/Roboto/Roboto-Bold.ttf',
            'sans-serif|Italic': process.env.PUBLIC_URL + '/fonts/Roboto/Roboto-Italic.ttf',
            'sans-serif|BoldItalic': process.env.PUBLIC_URL + '/fonts/Roboto/Roboto-BoldItalic.ttf'
        });
        savePDF(view.dom, pdfExportProps);
        view.dom.classList.remove('k-pdf-export');
    };

    return <Button iconClass="k-icon k-i-file-pdf k-button-icon" title="Export as PDF" onClick={onClick} />;
};

export const tableRegex = /^table$/;
export const rowRegex = /^table_row$/;
export const cellRegex = /^table_cell|table_header$/;
export const closest = function(selection: Selection, name: RegExp) {
    var pos = selection.$head;
    for (var i = pos.depth; i > 0; i--) {
        var node = pos.node(i);
        if (name.test(node.type.name)) {
            return {
                pos: pos.before(i),
                node: node
            };
        }
    }
    return null;
};

const fontSizeToolSettings: EditorToolsSettings.StyleDropDownListSettings = {
    ...EditorToolsSettings.fontSize,
    defaultItem: { text: 'Size', value: '' },
    items: [
        { text: '10', value: '10px' },
        { text: '11', value: '11px' },
        { text: '12', value: '12px' },
        { text: '14', value: '14px' },
        { text: '16', value: '16px' },
        { text: '18', value: '18px' },
        { text: '21', value: '21px' },
        { text: '24', value: '24px' },
        { text: '30', value: '30px' },
        { text: '36', value: '36px' },
        { text: '48', value: '48px' }
    ]
};

export const customFormatBlockToolSettings: EditorToolsSettings.FormatBlockDropDownListSettings = {
    ...EditorToolsSettings.formatBlock,
    items: [
        { text: 'Paragraph', value: 'p', style: { display: 'block', marginLeft: 0 } },
        { text: 'Heading 1', value: 'h1', style: { display: 'block', fontSize: '24px', marginLeft: 0, fontWeight: 'bold' } },
        { text: 'Heading 2', value: 'h2', style: { display: 'block', fontSize: '21px', marginLeft: 0, fontWeight: 'bold' } },
        { text: 'Heading 3', value: 'h3', style: { display: 'block', fontSize: '18px', marginLeft: 0, fontWeight: 'bold' } },
        { text: 'Heading 4', value: 'h4', style: { display: 'block', fontSize: '16px', marginLeft: 0, fontWeight: 'bold' } },
        { text: 'Heading 5', value: 'h5', style: { display: 'block', fontSize: '14px', marginLeft: 0, fontWeight: 'bold' } },
        { text: 'Heading 6', value: 'h6', style: { display: 'block', fontSize: '12px', marginLeft: 0, fontWeight: 'bold' } }
    ]
};

const CustomFontSize = EditorTools.createStyleDropDownList(fontSizeToolSettings);
const CustomFormatBlock = EditorTools.createFormatBlockDropDownList(customFormatBlockToolSettings);

export const UndoEnhanced = (props: EditorTools.UndoProps) => <Undo {...props} />;
export const RedoEnhanced = (props: EditorTools.RedoProps) => <Redo {...props} />;
export const FontSizeEnhanced = (props: EditorTools.FontSizeProps) => <CustomFontSize {...props} style={{ width: 80 }} />;
export const FormatBlockEnhanced = (props: EditorTools.FormatBlockProps) => (
    <CustomFormatBlock {...props} style={{ width: 130 }} popupSettings={{ width: 'auto' }} />
);
export const BoldEnhanced = (props: EditorTools.BoldProps) => <Bold {...props} />;
export const ItalicEnhanced = (props: EditorTools.ItalicProps) => <Italic {...props} />;
export const UnderlineEnhanced = (props: EditorTools.UnderlineProps) => <Underline {...props} />;
export const StrikethroughEnhanced = (props: EditorTools.StrikethroughProps) => <Strikethrough {...props} />;
export const AlignLeftEnhanced = (props: EditorTools.AlignLeftProps) => <AlignLeft {...props} />;
export const AlignCenterEnhanced = (props: EditorTools.AlignCenterProps) => <AlignCenter {...props} />;
export const AlignRightEnhanced = (props: EditorTools.AlignRightProps) => <AlignRight {...props} />;
export const AlignJustifyEnhanced = (props: EditorTools.AlignJustifyProps) => <AlignJustify {...props} />;
export const OrderedListEnhanced = (props: EditorTools.OrderedListProps) => <OrderedList {...props} />;
export const UnorderedListEnhanced = (props: EditorTools.UnorderedListProps) => <UnorderedList {...props} />;
export const LinkEnhanced = (props: EditorTools.LinkProps) => <Link {...props} />;
export const UnlinkEnhanced = (props: EditorTools.UnlinkProps) => <Unlink {...props} />;
export const InsertTableEnhanced = (props: EditorTools.InsertTableProps) => <InsertTable {...props} />;
