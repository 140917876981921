import { Dialog } from '@progress/kendo-react-dialogs';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { NotFoundException } from '../../services/httpServiceBase';
import { InterviewStageV2, InterviewV2, IvaChatInterviewDetailV2 } from '../../services/interviewsV2Service';
import { AIAvatar } from '../ai/aiAvatar';
import { ChatView } from '../ai/chatView';
import { InterviewTranscriptView } from '../ai/transcriptView';
import LoadingIndicator from '../ui/loadingIndicator';
import { TypingDots } from '../ui/typingDots';
import { H1 } from '../ui/typography';
import { InterviewProgress, InterviewStatusIndicator, InterviewViewers, StickyStamp } from './common';

export function AILedInterviewViewModal({ ideaId, interview, onClose }: { ideaId: string; interview?: InterviewV2; onClose?: () => void }) {
    if (interview && !interview.aiLed) throw new NotFoundException();

    const intervieweeName =
        interview &&
        ((interview.contact ? `${interview.contact.firstName} ${interview.contact.lastName}` : interview.intervieweeName) || 'Unknown interviewee');
    const interviewDetails = interview && (interview.detail as IvaChatInterviewDetailV2);

    return (
        <Dialog
            className="k-icp-dialog-maximized k-icp-dialog-no-padding k-icp-dialog-with-title-shadow"
            title={
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-justify-content-between k-w-full">
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-min-w-0">
                        <div className="k-text-ellipsis">
                            Interview with {intervieweeName ?? <Skeleton shape="text" style={{ width: 200 }} className="!k-display-inline-block" />}
                        </div>
                        {interview &&
                            (interview.stage === InterviewStageV2.Completed ? (
                                <InterviewStatusIndicator text="Completed" type="success" />
                            ) : (
                                <InterviewStatusIndicator text="Pending interview" type="warning" />
                            ))}
                    </StackLayout>

                    {interview && interview.stage !== InterviewStageV2.Completed && (
                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-mr-4 k-icp-component-border">
                            <InterviewProgress progressPercentage={interviewDetails?.percentCompleted ?? 0} />
                            <InterviewViewers interviewId={interview.id} addSeparator />
                        </StackLayout>
                    )}
                </StackLayout>
            }
            onClose={onClose}
        >
            {!interview ? (
                <LoadingIndicator size="big" className="k-centered" />
            ) : interview.stage === InterviewStageV2.Completed ? (
                <CompletedAILedInterviewView ideaId={ideaId} interviewId={interview.id} />
            ) : interviewDetails && interviewDetails.chat && interviewDetails.chat.initialized ? (
                <ChatView ideaId={ideaId} chatId={interviewDetails.chat.id} chatTag={interviewDetails.chat.tag} />
            ) : (
                <PendingInterviewView />
            )}
        </Dialog>
    );
}

function CompletedAILedInterviewView({ ideaId, interviewId }: { ideaId: string; interviewId: number }) {
    return (
        <div style={{ width: '720px' }} className="page-content-middle k-px-4 k-pb-4">
            <StickyStamp text="Interview transcript" />
            <InterviewTranscriptView ideaId={ideaId} interviewId={interviewId} />
        </div>
    );
}

function PendingInterviewView() {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-w-full k-h-full k-gap-3">
            <AIAvatar size={184} borderSize="k-border-2" animate iconSize={120} />
            <H1 className="ai-text-color">
                Waiting for the contact to start the interview
                <TypingDots alwaysUseSpace />
            </H1>
        </StackLayout>
    );
}
