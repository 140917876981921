import { useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ideasService } from '../../services/ideasService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setIdea } from '../../state/idea/ideaSlice';

export function SetStartupFlagPage() {
    const idea = useAppSelector(s => s.idea.current);
    const { flag, flagState } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!idea || !flag || (flagState !== 'on' && flagState !== 'off')) {
            return;
        }

        (async () => {
            const flags = idea.flags as string[];

            if (flagState === 'on') {
                await ideasService.setFlag(idea.uniqueId, flag);
                if (!flags.includes(flag)) dispatch(setIdea({ ...idea, flags: [...flags, flag] as any }));
            } else if (flagState === 'off') {
                await ideasService.unsetFlag(idea.uniqueId, flag);
                if (flags.includes(flag)) dispatch(setIdea({ ...idea, flags: flags.filter(f => f !== flag) as any }));
            }

            navigate('/');
        })();
    }, [flag, flagState, navigate, idea, dispatch]);

    if (flagState !== 'on' && flagState !== 'off') {
        return <Navigate to="/404" />;
    }

    return <></>;
}
