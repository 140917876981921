import { ParsedEvent } from 'eventsource-parser/stream';
import { ErrorWithOperationDisplayName } from './common';
import { ReducedPerson } from './contactsService';
import { dateTimeService } from './dateTimeService';
import { HttpServiceBase, PagedResponse, RequestMethod, SortDirection } from './httpServiceBase';
import { ReducedUser } from './usersService';

export enum ChatMessageType {
    User = 'User',
    Agent = 'Agent',
    Contact = 'Contact'
}

export enum ChatMessageBlockType {
    Text = 'Text',
    Options = 'Options',
    Canvas = 'Canvas',
    GoToJourney = 'GoToJourney',
    Tracking = 'Tracking',
    ChatEnd = 'ChatEnd',
    FileUpload = 'FileUpload'
}

interface RawChatMessageBlock {
    type: ChatMessageBlockType;
    content: string;
}

type ChatMessageBlockBase<TType extends ChatMessageBlockType, TData> = {
    type: TType;
    data: TData;
};

export type ChatMessageOption = { index: number; content: string };
export type ChatMessageBlockDataMap = {
    [ChatMessageBlockType.Text]: string;
    [ChatMessageBlockType.Options]: ChatMessageOption[];
    [ChatMessageBlockType.Tracking]: string;
    [ChatMessageBlockType.Canvas]: unknown;
    [ChatMessageBlockType.GoToJourney]: unknown;
    [ChatMessageBlockType.ChatEnd]: unknown;
    [ChatMessageBlockType.FileUpload]: unknown;
};
export type ChatMessageBlockTypeMap = {
    [K in ChatMessageBlockType]: ChatMessageBlockBase<K, ChatMessageBlockDataMap[K]>;
};
export type ChatMessageBlock = ChatMessageBlockTypeMap[ChatMessageBlockType];

function isRawMessageBlock(block: RawChatMessageBlock | ChatMessageBlock): block is RawChatMessageBlock {
    return 'content' in block;
}

type ChatMessageBase = {
    id: number;
    createdOn: Date;
    updatedOn?: Date;
};

export type AgentChatMessage = ChatMessageBase & {
    type: ChatMessageType.Agent;
    blocks: ChatMessageBlock[];
};

export type UserChatMessage = ChatMessageBase & {
    type: ChatMessageType.User;
    user: ReducedUser | null;
    content: string;
    file?: ChatMessageFile | null;
};

export type ContactChatMessage = ChatMessageBase & {
    type: ChatMessageType.Contact;
    contact: ReducedPerson | null;
    content: string;
    file?: ChatMessageFile | null;
};

export type ChatMessage = AgentChatMessage | UserChatMessage | ContactChatMessage;

const chatStreamMessageTypes = ['block', 'userMessage', 'agentMessage', 'contactMessage', 'chat', 'progress'] as const;
export type ChatStreamMessageType = typeof chatStreamMessageTypes[number];
type ChatStreamMessagesTypeMap = {
    block: ChatMessageBlock;
    userMessage: UserChatMessage;
    agentMessage: AgentChatMessage;
    contactMessage: ContactChatMessage;
    chat: Chat;
    progress: number;
};

export type ChatStreamMessage = {
    [K in ChatStreamMessageType]: {
        type: K;
        data: ChatStreamMessagesTypeMap[K];
    };
}[ChatStreamMessageType];

export interface Chat {
    id: number;
    tag: string;
    initialized: boolean;
    closed: boolean;
}

const defaultMessageBlockParser = (content: string) => content;
const chatMessageBlockParsers: {
    [K in ChatMessageBlockType]: (content: string) => ChatMessageBlockDataMap[K];
} = {
    [ChatMessageBlockType.Text]: defaultMessageBlockParser,
    [ChatMessageBlockType.Canvas]: defaultMessageBlockParser,
    [ChatMessageBlockType.GoToJourney]: defaultMessageBlockParser,
    [ChatMessageBlockType.Options]: content => {
        const xmlParser = new DOMParser();
        const optionsDoc = xmlParser.parseFromString(content, 'application/xml');
        const optionsElements = optionsDoc.documentElement.children;
        const options = Array.from(optionsElements).map<ChatMessageOption>(option => ({
            index: parseInt(option.getAttribute('index')!),
            content: option.textContent!.trim()
        }));

        return options;
    },
    [ChatMessageBlockType.Tracking]: content => {
        const xmlParser = new DOMParser();
        const trackingDoc = xmlParser.parseFromString(content, 'application/xml');
        return trackingDoc.documentElement.textContent!;
    },
    [ChatMessageBlockType.ChatEnd]: defaultMessageBlockParser,
    [ChatMessageBlockType.FileUpload]: defaultMessageBlockParser
};

export type ChatMessageFile = { id: number; name: string; key: string };
export type NewChatMessage = string | { fileId: number };

class ChatsService extends HttpServiceBase {
    constructor() {
        super('/api/chats');
    }

    private static ensureChatMessageDateFields(message: ChatMessage) {
        dateTimeService.ensureDateField(message, 'createdOn');
        dateTimeService.ensureDateField(message, 'updatedOn');
    }

    private static parseChatMessageBlock(block: ChatMessageBlock | RawChatMessageBlock): ChatMessageBlock {
        if (!isRawMessageBlock(block)) return block;

        const blockParser = chatMessageBlockParsers[block.type];
        const blockData = blockParser(block.content);

        return {
            type: block.type,
            data: blockData
        } as ChatMessageBlock;
    }

    private static parseChatMessageBlocks(message: ChatMessage) {
        if (!('blocks' in message)) return;
        message.blocks = message.blocks.map(ChatsService.parseChatMessageBlock);
    }

    private static parseChatMessageFields(message: ChatMessage) {
        ChatsService.ensureChatMessageDateFields(message);
        ChatsService.parseChatMessageBlocks(message);
    }

    @ErrorWithOperationDisplayName('Get chat messages')
    async getChatMessages(
        ideaId: string,
        tag: string,
        order?: SortDirection,
        afterId?: number,
        skip?: number,
        take?: number
    ): Promise<PagedResponse<'messages', ChatMessage>> {
        const queryParams = new URLSearchParams();

        this.addQueryParamIfPresent(queryParams, 'order', order);
        this.addQueryParamIfPresent(queryParams, 'afterId', afterId?.toString());
        this.addQueryParamIfPresent(queryParams, 'skip', skip?.toString());
        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());

        return this.performRequest<PagedResponse<'messages', ChatMessage>>({
            path: `/${ideaId}/${tag}/messages`,
            queryParams
        }).then(r => {
            r.messages.forEach(ChatsService.parseChatMessageFields);
            return r;
        });
    }

    @ErrorWithOperationDisplayName('Get all chat messages')
    async getAllChatMessages(ideaId: string, tag: string): Promise<ChatMessage[]> {
        const allMessages: ChatMessage[] = [];
        let afterId: number | undefined;
        while (true) {
            const currentPageMessages = await this.getChatMessages(ideaId, tag, SortDirection.Asc, afterId);
            allMessages.push(...currentPageMessages.messages);
            if (!currentPageMessages.messages.length || allMessages.length === currentPageMessages.totalCount) return allMessages;
            afterId = currentPageMessages.messages.at(-1)!.id;
        }
    }

    @ErrorWithOperationDisplayName('Get chat message')
    async getChatMessage(ideaId: string, tag: string, messageId: number): Promise<ChatMessage> {
        const message = await this.performRequest<ChatMessage>({
            path: `/${ideaId}/${tag}/messages/${messageId}`
        });

        ChatsService.parseChatMessageFields(message);

        return message;
    }

    @ErrorWithOperationDisplayName('Get chat')
    getChat(ideaId: string, tag: string): Promise<Chat> {
        return this.performRequest<Chat>({
            path: `/${ideaId}/${tag}`
        });
    }

    @ErrorWithOperationDisplayName('Initialize chat')
    initializeChat(ideaId: string, tag: string): Promise<Chat> {
        return this.performRequest<Chat>({
            path: `/${ideaId}/${tag}/initialize`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Fix chat')
    async *fixChat(ideaId: string, tag: string): AsyncIterable<ChatStreamMessage> {
        const response = await this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/${tag}/fix`,
            method: RequestMethod.POST,
            headers: {
                Accept: 'text/event-stream'
            }
        });

        const eventsIterator = this.processSseResponse(response);
        return yield* this.parseChatMessagesStream(eventsIterator);
    }

    @ErrorWithOperationDisplayName('Send message to chat')
    async *sendMessage(ideaId: string, tag: string, message: NewChatMessage): AsyncIterable<ChatStreamMessage> {
        const isFile = typeof message === 'object' && 'fileId' in message;
        const response = await this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/${tag}/messages/${isFile ? 'file' : ''}`,
            method: RequestMethod.POST,
            body: isFile ? message : { text: message },
            headers: {
                Accept: 'text/event-stream'
            }
        });
        const eventsIterator = this.processSseResponse(response);
        return yield* this.parseChatMessagesStream(eventsIterator);
    }

    @ErrorWithOperationDisplayName('Finish chat')
    finishChat(ideaId: string, tag: string): Promise<Chat> {
        return this.performRequest<Chat>({
            path: `/${ideaId}/${tag}/finish`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Upload file')
    uploadFile(ideaId: string, file: File): Promise<ChatMessageFile> {
        const formData = new FormData();
        formData.append('file', file);

        return this.performRequest({
            path: `/${ideaId}/files`,
            method: RequestMethod.POST,
            body: formData
        });
    }

    private async *parseChatMessagesStream(eventsIterator: AsyncIterable<ParsedEvent>) {
        for await (const event of eventsIterator) {
            if (!this.isValidStreamMessageType(event.event)) throw new Error('Unknown stream event type: ' + event.event);
            let messageData = JSON.parse(event.data);
            if (event.event === 'agentMessage' || event.event === 'userMessage' || event.event === 'contactMessage')
                ChatsService.parseChatMessageFields(messageData);
            else if (event.event === 'block') messageData = ChatsService.parseChatMessageBlock(messageData);
            yield { type: event.event, data: messageData };
        }
    }

    private isValidStreamMessageType(type?: string): type is ChatStreamMessageType {
        return type !== undefined && chatStreamMessageTypes.includes(type as any);
    }
}

export const chatsService = new ChatsService();

const PUBLIC_CHAT_ROUTE = 'public';
class PublicChatsService {
    @ErrorWithOperationDisplayName('Initialize chat')
    initializeChat(tag: string): Promise<Chat> {
        return chatsService.initializeChat(PUBLIC_CHAT_ROUTE, tag);
    }

    @ErrorWithOperationDisplayName('Get messages')
    getAllChatMessages(tag: string): Promise<ChatMessage[]> {
        return chatsService.getAllChatMessages(PUBLIC_CHAT_ROUTE, tag);
    }

    @ErrorWithOperationDisplayName('Send message')
    sendMessage(tag: string, message: NewChatMessage): AsyncIterable<ChatStreamMessage> {
        return chatsService.sendMessage(PUBLIC_CHAT_ROUTE, tag, message);
    }

    @ErrorWithOperationDisplayName('Fix message')
    fixChat(tag: string): AsyncIterable<ChatStreamMessage> {
        return chatsService.fixChat(PUBLIC_CHAT_ROUTE, tag);
    }

    @ErrorWithOperationDisplayName('Finish message')
    finishChat(tag: string): Promise<Chat> {
        return chatsService.finishChat(PUBLIC_CHAT_ROUTE, tag);
    }
}

export const publicChatsService = new PublicChatsService();
