import { EditorState, Plugin, PluginKey, TextSelection, Transaction } from 'prosemirror-state';
import { Step, Transform } from 'prosemirror-transform';
import { EditorView } from 'prosemirror-view';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAsRef } from '../../../hooks/commonHooks';
import { ChangesStatus } from '../../../hooks/textEditorSaveIndicator';
import { debounce } from '../../../services/common';
import { DocumentChange, DocumentChangeRequest, documentsService, EditorDocument } from '../../../services/documentsService';
import { HttpException } from '../../../services/httpServiceBase';
import { RealTimeUpdateDocumentEventData, realTimeUpdatesEventHub } from '../../../services/realTimeUpdatesService';

enum SCRIPT_EDITOR_STATE {
    SENDING_CHANGES = 'SENDING_CHANGES',
    REBASING_SERVER_CHANGES = 'REBASING_SERVER_CHANGES',
    WAITING_FOR_CHANGES = 'WAITING_FOR_CHANGES'
}

const DEBOUNCE_TIME_MS = 100;

export function useDocumentCollabBehavior(
    editorViewRef: React.MutableRefObject<EditorView | null>,
    ideaId: string,
    editorDocument: EditorDocument,
    onSavingStatusChange: ((status: ChangesStatus) => void) | undefined
) {
    const stepsToSendRef = useRef<Step[]>([]);
    const onSavingStatusChangeRef = useAsRef(onSavingStatusChange);
    const scriptEditorStateRef = useRef(SCRIPT_EDITOR_STATE.WAITING_FOR_CHANGES);

    const rebaseServerChanges = useCallback(
        async (view: EditorView) => {
            const docVersion = getVersion(view.state);
            const currentChanges = await documentsService.getDocumentChanges(ideaId, editorDocument.id, docVersion);
            if (currentChanges.length === 0) {
                scriptEditorStateRef.current = SCRIPT_EDITOR_STATE.WAITING_FOR_CHANGES;
                view.dispatch(receiveTransaction(view.state, [], docVersion));
                return;
            }
            const newSteps = currentChanges.flatMap(change => change.steps.map(stepJson => Step.fromJSON(view.state.schema, JSON.parse(stepJson))));
            const targetVersion = currentChanges[currentChanges.length - 1].targetVersion;
            const tr = receiveTransaction(view.state, newSteps, targetVersion);

            scriptEditorStateRef.current = SCRIPT_EDITOR_STATE.WAITING_FOR_CHANGES;
            view.dispatch(tr);
        },
        [ideaId, editorDocument.id]
    );

    useEffect(() => {
        const handleDocumentUpdated = (e: RealTimeUpdateDocumentEventData) => {
            if (!editorViewRef.current || e.ideaId !== ideaId || e.documentId !== editorDocument?.id) return;
            if (scriptEditorStateRef.current !== SCRIPT_EDITOR_STATE.WAITING_FOR_CHANGES) return;
            scriptEditorStateRef.current = SCRIPT_EDITOR_STATE.REBASING_SERVER_CHANGES;
            rebaseServerChanges(editorViewRef.current);
        };

        realTimeUpdatesEventHub.addEventListener('document', 'update', handleDocumentUpdated);
        return () => {
            realTimeUpdatesEventHub.removeEventListener('document', 'update', handleDocumentUpdated);
        };
    }, [ideaId, editorDocument?.id, rebaseServerChanges, editorViewRef]);

    const sendLatestChanges = useCallback(
        async (view: EditorView, stepsToSend: Step[]) => {
            const docVersion = getVersion(view.state);
            const changeRequest: DocumentChangeRequest = {
                sourceVersion: docVersion,
                steps: stepsToSend.map(step => JSON.stringify(step.toJSON()))
            };

            const change = await documentsService.applyDocumentChange(ideaId, editorDocument.id, changeRequest);
            const tr = clearUncommittedAndUpdateVersion(view.state, change);
            scriptEditorStateRef.current = SCRIPT_EDITOR_STATE.WAITING_FOR_CHANGES;
            view.dispatch(tr);
        },
        [editorDocument.id, ideaId]
    );

    const debouncedSendLatestChanges = useMemo(
        () =>
            debounce(async (view: EditorView) => {
                if (stepsToSendRef.current.length === 0 || scriptEditorStateRef.current !== SCRIPT_EDITOR_STATE.WAITING_FOR_CHANGES) return;

                onSavingStatusChangeRef.current?.(ChangesStatus.SendingChanges);
                scriptEditorStateRef.current = SCRIPT_EDITOR_STATE.SENDING_CHANGES;
                const stepsToSend = stepsToSendRef.current;
                try {
                    await sendLatestChanges(view, stepsToSend);
                } catch (error) {
                    if (error instanceof HttpException && error.status === 409) {
                        scriptEditorStateRef.current = SCRIPT_EDITOR_STATE.REBASING_SERVER_CHANGES;
                        await rebaseServerChanges(view);
                        scriptEditorStateRef.current = SCRIPT_EDITOR_STATE.WAITING_FOR_CHANGES;
                    } else {
                        throw error;
                    }
                } finally {
                    scriptEditorStateRef.current = SCRIPT_EDITOR_STATE.WAITING_FOR_CHANGES;
                    onSavingStatusChangeRef.current?.(ChangesStatus.ChangesSaved);
                }
            }, DEBOUNCE_TIME_MS),
        [onSavingStatusChangeRef, rebaseServerChanges, sendLatestChanges]
    );

    function onDispatchTransaction(newState: EditorState, view: EditorView) {
        let sendable = sendableSteps(newState);
        stepsToSendRef.current = sendable ? [...sendable.steps] : [];
        if (sendable) {
            onSavingStatusChange?.(ChangesStatus.PendingChanges);
            debouncedSendLatestChanges(view);
        }
    }

    return { onDispatchTransaction };
}

class Rebaseable {
    constructor(readonly step: Step, readonly inverted: Step, readonly origin: Transform) {}
}

class CollabState {
    constructor(readonly version: number, readonly unconfirmed: readonly Rebaseable[]) {}
}

type CollabConfig = {
    version?: number;
};

const collabKey = new PluginKey('collab');

/**
 * CollabPlugin encapsulates the collaborative editing functionality
 */
export class CollabPlugin {
    /**
     * Creates a plugin that enables the collaborative editing framework for the editor
     * @param config Configuration options for the collab plugin
     * @returns A ProseMirror plugin
     */
    static create(config: CollabConfig = {}): Plugin {
        let conf: Required<CollabConfig> = {
            version: config.version || 0
        };

        return new Plugin({
            key: collabKey,
            state: {
                init: () => new CollabState(conf.version, []),
                apply(tr, collab) {
                    const newState = tr.getMeta(collabKey);
                    if (newState) return newState;
                    if (tr.docChanged) return new CollabState(collab.version, collab.unconfirmed.concat(CollabPlugin.unconfirmedFrom(tr)));
                    return collab;
                }
            },

            config: conf,
            historyPreserveItems: true
        });
    }

    private static unconfirmedFrom(transform: Transform): Rebaseable[] {
        const result: Rebaseable[] = [];
        for (let i = 0; i < transform.steps.length; i++) {
            result.push(new Rebaseable(transform.steps[i], transform.steps[i].invert(transform.docs[i]), transform));
        }
        return result;
    }

    /**
     * Undo a given set of steps, apply a set of other steps, and then redo them
     * @param steps Steps to rebase
     * @param over Steps to apply
     * @param transform Transform to apply steps to
     * @returns Rebased steps
     */
    private static rebaseSteps(steps: readonly Rebaseable[], over: readonly Step[], transform: Transform): Rebaseable[] {
        for (let i = steps.length - 1; i >= 0; i--) transform.step(steps[i].inverted);
        for (let i = 0; i < over.length; i++) transform.step(over[i]);

        const result: Rebaseable[] = [];
        for (let i = 0, mapFrom = steps.length; i < steps.length; i++) {
            const mapped = steps[i].step.map(transform.mapping.slice(mapFrom));
            mapFrom--;
            if (mapped && !transform.maybeStep(mapped).failed) {
                (transform.mapping as any).setMirror(mapFrom, transform.steps.length - 1);
                result.push(new Rebaseable(mapped, mapped.invert(transform.docs[transform.docs.length - 1]), steps[i].origin));
            }
        }
        return result;
    }

    /**
     * Receive transaction from a remote source
     * @param state Current editor state
     * @param steps Steps to apply
     * @param version New version number
     * @returns A transaction that applies the steps
     */
    static receiveTransaction(state: EditorState, steps: readonly Step[], version: number): Transaction {
        const collabState = collabKey.getState(state) as CollabState;
        let unconfirmed = collabState.unconfirmed.slice(0);

        const nUnconfirmed = unconfirmed.length;
        const tr = state.tr;

        if (nUnconfirmed) {
            unconfirmed = CollabPlugin.rebaseSteps(unconfirmed, steps, tr);
        } else {
            for (let i = 0; i < steps.length; i++) tr.step(steps[i]);
            unconfirmed = [];
        }

        // If the current selection is a text selection, its
        // sides are mapped with a negative bias for this transaction, so
        // that content inserted at the cursor ends up after the cursor.
        tr.setSelection(
            TextSelection.between(tr.doc.resolve(tr.mapping.map(state.selection.anchor, -1)), tr.doc.resolve(tr.mapping.map(state.selection.head, -1)), -1)
        );

        // Set selection raises a flag that selection was updated.
        // The below code removes that flag
        (tr as any).updated &= ~1;

        return tr
            .setMeta('rebased', nUnconfirmed)
            .setMeta('addToHistory', false)
            .setMeta(collabKey, new CollabState(version, unconfirmed));
    }

    /**
     * Clear uncommitted changes and update version
     * @param state Current editor state
     * @param change Document change
     * @returns A transaction that updates the version
     */
    static clearUncommittedAndUpdateVersion(state: EditorState, change: DocumentChange): Transaction {
        const unconfirmed = (collabKey.getState(state) as CollabState).unconfirmed;
        return state.tr.setMeta(collabKey, new CollabState(change.targetVersion, unconfirmed.slice(change.steps.length)));
    }

    /**
     * Get sendable steps from the current state
     * @param state Current editor state
     * @returns Object containing version, steps, and origins, or null if no steps
     */
    static sendableSteps(
        state: EditorState
    ): {
        version: number;
        steps: readonly Step[];
        origins: readonly Transaction[];
    } | null {
        const collabState = collabKey.getState(state) as CollabState;
        if (collabState.unconfirmed.length === 0) return null;

        return {
            version: collabState.version,
            steps: collabState.unconfirmed.map(s => s.step),
            get origins() {
                return (this as any)._origins || ((this as any)._origins = collabState.unconfirmed.map(s => s.origin));
            }
        };
    }

    /**
     * Get the current version from the state
     * @param state Current editor state
     * @returns Current version number
     */
    static getVersion(state: EditorState): number {
        return (collabKey.getState(state) as CollabState).version;
    }
}

export const collab = CollabPlugin.create;
export const { receiveTransaction, clearUncommittedAndUpdateVersion, sendableSteps, getVersion } = CollabPlugin;
export { collabKey };
