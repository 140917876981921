import { ButtonGroup } from '@progress/kendo-react-buttons';
import { Editor, EditorTools } from '@progress/kendo-react-editor';
import { Plugin, PluginKey } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import React, { useCallback, useEffect } from 'react';
import { closest, tableRegex } from './common';

const { AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter, DeleteRow, DeleteColumn, DeleteTable, MergeCells, SplitCell } = EditorTools;

const TOOL_HEIGHT = 50;

const useToolbarOptionsPlugin = (editorRef: React.RefObject<Editor>) => {
    const [showTools, setShowTools] = React.useState(false);
    const [position, setPosition] = React.useState({});
    const toolsRef = React.useRef([
        [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
        [DeleteRow, DeleteColumn],
        [MergeCells, SplitCell],
        DeleteTable
    ]);

    const PROXIMITY_THRESHOLD = 52 + TOOL_HEIGHT;

    const calculateToolbarPosition = useCallback(
        (editorView: EditorView) => {
            const state = editorView.state;
            const editorRect = editorView.dom.getBoundingClientRect();
            const closestTableNode = closest(state.selection, tableRegex);

            if (!closestTableNode) return { showTools: false, position: {} };

            const start = editorView.coordsAtPos(closestTableNode.pos);
            const topPosition = start.top - editorRect.top;

            if (topPosition <= PROXIMITY_THRESHOLD) {
                const tableEndNode = editorView.coordsAtPos(closestTableNode.pos + closestTableNode.node.content.size);
                return {
                    showTools: true,
                    position: {
                        top: tableEndNode.bottom - editorRect.top,
                        right: window.innerWidth - start.right
                    }
                };
            }

            return {
                showTools: true,
                position: {
                    top: topPosition - TOOL_HEIGHT,
                    right: window.innerWidth - start.right
                }
            };
        },
        [PROXIMITY_THRESHOLD]
    );

    useEffect(() => {
        if (!showTools || !editorRef.current?.element) return;

        const editorTextArea = editorRef.current.contentElement;

        const handleScroll = () => {
            if (!editorRef.current?.view) return;
            const editorView = editorRef.current.view;

            const { showTools: newShowTools, position: newPosition } = calculateToolbarPosition(editorView);

            if (newShowTools) {
                setPosition(newPosition);
            }
        };

        const handleResize = () => {
            if (!editorRef.current?.view) return;
            const editorView = editorRef.current.view;

            const { showTools: newShowTools, position: newPosition } = calculateToolbarPosition(editorView);

            if (newShowTools) {
                setPosition(newPosition);
            }
        };

        editorTextArea?.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            editorTextArea?.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [showTools, editorRef, calculateToolbarPosition]);

    const toolbarOptionsPlugin = () =>
        new Plugin({
            key: new PluginKey('toolbar-options'),

            view: () => ({
                update: (newView, _prevState) => {
                    const { showTools: newShowTools, position: newPosition } = calculateToolbarPosition(newView);
                    setPosition(newPosition);
                    setShowTools(newShowTools);
                }
            })
        });

    const renderToolsFn = useCallback(() => {
        if (!editorRef.current?.view) return null;
        return renderTools(editorRef.current.view, showTools, toolsRef.current, position);
    }, [editorRef, position, showTools]);

    return { toolbarOptionsPlugin, renderTools: renderToolsFn };
};

function renderTools(editorView: EditorView, showTools: boolean, tools: any[], position: { top?: number; left?: number; right?: number; bottom?: number }) {
    return (
        showTools && (
            <span
                className="k-toolbar k-toolbar-md k-d-flex k-flex-row k-rounded-lg k-icp-shadow-sm"
                style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    ...position
                }}
            >
                {tools.map((toolsGroup, index) => {
                    if (Array.isArray(toolsGroup)) {
                        return (
                            <ButtonGroup key={index}>
                                {toolsGroup.map((Tool, toolIndex) => (
                                    <Tool view={editorView} key={`${index}-${toolIndex}`} />
                                ))}
                            </ButtonGroup>
                        );
                    } else {
                        const Tool = toolsGroup;
                        return <Tool view={editorView} key={index} />;
                    }
                })}
            </span>
        )
    );
}

export default useToolbarOptionsPlugin;
