import { BoxType } from './canvasService';
import {
    ErrorWithOperationDisplayName,
    FileResponse,
    generateInitials as generateInitialsFromNames,
    getPreferredColorIndex as getPreferredColorIndexById
} from './common';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';

export interface ReducedUser {
    userId: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    picture?: string | null;
}

export interface User extends ReducedUser {
    status: UserStatus;
    phoneNumber?: string | null;
    countryCode?: string | null;
    city?: string | null;
    jobTitle?: string | null;
    companyName?: string | null;
    linkedInProfileUrl?: string | null;
    facebookProfileUrl?: string | null;
    flags: string[];
    license: LicenseData;
}

export enum UserStatus {
    New = 'New',
    Active = 'Active',
    Deactivated = 'Deactivated'
}

export enum LicenseType {
    Trial = 'Trial',
    Full = 'Full',
    Internal = 'Internal'
}

export interface LicenseData {
    // expires is kept as string since it's stored in the redux state and it cannot contain non-serializable values
    expires: string;
    type: LicenseType;
}

export enum UserRole {
    Viewer = 'Viewer',
    Editor = 'Editor',
    Administrator = 'Administrator'
}

export interface UserMembershipEntry {
    user: ReducedUser;
    role: UserRole;
}

export enum ActionType {
    Add = 'Add',
    Edit = 'Edit'
}

export type CanvasBoxPendingAction = {
    type: 'canvass-box';
    box: BoxType;
    operation: ActionType;
};

export type DocumentPendingAction = {
    type: 'document';
    documentId: number;
    operation: 'View';
};

export type InterviewPendingAction = {
    type: 'interview';
    interviewId: number;
    operation: 'View';
};

export type PendingAction = CanvasBoxPendingAction | DocumentPendingAction | InterviewPendingAction;

export interface MemberActivity extends UserMembershipEntry {
    pendingActions: PendingAction[];
}

export enum EmailAddressAvailability {
    Available = 'Available',
    Taken = 'Taken',
    Pending = 'Pending',
    Deleted = 'Deleted'
}

export type PartiallyUpdateUserData = Partial<Omit<User, 'userId' | 'emailAddress' | 'license'>>;

class UsersService extends HttpServiceBase {
    constructor() {
        super('/api/users');
    }

    @ErrorWithOperationDisplayName('Get current user data')
    getCurrent(): Promise<User> {
        return this.performRequest<User>({ path: '/current' });
    }

    @ErrorWithOperationDisplayName('Get user')
    getUser(userId: string): Promise<User> {
        return this.performRequest<User>({ path: `/${userId}` });
    }

    @ErrorWithOperationDisplayName('Delete current user')
    deleteCurrent(): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({ path: '/current', method: RequestMethod.DELETE });
    }

    @ErrorWithOperationDisplayName('Partially update current user profile')
    partiallyUpdateCurrent(data: PartiallyUpdateUserData): Promise<User> {
        return this.performRequest<User>({ path: '/current', method: RequestMethod.PATCH, body: data });
    }

    @ErrorWithOperationDisplayName('Upload avatar')
    uploadAvatar(file: File): Promise<FileResponse> {
        const dataToPost = new FormData();
        dataToPost.append('file', file);

        return this.performRequest({ path: '/current/avatar', method: RequestMethod.POST, body: dataToPost });
    }

    @ErrorWithOperationDisplayName('Check email availability')
    getEmailAvailability(emailAddress: string) {
        return this.performRequest<{ availability: EmailAddressAvailability }>({
            path: '/availability',
            queryParams: {
                emailAddress
            }
        }).then(r => r.availability);
    }

    @ErrorWithOperationDisplayName('Get license')
    getCurrentLicense(): Promise<LicenseData> {
        return this.performRequest<LicenseData>({
            path: '/current/license'
        });
    }
}

export const generateInitials = (user: ReducedUser, desiredLength: number) => {
    return generateInitialsFromNames(desiredLength, user.firstName, user.lastName);
};

export const getPreferredColorIndex = (user: ReducedUser) => {
    return getPreferredColorIndexById(user.userId);
};

export type ReducedUserViewModel = ReducedUser & {
    initials: string;
    colorIndex: number;
};

export const buildUserViewModel = (user: ReducedUser): ReducedUserViewModel => ({
    ...user,
    initials: generateInitials(user, 2),
    colorIndex: getPreferredColorIndex(user)
});

export const UserPermissionsMap = {
    [UserRole.Administrator]: 'Administrator',
    [UserRole.Editor]: 'Can edit',
    [UserRole.Viewer]: 'Can view'
};

export const usersService = new UsersService();
