import { JourneyTaskNavigationHighlight } from './services/journeyService';
import staticConfig from './staticConfig.json';
import { VERSION_BUILD_NUMBER } from './version';

export type EnvironmentConfig = {
    webSocketUrl: string;
    recaptchaConfig: { enabled: boolean; siteKey: string };
    stripePublishableKey: string;
    googleTagManagerContainerId: string;
    underConstructionTaskIndex: number;
    marketingWebsiteUrl: string;
    enableMultiseats: boolean;
};

const dynamicConfig: EnvironmentConfig = typeof window !== 'undefined' ? window.config : ({} as EnvironmentConfig);

const extendedStaticConfig = {
    ...staticConfig,
    canvas: {
        ...staticConfig.canvas,
        boxes: {
            ...staticConfig.canvas.boxes,
            CustomerSegments: { ...staticConfig.canvas.boxes.CustomerSegments, highlight: JourneyTaskNavigationHighlight.FocusSegments },
            Problem: { ...staticConfig.canvas.boxes.Problem, highlight: JourneyTaskNavigationHighlight.FocusProblem },
            UniqueValueProposition: { ...staticConfig.canvas.boxes.UniqueValueProposition, highlight: JourneyTaskNavigationHighlight.FocusUVP },
            Solution: { ...staticConfig.canvas.boxes.Solution, highlight: JourneyTaskNavigationHighlight.FocusSolution },
            UnfairAdvantage: { ...staticConfig.canvas.boxes.UnfairAdvantage, highlight: JourneyTaskNavigationHighlight.FocusAdvantage },
            RevenueStreams: { ...staticConfig.canvas.boxes.RevenueStreams, highlight: JourneyTaskNavigationHighlight.FocusRevenue },
            CostStructure: { ...staticConfig.canvas.boxes.CostStructure, highlight: JourneyTaskNavigationHighlight.FocusCost },
            KeyMetrics: { ...staticConfig.canvas.boxes.KeyMetrics, highlight: JourneyTaskNavigationHighlight.FocusMetrics },
            Channel: { ...staticConfig.canvas.boxes.Channel, highlight: JourneyTaskNavigationHighlight.FocusChannel }
        },
        orderedPaletteColors: [...staticConfig.canvas.palette].sort((p1, p2) => p1.assignmentIndex - p2.assignmentIndex).map(p => p.color),
    },
    notes: {
        tagsPalette: [...staticConfig.notes.tagsPalette].sort((p1, p2) => p1.assignmentIndex - p2.assignmentIndex).map(p => p.color)
    }
};

export const appConfig = { ...extendedStaticConfig, ...dynamicConfig, appVersion: VERSION_BUILD_NUMBER };
