import { Button } from '@progress/kendo-react-buttons';
import { Skeleton } from '@progress/kendo-react-indicators';
import { Card, CardBody, StackLayout } from '@progress/kendo-react-layout';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, Outlet, To, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import {
    AnalysisPanelSectionWithScore,
    AreasAndStrengthsSection,
    InsightView,
    InterviewAnalysisAggregatePanel,
    InterviewAnalysisAggregateWithViewDetails,
    InterviewSummaryPanel,
    MeetingInstructionsView,
    QualityNote,
    RecommendationView,
    Section,
    SectionsView,
    SkeletonSection,
    SummaryHeader
} from '../../components/common/analysisComponents';
import { interviewStageLabelMap, interviewStageToUrlPathMap, InterviewStatusIndicator } from '../../components/interview2/common';
import { ViewInterviewTranscriptDialog } from '../../components/research2/viewInterviewTranscriptComponent';
import { PresentAndCopyLink } from '../../components/ui/presentAndCopyLink';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { ReactComponent as ArrowRightIcon } from '../../icons/arrow-right.svg';
import { ReactComponent as QualityAndSuggestionsIcon } from '../../icons/award.svg';
import { ReactComponent as InsightIcon } from '../../icons/insight.svg';
import { ReactComponent as InterviewIcon } from '../../icons/interview.svg';
import { ReactComponent as LinkIcon } from '../../icons/link.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import emptyInsightsIllustrationUrl from '../../images/insight-in-circle-illustration.svg';
import emptyQualityIllustrationUrl from '../../images/quality-in-circle-illustration.svg';
import { combineClassNames, resolveAbsoluteUrl } from '../../services/common';
import { NotFoundException } from '../../services/httpServiceBase';
import { InterviewStageV2 } from '../../services/interviewsV2Service';
import { RealTimeUpdateResearch2EventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import {
    ResearchAnalysisStatusV2,
    ResearchAnalysisV2,
    ResearchV2,
    ResearchV2Insight,
    ResearchV2InsightCategory,
    ResearchV2InsightSummaryItem,
    ResearchV2QualityAssessmentArea,
    ResearchV2QualityAssessmentRecommendation,
    researchV2Service
} from '../../services/researchV2Service';
import { UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { useTabbedLayoutData } from '../layouts/tabbedLayout';

const HIGHLIGHT_INTERVIEWS_COUNT_REGEX = /(?:\d+([.,]\d+)?%\s*)?(?:\(\s*)?(\d+)\s+out\s+of\s+(\d+)\s+interview(?:s|ees)?(?:\s*\))?/gi;

export const ResearchOverviewTab: React.FC = () => {
    const { analysis, research: researchDetails } = useTabbedLayoutData<{
        analysis: ResearchAnalysisV2 | undefined | null;
        research: ResearchV2;
    }>();

    const navigate = useNavigate();

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="page-content-middle page-content--xxl k-pb-6 k-gap-8">
            <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }} className="k-gap-8">
                <InterviewSummaryPanel
                    icon={InterviewIcon}
                    headerContent={
                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                            <span className="k-display-4">{researchDetails.interviewCount}</span>
                            <span>Total Interviews</span>
                        </StackLayout>
                    }
                    style={{ width: 300 }}
                >
                    <ResearchInterviewsPanelContent researchDetails={researchDetails} />
                </InterviewSummaryPanel>
                <InterviewSummaryPanel
                    icon={LinkIcon}
                    title="AI-led interviews"
                    subtitle="accessible by anyone with the link"
                    headerContent={
                        <InterviewStatusIndicator
                            className="k-ml-auto"
                            text={researchDetails.aiLedInterviewsEnabled ? 'Enabled' : 'Disabled'}
                            type={researchDetails.aiLedInterviewsEnabled ? 'success' : 'warning'}
                        />
                    }
                    className="k-flex-1"
                >
                    <MeetingInstructionsView
                        meetingInstructions={[
                            'IVA will follow your interview script to collect all the necessary information and organize the results for your review.',
                            <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-1">
                                <div>Simply share the link below to begin:</div>
                                <CopyAILedInterviewLink researchPublicCode={researchDetails.publicCode} disabled={!researchDetails.aiLedInterviewsEnabled} />
                            </StackLayout>
                        ]}
                    />
                </InterviewSummaryPanel>
            </StackLayout>
            <StackLayout align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-8">
                <InterviewSummaryPanel
                    icon={InsightIcon}
                    title="Insights Summary"
                    subtitle={`from ${analysis ? analysis.interviewCount : researchDetails.completeCount} completed interviews`}
                >
                    <SummaryPanelContent
                        items={analysis?.insightSummaryItems.slice(0, 3)}
                        isPending={
                            analysis === undefined ||
                            (analysis !== null &&
                                !analysis.insightSummaryItems.length &&
                                (analysis.status === ResearchAnalysisStatusV2.NotStarted || analysis.status === ResearchAnalysisStatusV2.Pending))
                        }
                        onButtonClick={() => navigate('../insights')}
                        buttonText="View insights"
                        emptyStateText={
                            analysis === null
                                ? 'Insights will appear here once your first interview gets completed.'
                                : 'No insights were identified in this research.'
                        }
                    />
                </InterviewSummaryPanel>
                <InterviewSummaryPanel
                    icon={QualityAndSuggestionsIcon}
                    title="Quality and Suggestions"
                    subtitle={`from ${analysis ? analysis.interviewCount : researchDetails.completeCount} completed interviews`}
                >
                    <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                        <AnalysisPanelSectionWithScore
                            isPending={
                                analysis === undefined ||
                                (analysis !== null &&
                                    !analysis.qualitySummary &&
                                    (analysis.status === ResearchAnalysisStatusV2.NotStarted || analysis.status === ResearchAnalysisStatusV2.Pending))
                            }
                            title="Quality score"
                            score={analysis?.qualitySummary?.score}
                            text={analysis?.qualitySummary?.text}
                            emptyText={
                                analysis === null
                                    ? 'Quality score will appear here once your first interview gets completed.'
                                    : 'No quality score for this research.'
                            }
                            hasButton
                            buttonText="View more"
                            onButtonClick={() => navigate('../quality')}
                        />
                        <div className="k-separator k-icp-component-border" />
                        <AnalysisPanelSectionWithScore
                            isPending={
                                analysis === undefined ||
                                (analysis !== null &&
                                    !analysis.scriptCoverage &&
                                    (analysis.status === ResearchAnalysisStatusV2.NotStarted || analysis.status === ResearchAnalysisStatusV2.Pending))
                            }
                            title="Script coverage"
                            score={analysis?.scriptCoverage?.coverage}
                            showFullText
                            text={analysis?.scriptCoverage?.text}
                            emptyText={
                                analysis === null
                                    ? 'Script coverage will appear here once your first interview gets completed.'
                                    : 'None of the uploaded interviews have an interview script attached.'
                            }
                        />
                    </StackLayout>
                </InterviewSummaryPanel>
            </StackLayout>
        </StackLayout>
    );
};

const SUMMARY_CATEGORY_ID = -1;
export const ResearchInsightsTab: React.FC = () => {
    const { ideaId } = useIdeaParams();
    const { categoryId } = useParams();
    const { analysis, research: researchDetails, pinInsightsCategory } = useTabbedLayoutData<{
        analysis: ResearchAnalysisV2 | undefined | null;
        research: ResearchV2;
        pinInsightsCategory?: (categoryName: string) => Promise<unknown>;
    }>();
    const researchId = researchDetails.id;
    const navigate = useNavigate();
    const [categories, setCategories] = useState<ResearchV2InsightCategory[]>();
    const [addingCategory, handleAddingSuggestedCategory] = useSingleClickButton();
    const selectedCategoryId = categoryId ? parseInt(categoryId) : SUMMARY_CATEGORY_ID;
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canEdit = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;

    if (Number.isNaN(selectedCategoryId)) throw new NotFoundException();

    const fetchInsightCategories = useCallback(async () => {
        const insightCategories = await researchV2Service.getResearchInsights(ideaId, researchId);
        setCategories(insightCategories);
    }, [ideaId, researchId]);

    useEffect(() => {
        fetchInsightCategories();
    }, [fetchInsightCategories, ideaId, researchId]);

    useEffect(() => {
        function onAnalysisCreatedOrUpdated(e: RealTimeUpdateResearch2EventData) {
            if (e.ideaId !== ideaId || e.researchId !== researchId) return;
            fetchInsightCategories();
        }

        realTimeUpdatesEventHub.addEventListener('research2', 'analysisCreate', onAnalysisCreatedOrUpdated);
        realTimeUpdatesEventHub.addEventListener('research2', 'analysisUpdate', onAnalysisCreatedOrUpdated);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('research2', 'analysisCreate', onAnalysisCreatedOrUpdated);
            realTimeUpdatesEventHub.removeEventListener('research2', 'analysisUpdate', onAnalysisCreatedOrUpdated);
        };
    }, [ideaId, fetchInsightCategories, researchId]);

    useEffect(() => {
        if (selectedCategoryId === SUMMARY_CATEGORY_ID || !categories || !categories.length) return;

        if (!categories.some(c => c.id === selectedCategoryId)) navigate('');
    }, [categories, navigate, selectedCategoryId]);

    function handleSelectedCategoryChange(categoryId: number) {
        if (categoryId === SUMMARY_CATEGORY_ID) {
            if (categoryId === selectedCategoryId) return;
            navigate('');
        } else navigate(categoryId.toString());
    }

    const handleSuggestionClick = handleAddingSuggestedCategory(async (categoryId: number, categoryName: string) => {
        navigate(categoryId.toString());

        await pinInsightsCategory?.(categoryName);
    });

    function onInsightSummaryItemDetails(summaryItemTitle: string) {
        if (!analysis || !categories) return;
        const summaryItemCategory = categories.find(c => c.name === summaryItemTitle);
        if (!summaryItemCategory) return;
        const isCategoryPinned = analysis.pinnedInsightCategories.includes(summaryItemCategory.name);
        if (isCategoryPinned) handleSelectedCategoryChange(summaryItemCategory.id);
        else handleSuggestionClick(summaryItemCategory.id, summaryItemCategory.name);
    }

    if (
        analysis === undefined ||
        !categories ||
        (analysis !== null && (analysis.status === ResearchAnalysisStatusV2.Pending || analysis.status === ResearchAnalysisStatusV2.NotStarted))
    ) {
        return (
            <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} style={{ maxWidth: 320 }} className="k-flex-1 k-gap-2">
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                </StackLayout>
                <div className="k-separator k-icp-component-border" />
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} style={{ maxWidth: 640 }} className="-flex-2 k-gap-4">
                    <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                        <Skeleton className="k-rounded-full k-shrink-0" shape="rectangle" style={{ width: '64px', height: '64px' }} />
                        <Skeleton shape="text" className="k-flex-1 k-fs-lg" />
                    </StackLayout>
                    <div>
                        <Skeleton shape="text" />
                        <Skeleton shape="text" />
                    </div>
                    <Skeleton shape="rectangle" className="k-rounded-md" style={{ height: '176px' }} />
                </StackLayout>
            </StackLayout>
        );
    }

    const pinnedSections: Section[] = [];
    const suggestedCategories: ResearchV2InsightCategory[] = [];
    if (analysis) {
        if (analysis.insightSummaryItems.length)
            pinnedSections.push({
                id: SUMMARY_CATEGORY_ID,
                text: 'Insights Summary'
            });

        for (const category of categories) {
            const isCategoryPinned = analysis.pinnedInsightCategories.includes(category.name);
            if (isCategoryPinned) pinnedSections.push({ id: category.id, text: category.name });
            else suggestedCategories.push(category);
        }
    }

    if (analysis === null || (!pinnedSections.length && !pinnedSections.length)) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1 page-content-middle -w2 k-text-center">
                <img src={emptyInsightsIllustrationUrl} width="80" height="80" alt="No insights" />
                <div className="k-mt-6">{analysis === null ? 'Insights will appear here once your first interview is completed.' : 'No insights found'}</div>
            </StackLayout>
        );
    }

    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6">
            <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} style={{ maxWidth: 320 }} className="k-gap-6 k-flex-1">
                {pinnedSections.length > 0 && (
                    <SectionsView
                        className="k-w-full"
                        sectionName="Categories"
                        sections={pinnedSections}
                        selectedSectionId={selectedCategoryId}
                        onSelectedSection={handleSelectedCategoryChange}
                    />
                )}
                {suggestedCategories.length > 0 && (
                    <div className="k-w-full">
                        <div className="k-mb-2 k-icp-text-smaller k-text-uppercase">Suggestions</div>
                        <InsightSuggestionPanel
                            suggestedCategories={suggestedCategories}
                            onSuggestionClick={handleSuggestionClick}
                            disabled={!canEdit || addingCategory}
                        />
                    </div>
                )}
            </StackLayout>
            <div className="k-separator k-icp-component-border" />
            {selectedCategoryId === SUMMARY_CATEGORY_ID ? (
                <ResearchInsightsSummaryView
                    analysis={analysis}
                    summaryItemsWithDetails={categories.map(c => c.name)}
                    onShowDetails={onInsightSummaryItemDetails}
                />
            ) : (
                <Outlet context={{ categories, analysis }} />
            )}
        </StackLayout>
    );
};

export function ResearchInsightsSummaryView({
    analysis,
    onShowDetails,
    summaryItemsWithDetails
}: {
    analysis: ResearchAnalysisV2;
    onShowDetails?: (summaryItemTitle: string) => void;
    summaryItemsWithDetails?: string[];
}) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'middle' }} className="k-gap-4 -flex-2" style={{ maxWidth: 640 }}>
            <SummaryHeader
                title="Insights Summary"
                subtitle={`from ${analysis.interviewCount} completed interviews`}
                summary={`The summary covers key business insights extracted from all ${analysis.interviewCount} interviews you've uploaded. It presents the most significant findings and prevalent opinions related to topics covered with your interviewees.`}
                icon={InsightIcon}
            />
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                {analysis.insightSummaryItems.map((summaryItem, summaryItemIndex) => (
                    <InterviewAnalysisAggregateWithViewDetails
                        key={summaryItemIndex}
                        title={summaryItem.title}
                        buttonText="Show insights"
                        onButtonClick={
                            summaryItemsWithDetails && summaryItemsWithDetails.includes(summaryItem.title)
                                ? onShowDetails && (() => onShowDetails(summaryItem.title))
                                : undefined
                        }
                    >
                        <div>{summaryItem.content}</div>
                    </InterviewAnalysisAggregateWithViewDetails>
                ))}
            </StackLayout>
        </StackLayout>
    );
}

export function ResearchInsightCategoryView() {
    const { categories, analysis } = useOutletContext<{
        categories: ResearchV2InsightCategory[];
        analysis: ResearchAnalysisV2;
    }>();
    const { categoryId, aggragateInsightsId } = useParams();
    const selectedCategory = categoryId && categories ? categories.find(category => category.id === parseInt(categoryId)) : undefined;

    if (!selectedCategory) {
        return null;
    }

    const aggregateInsightDetails = aggragateInsightsId ? selectedCategory.insights.find(r => r.id === parseInt(aggragateInsightsId)) : undefined;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'middle' }} className="k-gap-4 -flex-2" style={{ maxWidth: 640 }}>
            <SummaryHeader
                title={
                    <div>
                        {aggregateInsightDetails ? (
                            <Link className="k-icp-hover-subtle-text-effect" to={`../${selectedCategory.id}`}>
                                {selectedCategory.name}
                            </Link>
                        ) : (
                            <span>{selectedCategory.name}</span>
                        )}

                        {aggregateInsightDetails && (
                            <>
                                <span>{' > '}</span>
                                {aggregateInsightDetails.title}
                            </>
                        )}
                    </div>
                }
                subtitle={!aggregateInsightDetails ? `from ${analysis.interviewCount} completed interviews` : undefined}
                summary={aggregateInsightDetails ? aggregateInsightDetails.insight : selectedCategory.description}
                icon={InsightIcon}
            />
            {aggregateInsightDetails ? (
                <Outlet context={aggregateInsightDetails} />
            ) : (
                <ItemsConciseView
                    items={selectedCategory.insights.map(i => ({ id: i.id, title: i.title, text: i.insight }))}
                    itemTextParser={item => highlightInterviewCounts(item.text, HIGHLIGHT_INTERVIEWS_COUNT_REGEX, `${item.id}/details`)}
                />
            )}
        </StackLayout>
    );
}

export function ResearchInsightCategoryDetailsView() {
    const { ideaId } = useIdeaParams();
    const aggregateInsight = useOutletContext<ResearchV2Insight>();
    const [viewInterviewId, setViewInterviewId] = useState<number>();

    return (
        <>
            <StackLayout orientation="vertical" className="k-gap-4">
                {aggregateInsight.contributingInsights.map((insight, idx) => {
                    return (
                        <InterviewAnalysisAggregatePanel
                            key={insight.interviewId}
                            title={insight.interviewTitle}
                            onButtonClick={() => setViewInterviewId(insight.interviewId)}
                        >
                            <StackLayout orientation="vertical" className="k-gap-4">
                                {insight.insights.map((insight, idx) => (
                                    <React.Fragment key={idx}>
                                        {idx > 0 && <div className="k-separator k-icp-component-border" />}
                                        <InsightView insight={insight} />
                                    </React.Fragment>
                                ))}
                            </StackLayout>
                        </InterviewAnalysisAggregatePanel>
                    );
                })}
            </StackLayout>

            {viewInterviewId !== undefined && (
                <ViewInterviewTranscriptDialog ideaId={ideaId!} interviewId={viewInterviewId} onClose={() => setViewInterviewId(undefined)} />
            )}
        </>
    );
}

export const ResearchQualityTab: React.FC = () => {
    const { ideaId } = useIdeaParams();
    const { areaId } = useParams();
    const { analysis, research: researchDetails } = useTabbedLayoutData<{
        analysis: ResearchAnalysisV2 | undefined | null;
        research: ResearchV2;
    }>();
    const researchId = researchDetails.id;
    const navigate = useNavigate();
    const [qualityAssessmentAreas, setQualityAssessmentAreas] = useState<ResearchV2QualityAssessmentArea[]>();
    const selectedAreaId = areaId ? parseInt(areaId) : undefined;
    if (selectedAreaId !== undefined && Number.isNaN(selectedAreaId)) throw new NotFoundException();

    const fetchQualityAssessmentAreas = useCallback(() => researchV2Service.getResearchQualityAssessment(ideaId, researchId).then(setQualityAssessmentAreas), [
        ideaId,
        researchId
    ]);

    useEffect(() => {
        fetchQualityAssessmentAreas();
    }, [fetchQualityAssessmentAreas]);

    useEffect(() => {
        function onAnalysisCreatedOrUpdated(e: RealTimeUpdateResearch2EventData) {
            if (e.ideaId !== ideaId || e.researchId !== researchId) return;
            fetchQualityAssessmentAreas();
        }

        realTimeUpdatesEventHub.addEventListener('research2', 'analysisCreate', onAnalysisCreatedOrUpdated);
        realTimeUpdatesEventHub.addEventListener('research2', 'analysisUpdate', onAnalysisCreatedOrUpdated);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('research2', 'analysisCreate', onAnalysisCreatedOrUpdated);
            realTimeUpdatesEventHub.removeEventListener('research2', 'analysisUpdate', onAnalysisCreatedOrUpdated);
        };
    }, [ideaId, fetchQualityAssessmentAreas, researchId]);

    useEffect(() => {
        if (!qualityAssessmentAreas || !qualityAssessmentAreas.length) return;

        // Navigate to first area if no area is selected or if the selected area does not exist in the available areas
        if (selectedAreaId === undefined || !qualityAssessmentAreas.some(a => a.id === selectedAreaId)) navigate(qualityAssessmentAreas[0].id.toString());
    }, [navigate, qualityAssessmentAreas, selectedAreaId]);

    const handleSelectedSectionChange = useCallback(
        (sectionId: number) => {
            navigate(sectionId.toString());
        },
        [navigate]
    );

    if (
        analysis === undefined ||
        !qualityAssessmentAreas ||
        (analysis !== null && (analysis.status === ResearchAnalysisStatusV2.Pending || analysis.status === ResearchAnalysisStatusV2.NotStarted))
    ) {
        return (
            <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} style={{ maxWidth: 320 }} className="k-flex-1 k-gap-2">
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                </StackLayout>
                <div className="k-separator k-icp-component-border" />
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} style={{ maxWidth: 640 }} className="-flex-2 k-gap-4">
                    <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                        <Skeleton className="k-rounded-full k-shrink-0" shape="rectangle" style={{ width: '64px', height: '64px' }} />
                        <Skeleton shape="text" className="k-flex-1 k-fs-lg" />
                    </StackLayout>
                    <div>
                        <Skeleton shape="text" />
                        <Skeleton shape="text" />
                    </div>
                    <Skeleton shape="rectangle" className="k-rounded-md" style={{ height: '176px' }} />
                </StackLayout>

                <div className="k-separator k-icp-component-border" />
                <QualityNote className="k-flex-1" text={analysis?.qualitySummary?.text} score={analysis?.qualitySummary?.score} maxWidth={296} />
            </StackLayout>
        );
    }

    if (analysis === null || qualityAssessmentAreas.length === 0) {
        return analysis && analysis.qualitySummary ? (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }}>
                <QualityNote text={analysis.qualitySummary.text} score={analysis.qualitySummary.score} maxWidth={540} />
            </StackLayout>
        ) : (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1 page-content-middle -w2 k-text-center">
                <img src={emptyQualityIllustrationUrl} width="80" height="80" alt="No areas for improvement" />
                <div className="k-mt-6">
                    {analysis === null ? 'Quality score will appear here once your first interview is completed.' : 'No areas for improvement found'}
                </div>
            </StackLayout>
        );
    }

    const sections = qualityAssessmentAreas.map(area => ({ id: area.id, text: area.title, scorePercentage: area.percentageImprovement } as Section));

    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6">
            <AreasAndStrengthsSection sections={sections} selectedSectionId={selectedAreaId} onSelectedSectionId={handleSelectedSectionChange} />
            <div className="k-separator k-icp-component-border" />
            <Outlet context={{ qualityAssessmentAreas, researchDetails, analysis }} />

            {analysis.qualitySummary && (
                <>
                    <div className="k-separator k-icp-component-border" />
                    <QualityNote className="k-flex-1" text={analysis.qualitySummary.text} score={analysis.qualitySummary.score} maxWidth={296} />
                </>
            )}
        </StackLayout>
    );
};

export function ImprovementAreaView() {
    const { qualityAssessmentAreas, analysis } = useOutletContext<{
        qualityAssessmentAreas: ResearchV2QualityAssessmentArea[];
        analysis: ResearchAnalysisV2;
    }>();

    const { areaId, interviewRecommendationsId } = useParams();
    const selectedArea = areaId && qualityAssessmentAreas ? qualityAssessmentAreas.find(area => area.id === parseInt(areaId)) : undefined;
    if (!selectedArea) {
        return null;
    }

    const aggregateRecommendationDetails = interviewRecommendationsId
        ? selectedArea.recommendations.find(r => r.id === parseInt(interviewRecommendationsId))
        : undefined;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'middle' }} className={'k-gap-4 -flex-2'} style={{ maxWidth: 640 }}>
            <SummaryHeader
                title={
                    <div>
                        {aggregateRecommendationDetails ? (
                            <Link className="k-icp-hover-subtle-text-effect" to={`../${selectedArea.id}`}>
                                {selectedArea.title}
                            </Link>
                        ) : (
                            <span>{selectedArea.title}</span>
                        )}
                        {aggregateRecommendationDetails && (
                            <>
                                <span>{' > '}</span>
                                {aggregateRecommendationDetails.title}
                            </>
                        )}
                    </div>
                }
                subtitle={!aggregateRecommendationDetails ? `from ${analysis.interviewCount} completed interviews` : undefined}
                summary={aggregateRecommendationDetails ? aggregateRecommendationDetails.recommendation : selectedArea.text}
                icon={InsightIcon}
            />
            {aggregateRecommendationDetails ? (
                <Outlet context={{ selectedArea, aggregateRecommendationDetails }} />
            ) : (
                <ItemsConciseView
                    items={selectedArea.recommendations.map(r => ({ id: r.id, title: r.title, text: r.recommendation }))}
                    itemTextParser={item => highlightInterviewCounts(item.text, HIGHLIGHT_INTERVIEWS_COUNT_REGEX, `${item.id}/details`)}
                />
            )}
        </StackLayout>
    );
}

type ConciseItem = { id: number; title: string; text: string };
function ItemsConciseView({ items, itemTextParser }: { items: ConciseItem[]; itemTextParser: (item: ConciseItem) => React.ReactNode }) {
    const navigate = useNavigate();

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
            {items.map((item, idx) => (
                <InterviewAnalysisAggregateWithViewDetails key={idx} title={item.title} onButtonClick={() => navigate(`${item.id}/details`)}>
                    <div>{itemTextParser(item)}</div>
                </InterviewAnalysisAggregateWithViewDetails>
            ))}
        </StackLayout>
    );
}

export function ImprovementAreaDetailsView() {
    const { ideaId } = useIdeaParams();
    const { selectedArea, aggregateRecommendationDetails } = useOutletContext<{
        selectedArea: ResearchV2QualityAssessmentArea;
        aggregateRecommendationDetails: ResearchV2QualityAssessmentRecommendation;
    }>();
    const [viewInterviewId, setViewInterviewId] = useState<number>();

    return (
        <>
            {selectedArea.percentageImprovement > 0 ? (
                <StackLayout orientation="vertical" className="k-gap-4">
                    {aggregateRecommendationDetails.contributingInterviewCount > aggregateRecommendationDetails.contributingRecommendations.length && (
                        <div className="k-font-weight-bold k-mt-4">
                            Recommendations from the {aggregateRecommendationDetails.contributingRecommendations.length} most recent interviews
                        </div>
                    )}
                    {aggregateRecommendationDetails.contributingRecommendations.map(recommendation => {
                        return (
                            <InterviewAnalysisAggregatePanel
                                key={recommendation.interviewId}
                                title={recommendation.interviewTitle}
                                onButtonClick={() => setViewInterviewId(recommendation.interviewId)}
                            >
                                <StackLayout orientation="vertical" className="k-gap-4"></StackLayout>
                                {recommendation.recommendations.map((recommendation, idx) => (
                                    <React.Fragment key={idx}>
                                        {idx > 0 && <div className="k-separator k-icp-component-border" />}
                                        <RecommendationView key={idx} recommendation={recommendation} />
                                    </React.Fragment>
                                ))}
                            </InterviewAnalysisAggregatePanel>
                        );
                    })}
                </StackLayout>
            ) : (
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-flex-1 k-gap-4">
                    {aggregateRecommendationDetails.contributingRecommendations.map(recommendation => (
                        <React.Fragment key={recommendation.interviewId}>
                            {recommendation.recommendations.map((recommendation, idx) => {
                                return <RecommendationView key={idx} recommendation={recommendation} asPanel showPercentage />;
                            })}
                        </React.Fragment>
                    ))}
                </StackLayout>
            )}
            {viewInterviewId !== undefined && (
                <ViewInterviewTranscriptDialog ideaId={ideaId} interviewId={viewInterviewId} onClose={() => setViewInterviewId(undefined)} />
            )}
        </>
    );
}

function InsightSuggestionPanel({
    suggestedCategories,
    disabled,
    onSuggestionClick
}: {
    suggestedCategories: ResearchV2InsightCategory[];
    disabled?: boolean;
    onSuggestionClick: (categoryId: number, categoryName: string) => void;
}) {
    const firstColumnCategories = suggestedCategories.slice(0, Math.ceil(suggestedCategories.length / 2));
    const secondColumnCategories = suggestedCategories.slice(Math.ceil(suggestedCategories.length / 2));

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }} style={{ maxWidth: 320 }} className="k-icp-panel-base k-p-2.5 k-rounded-md k-gap-2">
            {[firstColumnCategories, secondColumnCategories].map((column, index) => (
                <StackLayout key={index} orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2 k-w-1/2">
                    {column.map(category => (
                        <InsightCategorySuggestionCard
                            key={category.id}
                            disabled={disabled}
                            category={category}
                            onCategoryClick={() => onSuggestionClick(category.id, category.name)}
                        />
                    ))}
                </StackLayout>
            ))}
        </StackLayout>
    );
}

function InsightCategorySuggestionCard({
    category,
    onCategoryClick,
    disabled
}: {
    category: ResearchV2InsightCategory;
    onCategoryClick: () => void;
    disabled?: boolean;
}) {
    return (
        <div onClick={onCategoryClick} className={combineClassNames(disabled ? 'k-disabled' : undefined)}>
            <Card
                key={category.id}
                style={{ maxWidth: '146px', minWidth: '80px' }}
                className={combineClassNames('k-icp-no-shadow !k-rounded-md', disabled ? undefined : 'k-icp-interactive-card-sm k-cursor-pointer')}
            >
                <CardBody className="!k-p-2">
                    <PlusIcon className="k-icp-icon k-icp-icon-size-4 k-mb-1" />
                    <div className="k-fs-sm">Show insights for {category.name}</div>
                </CardBody>
            </Card>
        </div>
    );
}

function ResearchInterviewsPanelContent({ researchDetails }: { researchDetails: ResearchV2 }) {
    const navigate = useNavigate();

    const interviewsCountData = [
        {
            name: interviewStageLabelMap[InterviewStageV2.NotStarted],
            value: researchDetails.notStartedCount,
            onButtonClick: () => navigate(`../interviews/${interviewStageToUrlPathMap[InterviewStageV2.NotStarted]}`)
        },
        {
            name: interviewStageLabelMap[InterviewStageV2.InProgress],
            value: researchDetails.inProgressCount,
            onButtonClick: () => navigate(`../interviews/${interviewStageToUrlPathMap[InterviewStageV2.InProgress]}`)
        },
        {
            name: interviewStageLabelMap[InterviewStageV2.Completed],
            value: researchDetails.completeCount,
            onButtonClick: () => navigate(`../interviews/${interviewStageToUrlPathMap[InterviewStageV2.Completed]}`)
        }
    ];

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-icp-component-border">
            {interviewsCountData.map((item, idx) => {
                return (
                    <React.Fragment key={idx}>
                        {idx > 0 && <div className="k-separator" />}
                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-w-full k-gap-2">
                            <div className="k-flex-1">{item.name}</div>
                            <span className="k-font-weight-medium k-fs-xl">{item.value}</span>
                            <Button themeColor="base" fillMode="flat" size="small" className="k-icp-svg-icon-button" onClick={item.onButtonClick}>
                                <ArrowRightIcon className="k-icp-icon" />
                            </Button>
                        </StackLayout>
                    </React.Fragment>
                );
            })}
        </StackLayout>
    );
}

function CopyAILedInterviewLink({ researchPublicCode, disabled }: { researchPublicCode: string; disabled?: boolean }) {
    const publicStartInterviewLink = resolveAbsoluteUrl(researchV2Service.getResearchInterviewPublicUrl(researchPublicCode));
    return <PresentAndCopyLink link={publicStartInterviewLink} name="Interview link" disabled={disabled} />;
}

function SummaryPanelContent({
    items,
    isPending,
    onButtonClick,
    buttonText,
    emptyStateText
}: {
    items: ResearchV2InsightSummaryItem[] | null | undefined;
    isPending: boolean;
    onButtonClick?: () => void;
    buttonText: string;
    emptyStateText: string;
}) {
    return items && items.length > 0 ? (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
            {items.map(item => (
                <React.Fragment key={item.title}>
                    <div>
                        <div className="k-mb-2 k-font-semibold">{item.title}</div>
                        <div>{item.content}</div>
                    </div>
                    <div className="k-separator k-icp-component-border" />
                </React.Fragment>
            ))}
            <Button themeColor="secondary" fillMode="flat" size="small" onClick={onButtonClick}>
                {buttonText}
            </Button>
        </StackLayout>
    ) : isPending ? (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'middle' }} className="k-gap-4 ">
            {[60, 50, 80].map((lastSkeletonWidthPercentage, idx) => {
                return (
                    <React.Fragment key={idx}>
                        <SkeletonSection lastSkeletonWidthPercentage={lastSkeletonWidthPercentage} />
                        <div className="k-separator k-icp-component-border" />
                    </React.Fragment>
                );
            })}
            <Skeleton shape="rectangle" style={{ width: '108px', height: '24px' }} className="k-rounded-sm" />
        </StackLayout>
    ) : (
        <div className="k-icp-subtle-text">{emptyStateText}</div>
    );
}

function highlightInterviewCounts(text: string, regex: RegExp, linkTo?: To, onClick?: () => void, className: string = 'k-text-secondary') {
    let lastIndex = 0;
    const parts = [];
    const matches = Array.from(text.matchAll(regex));
    for (const match of matches) {
        if (match.index === undefined) continue;
        parts.push(text.slice(lastIndex, match.index));
        const matchElement = linkTo ? (
            <Link key={match.index} to={linkTo} className={className} onClick={onClick}>
                {match[0]}
            </Link>
        ) : (
            <span key={match.index} className={combineClassNames(className, onClick ? 'k-cursor-pointer' : undefined)} onClick={onClick}>
                {match[0]}
            </span>
        );

        parts.push(matchElement);
        lastIndex = match.index + match[0].length;
    }
    parts.push(text.slice(lastIndex));
    return parts;
}
