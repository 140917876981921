import { Chip } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridRowClickEvent } from '@progress/kendo-react-grid';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AILabel } from '../../components/ai/aiLabel';
import { BoundDropDownButton } from '../../components/common/boundDropDownButton';
import { CellTemplateProps, createCellTemplateFromComponent, gridCommandHandler, triggerGridCommand } from '../../components/common/grid';
import { SearchBar } from '../../components/common/searchbar';
import { useIdeaNavigate } from '../../components/startup/ideaZone';
import { StartupMembershipList } from '../../components/startup/startupMembershipList';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { Pager } from '../../components/ui/pager';
import { PredefinedAction, PredefinedActionsList } from '../../components/ui/predefinedActions';
import { RoundedIconBackground } from '../../components/ui/roundedIconBackground';
import { TextMarker } from '../../components/ui/textMarker';
import { H1 } from '../../components/ui/typography';
import { useSingleClickCallback } from '../../hooks/commonHooks';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { useThrottledFetch } from '../../hooks/useThrottledFetch';
import { ReactComponent as NotesIcon } from '../../icons/notes.svg';
import { ReactComponent as CreateScriptIcon } from '../../icons/plus.svg';
import dailyPlanningIllustrationUrl from '../../images/daily-planning-illustration.svg';
import meetingNotesIllustrationUrl from '../../images/meeting-notes-illustration.svg';
import { ReactComponent as NotesIllustration } from '../../images/notes-illustration.svg';
import { ReactComponent as NotesInCircleIllustration } from '../../images/notes-in-circle-illustration.svg';
import releaseNotesIllustrationUrl from '../../images/release-notes-illustration.svg';
import { combineClassNames, debounce } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import { listPreferencesService, NotesListPreferences } from '../../services/listPreferencesService';
import { Notes, notesService, NoteTemplateType, ReducedNote } from '../../services/notesService';
import {
    RealTimeUpdateNoteEventData,
    RealTimeUpdateNoteTagEventData,
    RealTimeUpdateNoteUpdateData,
    realTimeUpdatesEventHub
} from '../../services/realTimeUpdatesService';
import { buildUserViewModel, UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';

export function NotesPage() {
    const [notes, setNotes] = useState<Notes | null | undefined>(undefined);
    const { ideaId } = useIdeaParams();
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canEditNotes = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const [listPreferences, setListPreferences] = useState<NotesListPreferences>(() => listPreferencesService.getNotesListPreferences());
    const [skip, setSkip] = useState(0);
    const [searchPending, setSearchPending] = useState(false);
    const filterTextRef = useRef<string | undefined>(undefined);
    const { show: showConfirmDialog, element: confirmDialog } = useConfirmDialog();
    const navigate = useNavigate();
    const loading = notes === undefined;
    const { throttledFetch: throttledLoadNotes } = useThrottledFetch(() => loadNotes(filterTextRef.current));

    const loadNotes = useCallback(
        async (filter?: string) => {
            const notes = await notesService.getNotes(ideaId, undefined, undefined, skip, listPreferences.pageSize, undefined, filter || undefined);

            //This will reload notes and remove the pagination
            if (notes.totalCount > 0 && notes.totalCount <= skip) {
                setSkip(0);
            }

            if (!filter && notes.totalCount === 0) {
                setNotes(null);
            } else {
                setNotes(notes);
            }
        },
        [ideaId, skip, listPreferences.pageSize]
    );

    useEffect(() => {
        let getNoteTimeoutId: NodeJS.Timer | undefined;

        function onNoteAdded(e: RealTimeUpdateNoteEventData) {
            if (e.ideaId !== ideaId) return;
            loadNotes(filterTextRef.current);
        }

        function onNoteUpdated(e: RealTimeUpdateNoteUpdateData) {
            if (e.ideaId !== ideaId) return;
            if (e.lowPriority) {
                throttledLoadNotes();
            } else {
                loadNotes(filterTextRef.current);
            }
        }

        function onNoteDeleted(e: RealTimeUpdateNoteEventData) {
            if (e.ideaId !== ideaId) return;
            loadNotes(filterTextRef.current);
        }

        function onNoteRestored(e: RealTimeUpdateNoteEventData) {
            if (e.ideaId !== ideaId) return;
            loadNotes(filterTextRef.current);
        }

        async function onNoteTagChanged(e: RealTimeUpdateNoteTagEventData) {
            if (e.ideaId !== ideaId) return;
            loadNotes(filterTextRef.current);
        }

        realTimeUpdatesEventHub.addEventListener('notes', 'noteAdd', onNoteAdded);
        realTimeUpdatesEventHub.addEventListener('notes', 'noteUpdate', onNoteUpdated);
        realTimeUpdatesEventHub.addEventListener('notes', 'noteDelete', onNoteDeleted);
        realTimeUpdatesEventHub.addEventListener('notes', 'noteRestore', onNoteRestored);

        realTimeUpdatesEventHub.addEventListener('notes', 'tagAdd', onNoteTagChanged);
        realTimeUpdatesEventHub.addEventListener('notes', 'tagUpdate', onNoteTagChanged);
        realTimeUpdatesEventHub.addEventListener('notes', 'tagDelete', onNoteTagChanged);
        realTimeUpdatesEventHub.addEventListener('notes', 'tagRestore', onNoteTagChanged);

        return () => {
            if (getNoteTimeoutId) {
                clearTimeout(getNoteTimeoutId);
                getNoteTimeoutId = undefined;
            }

            realTimeUpdatesEventHub.removeEventListener('notes', 'noteAdd', onNoteAdded);
            realTimeUpdatesEventHub.removeEventListener('notes', 'noteUpdate', onNoteUpdated);
            realTimeUpdatesEventHub.removeEventListener('notes', 'noteDelete', onNoteDeleted);
            realTimeUpdatesEventHub.removeEventListener('notes', 'noteRestore', onNoteRestored);

            realTimeUpdatesEventHub.removeEventListener('notes', 'tagAdd', onNoteTagChanged);
            realTimeUpdatesEventHub.removeEventListener('notes', 'tagUpdate', onNoteTagChanged);
            realTimeUpdatesEventHub.removeEventListener('notes', 'tagDelete', onNoteTagChanged);
            realTimeUpdatesEventHub.removeEventListener('notes', 'tagRestore', onNoteTagChanged);
        };
    }, [ideaId, loadNotes, throttledLoadNotes]);

    const [isCreatingNote, handleCreateNote] = useSingleClickCallback(async (templateType?: NoteTemplateType) => {
        const note = await notesService.createNote(ideaId, templateType);
        navigate(`${note.id}`);
    });

    const handleDuplicateNote = async (note: ReducedNote) => {
        await notesService.duplicateNote(ideaId, note.id);
        await loadNotes(filterTextRef.current);
    };

    const handleDeleteNote = async (note: ReducedNote) => {
        showConfirmDialog({
            title: 'Delete note',
            content: <div>Are you sure you want to delete this note?</div>,
            confirmButtonText: 'Delete note',
            callback: async () => {
                await notesService.deleteNote(ideaId, note.id);
                await loadNotes(filterTextRef.current);
            }
        });
    };

    const handleSearchTextChange = useCallback(
        async (text?: string) => {
            await loadNotes(text);
            setSearchPending(false);
        },
        [loadNotes]
    );

    const handleSearchTextChangeDebounced = useMemo(() => debounce(handleSearchTextChange, 300), [handleSearchTextChange]);

    useEffect(() => {
        loadNotes(filterTextRef.current);
    }, [loadNotes]);

    const noteTemplates = [
        { type: NoteTemplateType.DailyPlanning, text: 'Daily planning template', illustration: dailyPlanningIllustrationUrl },
        { type: NoteTemplateType.MeetingNotes, text: 'Meeting notes', illustration: meetingNotesIllustrationUrl },
        { type: NoteTemplateType.ReleaseNotes, text: 'Release notes', illustration: releaseNotesIllustrationUrl }
    ];

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-6 k-min-h-full">
            <TextMarker text="Early Preview">
                <H1>Notes</H1>
            </TextMarker>
            <PredefinedActionsList className="k-shrink-0" disabled={loading || isCreatingNote}>
                <PredefinedAction text="Create new note" action={() => handleCreateNote()} disabled={!canEditNotes}>
                    <CreateScriptIcon width="48" height="48" className="k-icp-icon k-my-2 k-mx-auto" />
                </PredefinedAction>

                {noteTemplates.map(template => (
                    <PredefinedAction
                        key={template.type}
                        text={template.text}
                        cardClassName="k-pos-relative"
                        action={() => handleCreateNote(template.type)}
                        disabled={!canEditNotes}
                    >
                        <img src={template.illustration} alt={template.text} width="64" height="64" className="k-display-block k-mx-auto" />
                        <AILabel className="k-pos-absolute k-top-1 k-left-1">Template</AILabel>
                    </PredefinedAction>
                ))}
            </PredefinedActionsList>

            {notes === undefined ? (
                <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                    <LoadingIndicator size="big" />
                </StackLayout>
            ) : notes === null ? (
                <NotesEmptyView className="k-flex-1" />
            ) : (
                <StackLayout
                    orientation="vertical"
                    align={{ horizontal: 'stretch', vertical: 'top' }}
                    className="k-flex-1 k-min-h-0 page-content-middle page-content--xxl"
                >
                    <SearchBar
                        placeholder="Search by note name"
                        className="k-mb-6"
                        disabled={loading}
                        isLoading={searchPending}
                        onChange={text => {
                            setSearchPending(true);
                            filterTextRef.current = text;
                            handleSearchTextChangeDebounced(text);
                        }}
                    />
                    {notes.totalCount > 0 ? (
                        <NotesGrid
                            notes={notes.notes}
                            className="k-overflow-auto"
                            onDuplicateNote={handleDuplicateNote}
                            onDeleteNote={handleDeleteNote}
                            readonly={!canEditNotes}
                        />
                    ) : (
                        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1 k-gap-6">
                            <NotesInCircleIllustration />
                            <div>No notes found</div>
                        </StackLayout>
                    )}
                    <Pager
                        total={notes.totalCount}
                        skip={skip}
                        take={listPreferences.pageSize}
                        onPageChange={(skip, take) => {
                            const newListPreferences = { ...listPreferences, pageSize: take };
                            listPreferencesService.saveNotesListPreferences(newListPreferences);
                            setListPreferences(newListPreferences);
                            setSkip(skip);
                        }}
                    />
                </StackLayout>
            )}
            {confirmDialog}
        </StackLayout>
    );
}

function NotesEmptyView({ className }: { className?: string }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className={combineClassNames(className, 'k-gap-6')}>
            <NotesIllustration />
            <div>Get a note template or create one yourself</div>
        </StackLayout>
    );
}

function NotesGrid({
    notes,
    className,
    readonly,
    onDuplicateNote,
    onDeleteNote
}: {
    notes: ReducedNote[];
    className?: string;
    readonly?: boolean;
    onDuplicateNote?: (note: ReducedNote) => void;
    onDeleteNote?: (note: ReducedNote) => void;
}) {
    const ideaNavigate = useIdeaNavigate();

    function navigateToNote(note: ReducedNote) {
        ideaNavigate(`notes/${note.id}`);
    }

    function handleRowClick(e: GridRowClickEvent) {
        navigateToNote(e.dataItem as ReducedNote);
    }

    const notesWithReadonly = useMemo(() => {
        return notes.map(note => ({ ...note, disabled: readonly }));
    }, [notes, readonly]);

    return (
        <Grid
            data={notesWithReadonly}
            onRowClick={handleRowClick}
            className={combineClassNames(className, 'k-grid-no-scrollbar k-icp-grid-navigatable k-icp-grid-light-header k-overflow-visible')}
            onItemChange={gridCommandHandler<ReducedNote>(function(dataItem, commandName, commandValue) {
                if (commandName === 'open-note') return navigateToNote(dataItem);
                if (commandName === 'duplicate-note') return onDuplicateNote?.(dataItem);
                if (commandName === 'delete-note') return onDeleteNote?.(dataItem);
            })}
        >
            <GridColumn title="Note" cell={NoteTitleCellTemplate} />
            <GridColumn title="Contributors" cell={ContributorsCellTemplate} width={120} />
            <GridColumn title="Tags" cell={TagsCellTemplate} width={220} />
            <GridColumn title="Last modified" cell={LastModifiedCellTemplate} width={122} />
            <GridColumn cell={ActionsCellTemplate} width={80} />
        </Grid>
    );
}

const NoteTitleCellTemplate = createCellTemplateFromComponent(function({ dataItem }: CellTemplateProps<ReducedNote>) {
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2 k-no-click">
            <RoundedIconBackground icon={NotesIcon} theme="base" size="medium" />
            <div className=" k-mt-1.5">{dataItem.name}</div>
        </StackLayout>
    );
});

const ContributorsCellTemplate = createCellTemplateFromComponent(function({ dataItem }: CellTemplateProps<ReducedNote>) {
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-no-click">
            <StartupMembershipList
                users={dataItem.contributors?.map(contributor => buildUserViewModel(contributor))}
                className="k-icp-avatar-compact-list-sm"
                avatarSize="small"
                maxCount={8}
            />
        </StackLayout>
    );
});

const TagsCellTemplate = createCellTemplateFromComponent(function({ dataItem, cellProps }: CellTemplateProps<ReducedNote>) {
    const containerRef = useRef<HTMLDivElement>(null);
    const orderedTags = useMemo(() => {
        return dataItem.tags?.sort((a, b) => dataItem.tagIdsOrder.indexOf(a.id) - dataItem.tagIdsOrder.indexOf(b.id));
    }, [dataItem.tagIdsOrder, dataItem.tags]);
    const [visibleTags, setVisibleTags] = useState<Array<any>>(orderedTags || []);
    const [overflowCount, setOverflowCount] = useState(0);

    useEffect(() => {
        if (!containerRef.current) return;

        const calculateVisibleTags = () => {
            const container = containerRef.current;
            if (!container || !orderedTags) return;

            if (orderedTags.length < 2) {
                setVisibleTags(orderedTags);
                setOverflowCount(0);
                return;
            }

            const containerWidth = container.clientWidth;
            const tags = [...orderedTags];
            let totalWidth = 0;
            let visibleCount = 0;

            const tempSpan = document.createElement('span');
            tempSpan.style.visibility = 'hidden';
            tempSpan.style.position = 'absolute';
            tempSpan.className = 'k-chip k-chip-sm k-rounded-md k-chip-solid k-chip-solid-base k-icp-chip-solid-base-not-interactive !k-fs-sm !k-p-0';
            const additionalOffset = 25; //8px palette-color + 4px gap + 12px margin sides total,
            document.body.appendChild(tempSpan);

            const overflowChipWidth = 30;
            const availableWidth = containerWidth - overflowChipWidth;

            for (let i = 0; i < tags.length; i++) {
                tempSpan.textContent = tags[i].name;
                const tagWidth = tempSpan.offsetWidth + additionalOffset;

                if (i > 0 && totalWidth + tagWidth > availableWidth) {
                    break;
                }

                totalWidth += tagWidth;
                visibleCount++;
            }

            document.body.removeChild(tempSpan);

            if (visibleCount < tags.length) {
                setVisibleTags(tags.slice(0, visibleCount));
                setOverflowCount(tags.length - visibleCount);
            } else {
                setVisibleTags(tags);
                setOverflowCount(0);
            }
        };

        calculateVisibleTags();

        const resizeObserver = new ResizeObserver(calculateVisibleTags);
        const container = containerRef.current;
        resizeObserver.observe(container);

        return () => {
            if (container) {
                resizeObserver.unobserve(container);
            }
        };
    }, [dataItem.tags, orderedTags]);

    return (
        <div className="k-no-click" ref={containerRef}>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                {visibleTags.map(t => (
                    <Chip size="small" key={t.id} text={t.name} className="k-icp-chip-solid-base-not-interactive !k-fs-sm !k-p-0">
                        <span className="palette-color palette-color-xs k-flex-shrink-0" style={{ backgroundColor: `#${t.colorCode}` }}></span>
                        <span className="k-mx-1 k-text-ellipsis">{t.name}</span>
                    </Chip>
                ))}
                {overflowCount > 0 && (
                    <Chip size="small" text={`+${overflowCount}`} className="k-flex-shrink-0 k-icp-chip-solid-base-not-interactive !k-fs-sm !k-p-0" />
                )}
            </StackLayout>
        </div>
    );
});

const LastModifiedCellTemplate = createCellTemplateFromComponent(function({ dataItem }: CellTemplateProps<ReducedNote>) {
    const now = new Date();
    const updatedDate = dataItem.updatedOn as Date;

    if (dateTimeService.isToday(updatedDate)) {
        return <div className="k-fs-sm k-no-click">{dateTimeService.stringifyDuration(now, updatedDate, true)}</div>;
    }

    return <div className="k-fs-sm k-no-click">{dateTimeService.stringifyToDay(updatedDate, false, true)}</div>;
});

const ActionsCellTemplate = createCellTemplateFromComponent(function({ dataItem: note, cellProps }: CellTemplateProps<ReducedNote & { disabled?: boolean }>) {
    return (
        <div className="k-display-flex k-justify-content-end">
            <BoundDropDownButton
                fillMode="flat"
                size="small"
                icon="more-horizontal"
                popupSettings={{
                    anchorAlign: { horizontal: 'left', vertical: 'bottom' },
                    popupAlign: { horizontal: 'left', vertical: 'top' }
                }}
                items={[
                    {
                        text: 'Open',
                        action: e => triggerGridCommand(cellProps, e.syntheticEvent, 'open-note')
                    },
                    {
                        text: 'Duplicate',
                        action: e => triggerGridCommand(cellProps, e.syntheticEvent, 'duplicate-note'),
                        disabled: note.disabled
                    },
                    {
                        text: 'Delete note',
                        action: e => triggerGridCommand(cellProps, e.syntheticEvent, 'delete-note'),
                        separated: true,
                        disabled: note.disabled,
                        danger: true
                    }
                ]}
            />
        </div>
    );
});
