import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { canvasItemsZoneDependent } from '../../components/canvas/canvasItemsZone';
import { PersonPickerInput } from '../../components/contacts/contactPicker';
import { IVAUser, IVA_USER, UNKNOWN_HOST_USER, UnknownUser, hostValidator, intervieweeValidator, scriptValidator } from '../../components/interview2/common';
import { HostsPicker } from '../../components/interview2/hostPicker';
import { CreateNewScriptOptions, useCreateNewScriptWithOptions } from '../../components/interviewScript2/createNewScriptOptionsComponent';
import { FullScreenModalWithIcon } from '../../components/ui/appModal';
import { ValidatedInput } from '../../components/ui/inputs';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { useInProgressOperationsTracker, useSingleClickCallback } from '../../hooks/commonHooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { ReactComponent as InterviewAddIcon } from '../../icons/interview-add.svg';
import { ReducedPerson } from '../../services/contactsService';
import { InterviewScript2, interviewScripts2Service } from '../../services/interviewScripts2Service';
import { Interview2Type, InterviewV2, interviewsV2Service } from '../../services/interviewsV2Service';
import { ReducedUser } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { InterviewScriptSelector } from '../scripts/interviewScriptSelector';

export type CreateInterviewOptions = {
    researchId?: number;
    scriptType?: Interview2Type;
    scriptCustomerSegmentId?: number;
    defaultScriptId?: number;
    cancelReturnUrl?: string;
    saveReturnUrl?: string | ((interview: InterviewV2) => string);
};
export const CreateInterviewPage = canvasItemsZoneDependent(function CreateInterviewPage() {
    const navigate = useNavigate();
    const { state: options } = useLocation() as { state?: CreateInterviewOptions };
    const { ideaId } = useIdeaParams();
    const [defaultInterviewScript, setDefaultInterviewScript] = useState<InterviewScript2>();
    const currentUserId = useAppSelector(s => s.user?.userId);
    const [isSubmitInProgress, trackFormSubmit] = useInProgressOperationsTracker();
    const { handleChosenAction, createScriptModal, editScriptModal } = useCreateNewScriptWithOptions({
        ideaId,
        onScriptEditorClose: scriptId => {
            prefillScript(scriptId);
        }
    });
    const [isCreatingNewScript, handleNewScriptCallback] = useSingleClickCallback(handleChosenAction) as [boolean, typeof handleChosenAction];
    const formRef = useRef<Form>(null);

    const defaultScriptId = options?.defaultScriptId;
    useEffect(() => {
        if (typeof defaultScriptId !== 'number') return;

        interviewScripts2Service.getInterviewScript(ideaId, defaultScriptId).then(setDefaultInterviewScript);
    }, [defaultScriptId, ideaId]);

    const isLoading = !ideaId || (typeof defaultScriptId === 'number' && !defaultInterviewScript);

    const prefillScript = async (scriptId: number) => {
        const script = await interviewScripts2Service.getInterviewScript(ideaId, scriptId);
        formRef.current?.valueSetter('script', script);
        formRef.current?.forceUpdate();
    };

    async function onCreateInterviewSubmit(data: Record<string, any>) {
        const interviewee = data.interviewee as ReducedPerson;
        const script = data.script as InterviewScript2;
        const host = data.host as IVAUser | UnknownUser | ReducedUser;
        const aiLed = host.userId === IVA_USER.userId;
        const hostId = host.userId !== IVA_USER.userId && host.userId !== UNKNOWN_HOST_USER.userId ? host.userId : undefined;

        const createdInterview = await interviewsV2Service.createInterview(ideaId, interviewee.id, script.id, aiLed, hostId, options?.researchId);
        const returnUrl = options?.saveReturnUrl
            ? typeof options.saveReturnUrl === 'string'
                ? options.saveReturnUrl
                : options.saveReturnUrl(createdInterview)
            : `../${createdInterview.id}`;
        navigate(returnUrl);
    }

    const cancelReturnUrl = options?.cancelReturnUrl || './..';
    return (
        <FullScreenModalWithIcon title="New interview" onClose={() => navigate(cancelReturnUrl)} icon={InterviewAddIcon}>
            {!isLoading ? (
                <Form
                    ref={formRef}
                    initialValues={{
                        host: IVA_USER,
                        script: defaultInterviewScript
                    }}
                    onSubmit={trackFormSubmit(onCreateInterviewSubmit)}
                    render={({ allowSubmit, onChange: onFormChange }) => {
                        return (
                            <FormElement className="k-p-4">
                                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-10 page-content-middle">
                                    <Field
                                        name="interviewee"
                                        label="Interviewee"
                                        labelClassName="!k-mb-4 k-font-bold"
                                        component={ValidatedInput}
                                        inputType={PersonPickerInput}
                                        simplePickerEmptyText="No contacts found"
                                        ideaId={ideaId}
                                        simplePicker
                                        placeholder="Select or create contact..."
                                        validator={intervieweeValidator}
                                    />
                                    <div>
                                        <Field
                                            name="script"
                                            label="Script"
                                            labelClassName="!k-mb-4 k-font-bold"
                                            component={ValidatedInput}
                                            inputType={InterviewScriptSelector}
                                            ideaId={ideaId}
                                            customerSegmentId={options?.scriptCustomerSegmentId}
                                            interviewType={options?.scriptType}
                                            validator={scriptValidator}
                                            placeholder="Select from existing scripts..."
                                        />

                                        <div className="k-mt-6">Or create new script</div>
                                        <CreateNewScriptOptions
                                            disabled={!!isSubmitInProgress || isCreatingNewScript}
                                            onAction={type =>
                                                type === 'blank' || type === 'file'
                                                    ? handleNewScriptCallback(type, options?.scriptCustomerSegmentId, options?.scriptType)
                                                    : handleNewScriptCallback(type, options?.scriptCustomerSegmentId)
                                            }
                                            interviewTypes={options?.scriptType ? [options?.scriptType] : undefined}
                                        />
                                    </div>

                                    <Field
                                        name="host"
                                        label="Interview host"
                                        labelClassName="!k-mb-4 k-font-bold"
                                        component={ValidatedInput}
                                        inputType={HostsPicker}
                                        otherHostsComingSoon
                                        emptyText="No hosts found"
                                        addIva
                                        ideaId={ideaId}
                                        currentUserId={currentUserId}
                                        validator={hostValidator}
                                    />
                                    <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-4">
                                        <Button size="large" fillMode="solid" themeColor="primary" type="submit" disabled={!allowSubmit || isSubmitInProgress}>
                                            Create interview
                                        </Button>
                                        <Button
                                            size="large"
                                            fillMode="solid"
                                            themeColor="base"
                                            disabled={isSubmitInProgress}
                                            onClick={() => navigate(cancelReturnUrl)}
                                        >
                                            Cancel
                                        </Button>
                                    </StackLayout>
                                </StackLayout>
                            </FormElement>
                        );
                    }}
                ></Form>
            ) : (
                <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className="k-h-full k-w-full">
                    <LoadingIndicator size="big" />
                </StackLayout>
            )}
            {createScriptModal}
            {editScriptModal}
        </FullScreenModalWithIcon>
    );
});
