import { StackLayout } from '@progress/kendo-react-layout';
import { useNavigate } from 'react-router-dom';
import { BoundDropDownButton } from '../../components/common/boundDropDownButton';
import { hypothesisGroupLabelMap, hypothesisGroupUrlPathMap } from '../../components/hypotheses/common';
import { SvgIconButtonContent } from '../../components/ui/svgIconButtonContent';
import { H1 } from '../../components/ui/typography';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { HypothesisGroup } from '../../services/hypothesesService';
import { UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { TabHeader, TabbedLayout } from '../layouts/tabbedLayout';

const tabs: TabHeader[] = [
    {
        title: `${hypothesisGroupLabelMap[HypothesisGroup.CustomerProblem]} Hypotheses`,
        to: hypothesisGroupUrlPathMap[HypothesisGroup.CustomerProblem]
    },
    {
        title: `${hypothesisGroupLabelMap[HypothesisGroup.Solution]} Hypotheses`,
        to: hypothesisGroupUrlPathMap[HypothesisGroup.Solution]
    },
    {
        title: `${hypothesisGroupLabelMap[HypothesisGroup.GoToMarket]} Hypotheses`,
        to: hypothesisGroupUrlPathMap[HypothesisGroup.GoToMarket]
    },
    {
        title: `${hypothesisGroupLabelMap[HypothesisGroup.FeasibilityAndViability]} Hypotheses`,
        to: hypothesisGroupUrlPathMap[HypothesisGroup.FeasibilityAndViability]
    }
];

export function HypothesesPage() {
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canCreateHypothesis = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const navigate = useNavigate();

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="-minh100">
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-8 heading-row">
                <H1>Hypotheses</H1>
                {canCreateHypothesis && (
                    <>
                        <div className="vertical-separator" />
                        <BoundDropDownButton
                            text={<SvgIconButtonContent icon={PlusIcon}>New hypothesis</SvgIconButtonContent>}
                            items={[
                                {
                                    text: `${hypothesisGroupLabelMap[HypothesisGroup.CustomerProblem]} Hypothesis`,
                                    disabled: !canCreateHypothesis,
                                    action() {
                                        navigate(`${hypothesisGroupUrlPathMap[HypothesisGroup.CustomerProblem]}/create`);
                                    }
                                },
                                {
                                    text: `${hypothesisGroupLabelMap[HypothesisGroup.Solution]} Hypothesis`,
                                    disabled: true || !canCreateHypothesis,
                                    action() {
                                        navigate(`${hypothesisGroupUrlPathMap[HypothesisGroup.Solution]}/create`);
                                    }
                                },
                                {
                                    text: `${hypothesisGroupLabelMap[HypothesisGroup.GoToMarket]} Hypothesis`,
                                    disabled: true || !canCreateHypothesis,
                                    action() {
                                        navigate(`${hypothesisGroupUrlPathMap[HypothesisGroup.GoToMarket]}/create`);
                                    }
                                },
                                {
                                    text: `${hypothesisGroupLabelMap[HypothesisGroup.FeasibilityAndViability]} Hypothesis`,
                                    disabled: true || !canCreateHypothesis,
                                    action() {
                                        navigate(`${hypothesisGroupUrlPathMap[HypothesisGroup.FeasibilityAndViability]}/create`);
                                    }
                                }
                            ]}
                            themeColor="primary"
                            size="large"
                        />
                    </>
                )}
            </StackLayout>

            <TabbedLayout headers={tabs} />
        </StackLayout>
    );
}
