import { Dialog } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { PopupPropsContext } from '@progress/kendo-react-popup';
import { useEffect, useRef, useState } from 'react';
import LoadingIndicator from '../ui/loadingIndicator';

import { InterviewTranscriptEntry } from '../../services/interviewsV2Service';
import { InterviewTranscriptList } from '../ai/transcriptView';
import { InterviewStatusIndicator, ScriptQuestionCard, ScriptQuestionsSection, ScriptSection, StickyStamp } from './common';
import { InterviewStatus, MockHumanLedChatMessages, MockNotes, MockScriptCompletedSections } from './interviewMockData';

type TextDoc = {
    id: number;
    text: string;
};

type InterviewPreviewPageProps = {
    interviewTitle: string;
    interviewStatus: InterviewStatus;
    notes: TextDoc;
};

const MockInterviewPreviewPageProps: InterviewPreviewPageProps = {
    interviewTitle: 'Interview with John Doe',
    interviewStatus: InterviewStatus.Completed,
    notes: MockNotes
};

type InterviewData = {
    interviewStatus: InterviewStatus;
    interviewTitle: string;
    transcriptEntries: InterviewTranscriptEntry[];
};

const MockInterviewData: InterviewData = {
    interviewStatus: InterviewStatus.Completed,
    interviewTitle: 'Interview with John Doe',
    transcriptEntries: MockHumanLedChatMessages
};

export function HumanLedInterviewViewModal({ onClose }: { onClose?: () => void }) {
    const dialogRef = useRef<Dialog>(null);
    const [interviewData, setInterviewData] = useState<InterviewData | undefined>(undefined);

    useEffect(() => {
        const timer = setTimeout(() => {
            setInterviewData(MockInterviewData);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const isLoading = interviewData === undefined;

    return (
        <PopupPropsContext.Provider value={p => (dialogRef.current?.element ? { ...p, appendTo: dialogRef.current?.element } : p)}>
            <Dialog
                ref={dialogRef}
                className="k-icp-dialog-maximized k-icp-dialog-no-padding k-icp-dialog-with-title-shadow"
                title={
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-flex-1">
                        <div className="k-text-ellipsis">{MockInterviewPreviewPageProps.interviewTitle}</div>
                        <InterviewStatusIndicator text={'Completed interview'} type="success" />
                    </StackLayout>
                }
                onClose={onClose}
            >
                {isLoading ? (
                    <div className="k-display-flex k-justify-content-center k-align-items-center k-w-full k-h-full">
                        <LoadingIndicator size="big" />
                    </div>
                ) : (
                    <HumanLedChatPreview
                        scriptSections={MockScriptCompletedSections}
                        transcriptEntries={interviewData.transcriptEntries}
                        notesTxt={MockInterviewPreviewPageProps.notes.text}
                    />
                )}
            </Dialog>
        </PopupPropsContext.Provider>
    );
}

function HumanLedChatPreview({
    scriptSections,
    transcriptEntries,
    notesTxt
}: {
    scriptSections: ScriptSection[];
    transcriptEntries: InterviewTranscriptEntry[];
    notesTxt: string;
}) {
    const transcriptPanel = (
        <div className="k-px-4 k-pb-4">
            <StickyStamp text="Interview transcript" />
            <InterviewTranscriptList transcriptEntries={transcriptEntries} />
        </div>
    );

    const scriptPanel = (
        <div className="k-px-4 k-pb-4">
            <StickyStamp text="Script questions" />
            <StackLayout orientation="vertical" className="k-gap-4">
                {scriptSections.map((section, index) => (
                    <ScriptQuestionsSection key={index} section={section}>
                        {section.questions.map((question, index) => (
                            <ScriptQuestionCard key={index} status={question.status} text={question.text} />
                        ))}
                    </ScriptQuestionsSection>
                ))}
            </StackLayout>
        </div>
    );

    const notesPanel = (
        <div className="k-px-4 k-pb-4">
            <StickyStamp text="Notes" />
            <div className="k-white-space-pre-wrap">{notesTxt}</div>
        </div>
    );

    return <HumanLedChat3PanelsLayout panel1={transcriptPanel} panel2={scriptPanel} panel3={notesPanel} />;
}

function HumanLedChat3PanelsLayout({ panel1, panel2, panel3 }: { panel1: React.ReactNode; panel2: React.ReactNode; panel3: React.ReactNode }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }} className="k-h-full">
            <div className="k-flex-1 k-overflow-auto">{panel1}</div>
            <div className="k-overflow-auto k-flex-1 k-icp-component-border k-icp-panel-base k-border-r k-border-r-solid k-border-l k-border-l-solid">
                {panel2}
            </div>
            <div className="k-flex-1 k-overflow-auto">{panel3}</div>
        </StackLayout>
    );
}
