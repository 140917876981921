import { useCallback, useEffect, useRef } from 'react';

export const DEFAULT_THROTTLE_TIMEOUT_MS = 15000;

/**
 * A hook that provides throttled fetch functionality to prevent excessive API calls
 *
 * @param fetchFn The function to call for fetching data
 * @param throttleTimeoutMs Optional timeout in milliseconds (defaults to 15000ms)
 * @returns An object with throttledFetch function and cleanup function
 */
export function useThrottledFetch<T extends (...args: any[]) => any>(fetchFn: T, throttleTimeoutMs = DEFAULT_THROTTLE_TIMEOUT_MS) {
    const throttleTimerRef = useRef<NodeJS.Timeout | null>(null);
    const pendingRefreshRef = useRef<boolean>(false);

    useEffect(() => {
        return () => {
            if (throttleTimerRef.current) {
                clearTimeout(throttleTimerRef.current);
                throttleTimerRef.current = null;
            }
        };
    }, []);

    const throttledFetch = useCallback(() => {
        if (throttleTimerRef.current) {
            pendingRefreshRef.current = true;
            return;
        }

        fetchFn();

        throttleTimerRef.current = setTimeout(() => {
            throttleTimerRef.current = null;
            if (pendingRefreshRef.current) {
                pendingRefreshRef.current = false;
                fetchFn();
            }
        }, throttleTimeoutMs);
    }, [fetchFn, throttleTimeoutMs]);

    return { throttledFetch };
}
