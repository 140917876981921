import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AILabel } from '../../components/ai/aiLabel';
import { CreateStartupModal } from '../../components/startup/createStartupModal';
import StartupCard from '../../components/startup/startupCard';
import { GTM_INTRO_CHAT_TYPE } from '../../components/ui/chatComponents';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { PredefinedAction, PredefinedActionsList } from '../../components/ui/predefinedActions';
import { H1 } from '../../components/ui/typography';
import { useSingleClickCallback } from '../../hooks/commonHooks';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { useRealTimeUpdatesFocus } from '../../hooks/realTimeUpdatesHooks';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import growBusinessIllustrationUrl from '../../images/grow_business_illustration.svg';
import developIdeaIllustrationUrl from '../../images/idea_develop_illustration.svg';
import ideasIllustrationUrl from '../../images/ideas-illustration.svg';
import blankStartupsImage from '../../images/startups_blank_illustration.svg';
import { googleTagManager } from '../../scripts/googleTagManager';
import { AcceptedInviteEventData, appEventHub } from '../../services/appEvents';
import { dateTimeService } from '../../services/dateTimeService';
import { Idea, IdeaTemplate, Invite, ideasService } from '../../services/ideasService';
import { ConnectionFocus, RealTimeUpdateIdeaEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { ReducedUserViewModel, UserMembershipEntry, UserRole, generateInitials, getPreferredColorIndex } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';

export interface UserMembershipViewModel extends UserMembershipEntry {
    user: ReducedUserViewModel;
}
export interface IdeaViewModel extends Idea {
    currentUserRole?: UserRole;
    memberships?: UserMembershipViewModel[];
    editedAgo?: string;
}

const castIdeaToIdeaViewModel = (idea: Idea, currentUserId: string | undefined): IdeaViewModel => {
    const ideaViewModel = idea as IdeaViewModel;
    ideaViewModel.currentUserRole = idea.memberships?.find(m => m.user.userId === currentUserId)?.role;
    ideaViewModel.memberships?.forEach(m => {
        m.user.initials = generateInitials(m.user, 2);
        m.user.colorIndex = getPreferredColorIndex(m.user);
    });

    if (idea.stats?.lastActivity) {
        ideaViewModel.editedAgo = dateTimeService.stringifyDuration(new Date(), idea.stats.lastActivity);
    }

    return ideaViewModel;
};

const STARTUP_TYPE_DATA_MAP: Partial<Record<IdeaTemplate, { label: string; illustrationUrl: string; trackingEvent: string }>> = {
    [IdeaTemplate.NoIdea]: {
        label: 'Discover a new business idea with IVA',
        illustrationUrl: ideasIllustrationUrl,
        trackingEvent: GTM_INTRO_CHAT_TYPE.LOOKING_FOR_BUSINESS_IDEA
    },
    [IdeaTemplate.ExistingIdea]: {
        label: 'Develop my idea with IVA',
        illustrationUrl: developIdeaIllustrationUrl,
        trackingEvent: GTM_INTRO_CHAT_TYPE.WITH_BUSINESS_IDEA
    },
    [IdeaTemplate.ExistingBusiness]: {
        label: 'Grow my business with IVA',
        illustrationUrl: growBusinessIllustrationUrl,
        trackingEvent: GTM_INTRO_CHAT_TYPE.IMPROVE_EXISTING_BUSINESS
    }
};

export default function StartupsPage() {
    const [ideas, setIdeas] = useState<IdeaViewModel[]>([]);
    const [loading, setLoading] = useState(true);
    const currentUserId = useAppSelector(s => s.user?.userId);
    const { show: showConfirmDialog, element: confirmDialog } = useConfirmDialog();
    const [showCreateModal, setShowCreateModal] = useState(false);
    useRealTimeUpdatesFocus(ConnectionFocus.AllIdeas);
    const navigate = useNavigate();

    const fetchAvailableIdeas = useCallback(async () => {
        const availableIdeasResponse = await ideasService.getAvailable(true, true, true);
        setIdeas(availableIdeasResponse.map(i => castIdeaToIdeaViewModel(i, currentUserId)));
    }, [currentUserId]);

    const loadAvailableIdeas = useCallback(async () => {
        setLoading(true);
        try {
            await fetchAvailableIdeas();
        } finally {
            setLoading(false);
        }
    }, [fetchAvailableIdeas]);

    useEffect(() => {
        loadAvailableIdeas();
    }, [loadAvailableIdeas]);

    const [isCreatingStartup, onCreateStartupClick] = useSingleClickCallback(async (ideaTemplate: IdeaTemplate) => {
        const createdStartup = await ideasService.create({
            useIntroChat: true,
            template: ideaTemplate
        });
        googleTagManager.reportStartupCreatedEvent();
        googleTagManager.reportChatOptionSelectedEvent(STARTUP_TYPE_DATA_MAP[ideaTemplate]!.trackingEvent, 'create-idea');
        navigate(ideasService.getStartupRedirectUrlById(true, createdStartup.uniqueId));
    });

    const deleteIdea = async (ideaId: string) => {
        setIdeas(ideas.filter(i => i.uniqueId !== ideaId));
        await ideasService.delete(ideaId);
    };

    const deleteIdeaWithConfirmation = (ideaId: string) => {
        const ideaToDelete = ideas.find(i => i.uniqueId === ideaId);
        if (!ideaToDelete) return;

        showConfirmDialog({
            title: 'Delete startup',
            content: (
                <>
                    Are you sure you want to delete the <strong>“{ideaToDelete.title}”</strong> startup?
                </>
            ),
            confirmCheckboxText: 'I acknowledge that this cannot be undone',
            confirmButtonText: 'Delete startup',
            callback: () => deleteIdea(ideaId)
        });
    };

    const leaveIdea = async (ideaId: string) => {
        setIdeas(ideas.filter(i => i.uniqueId !== ideaId));
        await ideasService.leave(ideaId);
    };

    const leaveIdeaWithConfirmation = (ideaId: string) => {
        const ideaToLeave = ideas.find(i => i.uniqueId === ideaId);
        if (!ideaToLeave) return;

        showConfirmDialog({
            title: 'Leave startup',
            content: (
                <>
                    Are you sure you want to leave <strong>“{ideaToLeave.title}”</strong> startup?
                </>
            ),
            confirmButtonText: 'Leave startup',
            callback: () => leaveIdea(ideaId)
        });
    };

    const onStartupClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const clickedElement = e.target as HTMLElement;
        if (!clickedElement) return;
        // Prevent default if:
        // 1. The clicked element is outside of the link in the DOM thee but is inside in the react virtual dom tree or
        // 2. The clicked element is inside a startup-card-actions-area
        if (!e.currentTarget.contains(clickedElement) || clickedElement.closest('.startup-card-actions-area')) e.preventDefault();
    };

    const onInviteCreated = (ideaId: string, invite: Invite) => {
        setIdeas(ideas =>
            ideas.map(i => {
                if (i.uniqueId !== ideaId) return i;

                return { ...i, invites: [...(i.invites || []), invite] };
            })
        );
    };

    const refreshIdea = useCallback(
        async (e: RealTimeUpdateIdeaEventData | AcceptedInviteEventData) => {
            const freshIdea = await ideasService.get(e.ideaId, true, true, true);
            if (!freshIdea) return;

            const freshIdeaViewModel = castIdeaToIdeaViewModel(freshIdea, currentUserId);

            setIdeas(ideas => [freshIdeaViewModel, ...ideas.filter(i => i.uniqueId !== freshIdeaViewModel.uniqueId)]);
        },
        [currentUserId]
    );

    useEffect(() => {
        const removeIdea = (e: RealTimeUpdateIdeaEventData) => {
            setIdeas(ideas => ideas.filter(i => i.uniqueId !== e.ideaId));
        };

        appEventHub.addEventListener('invitation', 'accepted', refreshIdea);

        realTimeUpdatesEventHub.addEventListener('membership', 'new', refreshIdea);
        realTimeUpdatesEventHub.addEventListener('membership', 'change', refreshIdea);
        realTimeUpdatesEventHub.addEventListener('membership', 'revoke', removeIdea);

        realTimeUpdatesEventHub.addEventListener('idea', 'created', refreshIdea);
        realTimeUpdatesEventHub.addEventListener('idea', 'updated', refreshIdea);
        realTimeUpdatesEventHub.addEventListener('idea', 'restored', refreshIdea);
        realTimeUpdatesEventHub.addEventListener('idea', 'deleted', removeIdea);

        realTimeUpdatesEventHub.addEventListener('connection', 'reconnected', fetchAvailableIdeas);

        return () => {
            appEventHub.removeEventListener('invitation', 'accepted', refreshIdea);

            realTimeUpdatesEventHub.removeEventListener('membership', 'new', refreshIdea);
            realTimeUpdatesEventHub.removeEventListener('membership', 'change', refreshIdea);
            realTimeUpdatesEventHub.removeEventListener('membership', 'revoke', removeIdea);

            realTimeUpdatesEventHub.removeEventListener('idea', 'created', refreshIdea);
            realTimeUpdatesEventHub.removeEventListener('idea', 'updated', refreshIdea);
            realTimeUpdatesEventHub.removeEventListener('idea', 'restored', refreshIdea);
            realTimeUpdatesEventHub.removeEventListener('idea', 'deleted', removeIdea);

            realTimeUpdatesEventHub.removeEventListener('connection', 'reconnected', fetchAvailableIdeas);
        };
    }, [refreshIdea, fetchAvailableIdeas]);

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="main-column page-content-section k-gap-6">
            <H1>My startups</H1>
            <PredefinedActionsList className="k-shrink-0">
                {[IdeaTemplate.NoIdea, IdeaTemplate.ExistingIdea, IdeaTemplate.ExistingBusiness].map(interviewType => {
                    const interviewTypeLabel = STARTUP_TYPE_DATA_MAP[interviewType]!;
                    return (
                        <PredefinedAction
                            key={interviewType}
                            text={interviewTypeLabel.label}
                            cardClassName="k-pos-relative"
                            action={() => onCreateStartupClick(interviewType)}
                            disabled={isCreatingStartup}
                        >
                            <img
                                src={interviewTypeLabel.illustrationUrl}
                                alt={interviewTypeLabel.label}
                                width="64"
                                height="64"
                                className="k-display-block k-mx-auto"
                            />
                            <AILabel className="k-pos-absolute k-top-1 k-left-1">Create with IVA</AILabel>
                        </PredefinedAction>
                    );
                })}
                <PredefinedAction text="Start from scratch" action={() => setShowCreateModal(true)} disabled={isCreatingStartup}>
                    <PlusIcon width="48" height="48" className="k-icp-icon k-my-2 k-mx-auto" />
                </PredefinedAction>
            </PredefinedActionsList>

            {loading ? (
                <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                    <LoadingIndicator size="big" />
                </StackLayout>
            ) : ideas.length ? (
                <div className="cards-relaxed-grid">
                    {ideas.map(idea => {
                        const redirectUrl = ideasService.getStartupRedirectUrl(idea);
                        return (
                            <Link key={idea.uniqueId} className="k-link" to={redirectUrl} onClick={onStartupClick}>
                                <StartupCard
                                    idea={idea}
                                    onView={() => navigate(redirectUrl)}
                                    onSettings={ideasService.isIntroChatPending(idea) ? undefined : () => navigate(`startups/${idea.uniqueId}/settings`)}
                                    onDelete={() => deleteIdeaWithConfirmation(idea.uniqueId)}
                                    onLeave={() => leaveIdeaWithConfirmation(idea.uniqueId)}
                                    onInvited={i => onInviteCreated(idea.uniqueId, i)}
                                />
                            </Link>
                        );
                    })}
                </div>
            ) : (
                <StackLayout className="k-flex-1" orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }}>
                    <img src={blankStartupsImage} alt="No startups" className="k-mb-6" />
                    <div className="page-content-middle" style={{ maxWidth: 480 }}>
                        Let the Icanpreneur Virtual Assistant (IVA) help you put together a business model or create a new startup and do so at your own pace
                    </div>
                </StackLayout>
            )}
            {confirmDialog}
            {showCreateModal && <CreateStartupModal onClose={() => setShowCreateModal(false)} />}
        </StackLayout>
    );
}
