import { Form } from '@progress/kendo-react-form';
import { Avatar, StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsRef, useSingleClickButton } from '../../hooks/commonHooks';
import { useQueryParam, useReturnUrl } from '../../hooks/routerHooks';
import { AuthForm, AuthFormSubmitButton } from '../../pages/layouts/authLayout';
import { getPersonFullName } from '../../services/common';
import { Idea, ideasService, Invite } from '../../services/ideasService';
import { publicInterviewsService } from '../../services/interviewsV2Service';
import { EmailAddressAvailability, ReducedUser, usersService } from '../../services/usersService';
import { EmailField } from '../ui/inputs';
import { P } from '../ui/typography';

export function UserEmailForm({
    onComplete,
    submitButtonText
}: {
    onComplete?: (email: string, availability: EmailAddressAvailability) => void;
    submitButtonText?: string;
}) {
    const [hasGeneralEmailError, setHasGeneralEmailError] = useState<boolean>();
    const [submitDisabled, submitActionCreator] = useSingleClickButton<Parameters<typeof onSubmit>, ReturnType<typeof onSubmit>>();

    async function onSubmit(data: Record<string, any>) {
        const email: string = data.emailAddress;
        const emailAvailability = await usersService.getEmailAvailability(email);
        if (emailAvailability === EmailAddressAvailability.Deleted) {
            setHasGeneralEmailError(true);
            return;
        }
        onComplete?.(email, emailAvailability);
    }

    return (
        <Form
            onSubmit={submitActionCreator(onSubmit)}
            ignoreModified={true}
            render={formRenderProps => (
                <AuthForm
                    button={
                        <AuthFormSubmitButton disabled={!formRenderProps.allowSubmit || submitDisabled}>{submitButtonText || 'Continue'}</AuthFormSubmitButton>
                    }
                >
                    <EmailField errorMessage={hasGeneralEmailError ? <EmailGeneralError /> : undefined} onChange={() => setHasGeneralEmailError(false)} />
                </AuthForm>
            )}
        />
    );
}

function EmailGeneralError() {
    const supportEmailAddress = 'support@icanpreneur.com';
    return (
        <span>
            Cannot register user with this email. Contact{' '}
            <a href={`mailto:${supportEmailAddress}`} className="k-button-link-secondary" target="_blank" rel="noreferrer">
                {supportEmailAddress}
            </a>
            .
        </span>
    );
}

export type AuthUserData = {
    firstName?: string;
    lastName?: string;
};
export type AuthLocationState = { email?: string; hasInvitation?: boolean; activationCode?: string; userData?: AuthUserData; registrationButtonText?: string };
export function useAuthLocationStateParameters(): AuthLocationState {
    const location = useLocation();
    const navigate = useNavigate();
    const returnUrl = useReturnUrl();
    const locationStateParams = location.state as AuthLocationState | undefined;
    const email = locationStateParams?.email;

    useEffect(() => {
        if (email) return;

        navigate(returnUrl);
    }, [email, navigate, returnUrl]);

    return {
        email,
        hasInvitation: locationStateParams?.hasInvitation,
        activationCode: locationStateParams?.activationCode,
        userData: locationStateParams?.userData,
        registrationButtonText: locationStateParams?.registrationButtonText
    };
}

export const useInvitation = (authLocationState: AuthLocationState) => {
    const { hasInvitation } = authLocationState;
    const inviteCode = useQueryParam('inviteCode');
    const [invitationData, setInvitationData] = useState<{ invitation: Invite; inviteCode: string } | undefined>();

    useEffect(() => {
        if (!hasInvitation) return;

        const fetchInvitation = async () => {
            if (!inviteCode) {
                throw new Error('Invite code is missing');
            }

            const invitation = await ideasService.getInvite(inviteCode);
            setInvitationData({ invitation, inviteCode });
        };

        fetchInvitation();
    }, [hasInvitation, inviteCode]);

    return invitationData;
};

export const InvitationMessageComponent = ({ inviteIdea, inviteCreator }: { inviteIdea: Idea; inviteCreator: ReducedUser }) => {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-p-6 k-rounded-lg k-icp-secondary-pale-bg">
            {inviteIdea.logo ? (
                <Avatar rounded="full" type="image" themeColor="base" size="large">
                    <img src={inviteIdea.logo} alt={`${inviteIdea.title} logo`} />
                </Avatar>
            ) : (
                <Avatar rounded="full" type="text" themeColor="base" size="large" className="k-icp-avatar-simple-bg-0 k-icp-avatar-gradient-bg">
                    LOGO
                </Avatar>
            )}
            <P>
                <span className="k-font-weight-semibold">{getPersonFullName(inviteCreator.firstName, inviteCreator.lastName)}</span> has invited you to join
                their startup, <span className="k-font-weight-semibold">{inviteIdea.title}</span>.
            </P>
        </StackLayout>
    );
};

type AuthenticationPrefillData = {
    email: string;
    userData?: AuthUserData;
};

type AuthenticationPrefillDataResolver = {
    resolvePrefillData(params: URLSearchParams): Promise<AuthenticationPrefillData | undefined>;
};

class InterviewPrefillDataResolver implements AuthenticationPrefillDataResolver {
    async resolvePrefillData(params: URLSearchParams): Promise<AuthenticationPrefillData | undefined> {
        const interviewPublicCode = params.get('interview');
        if (!interviewPublicCode) return undefined;

        const interview = await publicInterviewsService.getInterview(interviewPublicCode);
        if (!interview.contact || !interview.contact.emailAddress) return undefined;

        return {
            email: interview.contact.emailAddress,
            userData: {
                firstName: interview.contact.firstName,
                lastName: interview.contact.lastName
            }
        };
    }
}

const prefillDataResolvers: Record<string, AuthenticationPrefillDataResolver> = {
    interview: new InterviewPrefillDataResolver()
};

export function useAuthenticationPrefill(
    queryParams: URLSearchParams,
    onPrefillCompleted?: (resolver: string, data: AuthenticationPrefillData, emailAvailability: EmailAddressAvailability) => void
) {
    const [prefillCompleted, setPrefillCompleted] = useState(false);
    const resolverName = queryParams.get('prefill');
    const resolver = resolverName ? prefillDataResolvers[resolverName] : undefined;
    const onPrefillCompletedRef = useAsRef(onPrefillCompleted);

    useEffect(() => {
        if (!resolver || !resolverName) return;

        (async () => {
            setPrefillCompleted(false);
            const prefillData = await resolver.resolvePrefillData(queryParams);
            if (!prefillData) return;
            const emailAvailability = await usersService.getEmailAvailability(prefillData.email);
            onPrefillCompletedRef.current?.(resolverName, prefillData, emailAvailability);
        })().finally(() => setPrefillCompleted(true));
    }, [onPrefillCompletedRef, resolver, queryParams, resolverName]);

    return resolver !== undefined && !prefillCompleted;
}
