import { ComponentType } from 'react';
import { ReactComponent as DocFileIcon } from '../../icons/file-doc.svg';
import { ReactComponent as DocxFileIcon } from '../../icons/file-docx.svg';
import { ReactComponent as PdfFileIcon } from '../../icons/file-pdf.svg';
import { ReactComponent as PptxFileIcon } from '../../icons/file-pptx.svg';
import { ReactComponent as RtfFileIcon } from '../../icons/file-rtf.svg';
import { ReactComponent as TxtFileIcon } from '../../icons/file-txt.svg';
import { ReactComponent as DefaultFileIcon } from '../../icons/file.svg';
import { combineClassNames } from '../../services/common';

const fileExtensionToIconMap: Partial<Record<string, ComponentType<React.SVGProps<SVGSVGElement>>>> = {
    '.pdf': PdfFileIcon,
    '.docx': DocxFileIcon,
    '.doc': DocFileIcon,
    '.rtf': RtfFileIcon,
    '.txt': TxtFileIcon,
    '.pptx': PptxFileIcon
};

export function FileIcon({
    fileNameOrExtension,
    size = '6',
    className
}: {
    fileNameOrExtension?: string;
    size?: '3' | '4' | '6' | '8' | '10';
    className?: string;
}) {
    if (!fileNameOrExtension) return null;

    const iconClassName = combineClassNames('k-icp-icon', `k-icp-icon-size-${size}`, className);
    const fileExtension = getFileExtension(fileNameOrExtension);

    const FileIconComponent = fileExtension ? fileExtensionToIconMap[fileExtension] ?? DefaultFileIcon : DefaultFileIcon;

    return <FileIconComponent className={iconClassName} />;
}

function getFileExtension(fileNameOrExtension: string) {
    const extensionIndex = fileNameOrExtension.lastIndexOf('.');
    if (extensionIndex === -1) return undefined;
    if (extensionIndex === 0) return fileNameOrExtension;

    return fileNameOrExtension.substring(extensionIndex);
}
