import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as ScriptIcon } from '../../icons/script.svg';
import { ReactComponent as ScriptEmptyIllustration } from '../../images/script-in-circle-illustration.svg';
import { debounce } from '../../services/common';
import { domService } from '../../services/domService';
import { InterviewScript2, interviewScripts2Service } from '../../services/interviewScripts2Service';
import { SearchBar } from '../common/searchbar';
import { ValidationScope, ValidationScopeHandle, ValidationUnit } from '../common/validation';
import { requiredValidator } from '../ui/inputs';
import LoadingIndicator from '../ui/loadingIndicator';
import { RadioOption } from '../ui/radioOption';

const scriptPickerValidator = requiredValidator('Script');

export function ScriptPickerDialog({
    ideaId,
    interviewId,
    title,
    currentScriptId,
    onSave,
    onCancel
}: {
    ideaId: string;
    interviewId: number;
    title: string;
    currentScriptId: number;
    onSave?: (scriptId: number) => void;
    onCancel?: () => void;
}) {
    const [selectedScriptId, setSelectedScriptId] = useState<number | undefined>(currentScriptId);
    const validationScopeRef = useRef<ValidationScopeHandle | null>(null);

    return (
        <ValidationScope ref={validationScopeRef}>
            <Dialog
                title={title}
                width={640}
                onClose={onCancel}
                className="k-icp-dialog-fixed-height"
                contentStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}
            >
                <ValidationUnit key={'script'} name={'script'} value={selectedScriptId} validator={scriptPickerValidator}>
                    {errorMessage => (
                        <>
                            <ScriptOptionsPicker ideaId={ideaId} interviewId={interviewId} value={selectedScriptId} onSelected={setSelectedScriptId} />
                            {errorMessage && <div className="k-mt-2 k-text-error">{errorMessage}</div>}
                        </>
                    )}
                </ValidationUnit>
                <DialogActionsBar layout="center">
                    <Button
                        themeColor="primary"
                        onClick={() => {
                            validationScopeRef.current?.validate();
                            if (validationScopeRef.current?.isValid) onSave?.(selectedScriptId!);
                        }}
                    >
                        Save
                    </Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActionsBar>
            </Dialog>
        </ValidationScope>
    );
}

function ScriptOptionsPicker({
    ideaId,
    interviewId,
    value,
    onSelected
}: {
    ideaId: string;
    interviewId: number;
    value?: number;
    onSelected: (value: number) => void;
}) {
    const [scripts, setScripts] = useState<InterviewScript2[] | undefined>(undefined);
    const [searchPending, setSearchPending] = useState(false);

    const loadScripts = useCallback(
        async (filterText?: string) => {
            const scripts = await interviewScripts2Service.getInterviewCompatibleScripts(ideaId, interviewId, filterText);

            setScripts(scripts);
        },
        [ideaId, interviewId]
    );

    const handleSearchTextChange = useCallback((text?: string) => loadScripts(text).finally(() => setSearchPending(false)), [loadScripts]);

    useEffect(() => {
        scrollToSelectedScript();
    }, [scripts, value]);

    const handleSearchTextChangeDebounced = useMemo(() => debounce(handleSearchTextChange, 300), [handleSearchTextChange]);

    const scrollingContainerRef = useRef<HTMLDivElement>(null);

    const scrollToSelectedScript = () => {
        if (!scrollingContainerRef.current) return;

        const selectedElement = document.querySelector("[data-selected='true']") as HTMLElement;
        if (selectedElement) {
            domService.scrollVerticallyIntoViewIfNeeded(selectedElement, scrollingContainerRef.current);
        }
    };

    useEffect(() => {
        loadScripts();
    }, [loadScripts]);

    return (
        <>
            <div className="k-icp-panel-base k-p-4 k-border-b k-border-b-solid k-icp-component-border">
                <div className="k-mb-2 k-font-weight-bold">Select from existing scripts</div>
                <SearchBar
                    disabled={!scripts}
                    isLoading={searchPending}
                    placeholder="Search for a script"
                    onChange={text => {
                        setSearchPending(true);
                        handleSearchTextChangeDebounced(text);
                    }}
                />
            </div>
            <div className="k-overflow-auto k-flex-1 k-p-4 k-min-h-0" ref={scrollingContainerRef}>
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-3 k-min-h-full">
                    {scripts && scripts.length > 0 ? (
                        scripts.map(script => (
                            <ScriptOptionView key={script.id} isSelected={script.id === value} onSelected={() => onSelected(script.id)} script={script} />
                        ))
                    ) : scripts ? (
                        <StackLayout className="k-flex-1" orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }}>
                            <ScriptEmptyIllustration className="k-mb-6" />
                            <div>No scripts found</div>
                        </StackLayout>
                    ) : (
                        <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                            <LoadingIndicator size="big" />
                        </StackLayout>
                    )}
                </StackLayout>
            </div>
        </>
    );
}

export function ScriptOptionView({ isSelected, onSelected, script }: { isSelected: boolean; onSelected: () => void; script: InterviewScript2 }) {
    return (
        <div data-selected={isSelected}>
            <RadioOption isSelected={isSelected} onClick={onSelected}>
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-flex-1">
                    <ScriptIcon className="k-shrink-0 k-icp-icon k-icp-icon-size-8" />
                    <div className="k-fs-sm">{script.name}</div>
                </StackLayout>
            </RadioOption>
        </div>
    );
}
