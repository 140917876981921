import EventEmitter from 'eventemitter3';
import { ScheduledItem } from './eventsService';

type AppEventsTypeMap = {
    tag: {
        created: TagEventData;
        updated: TagEventData;
        deleted: TagEventData;
        restored: TagEventData;
    };
    noteTag: {
        created: TagEventData;
        updated: TagEventData;
        deleted: TagEventData;
        restored: TagEventData;
    };
    scheduledItem: {
        created: ScheduledItemCreatedEventData;
    };
    invitation: {
        accepted: AcceptedInviteEventData;
    };
    research2: {
        existingInterviewsAdded: Research2ExistingInterviewsAddedEventData;
    };
};

export type AcceptedInviteEventData = { ideaId: string };
export type EventDataBase = { source?: string };
export type TagEventData = EventDataBase & { tagId: number };
export type NoteTagEventData = EventDataBase & { tagId: number };
export type ScheduledItemCreatedEventData = { item: ScheduledItem };
export type Research2ExistingInterviewsAddedEventData = { researchId: number };

class AppEventHub {
    private eventEmitter = new EventEmitter();

    addEventListener<TCategory extends keyof AppEventsTypeMap, TEvent extends keyof AppEventsTypeMap[TCategory] & string>(
        category: TCategory,
        event: TEvent,
        handler: (e: AppEventsTypeMap[TCategory][TEvent]) => void
    ): void {
        this.eventEmitter.on(`${category}.${event}`, handler);
    }

    removeEventListener<TCategory extends keyof AppEventsTypeMap, TEvent extends keyof AppEventsTypeMap[TCategory] & string>(
        category: TCategory,
        event: TEvent,
        handler: (e: AppEventsTypeMap[TCategory][TEvent]) => void
    ): void {
        this.eventEmitter.off(`${category}.${event}`, handler);
    }

    trigger<TCategory extends keyof AppEventsTypeMap, TEvent extends keyof AppEventsTypeMap[TCategory] & string>(
        category: TCategory,
        event: TEvent,
        data: AppEventsTypeMap[TCategory][TEvent]
    ) {
        this.eventEmitter.emit(`${category}.${event}`, data);
    }
}

export const appEventHub = new AppEventHub();
