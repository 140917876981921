import { useState } from 'react';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as UploadIcon } from '../../icons/upload.svg';
import { CreateInterviewScriptModal, CreateInterviewScriptModalConfigProps } from '../../pages/scripts/interviewScriptsPage';
import { InterviewScript2, interviewScripts2Service } from '../../services/interviewScripts2Service';
import { Interview2Type } from '../../services/interviewsV2Service';
import { AILabel } from '../ai/aiLabel';
import { interviewTypeToIllustrationUrlMap, interviewTypeToLabelMap } from '../interview2/common';
import { PredefinedAction, PredefinedActionsList } from '../ui/predefinedActions';
import { InterviewScriptModal } from './InterviewScriptModal';

export function useCreateNewScriptWithOptions({
    ideaId,
    onCreated,
    onCancel,
    onScriptEditorClose
}: {
    ideaId: string;
    onCreated?: (script: InterviewScript2) => void;
    onCancel?: () => void;
    onScriptEditorClose?: (scriptId: number) => void;
}) {
    const [createScriptModalData, setCreateScriptModalData] = useState<CreateInterviewScriptModalConfigProps | undefined>(undefined);
    const [showEditScriptModalId, setShowEditScriptModalId] = useState<number | undefined>(undefined);

    function handleChosenAction(type: Interview2Type, customerSegmentId?: number): void | Promise<void>;
    function handleChosenAction(type: 'blank' | 'file', customerSegmentId?: number, interviewType?: Interview2Type): void | Promise<void>;
    function handleChosenAction(type: 'blank' | 'file' | Interview2Type, customerSegmentId?: number, interviewType?: Interview2Type) {
        if (type === 'blank') {
            if (typeof customerSegmentId === 'number' && interviewType)
                return interviewScripts2Service
                    .createInterviewScript(ideaId, {
                        customerSegmentId,
                        interviewType,
                        useTemplate: false
                    })
                    .then(newBlankScript => setShowEditScriptModalId(newBlankScript.id));

            setCreateScriptModalData({ customerSegmentId, interviewType });
        } else if (type === 'file') {
            setCreateScriptModalData({ import: true, customerSegmentId, interviewType });
        } else {
            setCreateScriptModalData({ automaticGenerationType: type, customerSegmentId });
        }
    }

    const createScriptModal = createScriptModalData ? (
        <CreateInterviewScriptModal
            ideaBaseUrl={`/startups/${ideaId}`}
            ideaId={ideaId}
            {...createScriptModalData}
            onCreated={s => {
                setCreateScriptModalData(undefined);
                setShowEditScriptModalId(s.id);
                onCreated?.(s);
            }}
            onCancel={() => {
                setCreateScriptModalData(undefined);
                onCancel?.();
            }}
        />
    ) : (
        undefined
    );

    const editScriptModal = showEditScriptModalId ? (
        <InterviewScriptModal
            ideaId={ideaId}
            scriptId={showEditScriptModalId}
            onClose={() => {
                onScriptEditorClose?.(showEditScriptModalId);
                setShowEditScriptModalId(undefined);
            }}
        />
    ) : (
        undefined
    );

    return { handleChosenAction, createScriptModal, editScriptModal, editScript: showEditScriptModalId };
}

export function CreateNewScriptOptions({
    onAction,
    disabled,
    interviewTypes
}: {
    onAction: (type: 'blank' | 'file' | Interview2Type) => void;
    disabled: boolean;
    interviewTypes?: Interview2Type[];
}) {
    return (
        <PredefinedActionsList className="k-mt-1" disabled={disabled}>
            <PredefinedAction text="Create script from blank" textClassName="k-fs-sm !k-font-normal" action={disabled ? undefined : () => onAction('blank')}>
                <PlusIcon width="32" height="32" className="k-icp-icon k-my-4 k-mx-auto" />
            </PredefinedAction>
            <PredefinedAction text="Create script from file" textClassName="k-fs-sm !k-font-normal" action={disabled ? undefined : () => onAction('file')}>
                <UploadIcon width="32" height="32" className="k-icp-icon k-my-4 k-mx-auto" />
            </PredefinedAction>

            {(interviewTypes ?? [Interview2Type.ProblemDiscoveryAndValidation, Interview2Type.WinLoss, Interview2Type.BuyerPersona]).map(interviewType => {
                const interviewTypeLabel = interviewTypeToLabelMap[interviewType];
                return (
                    <PredefinedAction
                        key={interviewType}
                        text={`Get an IVA generated ${interviewTypeLabel} interview script`}
                        cardClassName="k-pos-relative k-p-4"
                        textClassName="k-fs-sm !k-font-normal"
                        action={disabled ? undefined : () => onAction(interviewType)}
                    >
                        <img
                            src={interviewTypeToIllustrationUrlMap[interviewType]}
                            alt={interviewTypeLabel}
                            width="48"
                            height="48"
                            className="k-display-block k-mx-auto"
                        />
                        <AILabel className="k-pos-absolute k-top-1 k-left-1">Create with IVA</AILabel>
                    </PredefinedAction>
                );
            })}
        </PredefinedActionsList>
    );
}
