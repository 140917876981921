import { ResearchTypeV2 } from '../../services/researchV2Service';
import { CardsPicker, CardsPickerItem } from '../ui/cardsPicker';
import { FormFieldProps } from '../ui/inputs';
import { researchTypeToIllustrationUrlMap, researchV2TypeTextMap } from './common';

const researchTypePickerCards = [ResearchTypeV2.ProblemDiscoveryAndValidation, ResearchTypeV2.BuyerPersona, ResearchTypeV2.WinLoss].map<
    CardsPickerItem<ResearchTypeV2>
>(researchType => ({
    value: researchType,
    label: `${researchV2TypeTextMap[researchType]} Interviews`,
    image: {
        width: 48,
        height: 48,
        description: researchV2TypeTextMap[researchType],
        url: researchTypeToIllustrationUrlMap[researchType]
    }
}));
export function ResearchTypePicker(formFieldProps: FormFieldProps<ResearchTypeV2>) {
    return <CardsPicker {...formFieldProps} cards={researchTypePickerCards} />;
}
