import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridHeaderCellProps, GridHeaderSelectionChangeEvent, GridSelectionChangeEvent } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useMemo, useState } from 'react';
import { ReactComponent as InterviewsInCircleIllustration } from '../../images/interviews-in-circle-illustration.svg';
import { debounce } from '../../services/common';
import { MinimalInterviewV2 } from '../../services/interviewsV2Service';
import { CellTemplateProps, createCellTemplateFromComponent } from '../common/grid';
import { SearchBar } from '../common/searchbar';
import LoadingIndicator from '../ui/loadingIndicator';
import { InterviewDateGridCellTemplate, IntervieweeInfoGridCellTemplate, InterviewStatusGridCellTemplate } from './interviewsGridComponents';

interface InterviewsGridPickerProps {
    interviews?: MinimalInterviewV2[];
    selectedInterviews: number[];
    handleSearchInterviews?: (text?: string) => Promise<void>;
    onSelectionChange?: (interviewsIds: number[]) => void;
}

interface InterviewWithSelection extends MinimalInterviewV2 {
    isSelected: boolean;
}

export function InterviewsGridPicker({ interviews, selectedInterviews, handleSearchInterviews, onSelectionChange }: InterviewsGridPickerProps) {
    const [searchPending, setSearchPending] = useState(false);

    const handleSearchTextChange = useCallback(
        async (text?: string) => {
            await handleSearchInterviews?.(text);
            setSearchPending(false);
        },
        [handleSearchInterviews]
    );

    const handleSearchTextChangeDebounced = useMemo(() => debounce(handleSearchTextChange, 300), [handleSearchTextChange]);

    const areAllInterviewsSelected = interviews && interviews.length > 0 && interviews.every(i => selectedInterviews.some(id => id === i.id));

    const interviewWithSelection = useMemo(
        () =>
            interviews?.map(interview => ({
                ...interview,
                isSelected: selectedInterviews.some(id => id === interview.id)
            })),
        [interviews, selectedInterviews]
    );

    const handleSelectionChange = (e: GridSelectionChangeEvent) => {
        const dataItem: InterviewWithSelection = e.dataItem ?? e.dataItems[e.startRowIndex];
        if (!dataItem) return;

        let newlySelectedInterviews = [...selectedInterviews];
        if (dataItem.isSelected) {
            newlySelectedInterviews = newlySelectedInterviews.filter(id => id !== dataItem.id);
        } else {
            newlySelectedInterviews.push(dataItem.id);
        }
        onSelectionChange?.(newlySelectedInterviews);
    };

    const handleSelectAllInterviews = (e: GridHeaderSelectionChangeEvent) => {
        if (!interviews) {
            return;
        }

        let newlySelectedInterviews: number[] = [];
        if (areAllInterviewsSelected) {
            newlySelectedInterviews = [...selectedInterviews.filter(id => !interviews.some(i2 => i2.id === id))];
        } else {
            const notSelectedInterviews = interviews.filter(i => !selectedInterviews.some(id => id === i.id)).map(i => i.id);
            newlySelectedInterviews = [...selectedInterviews, ...notSelectedInterviews];
        }
        onSelectionChange?.(newlySelectedInterviews);
    };

    const handleClearSelection = () => {
        onSelectionChange?.([]);
    };

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-overflow-auto k-flex-1">
            <StackLayout
                orientation="horizontal"
                align={{ horizontal: 'start', vertical: 'middle' }}
                className="k-rounded-md k-px-4 k-py-2 k-icp-panel-base k-gap-2"
            >
                <StackLayout className="k-flex-1 k-gap-2" align={{ horizontal: 'start', vertical: 'middle' }}>
                    <div className="k-font-semibold">
                        {selectedInterviews.length > 0
                            ? `${selectedInterviews.length} interview${selectedInterviews.length === 1 ? '' : 's'} selected`
                            : 'No interviews selected'}
                    </div>

                    {selectedInterviews.length > 0 && (
                        <Button size="small" onClick={handleClearSelection}>
                            Clear selection
                        </Button>
                    )}
                </StackLayout>
                <SearchBar
                    className="k-flex-1"
                    isLoading={searchPending}
                    placeholder="Search by interviewee name"
                    onChange={text => {
                        setSearchPending(true);
                        handleSearchTextChangeDebounced(text);
                    }}
                />
            </StackLayout>

            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-overflow-auto k-flex-1">
                {!interviews ? (
                    <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                        <LoadingIndicator size="big" />
                    </StackLayout>
                ) : interviews.length === 0 ? (
                    <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1 k-gap-6 k-mt-6">
                        <InterviewsInCircleIllustration />
                        <div>No relevant interviews found.</div>
                    </StackLayout>
                ) : (
                    <Grid
                        dataItemKey="id"
                        data={interviewWithSelection}
                        onHeaderSelectionChange={handleSelectAllInterviews}
                        className="k-grid-no-scrollbar k-icp-grid-navigatable k-icp-grid-light-header"
                        onSelectionChange={handleSelectionChange}
                        selectable={{ cell: false, mode: 'multiple', enabled: true, drag: false }}
                        selectedField="isSelected"
                    >
                        <GridColumn
                            width={50}
                            cell={InterviewCheckBoxCellTemplate}
                            headerSelectionValue={areAllInterviewsSelected}
                            headerCell={CustomHeaderCell}
                        />
                        <GridColumn title="Interviewee" cell={IntervieweeInfoGridCellTemplate} />
                        <GridColumn title="Interview Status" width={140} cell={InterviewStatusGridCellTemplate} />
                        <GridColumn title="Interview Date" width={120} cell={InterviewDateGridCellTemplate} />
                    </Grid>
                )}
            </StackLayout>
        </StackLayout>
    );
}
const InterviewCheckBoxCellTemplate = createCellTemplateFromComponent(function({ dataItem, cellProps }: CellTemplateProps<InterviewWithSelection>) {
    return <Checkbox checked={dataItem.isSelected} />;
});

const CustomHeaderCell = (props: GridHeaderCellProps) => {
    return <Checkbox checked={props.selectionValue} onChange={e => props.selectionChange(e.value)} />;
};
