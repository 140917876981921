import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { AIAvatar } from '../../components/ai/aiAvatar';
import { AILedChatComponent } from '../../components/ai/aiLedChat';
import { getButtonClassName } from '../../components/common/DivButton';
import { useInvisibleReCaptcha } from '../../components/common/invisibleReCaptcha';
import { InterviewProgress } from '../../components/interview2/common';
import { H1, H2, H3 } from '../../components/ui/typography';
import { useChatActions } from '../../hooks/chatHooks';
import { ResponsiveGroup, useAsRef, useResponsiveLayout, useSingleClickCallback } from '../../hooks/commonHooks';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { ReactComponent as ClockIcon } from '../../icons/clock.svg';
import completedIcanpreneurInterviewIllustrationUrl from '../../images/completed-icanpreneur-interview-illustration.svg';
import completedInterviewIllustrationUrl from '../../images/completed-interview-illustration.svg';
import interviewIllustrationUrl from '../../images/interview-illustration-2.svg';
import { ReactComponent as IcanpreneurLogo } from '../../images/logo.svg';
import { AgentChatMessage, Chat, ChatMessage, ChatMessageBlock, ChatMessageBlockType, NewChatMessage, publicChatsService } from '../../services/chatsService';
import { combineClassNames, urlWithQueryParams } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import { NotFoundException } from '../../services/httpServiceBase';
import { InterviewStageV2, PublicInterview, publicInterviewsService } from '../../services/interviewsV2Service';

export function AILedInterviewPage() {
    const { publicCode } = useParams();
    if (!publicCode) throw new NotFoundException();

    const [interview, setInterview] = useState<PublicInterview>();
    const [messages, setMessages] = useState<ChatMessage[]>();

    const [forceExecutionView, setForceExecutionView] = useState<boolean>();
    const responsiveGroup = useResponsiveLayout();
    const isMobile = responsiveGroup === ResponsiveGroup.xs;
    const isTablet = isMobile || responsiveGroup === ResponsiveGroup.sm;
    const getReCaptchaToken = useInvisibleReCaptcha();

    const interviewChatTag = interview?.detail?.chat?.tag;
    const setChat = useCallback((chat: Chat) => setInterview(i => (i && i.detail ? { ...i, detail: { ...i.detail, chat: chat } } : i)), []);
    const initializeChat = useCallback(() => {
        if (!interviewChatTag) throw new Error();
        return publicChatsService.initializeChat(interviewChatTag);
    }, [interviewChatTag]);
    const getAllChatMessages = useCallback(() => {
        if (!interviewChatTag) throw new Error();
        return publicChatsService.getAllChatMessages(interviewChatTag);
    }, [interviewChatTag]);
    const isChatClosedRef = useAsRef(interview?.detail?.chat?.closed);
    const sendMessage = useCallback(
        async function* sendMessage(message: NewChatMessage) {
            if (!interviewChatTag) throw new Error();
            const messageStream = publicChatsService.sendMessage(interviewChatTag, message);
            for await (const message of messageStream) {
                yield message;

                if (message.type === 'progress') {
                    const interviewProgress = message.data;
                    setInterview(i => (i && i.detail ? { ...i, detail: { ...i.detail, percentCompleted: interviewProgress } } : i));
                } else if (message.type === 'chat' && message.data.closed && !isChatClosedRef.current) {
                    setInterview(i => i && { ...i, stage: InterviewStageV2.Completed });
                    setForceExecutionView(true);
                }
            }
        },
        [interviewChatTag, isChatClosedRef]
    );
    const fixChat = useCallback(() => {
        if (!interviewChatTag) throw new Error();
        return publicChatsService.fixChat(interviewChatTag);
    }, [interviewChatTag]);

    const [handleSendMessage, handleMessageComplete, generateFixMessage] = useChatActions(
        interview?.detail?.chat ?? undefined,
        setChat,
        setMessages,
        interviewChatTag ? initializeChat : undefined,
        interviewChatTag ? getAllChatMessages : undefined,
        interviewChatTag ? sendMessage : undefined,
        interviewChatTag ? fixChat : undefined
    );

    useEffect(() => {
        publicInterviewsService.getInterview(publicCode).then(setInterview);
    }, [publicCode]);

    async function finishInterview() {
        if (!interviewChatTag) return;

        const chat = await publicChatsService.finishChat(interviewChatTag);
        setChat(chat);
        setInterview(i => i && { ...i, stage: InterviewStageV2.Completed });
    }

    return (
        <div className="page">
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=1.0, minimum-scale=1.0, maximum-scale=1.0" />
            </Helmet>
            <div
                className={combineClassNames(
                    'page-content page-content-section',
                    interview?.stage === InterviewStageV2.NotStarted ? 'ai-background' : undefined
                )}
            >
                {!interview ? (
                    <AIAvatar className="k-centered" size={184} borderSize="k-border-2" animate animationType="draw" iconSize={120} />
                ) : interview.stage === InterviewStageV2.NotStarted ? (
                    <StartInterviewView
                        interview={interview}
                        isMobile={isMobile}
                        onStartInterview={async () => {
                            const reCaptchaToken = await getReCaptchaToken?.();
                            const interview = await publicInterviewsService.startInterview(publicCode, reCaptchaToken);
                            setInterview(interview);
                        }}
                    />
                ) : interview.stage === InterviewStageV2.InProgress || forceExecutionView ? (
                    <ExecuteInterviewView
                        interview={interview}
                        messages={messages}
                        onFinishInterview={finishInterview}
                        onSendMessage={handleSendMessage}
                        onAIMessageTyped={handleMessageComplete}
                        fixChat={generateFixMessage}
                        readonly={interview.stage !== InterviewStageV2.InProgress || interview.detail?.chat?.closed}
                        isTablet={isTablet}
                    />
                ) : interview.isIcanpreneurIdea ? (
                    <FinishedIcanpreneurInterviewView interview={interview} isMobile={isMobile} />
                ) : (
                    <FinishedInterviewView interview={interview} isMobile={isMobile} />
                )}
            </div>
        </div>
    );
}

function StartInterviewView({
    interview,
    isMobile,
    onStartInterview
}: {
    interview: PublicInterview;
    isMobile: boolean;
    onStartInterview: () => void | Promise<void>;
}) {
    const [isStartingInterview, startInterviewCallback] = useSingleClickCallback(onStartInterview);
    // We are showing the research it it's only one. If not research or more than one - we are hiding it
    const research = interview.relatedResearch.length === 1 ? interview.relatedResearch[0] : undefined;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'middle' }} className="k-gap-8 k-min-h-full page-content-middle">
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2 k-text-center">
                {isMobile ? <H1>{interview.ideaTitle}</H1> : <div className="k-display-4">{interview.ideaTitle}</div>}
                {research && (isMobile ? <H3>{research.name}</H3> : <H2>{research.name}</H2>)}
                {Boolean(interview.scriptDurationInMinutes) && (
                    <div>
                        <ClockIcon className="!k-display-inline k-align-middle k-icp-icon k-icp-icon-size-4 k-mr-1.5" />
                        <span className="k-align-middle k-icp-line-height-inline-md">
                            Approximate interview time:
                            <span className="k-ml-1.5">{dateTimeService.stringifyMinutesToHours(interview.scriptDurationInMinutes!)}</span>
                        </span>
                    </div>
                )}
            </StackLayout>
            <StackLayout
                orientation="vertical"
                align={{ horizontal: 'center', vertical: 'top' }}
                className="k-text-center k-p-8 k-rounded-xl k-border-2 k-border-solid k-border-white bg-ghost-gradient k-gap-4"
            >
                <AIAvatar size={128} borderSize="k-border-2" iconSize={80} />
                <H1 className="ai-text-color">Hello, I'm IVA!</H1>
                <div className="k-fs-lg">
                    I'm excited to be your AI interviewer!
                    <br />
                    Whenever you're ready, begin the interview.
                </div>
                <Button disabled={isStartingInterview} type="button" themeColor="primary" size="large" className="k-mt-4" onClick={startInterviewCallback}>
                    Start interview
                </Button>
            </StackLayout>

            <StackLayout align={{ horizontal: 'end', vertical: 'middle' }} className="k-gap-2.5">
                <span>Powered by:</span>
                <IcanpreneurLogo className="k-mr-4" />
            </StackLayout>
        </StackLayout>
    );
}

function FinishedInterviewView({ interview, isMobile }: { interview: PublicInterview; isMobile: boolean }) {
    const research = interview.relatedResearch.length === 1 ? interview.relatedResearch[0] : undefined;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-8 k-text-center">
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                {isMobile ? <H1>{interview.ideaTitle}</H1> : <div className="k-display-4">{interview.ideaTitle}</div>}
                {research && (isMobile ? <H3>{research.name}</H3> : <H2>{research.name}</H2>)}
            </StackLayout>
            <img src={interviewIllustrationUrl} width="321" height="225" alt="Finished interview" className="responsive-image k-mx-auto" />
            <div className="k-fs-lg">
                Thank you for taking the time to answer the questions.
                <br />
                Your input is greatly appreciated!
            </div>
        </StackLayout>
    );
}

function FinishedIcanpreneurInterviewView({ interview, isMobile }: { interview: PublicInterview; isMobile: boolean }) {
    const research = interview.relatedResearch.length === 1 ? interview.relatedResearch[0] : undefined;
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="page-content-middle k-gap-8 k-text-center">
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                {isMobile ? <H1>{interview.ideaTitle}</H1> : <div className="k-display-4">{interview.ideaTitle}</div>}
                {research && (isMobile ? <H3>{research.name}</H3> : <H2>{research.name}</H2>)}
            </StackLayout>
            <div>
                Thank you so much for sharing your thoughts and experiences{interview.contact ? `, ${interview.contact.firstName}` : ''}! Your insights were
                captured and will help us create a better product for other startup founders.
            </div>
            <StackLayout
                orientation="vertical"
                align={{ horizontal: 'center', vertical: 'top' }}
                className="k-gap-8 k-p-8 k-pos-relative k-border k-border-solid k-rounded-xl k-border-transparent"
            >
                <img src={completedIcanpreneurInterviewIllustrationUrl} width="321" height="140" alt="Finished interview" className="responsive-image" />

                <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-2">
                    <strong className="k-fs-lg">Did you enjoy your conversation?</strong>
                    <div>
                        Icanpreneur can help you as well with building and growing your product.
                        <br />
                        Just go ahead and give it a try!
                    </div>
                </StackLayout>

                <Link
                    className={getButtonClassName({ size: 'large', themeColor: 'primary', className: '!k-px-5' })}
                    to={urlWithQueryParams('/login', { prefill: 'interview', interview: interview.publicCode })}
                >
                    Start now
                </Link>

                <div className="ai-border ai-border--purple k-pointer-events-none k-border k-border-solid k-rounded-xl k-border-transparent" />
            </StackLayout>
        </StackLayout>
    );
}

function ExecuteInterviewView({
    interview,
    messages,
    onFinishInterview,
    onSendMessage,
    onAIMessageTyped,
    fixChat,
    readonly,
    isTablet
}: {
    interview: PublicInterview;
    messages?: ChatMessage[];
    onFinishInterview?: () => void | Promise<void>;
    onSendMessage?: (message: NewChatMessage, setContext: (context: AgentChatMessage) => void) => AsyncGenerator<ChatMessageBlock>;
    onAIMessageTyped?: (context: AgentChatMessage | undefined) => void;
    fixChat?: (setContext: (context: AgentChatMessage) => void) => AsyncGenerator<ChatMessageBlock>;
    readonly?: boolean;
    isTablet?: boolean;
}) {
    const BoundIcanpreneurInterviewCompletedCard = useMemo(
        () => (interview.isIcanpreneurIdea ? IcanpreneurInterviewCompletedCard.bind(undefined, interview.publicCode) : undefined),
        [interview.isIcanpreneurIdea, interview.publicCode]
    );
    const CompletedInterviewComponentType = interview.isIcanpreneurIdea ? BoundIcanpreneurInterviewCompletedCard! : InterviewCompletedCard;
    return (
        <Dialog
            className="k-icp-dialog-maximized k-icp-dialog-no-padding k-icp-dialog-with-title-shadow"
            title={<InterviewDialogTitle interview={interview} onFinishInterview={onFinishInterview} isTablet={isTablet} />}
            closeIcon={false}
        >
            <AILedChatComponent
                messages={messages}
                onSendMessage={onSendMessage}
                onMessageComplete={onAIMessageTyped}
                readonly={readonly}
                generateFixMessage={fixChat}
                addTalking
                addListening
                animateIntro
                blockComponentsOverride={{
                    [ChatMessageBlockType.ChatEnd]: CompletedInterviewComponentType
                }}
            />
        </Dialog>
    );
}

function InterviewCompletedCard() {
    return (
        <InterviewCompletedCardLayout>
            <div className="k-fs-sm">
                That's all for now! Feel free to close this tab whenever you're ready. If you have anything else to share or just want to say 'hi,' reach me at{' '}
                <a href="mailto:iva@icanpreneur.com" className="k-button-link-secondary">
                    iva@icanpreneur.com
                </a>
            </div>
        </InterviewCompletedCardLayout>
    );
}

function IcanpreneurInterviewCompletedCard(interviewPublicCode: string) {
    return (
        <InterviewCompletedCardLayout>
            <div className="k-pt-1">
                <strong className="k-mb-1 k-display-block">Did you enjoy your conversation?</strong>
                <div className="k-fs-sm k-mb-2.5">
                    Icanpreneur can help you as well with building and growing your product. Just go ahead and give it a try!
                </div>

                <Link
                    className={getButtonClassName({ themeColor: 'primary', fillMode: 'flat', className: 'k-icp-button-flat-alt' })}
                    to={urlWithQueryParams('/login', { prefill: 'interview', interview: interviewPublicCode })}
                >
                    Start now
                    <div className="k-border k-border-solid k-rounded ai-border ai-border--rotate k-border-transparent k-pointer-events-none" />
                </Link>
            </div>
        </InterviewCompletedCardLayout>
    );
}

function InterviewCompletedCardLayout({ children }: { children?: ReactNode }) {
    const borderClasses = 'k-border k-border-solid k-rounded-lg k-border-transparent';
    return (
        <div className={combineClassNames('k-pos-relative k-p-4 k-mt-2', borderClasses)} style={{ maxWidth: 414 }}>
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                <img src={completedInterviewIllustrationUrl} alt="Interview is completed" width="48" height="48" />
                {children}
            </StackLayout>
            <div className={combineClassNames('ai-border ai-border--purple k-pointer-events-none', borderClasses)} />
        </div>
    );
}

function InterviewDialogTitle({
    interview,
    onFinishInterview,
    isTablet
}: {
    interview: PublicInterview;
    onFinishInterview?: () => void | Promise<void>;
    isTablet?: boolean;
}) {
    const { show: showFinishInterviewDialog, element: finishInterviewDialog } = useConfirmDialog();

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-w-full k-justify-content-between k-gap-4">
            <div className="k-text-ellipsis">Interview with IVA (Icanpreneur Virtual Assistant)</div>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-icp-component-border">
                <InterviewProgress progressPercentage={interview.detail?.percentCompleted ?? 0} hideLabel={isTablet} />
                <div className="k-separator" />
                <Button
                    type="button"
                    themeColor="secondary"
                    disabled={interview.stage !== InterviewStageV2.InProgress || !onFinishInterview}
                    onClick={() =>
                        showFinishInterviewDialog({
                            title: 'Finish interview',
                            content: 'Do you want to end the active interview? There are still unanswered questions.',
                            confirmButtonText: 'Finish interview',
                            cancelButtonText: 'Back to interview',
                            callback: onFinishInterview!,
                            buttonsLayout: 'center',
                            confirmButtonThemeColor: 'primary'
                        })
                    }
                >
                    {isTablet ? 'Finish' : 'Finish interview'}
                </Button>
            </StackLayout>
            {finishInterviewDialog}
        </StackLayout>
    );
}
