import { StackLayoutAlign } from '@progress/kendo-react-layout';
import { ReactComponent as CheckCircleIcon } from '../../icons/check-circle-closed.svg';
import { ReactComponent as CheckSquareIcon } from '../../icons/check-square.svg';
import { ReactComponent as CircleIcon } from '../../icons/circle.svg';
import { ReactComponent as SquareIcon } from '../../icons/square.svg';
import { combineClassNames } from '../../services/common';
import { ClickableStackLayout } from './clickableStackLayout';

export interface RadioOptionProps {
    isSelected: boolean;
    onClick?: () => void;
    children?: React.ReactNode;
    verticalAlign?: StackLayoutAlign['vertical'];
    deselectable?: boolean;
}
export function RadioOption({ isSelected, onClick, children, verticalAlign, deselectable }: RadioOptionProps) {
    const SelectedIcon = deselectable ? CheckSquareIcon : CheckCircleIcon;
    const DeselectedIcon = deselectable ? SquareIcon : CircleIcon;
    return (
        <ClickableStackLayout
            align={{ horizontal: 'start', vertical: verticalAlign ?? 'top' }}
            className={combineClassNames(
                'k-gap-2 k-cursor-pointer k-border k-border-solid k-rounded-md k-p-3',
                isSelected
                    ? combineClassNames('k-border-secondary', deselectable ? 'k-icp-hover-bg-secondary-8' : undefined)
                    : 'k-icp-component-border k-icp-hover-bg-base-4'
            )}
            onClick={onClick}
        >
            {children}
            {isSelected ? (
                <SelectedIcon className="k-shrink-0 k-icp-icon k-icp-icon-size-4 k-text-secondary" />
            ) : (
                <DeselectedIcon className="k-shrink-0 k-icp-icon k-icp-icon-size-4 k-icp-subtle-text" />
            )}
        </ClickableStackLayout>
    );
}
