import { Button, ButtonProps } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DialogActionsBarProps } from '@progress/kendo-react-dialogs/dist/npm/DialogActionsBar';
import { Checkbox } from '@progress/kendo-react-inputs';
import { ReactNode, useState } from 'react';

export type ConfirmDialogViewConfig = {
    title?: string;
    content?: ReactNode;
    confirmCheckboxText?: string;
    confirmButtonText?: string;
    confirmButtonThemeColor?: ButtonProps['themeColor'];
    confirmButtonDisabled?: boolean;
    cancelButtonText?: string;
    buttonsLayout?: DialogActionsBarProps['layout'];
};
export interface ConfirmDialogConfig extends ConfirmDialogViewConfig {
    callback: Function;
    onCancel?: Function;
}

export const useConfirmDialog = () => {
    const [confirmed, setConfirmed] = useState(false);
    const [config, setConfig] = useState<ConfirmDialogConfig>();

    const showDialog = (config: ConfirmDialogConfig) => {
        setConfig(config);
        setConfirmed(!config.confirmCheckboxText || !config.confirmCheckboxText.length);
    };

    const onCloseDialog = (confirmed: boolean) => {
        if (!config) return;

        if (confirmed) {
            const callbackResult = config.callback();
            if (callbackResult instanceof Promise) {
                setConfig(c => (c ? { ...c, confirmButtonDisabled: true } : c));
                callbackResult
                    .then(() => setConfig(undefined))
                    .catch(e => {
                        setConfig(c => (c ? { ...c, confirmButtonDisabled: false } : c));
                        return Promise.reject(e);
                    });
            } else setConfig(undefined);
        } else if (config.onCancel) {
            config.onCancel();
            setConfig(undefined);
        } else setConfig(undefined);
    };

    return {
        show: showDialog,
        element: config ? (
            <ConfirmDialog
                config={config}
                confirmed={confirmed}
                onConfirmation={setConfirmed}
                onClose={() => onCloseDialog(false)}
                onConfirm={() => onCloseDialog(true)}
            />
        ) : (
            undefined
        ),
        updateDialog(updateFunc: (currentConfig: ConfirmDialogConfig) => ConfirmDialogConfig) {
            setConfig(currentConfig => {
                if (!currentConfig) return currentConfig;

                return updateFunc(currentConfig);
            });
        }
    };
};

export function ConfirmDialog({
    config,
    onClose,
    onConfirm,
    onConfirmation,
    confirmed
}: {
    config?: ConfirmDialogViewConfig;
    onClose?: () => void;
    onConfirm?: () => void;
    onConfirmation?: (confirmation: boolean) => void;
    confirmed?: boolean;
}) {
    return (
        <Dialog title={config?.title || 'Confirm'} onClose={onClose} width={480}>
            {config?.content || 'Are you sure?'}
            {config?.confirmCheckboxText && (
                <div className="k-mt-4">
                    <Checkbox
                        rounded="small"
                        checked={confirmed}
                        onChange={onConfirmation && (e => onConfirmation(e.value))}
                        label={config?.confirmCheckboxText}
                    />
                </div>
            )}
            <DialogActionsBar layout={config?.buttonsLayout ?? 'end'}>
                <Button
                    onClick={onConfirm}
                    themeColor={config?.confirmButtonThemeColor === undefined ? 'error' : config.confirmButtonThemeColor}
                    disabled={!confirmed || config?.confirmButtonDisabled}
                >
                    {config?.confirmButtonText || 'Yes'}
                </Button>
                <Button onClick={onClose}>{config?.cancelButtonText || 'Cancel'}</Button>
            </DialogActionsBar>
        </Dialog>
    );
}
