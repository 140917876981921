import { ButtonGroup } from '@progress/kendo-react-buttons';
import { Editor } from '@progress/kendo-react-editor';
import { StackLayout } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import { EditorView } from 'prosemirror-view';
import { useCallback, useEffect, useState } from 'react';
import { combineClassNames } from '../../../services/common';
import { DivButton } from '../../common/DivButton';

interface ShrinkableToolbarSize {
    top: number;
    left: number;
    width: number;
}

const OFFSET_TOOLBAR_PADDING_TOP = 6;

export function useCollapseToolbarTool(editorRef: React.RefObject<Editor>, tools: any[], onCollapse?: () => void, onExpand?: () => void) {
    const [shrinkableToolbarSize, setShrinkableToolbarSize] = useState<ShrinkableToolbarSize | undefined>(undefined);
    const [editorView, setEditorView] = useState<EditorView | undefined>(undefined);

    useEffect(() => {
        if (!editorRef.current) return;
        const editorView = editorRef.current.view;
        setEditorView(editorView);
    }, [editorRef]);

    useEffect(() => {
        if (!editorRef.current) return;
        const toolbarElement = editorRef.current.element?.querySelector('.k-editor-toolbar');
        const editorElement = editorRef.current.element;

        if (!toolbarElement || !editorElement) return;

        const resizeShrinkableToolbar = () => {
            const toolbarRect = toolbarElement.getBoundingClientRect();
            const editorRect = editorElement.getBoundingClientRect();

            setShrinkableToolbarSize({
                top: toolbarRect.height + toolbarRect.top - editorRect.top,
                left: toolbarRect.left - editorRect.left,
                width: toolbarRect.width
            });
        };

        resizeShrinkableToolbar();

        window.addEventListener('resize', resizeShrinkableToolbar);

        return () => {
            window.removeEventListener('resize', resizeShrinkableToolbar);
        };
    }, [editorRef]);

    const CollapseToolbarTool = useCallback(
        () => (
            <CollapseToolbarComponent
                shrinkableToolbarSize={shrinkableToolbarSize}
                tools={tools}
                editorView={editorView}
                onCollapse={onCollapse}
                onExpand={onExpand}
                editorRef={editorRef}
            />
        ),
        [editorRef, editorView, onCollapse, onExpand, shrinkableToolbarSize, tools]
    );

    return {
        CollapseToolbarTool
    };
}

const CollapseToolbarComponent = ({
    shrinkableToolbarSize,
    tools,
    editorView,
    onCollapse,
    onExpand,
    editorRef
}: {
    shrinkableToolbarSize?: ShrinkableToolbarSize;
    tools: any[];
    editorView?: EditorView;
    onCollapse?: () => void;
    onExpand?: () => void;
    editorRef: React.RefObject<Editor>;
}) => {
    const [opened, setOpened] = useState(false);

    return (
        <>
            <DivButton
                className={combineClassNames('k-icp-svg-icon-button')}
                fillMode="flat"
                disabled={!shrinkableToolbarSize}
                onClick={() => {
                    if (opened) {
                        onCollapse?.();
                    } else {
                        onExpand?.();
                    }
                    setOpened(!opened);
                }}
                icon="more-horizontal"
            />
            {shrinkableToolbarSize && (
                <Popup
                    appendTo={editorRef.current?.element}
                    offset={{ top: shrinkableToolbarSize.top - OFFSET_TOOLBAR_PADDING_TOP, left: shrinkableToolbarSize.left }}
                    style={{ width: shrinkableToolbarSize.width }}
                    popupClass={'!k-border-t-0 k-icp-shadow-md-moved k-rounded-b-lg'}
                    show={opened}
                    anchorAlign={{ horizontal: 'left', vertical: 'top' }}
                >
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-icp-component-border k-p-2 k-gap-2">
                        {editorView &&
                            tools.map((toolsGroup, index) => {
                                if (Array.isArray(toolsGroup)) {
                                    return (
                                        <ButtonGroup key={index}>
                                            {toolsGroup.map((Tool, toolIndex) => (
                                                <Tool view={editorView} key={`${index}-${toolIndex}`} />
                                            ))}
                                        </ButtonGroup>
                                    );
                                } else {
                                    const Tool = toolsGroup;
                                    return <Tool view={editorView} key={index} />;
                                }
                            })}
                    </StackLayout>
                </Popup>
            )}
        </>
    );
};
