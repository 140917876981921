import { Link } from 'react-router-dom';
import { combineClassNames } from '../../services/common';

export function BackLinkHeader({ to, text, onClick, className }: { to: string; text: string; onClick?: () => void; className?: string }) {
    return (
        <header className="k-mb-6">
            <Link
                to={to}
                onClick={onClick}
                className={combineClassNames('k-button k-button-link k-fs-sm k-button-link-base k-font-weight-semibold k-mt-2 k-max-w-full', className)}
            >
                <span className="k-icon k-i-arrow-chevron-left"></span> <span className="k-text-ellipsis">{text}</span>
            </Link>
        </header>
    );
}
