import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { NavLink, Outlet, To } from 'react-router-dom';
import { combineClassNames } from '../../services/common';

type TabbedLayoutContextValue = { setHeaderContent: (content: ReactNode) => void };
const TabbedLayoutContext = createContext<TabbedLayoutContextValue>({ setHeaderContent(_) {} });

export function useTabbedLayoutContext() {
    return useContext(TabbedLayoutContext);
}

type TabbedLayoutDataContextValue = unknown;
const TabbedLayoutDataContext = createContext<TabbedLayoutDataContextValue>(undefined);
export function useTabbedLayoutData<TData>() {
    return useContext(TabbedLayoutDataContext) as TData;
}

export type TabHeader = { title: string; count?: number; to: To; end?: boolean; state?: any };
export type TabbedLayoutUIProps =
    | { presentation?: 'default'; size?: 'medium' | 'small'; theme?: 'primary' | 'secondary'; separated?: boolean }
    | { presentation: 'outline' };
export type TabbedLayoutProps = { headers: TabHeader[]; data?: unknown } & TabbedLayoutUIProps;
export function TabbedLayout(props: TabbedLayoutProps) {
    const [headerContent, setHeaderContent] = useState<ReactNode>();
    const tabbedLayoutContextValue = useMemo<TabbedLayoutContextValue>(() => ({ setHeaderContent }), []);

    return (
        <>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className={getWrapperClassName(props)}>
                <TabbedLayoutNav {...props} />
                {headerContent}
            </StackLayout>

            <TabbedLayoutDataContext.Provider value={props.data}>
                <TabbedLayoutContext.Provider value={tabbedLayoutContextValue}>
                    <Outlet />
                </TabbedLayoutContext.Provider>
            </TabbedLayoutDataContext.Provider>
        </>
    );
}

function getWrapperClassName(uiProps: TabbedLayoutUIProps) {
    const isDefaultPresentation = !uiProps.presentation || uiProps.presentation === 'default';

    return combineClassNames(
        'k-justify-content-between k-gap-16 k-mb-6',
        isDefaultPresentation && uiProps.separated ? 'k-border-b k-border-b-solid k-icp-component-border' : undefined,
        isDefaultPresentation && uiProps.size === 'small' ? 'k-mt-1' : undefined
    );
}

function getTabsWrapperClassName(uiProps: TabbedLayoutUIProps) {
    if (uiProps.presentation === 'outline') return 'k-gap-0.5 k-rounded k-border k-border-solid k-icp-component-border k-icp-panel-base';

    return combineClassNames(uiProps.size === 'small' ? 'k-gap-4' : 'k-gap-6');
}

function getTabClassName(isActive: boolean, uiProps: TabbedLayoutProps, index: number) {
    if (uiProps.presentation === 'outline')
        return combineClassNames(
            'k-button k-button-md k-rounded k-font-semibold !k-px-4 k-my--1px',
            isActive ? undefined : 'k-border-transparent highlight-border-on-hover',
            index === 0 ? 'k-ml--1px' : undefined,
            index === uiProps.headers.length - 1 ? 'k-mr--1px' : undefined
        );

    return combineClassNames(
        'k-icp-tabbed-nav-header',
        uiProps.size === 'small' ? 'k-font-semibold' : 'k-h2',
        isActive ? `!k-border-b-${uiProps.theme ?? 'primary'}` : undefined,
        uiProps.size === 'small' && isActive ? 'k-text-secondary' : undefined,
        uiProps.size === 'small' ? 'k-pt-1.5 k-pb-thin' : undefined
    );
}

function getCounterClassName(isActive: boolean, uiProps: TabbedLayoutUIProps) {
    return combineClassNames(
        'k-rounded-full k-icp-text-xs k-text-center k-border k-border-solid k-py-hair k-px-1.5 k-font-normal',
        !isActive && uiProps.presentation === 'outline' ? 'k-icp-component-border-16' : undefined
    );
}

function TabbedLayoutNav(props: TabbedLayoutProps) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className={getTabsWrapperClassName(props)}>
            {props.headers.map((header, index) => (
                <NavLink
                    key={index}
                    className={({ isActive }) => getTabClassName(isActive, props, index)}
                    to={header.to}
                    end={header.end ?? true}
                    state={header.state}
                >
                    {({ isActive }) =>
                        header.count !== undefined ? (
                            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                                <div>{header.title}</div>
                                <div className={getCounterClassName(isActive, props)} style={{ minWidth: 19 }}>
                                    {header.count}
                                </div>
                            </StackLayout>
                        ) : (
                            header.title
                        )
                    }
                </NavLink>
            ))}
        </StackLayout>
    );
}
