import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { immutableRemove, immutableUpdate } from '../services/common';
import { HttpException } from '../services/httpServiceBase';
import { InterviewsCounts, InterviewStageV2, InterviewsV2, interviewsV2Service, MinimalInterviewV2 } from '../services/interviewsV2Service';
import { Interviews2ListPreferences, listPreferencesService } from '../services/listPreferencesService';
import { RealTimeUpdateInterviewEventData, RealTimeUpdateResearch2EventData, realTimeUpdatesEventHub } from '../services/realTimeUpdatesService';

export function useInterviewsCounts(ideaId: string, researchId?: number) {
    const [counts, setCounts] = useState<InterviewsCounts>();

    const fetchCounts = useCallback(() => {
        interviewsV2Service.getInterviewsCounts(ideaId, researchId).then(setCounts);
    }, [ideaId, researchId]);

    useEffect(() => {
        fetchCounts();
    }, [fetchCounts]);

    useEffect(() => {
        const refreshCounts = (e: RealTimeUpdateInterviewEventData) => {
            if (e.ideaId !== ideaId) return;
            fetchCounts();
        };

        const refreshResearchCounts = (e: RealTimeUpdateResearch2EventData) => {
            if (e.ideaId !== ideaId || e.researchId !== researchId) return;
            fetchCounts();
        };

        const hasResearch = typeof researchId === 'number';

        realTimeUpdatesEventHub.addEventListener('interview3', 'update', refreshCounts);
        realTimeUpdatesEventHub.addEventListener('interview3', 'add', refreshCounts);
        realTimeUpdatesEventHub.addEventListener('interview3', 'delete', refreshCounts);
        realTimeUpdatesEventHub.addEventListener('interview3', 'restore', refreshCounts);
        hasResearch && realTimeUpdatesEventHub.addEventListener('research2', 'interviewsUpdate', refreshResearchCounts);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview3', 'update', refreshCounts);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'add', refreshCounts);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'delete', refreshCounts);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'restore', refreshCounts);
            hasResearch && realTimeUpdatesEventHub.removeEventListener('research2', 'interviewsUpdate', refreshResearchCounts);
        };
    }, [fetchCounts, ideaId, researchId]);

    return counts;
}

export function useInterviews(ideaId: string, skip?: number, take?: number, stage?: InterviewStageV2, researchId?: number) {
    const [interviews, setInterviews] = useState<InterviewsV2>();

    const fetchInterviews = useCallback(
        () => interviewsV2Service.getInterviews(ideaId, undefined, undefined, skip, take, researchId, undefined, undefined, stage).then(setInterviews),
        [ideaId, researchId, skip, stage, take]
    );

    const updateSingleInterview = useCallback(
        async (interviewId: number) => {
            const updatedInterview: MinimalInterviewV2 | null = await interviewsV2Service
                .getInterview(ideaId, interviewId)
                .catch(e => (e instanceof HttpException && e.status === 404 ? null : Promise.reject(e)));
            setInterviews(interviews => {
                if (!interviews) return interviews;
                const updatedInterviews = updatedInterview
                    ? immutableUpdate(interviews.interviews, updatedInterview, i => i.id === updatedInterview.id)
                    : immutableRemove<MinimalInterviewV2>(interviews.interviews, i => i.id !== interviewId);
                if (updatedInterviews === interviews.interviews) return interviews;
                return {
                    ...interviews,
                    interviews: updatedInterviews
                };
            });
        },
        [ideaId]
    );

    useEffect(() => {
        fetchInterviews();
    }, [fetchInterviews]);

    useEffect(() => {
        const handleSingleInterviewUpdateEvent = (e: RealTimeUpdateInterviewEventData) => {
            if (e.ideaId !== ideaId) return;
            updateSingleInterview(e.interviewId);
        };

        const handleInterviewsRefresh = (e: RealTimeUpdateInterviewEventData) => {
            if (e.ideaId !== ideaId) return;
            fetchInterviews();
        };

        // We are getting the entire list on interview update since the interview might be with different stage or research and we might need to remove it from the list instead of just updating it
        realTimeUpdatesEventHub.addEventListener('interview3', 'update', handleInterviewsRefresh);
        realTimeUpdatesEventHub.addEventListener('interview3', 'analysisUpdate', handleSingleInterviewUpdateEvent);
        realTimeUpdatesEventHub.addEventListener('interview3', 'add', handleInterviewsRefresh);
        realTimeUpdatesEventHub.addEventListener('interview3', 'delete', handleInterviewsRefresh);
        realTimeUpdatesEventHub.addEventListener('interview3', 'restore', handleInterviewsRefresh);
        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview3', 'update', handleSingleInterviewUpdateEvent);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'analysisUpdate', handleSingleInterviewUpdateEvent);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'add', handleInterviewsRefresh);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'delete', handleInterviewsRefresh);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'restore', handleInterviewsRefresh);
        };
    }, [fetchInterviews, ideaId, updateSingleInterview]);

    useEffect(() => {
        if (typeof researchId !== 'number') return;

        function handleInterviewsInResearchUpdate(e: RealTimeUpdateResearch2EventData) {
            if (e.ideaId !== ideaId || e.researchId !== researchId) return;

            fetchInterviews();
        }

        realTimeUpdatesEventHub.addEventListener('research2', 'interviewsUpdate', handleInterviewsInResearchUpdate);

        return () => realTimeUpdatesEventHub.removeEventListener('research2', 'interviewsUpdate', handleInterviewsInResearchUpdate);
    }, [fetchInterviews, ideaId, researchId]);

    return [interviews, fetchInterviews] as const;
}

export type ListPreferencesContextProps = {
    listPreferences: Interviews2ListPreferences;
    updateListPreferences: (prefs: Partial<Interviews2ListPreferences>) => void;
};

const InterviewsListPreferencesContext = createContext<ListPreferencesContextProps>({
    listPreferences: listPreferencesService.getInterviewsListPreferences(),
    updateListPreferences: () => {}
});

export function useInterviewsListPreferencesContext() {
    return useContext(InterviewsListPreferencesContext);
}

export function InterviewsListProvider({ children }: { children: ReactNode }) {
    const [listPreferences, setListPreferences] = useState<Interviews2ListPreferences>(() => listPreferencesService.getInterviewsListPreferences());

    const updateListPreferences = useCallback(
        function(updatedListPreferences: Partial<Interviews2ListPreferences>) {
            const newListPreferences = { ...listPreferences, ...updatedListPreferences };
            listPreferencesService.saveInterviewsListPreferences(newListPreferences);
            setListPreferences(newListPreferences);
        },
        [listPreferences]
    );

    return <InterviewsListPreferencesContext.Provider value={{ listPreferences, updateListPreferences }}>{children}</InterviewsListPreferencesContext.Provider>;
}
