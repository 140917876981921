import { ButtonItemProps, DropDownButton, DropDownButtonItemClickEvent, DropDownButtonProps } from '@progress/kendo-react-buttons';
import { ComponentType, ReactElement, forwardRef } from 'react';
import { combineClassNames } from '../../services/common';

export type DropDownButtonItem = {
    text?: string;
    children?: ReactElement;
    action?: (e: DropDownButtonItemClickEvent) => void;
    disabled?: boolean;
    separated?: boolean;
    asHeader?: boolean;
    danger?: boolean;
    className?: string;
    lineEndIcon?: ComponentType<React.SVGProps<SVGSVGElement>>;
    lineEndIconClassName?: string;
};

type BoundDropDownButtonAdditionalProps = { openedClassName?: string };
export const BoundDropDownButton = forwardRef<
    DropDownButton,
    Omit<DropDownButtonProps, 'items' | 'textField' | 'item' | 'itemRender' | 'onItemClick'> & {
        items?: DropDownButtonItem[];
    } & BoundDropDownButtonAdditionalProps
>(({ openedClassName, ...dropDownButtonProps }, ref) => {
    const openedButtonClassName = openedClassName ?? 'k-active';

    return (
        <DropDownButton
            {...dropDownButtonProps}
            ref={ref}
            item={BoundDropDownButtonItem}
            itemRender={(li: React.ReactElement<HTMLLIElement>, props: ButtonItemProps) => {
                const item: DropDownButtonItem = props.dataItem;

                if (item.asHeader) {
                    return <div className="k-icp-panel-base k-fs-sm k-p-2 k-pointer-events-none">{item.text}</div>;
                }

                if (item.separated && props.index)
                    return (
                        <>
                            <li className="k-separator !k-m-0"></li>
                            {li}
                        </>
                    );

                return li;
            }}
            onItemClick={e => (e.item as DropDownButtonItem).action?.(e)}
            onOpen={e => {
                e.target.element?.classList.add(openedButtonClassName);
                dropDownButtonProps.onOpen?.(e);
            }}
            onClose={e => {
                e.target.element?.classList.remove(openedButtonClassName);
                dropDownButtonProps.onClose?.(e);
            }}
        />
    );
});

function BoundDropDownButtonItem({ item, itemIndex }: { item: DropDownButtonItem; itemIndex: number }) {
    const className = combineClassNames(item.className, item.danger ? 'k-text-error' : undefined);
    return (
        <>
            <div className={combineClassNames('k-menu-link-text', className)}>{item.children ?? item.text}</div>
            {item.lineEndIcon && (
                <item.lineEndIcon className={combineClassNames('k-icp-icon k-icp-icon-size-4 k-menu-expand-arrow', item.lineEndIconClassName)} />
            )}
        </>
    );
}
