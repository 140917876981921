import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogCloseEvent } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { PopupPropsContext } from '@progress/kendo-react-popup';
import { useRef } from 'react';
import successImage from '../../images/success-animation.svg';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { InterviewModalData, ModalView, hideModal } from '../../state/ui/modalSlice';
import { NoTitleModal } from '../common/modal';
import { OpenInterviewButton } from '../interview/interviewMainActionButton';
import { H1 } from './typography';

export default function AppModal() {
    const dispatch = useAppDispatch();
    const modalState = useAppSelector(s => s.modal);
    const closeModal = () => {
        dispatch(hideModal());
    };

    if (!modalState) return null;

    if (modalState.view === ModalView.FeedbackSubmitted) return <FeedbackModal feedbackId={modalState.data} onClose={closeModal} />;
    if (modalState.view === ModalView.OpenInterviewModal) return <InterviewModal data={modalState.data} onClose={closeModal} />;

    return null;
}

function InterviewModal({ data, onClose }: { data: InterviewModalData; onClose: () => void }) {
    return (
        <NoTitleModal
            data={data}
            onClose={onClose}
            mainButton={
                <OpenInterviewButton ideaId={data.ideaId} interviewId={data.interviewId} themeColor="primary" onClick={onClose} showGuidanceOnOpen={true}>
                    {data.mainButtonText}
                </OpenInterviewButton>
            }
        />
    );
}

function FeedbackModal({ feedbackId, onClose }: { feedbackId: number; onClose: () => void }) {
    return (
        <Dialog title="Feedback sent" onClose={onClose} width={480}>
            <StackLayout align={{ horizontal: 'center', vertical: 'top' }} orientation="vertical" className="k-gap-4 k-text-center k-mb-4">
                <img src={successImage} alt="Feedback success" width={480} height={86} className="responsive-image" />
                <H1>Awesome!</H1>
                <p style={{ maxWidth: 288 }}>Thank you for reaching out! Your feedback ID is {feedbackId}.</p>
                <Button themeColor="primary" size="large" onClick={onClose}>
                    OK
                </Button>
            </StackLayout>
        </Dialog>
    );
}

export const FullScreenModalWithIcon = ({
    title,
    children,
    icon,
    autoFocus,
    closeIcon,
    onClose
}: {
    title: string;
    children: React.ReactNode;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    autoFocus?: boolean;
    closeIcon?: boolean;
    onClose?: (e: DialogCloseEvent) => void;
}) => {
    const dialogRef = useRef<Dialog>(null);
    return (
        <PopupPropsContext.Provider value={p => (dialogRef.current?.element ? { ...p, appendTo: dialogRef.current?.element } : p)}>
            <Dialog
                ref={dialogRef}
                closeIcon={closeIcon}
                className="k-icp-dialog-maximized k-icp-dialog-no-padding k-icp-dialog-with-title-shadow"
                title={<ModalTitleWithIcon text={title} icon={icon} />}
                autoFocus={autoFocus}
                onClose={e => {
                    onClose?.(e);
                }}
            >
                {children}
            </Dialog>
        </PopupPropsContext.Provider>
    );
};

const ModalTitleWithIcon = ({ text, icon }: { text: string; icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> }) => {
    const Icon = icon;
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
            <Icon className="k-icp-icon k-icp-icon-size-6" />
            <span className="k-fs-lg k-font-medium">{text}</span>
        </StackLayout>
    );
};
