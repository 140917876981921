import { Skeleton } from '@progress/kendo-react-indicators';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { ReactElement } from 'react';
import { BoxType } from '../../services/canvasService';
import { CanvasItemSimpleView, CanvasItemSimpleViewProps } from './canvasItemSimpleView';
import { useRequireCanvasBoxItemsInZone } from './canvasItemsZone';

export function CanvasItemInContextSimpleView({
    box,
    itemId,
    render,
    showLoading,
    ...props
}: Omit<CanvasItemSimpleViewProps, 'item'> & { box: BoxType; itemId?: number; render?: (element?: ReactElement) => JSX.Element; showLoading?: boolean }) {
    const itemInBox = useRequireCanvasBoxItemsInZone(itemId === undefined ? undefined : box);
    if (!itemInBox || itemId === undefined)
        return showLoading ? (
            <Skeleton shape="text" className={props.size === 'small' || props.size === 'mediumsmall' ? 'k-fs-sm' : undefined} style={{ width: 200 }} />
        ) : null;

    const canvasItem = itemInBox.find(s => s.id === itemId);
    if (!canvasItem) return null;

    const canvasItemSimpleViewElement = <CanvasItemSimpleView {...props} item={canvasItem} />;
    return render ? render(canvasItemSimpleViewElement) : canvasItemSimpleViewElement;
}

export function CustomerSegmentCircleView({ customerSegmentId }: { customerSegmentId: number }) {
    const customerSegments = useRequireCanvasBoxItemsInZone(BoxType.CustomerSegments);
    if (!customerSegments) return <Skeleton shape="rectangle" className="palette-color" />;

    const customerSegment = customerSegments.find(i => i.id === customerSegmentId);
    if (!customerSegment || !customerSegment.colorCode) return null;

    return (
        <Tooltip anchorElement="target" position="left" openDelay={0} style={{ maxWidth: 250 }}>
            <div className="palette-color" style={{ backgroundColor: `#${customerSegment.colorCode}` }} title={customerSegment.content} />
        </Tooltip>
    );
}
