import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar, DialogHandle } from '@progress/kendo-react-dialogs';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { StackLayout } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { appConfig } from '../../config';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { ReactComponent as CreateIcon } from '../../icons/check.svg';
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-2.svg';
import { ReactComponent as AddIcon } from '../../icons/plus.svg';
import { ReactComponent as DeleteIcon } from '../../icons/trash-2.svg';
import { ReactComponent as RemoveIcon } from '../../icons/x.svg';
import { ReactComponent as TagInCircleIllustration } from '../../images/tag-in-circle-illustration.svg';
import { appEventHub } from '../../services/appEvents';
import { combineClassNames, debounce } from '../../services/common';
import { NoteTag, notesService } from '../../services/notesService';
import { RealTimeUpdateNoteTagEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { useAppDispatch } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';
import { DivButton } from '../common/DivButton';
import { CellTemplateProps, createCellTemplateFromComponent } from '../common/grid';
import { PopupPropsNestedContextProvider } from '../common/popupPropsNestedContextProvider';
import { ValidatedInput, maxLengthValidator, requiredValidator } from '../ui/inputs';
import LoadingIndicator from '../ui/loadingIndicator';
import { P } from '../ui/typography';

type TagEventData = { tagId: number };

export interface NoteTagStats extends NoteTag {
    noteCount: number;
}

interface NoteEditableTagStats extends NoteTagStats {
    isEditing: boolean;
}

export function NoteTagsPicker({
    ideaId,
    value,
    onChange,
    id,
    placeholder,
    allowCreate = true,
    disabled
}: {
    ideaId: string;
    value?: NoteTag[];
    onChange?: (e: { value: NoteTag[] }) => void;
    id?: string;
    placeholder?: string;
    allowCreate?: boolean;
    disabled?: boolean;
}) {
    const [loading, setLoading] = useState(false);
    const [loadedTags, setLoadedTags] = useState<NoteTag[]>();
    const latestFilterRef = useRef<string>();
    const [internalValue, setInternalValue] = useState<NoteTag[]>(value || []);

    useEffect(() => {
        setInternalValue(value || []);
    }, [value]);

    const loadTags = useCallback(
        async (filter?: string) => {
            latestFilterRef.current = filter;
            setLoading(true);
            try {
                const tagsResponse = await notesService.getTags(ideaId, filter);
                if (latestFilterRef.current !== filter) return;
                setLoadedTags(tagsResponse.tags);
            } finally {
                setLoading(false);
            }
        },
        [ideaId]
    );
    const loadTagsDebounced = useMemo(() => debounce(loadTags, 300), [loadTags]);

    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;
    const valueRef = useRef(internalValue);
    valueRef.current = internalValue;

    useEffect(() => {
        async function onTagUpdated(tagId: number) {
            if (!onChangeRef.current || !valueRef.current || !valueRef.current.some(t => t.id === tagId)) return;

            const updatedTag = await notesService.getTagById(ideaId, tagId);
            if (!valueRef.current) return;

            const updatedTagIndex = valueRef.current.findIndex(t => t.id === tagId);
            if (updatedTagIndex === -1) return;

            const updatedValue = [...valueRef.current];
            updatedValue.splice(updatedTagIndex, 1, updatedTag);

            onChangeRef.current({ value: updatedValue });
        }

        function onTagDeleted(tagId: number) {
            if (!onChangeRef.current || !valueRef.current || !valueRef.current.some(t => t.id === tagId)) return;

            onChangeRef.current({ value: valueRef.current.filter(t => t.id !== tagId) });
        }

        async function onTagRestored(tagId: number) {
            if (!onChangeRef.current || !valueRef.current) return;

            const restoredTag = await notesService.getTagById(ideaId, tagId);
            if (!restoredTag) return;

            onChangeRef.current({ value: [...valueRef.current, restoredTag] });
        }

        function onTagUpdatedInTab(tagData: TagEventData) {
            onTagUpdated(tagData.tagId);
        }

        function onTagDeletedInTab(tagData: TagEventData) {
            onTagDeleted(tagData.tagId);
        }

        function onTagRestoredInTab(tagData: TagEventData) {
            onTagRestored(tagData.tagId);
        }

        function onTagUpdatedExternally(e: RealTimeUpdateNoteTagEventData) {
            if (e.ideaId !== ideaId) return;
            onTagUpdated(e.tagId);
        }

        function onTagRestoredExternally(e: RealTimeUpdateNoteTagEventData) {
            if (e.ideaId !== ideaId) return;
            onTagRestored(e.tagId);
        }

        function onTagDeletedExternally(e: RealTimeUpdateNoteTagEventData) {
            if (e.ideaId !== ideaId) return;
            onTagDeleted(e.tagId);
        }

        appEventHub.addEventListener('noteTag', 'updated', onTagUpdatedInTab);
        appEventHub.addEventListener('noteTag', 'deleted', onTagDeletedInTab);
        appEventHub.addEventListener('noteTag', 'restored', onTagRestoredInTab);
        realTimeUpdatesEventHub.addEventListener('notes', 'tagUpdate', onTagUpdatedExternally);
        realTimeUpdatesEventHub.addEventListener('notes', 'tagDelete', onTagDeletedExternally);
        realTimeUpdatesEventHub.addEventListener('notes', 'tagRestore', onTagRestoredExternally);
        return () => {
            appEventHub.removeEventListener('noteTag', 'updated', onTagUpdatedInTab);
            appEventHub.removeEventListener('noteTag', 'deleted', onTagDeletedInTab);
            appEventHub.removeEventListener('noteTag', 'restored', onTagRestoredInTab);
            realTimeUpdatesEventHub.removeEventListener('notes', 'tagUpdate', onTagUpdatedExternally);
            realTimeUpdatesEventHub.removeEventListener('notes', 'tagDelete', onTagDeletedExternally);
            realTimeUpdatesEventHub.removeEventListener('notes', 'tagRestore', onTagRestoredExternally);
        };
    }, [ideaId, loadTags]);

    // Handle changes to the selected tags
    const handleChange = async (e: { value: NoteTag[] }) => {
        if (!e.value.length) {
            setInternalValue([]);
            onChange?.({ value: [] });
            return;
        }

        if (allowCreate) {
            try {
                setLoading(true);
                const newValue = await Promise.all(
                    (e.value as NoteTag[]).map(async (t: NoteTag) => {
                        if (typeof t.id === 'undefined') {
                            return notesService.ensureTag(ideaId, t.name.slice(0, 50));
                        }
                        return t;
                    })
                );

                const newValueWithoutDuplicates = newValue.filter(function(t: NoteTag, index: number) {
                    return newValue.findIndex((t2: NoteTag) => t2.id === t.id) === index;
                });

                setInternalValue(newValueWithoutDuplicates);
                onChange?.({ value: newValueWithoutDuplicates });
            } finally {
                setLoading(false);
            }
        } else {
            setInternalValue(e.value);
            onChange?.({ value: e.value });
        }
    };

    return (
        <MultiSelect
            id={id}
            placeholder={placeholder}
            value={internalValue}
            onChange={handleChange}
            textField="name"
            dataItemKey="id"
            size="small"
            disabled={disabled}
            loading={loading}
            tagRender={(tagData, tagElement) => {
                return React.cloneElement(
                    tagElement,
                    {
                        ...tagElement.props,
                        removable: false,
                        className: combineClassNames(tagElement.props.className, 'k-icp-chip-solid-base-not-interactive !k-fs-sm !k-p-0')
                    },
                    <>
                        <span className="palette-color palette-color-xs k-flex-shrink-0" style={{ backgroundColor: `#${tagData.data[0].colorCode}` }}></span>
                        <span className="k-mx-1 k-text-ellipsis">{tagElement.props.text}</span>
                    </>
                );
            }}
            data={loadedTags}
            allowCustom={allowCreate}
            filterable={true}
            onFilterChange={e => loadTagsDebounced(e.filter.value || undefined)}
            onOpen={() => {
                if (!loadedTags) setLoading(true);
                loadTagsDebounced(latestFilterRef.current);
            }}
            listNoDataRender={e => React.cloneElement(e, undefined, <div>{loading ? <LoadingIndicator size="small" /> : 'NO TAGS FOUND.'}</div>)}
        />
    );
}

export function ManageNoteTagsButton({ ideaId, disabled, className }: { ideaId: string; disabled?: boolean; className?: string }) {
    const [showTagsManagementDialog, setShowTagsManagementDialog] = useState(false);

    return (
        <>
            <Button
                type="button"
                size="small"
                fillMode="flat"
                themeColor="secondary"
                className={className}
                onClick={() => setShowTagsManagementDialog(s => !s)}
                disabled={disabled}
            >
                Manage all tags
            </Button>

            {showTagsManagementDialog && <NoteTagsManageDialog ideaId={ideaId} onClose={() => setShowTagsManagementDialog(false)} />}
        </>
    );
}

const newTagId = -1;
export function NoteTagsManageDialog({ ideaId, onClose }: { ideaId: string; onClose: () => void }) {
    const [allTags, setAllTags] = useState<NoteEditableTagStats[]>();
    const dialogRef = useRef<DialogHandle>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        notesService.getTags(ideaId, undefined, true).then(tagsResponse =>
            setAllTags(
                tagsResponse.tags.map<NoteEditableTagStats>(t => ({ ...t, isEditing: false, noteCount: t.noteCount || 0 }))
            )
        );
    }, [ideaId]);

    function insertTag(newTag: NoteEditableTagStats) {
        setAllTags(tags => {
            if (!tags) return [newTag];

            return [newTag, ...tags.filter(t => t.id !== newTagId).map(t => (t.isEditing ? { ...t, isEditing: false } : t))];
        });
    }

    function addTag() {
        insertTag({ id: newTagId, name: '', colorCode: '', noteCount: 0, isEditing: true });
    }

    function onTagCreated(tag: NoteTag) {
        insertTag({ ...tag, isEditing: false, noteCount: 0 });
        appEventHub.trigger('noteTag', 'created', { tagId: tag.id });
    }

    function onTagUpdated(tag: NoteTag) {
        setAllTags(tags => {
            if (!tags) return tags;

            return tags.map(t => (t.id === tag.id ? { ...t, ...tag, isEditing: false } : t));
        });

        appEventHub.trigger('noteTag', 'updated', { tagId: tag.id });
    }

    function cancelEdit() {
        setAllTags(tags => (tags ? tags.filter(t => t.id !== newTagId).map(t => (t.isEditing ? { ...t, isEditing: false } : t)) : tags));
    }

    function editTag(tagId: number) {
        setAllTags(tags => {
            if (!tags) return tags;

            return tags.filter(t => t.id !== newTagId).map(t => (t.id === tagId ? { ...t, isEditing: true } : t.isEditing ? { ...t, isEditing: false } : t));
        });
    }

    async function deleteTag(tagId: number) {
        const tagToDeleteIndex = allTags?.findIndex(t => t.id === tagId);
        const tagFoundInCollection = typeof tagToDeleteIndex !== 'undefined' && tagToDeleteIndex !== -1;
        let deletedTag: NoteEditableTagStats | undefined;
        if (tagFoundInCollection && allTags) {
            deletedTag = allTags[tagToDeleteIndex];
            setAllTags(allTags.filter(t => t.id !== tagId));
        }
        await notesService.deleteTag(ideaId, tagId);
        appEventHub.trigger('noteTag', 'deleted', { tagId: tagId });
        dispatch(
            addNotification({ content: 'Tag deleted.', actionText: 'Undo' }, async () => {
                const restoredTag = await notesService.restoreTag(ideaId, tagId);
                appEventHub.trigger('noteTag', 'restored', { tagId: tagId });
                if (tagFoundInCollection) {
                    const restoredItem = { ...deletedTag!, ...restoredTag, noteCount: deletedTag?.noteCount || 0 };
                    setAllTags(tags => {
                        if (!tags) return [restoredItem];

                        const tagsWithRestoredItem = [...tags];
                        tagsWithRestoredItem.splice(tagToDeleteIndex, 0, restoredItem);

                        return tagsWithRestoredItem;
                    });
                }
            })
        );
    }

    const allTagsRef = useRef(allTags);
    allTagsRef.current = allTags;
    useEffect(() => {
        async function onTagChange(e: RealTimeUpdateNoteTagEventData) {
            if (e.ideaId !== ideaId) return;

            const tagsResponse = await notesService.getTags(ideaId, undefined, true);
            const currentTags = allTagsRef.current;
            const tagsIdsInEdit = currentTags?.filter(t => t.isEditing).map(t => t.id);
            const insertingTag = currentTags?.find(t => t.id === newTagId);
            const newAllTags: NoteEditableTagStats[] = [];
            if (insertingTag) newAllTags.push(insertingTag);
            tagsResponse.tags.forEach(t =>
                newAllTags.push({
                    ...t,
                    isEditing: typeof tagsIdsInEdit !== 'undefined' && tagsIdsInEdit.includes(t.id),
                    noteCount: t.noteCount || 0
                })
            );
            setAllTags(newAllTags);
        }

        realTimeUpdatesEventHub.addEventListener('notes', 'tagAdd', onTagChange);
        realTimeUpdatesEventHub.addEventListener('notes', 'tagUpdate', onTagChange);
        realTimeUpdatesEventHub.addEventListener('notes', 'tagDelete', onTagChange);
        realTimeUpdatesEventHub.addEventListener('notes', 'tagRestore', onTagChange);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('notes', 'tagAdd', onTagChange);
            realTimeUpdatesEventHub.removeEventListener('notes', 'tagUpdate', onTagChange);
            realTimeUpdatesEventHub.removeEventListener('notes', 'tagDelete', onTagChange);
            realTimeUpdatesEventHub.removeEventListener('notes', 'tagRestore', onTagChange);
        };
    }, [ideaId]);

    return (
        <Dialog ref={dialogRef} title="Manage tags" closeIcon={true} onClose={onClose} width={640} className="manage-tags-dialog">
            {allTags ? (
                allTags.length ? (
                    <PopupPropsNestedContextProvider
                        value={p => ({
                            ...p,
                            appendTo: dialogRef.current?.element
                        })}
                    >
                        <Grid
                            data={allTags}
                            className="k-grid-no-scrollbar k-icp-grid-no-header -maxh100"
                            dataItemKey="id"
                            rowRender={(row, props) => {
                                if (!props.isInEdit || props.rowType !== 'data') return row;

                                const tag: NoteEditableTagStats = props.dataItem;
                                const isNewTag = tag.id === newTagId;
                                return React.cloneElement(
                                    row,
                                    undefined,
                                    <td colSpan={props.children instanceof Array ? props.children.length : undefined}>
                                        <TagUpsertForm
                                            ideaId={ideaId}
                                            tagId={isNewTag ? undefined : tag.id}
                                            name={tag.name}
                                            colorCode={tag.colorCode}
                                            onSave={isNewTag ? onTagCreated : onTagUpdated}
                                            onCancel={cancelEdit}
                                        />
                                    </td>
                                );
                            }}
                            editField="isEditing"
                            onItemChange={e => {
                                if (e.field !== 'command') return;
                                const tag: NoteEditableTagStats = e.dataItem;
                                if (e.value === 'edit') editTag(tag.id);
                                else if (e.value === 'delete') deleteTag(tag.id);
                            }}
                        >
                            <GridToolbar>
                                <Button onClick={addTag}>
                                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                                        <AddIcon className="k-icp-icon k-icp-icon-size-4" /> Add new tag
                                    </StackLayout>
                                </Button>
                            </GridToolbar>
                            <GridColumn cell={TagColorGridCellTemplate} width={32} />
                            <GridColumn field="name" />
                            <GridColumn cell={TagNotesCountGridCellTemplate} />
                            <GridColumn cell={NoteTagActionsCellTemplate} width={100} className="k-icp-actions-column" />
                        </Grid>
                    </PopupPropsNestedContextProvider>
                ) : (
                    <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="-h100">
                        <TagInCircleIllustration className="k-mb-6" />
                        <P className="!k-mb-4">No tags available</P>
                        <Button onClick={addTag} fillMode="outline" themeColor="secondary">
                            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                                <AddIcon className="k-icp-icon k-icp-icon-size-4" /> Add new tag
                            </StackLayout>
                        </Button>
                    </StackLayout>
                )
            ) : (
                <LoadingIndicator size="big" className="k-display-block -block-center" />
            )}
            <DialogActionsBar layout="center">
                <Button type="button" onClick={onClose}>
                    Done
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}

const TagColorGridCellTemplate = createCellTemplateFromComponent(function({ dataItem: tag }: CellTemplateProps<NoteEditableTagStats>) {
    return <div className="palette-color palette-color--tags" style={{ backgroundColor: `#${tag.colorCode}` }}></div>;
});

const TagNotesCountGridCellTemplate = createCellTemplateFromComponent(function({ dataItem: tag }: CellTemplateProps<NoteEditableTagStats>) {
    return (
        <span className="k-fs-sm k-icp-subtle-text">
            {tag.noteCount} note{tag.noteCount !== 1 ? 's' : ''} with this tag
        </span>
    );
});

const tagnameValidators = [requiredValidator('Name'), maxLengthValidator('Name', 50)];
function TagUpsertForm({
    ideaId,
    tagId,
    colorCode,
    name,
    onSave,
    onCancel
}: {
    ideaId: string;
    tagId?: number;
    name: string;
    colorCode?: string;
    onSave: (tag: NoteTag) => void;
    onCancel: () => void;
}) {
    const [createDisabled, createTagCallbackCreator] = useSingleClickButton();
    const [leastUsedColor, setLeastUsedColor] = useState<string | undefined>();
    const formHandle = useRef<Form>(null);

    useEffect(() => {
        const fetchLeastUsedColor = async () => {
            const color = await notesService.getLeastUsedColorForAllTags(ideaId);
            setLeastUsedColor(color);
            formHandle.current?.valueSetter('colorCode', color);
        };

        if (!colorCode) {
            fetchLeastUsedColor();
        }
    }, [ideaId, colorCode]);

    return (
        <Form
            ref={formHandle}
            onSubmit={createTagCallbackCreator(async (data: Record<string, any>) => {
                const tagName = data.name;
                const colorCode = data.colorCode;
                const savedTag = tagId
                    ? await notesService.updateTag(ideaId, tagId, tagName, colorCode)
                    : await notesService.createTag(ideaId, tagName, colorCode);
                onSave(savedTag);
            })}
            ignoreModified={true}
            initialValues={{ name, colorCode }}
            render={formRenderProps => (
                <FormElement>
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
                        <Field
                            name="colorCode"
                            disabled={!colorCode && !leastUsedColor}
                            component={TagColorPicker}
                            availableColors={appConfig.notes.tagsPalette}
                        />
                        <Field
                            name="name"
                            component={ValidatedInput}
                            validator={tagnameValidators}
                            maxLength={50}
                            placeholder="Add tag name..."
                            wrapperClass="k-flex-1 k-mr-3"
                            disabled={createDisabled}
                        />

                        <Button disabled={!formRenderProps.allowSubmit || createDisabled} type="submit">
                            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                                <CreateIcon className="k-icp-icon k-icp-icon-size-4" /> {tagId ? 'Update' : 'Create'}
                            </StackLayout>
                        </Button>
                        <Button type="reset" onClick={onCancel} disabled={createDisabled}>
                            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                                <RemoveIcon className="k-icp-icon k-icp-icon-size-4" /> Cancel
                            </StackLayout>
                        </Button>
                    </StackLayout>
                </FormElement>
            )}
        />
    );
}

function TagColorPicker({
    availableColors,
    value,
    onChange,
    disabled
}: {
    availableColors?: string[];
    value: string;
    onChange: (e: any) => void;
    disabled?: boolean;
}) {
    const toggleButtonRef = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState(false);

    return (
        <>
            <DivButton ref={toggleButtonRef} fillMode="flat" size="small" onClick={() => setExpanded(s => !s)} disabled={disabled} className="!k-min-w-0">
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                    <span className="palette-color palette-color--tags" style={{ backgroundColor: `#${value}` }}></span>
                    <ChevronDownIcon className="k-icp-icon k-icp-icon-size-4" />
                </StackLayout>
            </DivButton>
            <Popup
                anchor={toggleButtonRef.current}
                show={expanded}
                anchorAlign={{ horizontal: 'left', vertical: 'bottom' }}
                popupAlign={{ horizontal: 'left', vertical: 'top' }}
            >
                <div>
                    <div className={combineClassNames('k-fs-sm k-p-1 k-icp-subtle-text')}>Assign color</div>
                    <div className="tag-colors-popover-content k-p-1">
                        {availableColors?.map(color => (
                            <Button
                                key={color}
                                fillMode="flat"
                                size="small"
                                className=" !k-min-w-0 -!p-0.75"
                                onClick={() => {
                                    onChange({ value: color });
                                    setExpanded(false);
                                }}
                            >
                                <div className="palette-color palette-color--tags" style={{ backgroundColor: `#${color}` }}></div>
                                {value === color && <div className="checked-color" />}
                            </Button>
                        ))}
                    </div>
                </div>
            </Popup>
        </>
    );
}

const NoteTagActionsCellTemplate = createCellTemplateFromComponent(function({ cellProps }: CellTemplateProps<NoteEditableTagStats>) {
    const onChange = cellProps.onChange;
    return (
        <StackLayout align={{ horizontal: 'end', vertical: 'middle' }} className="k-gap-3">
            <Button
                className="k-icp-svg-icon-button"
                onClick={
                    onChange
                        ? e => {
                              onChange({ dataItem: cellProps.dataItem, dataIndex: cellProps.dataIndex, syntheticEvent: e, field: 'command', value: 'edit' });
                          }
                        : undefined
                }
            >
                <EditIcon className="k-icp-icon" />
            </Button>
            <Button
                className="k-icp-svg-icon-button"
                onClick={
                    onChange
                        ? e => {
                              onChange({
                                  dataItem: cellProps.dataItem,
                                  dataIndex: cellProps.dataIndex,
                                  syntheticEvent: e,
                                  field: 'command',
                                  value: 'delete'
                              });
                          }
                        : undefined
                }
            >
                <DeleteIcon className="k-icp-icon" />
            </Button>
        </StackLayout>
    );
});
