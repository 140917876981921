import { GridCell, GridCellProps, GridItemChangeEvent } from '@progress/kendo-react-grid';
import { ComponentType, ReactElement, cloneElement } from 'react';

export type CellTemplateProps<TData> = {
    dataItem: TData;
    cellProps: GridCellProps;
};

export function createCellTemplateFromComponent<TData>(component: ComponentType<CellTemplateProps<TData>>) {
    return TemplatedGridCell.bind<undefined, ComponentType<CellTemplateProps<TData>>, [GridCellProps], JSX.Element>(undefined, component);
}

function TemplatedGridCell<TData>(CellTemplate: ComponentType<CellTemplateProps<TData>>, props: GridCellProps) {
    function renderTemplate(defaultCell: ReactElement | null, props: GridCellProps) {
        if (!defaultCell) return defaultCell;

        return cloneElement(defaultCell, undefined, <CellTemplate dataItem={props.dataItem} cellProps={props} />);
    }

    return <GridCell {...props} field={props.field ?? ''} render={renderTemplate} />;
}

export type GridCommand = string | { name: string; value?: unknown };
export function triggerGridCommand(props: GridCellProps, syntheticEvent: React.SyntheticEvent<any, Event>, command: GridCommand) {
    if (!props.onChange) return;
    props.onChange?.({
        dataItem: props.dataItem,
        dataIndex: props.dataIndex,
        syntheticEvent: syntheticEvent,
        field: 'command',
        value: command
    });
}

export type GridCommandHandler<TDataItem> = (dataItem: TDataItem, commandName: string, commandValue: unknown) => void;
export function gridCommandHandler<TDataItem>(handler: GridCommandHandler<TDataItem>) {
    return function(e: GridItemChangeEvent) {
        if (e.field !== 'command') return;
        const command: GridCommand = e.value;
        const commandName = typeof command === 'string' ? command : command.name;
        const commandValue = typeof command === 'string' ? undefined : command.value;
        const dataItem: TDataItem = e.dataItem;
        handler(dataItem, commandName, commandValue);
    };
}
