import buyerIllustrationUrl from '../../images/buyer-illustration.svg';
import customerProblemIllustrationUrl from '../../images/customer-problem-illustration.svg';
import diceIllustrationUrl from '../../images/dice-illustration.svg';
import { ResearchTypeV2, ResearchV2Status } from '../../services/researchV2Service';

export const researchV2TypeTextMap: Record<ResearchTypeV2, string> = {
    [ResearchTypeV2.ProblemDiscoveryAndValidation]: 'Problem discovery and validation',
    [ResearchTypeV2.WinLoss]: 'Win-loss',
    [ResearchTypeV2.BuyerPersona]: 'Buyer persona',
    [ResearchTypeV2.Other]: 'Other'
};

export const researchTypeToIllustrationUrlMap: Record<ResearchTypeV2, string> = {
    [ResearchTypeV2.ProblemDiscoveryAndValidation]: customerProblemIllustrationUrl,
    [ResearchTypeV2.WinLoss]: diceIllustrationUrl,
    [ResearchTypeV2.BuyerPersona]: buyerIllustrationUrl,
    [ResearchTypeV2.Other]: customerProblemIllustrationUrl
};

export const researchV2StatusTextMap: Record<ResearchV2Status, string> = {
    [ResearchV2Status.NotStarted]: 'Not started',
    [ResearchV2Status.InProgress]: 'In progress',
    [ResearchV2Status.Complete]: 'Completed'
};
