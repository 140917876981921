import { EventEmitter } from 'eventemitter3';
import { appConfig } from '../config';
import { BoxType } from './canvasService';
import { debounce, ErrorWithOperationDisplayName } from './common';
import { dateTimeService } from './dateTimeService';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';
import { HypothesisGroup, HypothesisType } from './hypothesesService';
import { InsightType } from './insightsService';
import { InterviewType } from './interviewsService';
import { JourneyTaskStatus } from './journeyService';
import { ResearchType } from './researchService';
import { PendingAction } from './usersService';

export enum ConnectionFocus {
    AllIdeas = 'ALL',
    NoIdeas = ''
}

class ConnectionsService extends HttpServiceBase {
    constructor() {
        super('/api/connections');
    }

    @ErrorWithOperationDisplayName('Subscribe for a group of real time updates')
    focusConnection(connectionId: string, focus: string, pendingAction?: PendingAction) {
        return this.performRequestWithoutParsingResponse({
            path: `/${connectionId}/focus`,
            method: RequestMethod.PUT,
            body: {
                ideaInterest: focus,
                pendingAction: pendingAction
            }
        });
    }
}

export const connectionsService = new ConnectionsService();

export class RealTimeUpdatesPipe {
    static readonly CONNECT_TIMEOUT = 5000;
    static readonly PING_TIMEOUT = 5000;
    static readonly RECONNECT_DELAYS = [5000, 10000, 30000, 90000];
    static readonly RESET_RECONNECT_DELAY_DELAY = 5000;
    static readonly HEARTBEAT_INTERVAL = 300000; // 5 minutes
    static readonly FOCUS_UPDATE_DEBOUNCE_INTERVAL = 250;
    static readonly WEBSOCKET_URL = appConfig.webSocketUrl;

    private onSocketOpenDelegate = this.onSocketOpen.bind(this);
    private onSocketMessageDelegate = this.onSocketMessage.bind(this);
    private updateCurrentFocusDebounced = debounce(this.updateCurrentFocus.bind(this), RealTimeUpdatesPipe.FOCUS_UPDATE_DEBOUNCE_INTERVAL);
    private onSocketCloseDelegate = this.onSocketClose.bind(this);
    private onConnectTimeoutDelegate = this.onConnectTimeout.bind(this);
    private onPingTimeoutDelegate = this.onPingTimeout.bind(this);
    private ensureSocketConnectedDelegate = this.ensureSocketConnected.bind(this);
    private onDocumentVisibilityChangeDelegate = this.onDocumentVisibilityChange.bind(this);

    private webSocket?: WebSocket;
    private closeRequested: boolean = false;
    private _connectionId: string = '';
    private lastKnownConnectionId = '';

    private currentFocus?: string;
    private pendingAction?: PendingAction;
    private delayReconnect: boolean = false;
    private connectTimeoutId?: NodeJS.Timeout;
    private pingTimeoutId?: NodeJS.Timeout;
    private reconnectTimeoutId?: NodeJS.Timeout;
    private resetDelayReconnectTimeoutId?: NodeJS.Timeout;
    private heartbeatIntervalId?: NodeJS.Timeout;
    private currentReconnectDelay = 0;

    private get connectionId() {
        return this._connectionId;
    }

    private set connectionId(val: string) {
        const oldConnectionId = this._connectionId;
        this._connectionId = val;

        if (oldConnectionId !== this._connectionId) {
            const data: RealTimeEventTypeMap['connection']['idChanged'] = {
                category: 'connection',
                type: 'idChanged',
                connectionId: this._connectionId
            };
            realTimeUpdatesEventHub.emitMessage(data);
        }

        if (this._connectionId) {
            if (this.lastKnownConnectionId && this._connectionId !== this.lastKnownConnectionId) {
                const data: RealTimeEventTypeMap['connection']['reconnected'] = {
                    category: 'connection',
                    type: 'reconnected',
                    connectionId: this._connectionId
                };
                realTimeUpdatesEventHub.emitMessage(data);
            }

            this.lastKnownConnectionId = this._connectionId;
        }
    }

    async open() {
        if (this.closeRequested) return;

        if (this.webSocket) throw new Error('The pipe is open already');

        if (this.closeRequested) return;

        try {
            this.connectTimeoutId = setTimeout(this.onConnectTimeoutDelegate, RealTimeUpdatesPipe.CONNECT_TIMEOUT);
            this.webSocket = new WebSocket(RealTimeUpdatesPipe.WEBSOCKET_URL);
            this.webSocket.addEventListener('open', this.onSocketOpenDelegate);
            this.webSocket.addEventListener('message', this.onSocketMessageDelegate);
            this.webSocket.addEventListener('close', this.onSocketCloseDelegate);
        } catch {
            this.delayReconnect = true;
            this.reconnect();
        }
    }

    close() {
        this.closeRequested = true;
        this.clearReconnectTimeout();
        this.cleanCurrentState();
        this.currentFocus = undefined;
        this.pendingAction = undefined;
    }

    focus(focus: string) {
        if (this.closeRequested) return;

        this.currentFocus = focus;
        this.updateCurrentFocusDebounced();
    }

    setPendingAction(pendingAction?: PendingAction) {
        if (this.closeRequested) return;

        this.pendingAction = pendingAction;
        this.updateCurrentFocusDebounced();
    }

    private async updateCurrentFocus() {
        if (this.closeRequested || !this.connectionId || typeof this.currentFocus === 'undefined') return;
        try {
            await connectionsService.focusConnection(this.connectionId, this.currentFocus, this.pendingAction);
        } catch (e) {
            this.reconnect();
        }
    }

    private onSocketOpen(e: Event) {
        this.clearConnectTimeout();

        this.ensureSocketConnected();
        this.heartbeatIntervalId = setInterval(this.ensureSocketConnectedDelegate, RealTimeUpdatesPipe.HEARTBEAT_INTERVAL);
        document.addEventListener('visibilitychange', this.onDocumentVisibilityChangeDelegate);

        this.clearResetDelayReconnectTimeout();
        this.resetDelayReconnectTimeoutId = setTimeout(() => {
            this.clearResetDelayReconnectTimeout();
            this.currentReconnectDelay = 0;
            this.delayReconnect = false;
        }, RealTimeUpdatesPipe.RESET_RECONNECT_DELAY_DELAY);
    }

    private ensureSocketConnected() {
        if (this.closeRequested) return;

        this.pingTimeoutId = setTimeout(this.onPingTimeoutDelegate, RealTimeUpdatesPipe.PING_TIMEOUT);
        this.webSocket?.send('PING');
    }

    private onSocketMessage(e: MessageEvent<string>) {
        if (this.closeRequested) return;

        const data = JSON.parse(e.data);
        if (data.connectionId) {
            this.onPingResponse(data);
            return;
        }

        realTimeUpdatesEventHub.emitMessage(data);
    }

    private onPingResponse(data: any) {
        this.clearPingTimeout();
        this.connectionId = data.connectionId;
        this.updateCurrentFocusDebounced();
    }

    private onSocketClose(e: CloseEvent) {
        this.reconnect();
    }

    private reconnect() {
        if (this.closeRequested || this.reconnectTimeoutId) return;

        this.cleanCurrentState();

        if (this.delayReconnect) {
            this.updateReconnectDelay();
            this.reconnectTimeoutId = setTimeout(() => {
                this.clearReconnectTimeout();
                this.open();
            }, this.currentReconnectDelay);
        } else {
            this.delayReconnect = true;
            this.open();
        }
    }

    private updateReconnectDelay() {
        this.currentReconnectDelay =
            RealTimeUpdatesPipe.RECONNECT_DELAYS.find(d => d > this.currentReconnectDelay) ||
            RealTimeUpdatesPipe.RECONNECT_DELAYS[RealTimeUpdatesPipe.RECONNECT_DELAYS.length - 1];
    }

    private onConnectTimeout() {
        this.clearConnectTimeout();

        this.reconnect();
    }

    private onPingTimeout() {
        this.clearPingTimeout();

        this.reconnect();
    }

    private onDocumentVisibilityChange() {
        if (document.visibilityState === 'visible' && !this.closeRequested && this.connectionId) this.ensureSocketConnected();
    }

    private cleanCurrentState() {
        this.clearConnectTimeout();
        this.clearPingTimeout();
        this.clearHeartbeatInterval();
        this.clearResetDelayReconnectTimeout();
        document.removeEventListener('visibilitychange', this.onDocumentVisibilityChangeDelegate);
        this.clearWebSocket();

        this.connectionId = '';
    }

    private clearWebSocket() {
        if (this.webSocket) {
            this.webSocket.removeEventListener('message', this.onSocketMessageDelegate);
            this.webSocket.removeEventListener('open', this.onSocketOpenDelegate);
            this.webSocket.removeEventListener('close', this.onSocketCloseDelegate);
            if (this.webSocket.readyState === WebSocket.OPEN) this.webSocket.close();
            this.webSocket = undefined;
        }
    }

    private clearHeartbeatInterval() {
        if (this.heartbeatIntervalId) {
            clearInterval(this.heartbeatIntervalId);
            this.heartbeatIntervalId = undefined;
        }
    }

    private clearConnectTimeout() {
        if (this.connectTimeoutId) {
            clearTimeout(this.connectTimeoutId);
            this.connectTimeoutId = undefined;
        }
    }

    private clearPingTimeout() {
        if (this.pingTimeoutId) {
            clearTimeout(this.pingTimeoutId);
            this.pingTimeoutId = undefined;
        }
    }

    private clearReconnectTimeout() {
        if (this.reconnectTimeoutId) {
            clearTimeout(this.reconnectTimeoutId);
            this.reconnectTimeoutId = undefined;
        }
    }

    private clearResetDelayReconnectTimeout() {
        if (this.resetDelayReconnectTimeoutId) {
            clearTimeout(this.resetDelayReconnectTimeoutId);
            this.resetDelayReconnectTimeoutId = undefined;
        }
    }
}

export type RealTimeEventTypeMap = {
    user: {
        profileUpdate: RealTimeUpdateEvent<'user', 'profileUpdate'> & RealTimeUpdateUserEventData;
        licenseUpdated: RealTimeUpdateEvent<'user', 'licenseUpdated'> & RealTimeUpdateUserEventData;
        activate: RealTimeUpdateEvent<'user', 'activate'> & RealTimeUpdateUserEventData;
    };
    notification: {
        new: RealTimeUpdateEvent<'notification', 'new'> & { id: number };
        seenChanged: RealTimeUpdateEvent<'notification', 'seenChanged'> & { lastSeen: number };
    };
    membership: {
        new: RealTimeUpdateEvent<'membership', 'new'> & RealTimeUpdateMembershipEventData;
        change: RealTimeUpdateEvent<'membership', 'change'> & RealTimeUpdateMembershipEventData;
        revoke: RealTimeUpdateEvent<'membership', 'revoke'> & RealTimeUpdateMembershipEventData;
    };
    idea: {
        created: RealTimeUpdateEvent<'idea', 'created'> & RealTimeUpdateIdeaEventData;
        updated: RealTimeUpdateEvent<'idea', 'updated'> & RealTimeUpdateIdeaEventData;
        deleted: RealTimeUpdateEvent<'idea', 'deleted'> & RealTimeUpdateIdeaEventData;
        restored: RealTimeUpdateEvent<'idea', 'restored'> & RealTimeUpdateIdeaEventData;
        itemAdd: RealTimeUpdateEvent<'idea', 'itemAdd'> & RealTimeUpdateCanvasItemEventData;
        itemUpdate: RealTimeUpdateEvent<'idea', 'itemUpdate'> & RealTimeUpdateCanvasItemEventData;
        itemDelete: RealTimeUpdateEvent<'idea', 'itemDelete'> & RealTimeUpdateCanvasItemEventData;
        itemRestore: RealTimeUpdateEvent<'idea', 'itemRestore'> & RealTimeUpdateCanvasItemEventData;
        boxReorder: RealTimeUpdateEvent<'idea', 'boxReorder'> & RealTimeUpdateCanvasBoxEventData;
        focusedChanged: RealTimeUpdateEvent<'idea', 'focusedChanged'> & RealTimeUpdateIdeaEventData;
        hypothesisAdd: RealTimeUpdateEvent<'idea', 'hypothesisAdd'> & RealTimeUpdateHypothesisEventData;
        hypothesisUpdate: RealTimeUpdateEvent<'idea', 'hypothesisUpdate'> & RealTimeUpdateHypothesisUpdateEventData;
        hypothesisDelete: RealTimeUpdateEvent<'idea', 'hypothesisDelete'> & RealTimeUpdateHypothesisEventData;
        hypothesisRestore: RealTimeUpdateEvent<'idea', 'hypothesisRestore'> & RealTimeUpdateHypothesisEventData;
    };
    notes: {
        noteAdd: RealTimeUpdateEvent<'notes', 'noteAdd'> & RealTimeUpdateNoteEventData;
        noteUpdate: RealTimeUpdateEvent<'notes', 'noteUpdate'> & RealTimeUpdateNoteUpdateData;
        noteDelete: RealTimeUpdateEvent<'notes', 'noteDelete'> & RealTimeUpdateNoteEventData;
        noteRestore: RealTimeUpdateEvent<'notes', 'noteRestore'> & RealTimeUpdateNoteEventData;
        tagAdd: RealTimeUpdateEvent<'notes', 'tagAdd'> & RealTimeUpdateNoteTagEventData;
        tagUpdate: RealTimeUpdateEvent<'notes', 'tagUpdate'> & RealTimeUpdateNoteTagEventData;
        tagDelete: RealTimeUpdateEvent<'notes', 'tagDelete'> & RealTimeUpdateNoteTagEventData;
        tagRestore: RealTimeUpdateEvent<'notes', 'tagRestore'> & RealTimeUpdateNoteTagEventData;
    };
    connection: {
        idChanged: RealTimeUpdateEvent<'connection', 'idChanged'> & RealTimeUpdateConnectionEventData;
        reconnected: RealTimeUpdateEvent<'connection', 'reconnected'> & RealTimeUpdateConnectionEventData;
    };
    task: {
        statusChanged: RealTimeUpdateEvent<'task', 'statusChanged'> & RealTimeUpdateTaskStatusEventData;
        unlocked: RealTimeUpdateEvent<'task', 'unlocked'> & RealTimeUpdateTaskEventData;
        sequenceVariationAdded: RealTimeUpdateEvent<'task', 'sequenceVariationAdded'> & RealTimeUpdateTaskSequenceEventData;
        sequenceVariationRemoved: RealTimeUpdateEvent<'task', 'sequenceVariationRemoved'> & RealTimeUpdateTaskSequenceEventData;
    };
    taskMarker: {
        changed: RealTimeUpdateEvent<'taskMarker', 'changed'> & RealTimeUpdateTaskMarkerEventData;
    };
    contact: {
        personAdd: RealTimeUpdateEvent<'contact', 'personAdd'> & RealTimeUpdatePersonEventData;
        personUpdate: RealTimeUpdateEvent<'contact', 'personUpdate'> & RealTimeUpdatePersonUpdateEventData;
        personDelete: RealTimeUpdateEvent<'contact', 'personDelete'> & RealTimeUpdatePersonEventData;
        personRestore: RealTimeUpdateEvent<'contact', 'personRestore'> & RealTimeUpdatePersonEventData;
        companyAdd: RealTimeUpdateEvent<'contact', 'companyAdd'> & RealTimeUpdateCompanyEventData;
        companyUpdate: RealTimeUpdateEvent<'contact', 'companyUpdate'> & RealTimeUpdateCompanyEventData;
        companyDelete: RealTimeUpdateEvent<'contact', 'companyDelete'> & RealTimeUpdateCompanyEventData;
        companyRestore: RealTimeUpdateEvent<'contact', 'companyRestore'> & RealTimeUpdateCompanyEventData;
        tagAdd: RealTimeUpdateEvent<'contact', 'tagAdd'> & RealTimeUpdateContactTagEventData;
        tagUpdate: RealTimeUpdateEvent<'contact', 'tagUpdate'> & RealTimeUpdateContactTagEventData;
        tagDelete: RealTimeUpdateEvent<'contact', 'tagDelete'> & RealTimeUpdateContactTagEventData;
        tagRestore: RealTimeUpdateEvent<'contact', 'tagRestore'> & RealTimeUpdateContactTagEventData;
        noteAdd: RealTimeUpdateEvent<'contact', 'noteAdd'> & RealTimeUpdateContactNoteEventData;
        noteUpdate: RealTimeUpdateEvent<'contact', 'noteUpdate'> & RealTimeUpdateContactNoteEventData;
        noteDelete: RealTimeUpdateEvent<'contact', 'noteDelete'> & RealTimeUpdateContactNoteEventData;
        noteRestore: RealTimeUpdateEvent<'contact', 'noteRestore'> & RealTimeUpdateContactNoteEventData;
        reachOutAdd: RealTimeUpdateEvent<'contact', 'reachOutAdd'> & RealTimeUpdateReachOutEventData;
        reachOutUpdate: RealTimeUpdateEvent<'contact', 'reachOutUpdate'> & RealTimeUpdateReachOutEventData;
        reachOutDelete: RealTimeUpdateEvent<'contact', 'reachOutDelete'> & RealTimeUpdateReachOutEventData;
        reachOutRestore: RealTimeUpdateEvent<'contact', 'reachOutRestore'> & RealTimeUpdateReachOutEventData;
    };
    scheduling: {
        meetingAdd: RealTimeUpdateEvent<'scheduling', 'meetingAdd'> & RealTimeUpdateMeetingEventData;
        meetingUpdate: RealTimeUpdateEvent<'scheduling', 'meetingUpdate'> & RealTimeUpdateMeetingEventData;
        meetingDelete: RealTimeUpdateEvent<'scheduling', 'meetingDelete'> & RealTimeUpdateMeetingEventData;
        meetingRestore: RealTimeUpdateEvent<'scheduling', 'meetingRestore'> & RealTimeUpdateMeetingEventData;
        scheduleAdd: RealTimeUpdateEvent<'scheduling', 'scheduleAdd'> & RealTimeUpdateScheduleEventData;
        scheduleUpdate: RealTimeUpdateEvent<'scheduling', 'scheduleUpdate'> & RealTimeUpdateScheduleEventData;
        scheduleDelete: RealTimeUpdateEvent<'scheduling', 'scheduleDelete'> & RealTimeUpdateScheduleEventData;
        scheduleRestore: RealTimeUpdateEvent<'scheduling', 'scheduleRestore'> & RealTimeUpdateScheduleEventData;
    };
    research: {
        add: RealTimeUpdateEvent<'research', 'add'> & RealTimeUpdateResearchEventData;
        update: RealTimeUpdateEvent<'research', 'update'> & RealTimeUpdateResearchEventData;
        delete: RealTimeUpdateEvent<'research', 'delete'> & RealTimeUpdateResearchEventData;
        restore: RealTimeUpdateEvent<'research', 'restore'> & RealTimeUpdateResearchEventData;
        hypothesesUpdated: RealTimeUpdateEvent<'research', 'hypothesesUpdated'> & RealTimeUpdateResearchEventData;
        schedulesUpdated: RealTimeUpdateEvent<'research', 'schedulesUpdated'> & RealTimeUpdateResearchEventData;
        scheduleAdded: RealTimeUpdateEvent<'research', 'scheduleAdded'> & RealTimeUpdateResearchScheduleAddedEventData;
        contactsUpdated: RealTimeUpdateEvent<'research', 'contactsUpdated'> & RealTimeUpdateResearchEventData;
        reachOutAdd: RealTimeUpdateEvent<'research', 'reachOutAdd'> & RealTimeUpdateResearchReachOutEventData;
        reachOutUpdate: RealTimeUpdateEvent<'research', 'reachOutUpdate'> & RealTimeUpdateResearchReachOutEventData;
        reachOutDelete: RealTimeUpdateEvent<'research', 'reachOutDelete'> & RealTimeUpdateResearchReachOutEventData;
        reachOutRestore: RealTimeUpdateEvent<'research', 'reachOutRestore'> & RealTimeUpdateResearchReachOutEventData;
        contactUpdated: RealTimeUpdateEvent<'research', 'contactUpdated'> & RealTimeUpdateResearchContactEventData;
    };
    interview: {
        add: RealTimeUpdateEvent<'interview', 'add'> & RealTimeUpdateInterviewEventData;
        update: RealTimeUpdateEvent<'interview', 'update'> & RealTimeUpdateInterviewEventData;
        delete: RealTimeUpdateEvent<'interview', 'delete'> & RealTimeUpdateInterviewEventData;
        restore: RealTimeUpdateEvent<'interview', 'restore'> & RealTimeUpdateInterviewEventData;
        entryAdd: RealTimeUpdateEvent<'interview', 'entryAdd'> & RealTimeUpdateInterviewEntryEventData;
        entryUpdate: RealTimeUpdateEvent<'interview', 'entryUpdate'> & RealTimeUpdateInterviewEntryEventData;
        entryDelete: RealTimeUpdateEvent<'interview', 'entryDelete'> & RealTimeUpdateInterviewEntryEventData;
        entryRestore: RealTimeUpdateEvent<'interview', 'entryRestore'> & RealTimeUpdateInterviewEntryEventData;
        hypothesisVerdictUpdate: RealTimeUpdateEvent<'interview', 'hypothesisVerdictUpdate'> & RealTimeUpdateInterviewHypothesisVerdictEventData;
        hypothesisVerdictDelete: RealTimeUpdateEvent<'interview', 'hypothesisVerdictDelete'> & RealTimeUpdateInterviewHypothesisVerdictEventData;
        quoteAdd: RealTimeUpdateEvent<'interview', 'quoteAdd'> & RealTimeUpdateInterviewQuoteEventData;
        quoteUpdate: RealTimeUpdateEvent<'interview', 'quoteUpdate'> & RealTimeUpdateInterviewQuoteEventData;
        quoteDelete: RealTimeUpdateEvent<'interview', 'quoteDelete'> & RealTimeUpdateInterviewQuoteEventData;
        quoteRestore: RealTimeUpdateEvent<'interview', 'quoteRestore'> & RealTimeUpdateInterviewQuoteEventData;

        scriptAdd: RealTimeUpdateEvent<'interview', 'scriptAdd'> & RealTimeUpdateInterviewScriptData;
        scriptUpdate: RealTimeUpdateEvent<'interview', 'scriptUpdate'> & RealTimeUpdateInterviewScriptData;
        scriptReorder: RealTimeUpdateEvent<'interview', 'scriptReorder'> & RealTimeUpdateInterviewScriptData;
        scriptDelete: RealTimeUpdateEvent<'interview', 'scriptDelete'> & RealTimeUpdateInterviewScriptData;
        scriptRestore: RealTimeUpdateEvent<'interview', 'scriptRestore'> & RealTimeUpdateInterviewScriptData;
        scriptSectionAdd: RealTimeUpdateEvent<'interview', 'scriptSectionAdd'> & RealTimeUpdateInterviewScriptSectionEventData;
        scriptSectionUpdate: RealTimeUpdateEvent<'interview', 'scriptSectionUpdate'> & RealTimeUpdateInterviewScriptSectionEventData;
        scriptSectionDelete: RealTimeUpdateEvent<'interview', 'scriptSectionDelete'> & RealTimeUpdateInterviewScriptSectionEventData;
        scriptSectionRestore: RealTimeUpdateEvent<'interview', 'scriptSectionRestore'> & RealTimeUpdateInterviewScriptSectionEventData;
        scriptSectionReorder: RealTimeUpdateEvent<'interview', 'scriptSectionReorder'> & RealTimeUpdateInterviewScriptSectionEventData;
        scriptEntryAdd: RealTimeUpdateEvent<'interview', 'scriptEntryAdd'> & RealTimeUpdateInterviewScriptEntryEventData;
        scriptEntryUpdate: RealTimeUpdateEvent<'interview', 'scriptEntryUpdate'> & RealTimeUpdateInterviewScriptEntryEventData;
        scriptEntryDelete: RealTimeUpdateEvent<'interview', 'scriptEntryDelete'> & RealTimeUpdateInterviewScriptEntryEventData;
        scriptEntryRestore: RealTimeUpdateEvent<'interview', 'scriptEntryRestore'> & RealTimeUpdateInterviewScriptEntryEventData;
        scriptTipAdd: RealTimeUpdateEvent<'interview', 'scriptTipAdd'> & RealTimeUpdateInterviewScriptTipEventData;
        scriptTipUpdate: RealTimeUpdateEvent<'interview', 'scriptTipUpdate'> & RealTimeUpdateInterviewScriptTipEventData;
        scriptTipDelete: RealTimeUpdateEvent<'interview', 'scriptTipDelete'> & RealTimeUpdateInterviewScriptTipEventData;
        scriptTipRestore: RealTimeUpdateEvent<'interview', 'scriptTipRestore'> & RealTimeUpdateInterviewScriptTipEventData;
    };
    timer: {
        update: RealTimeUpdateEvent<'timer', 'update'> & RealTimeUpdateTimerEventData;
    };
    insight: {
        add: RealTimeUpdateEvent<'insight', 'add'> & RealTimeUpdateInsightEventData;
        update: RealTimeUpdateEvent<'insight', 'update'> & RealTimeUpdateInsightEventData;
        delete: RealTimeUpdateEvent<'insight', 'delete'> & RealTimeUpdateInsightEventData;
        restore: RealTimeUpdateEvent<'insight', 'restore'> & RealTimeUpdateInsightEventData;
        coverageEntryAdd: RealTimeUpdateEvent<'insight', 'coverageEntryAdd'> & RealTimeUpdateInsightCoverageData;
        coverageEntryUpdate: RealTimeUpdateEvent<'insight', 'coverageEntryUpdate'> & RealTimeUpdateInsightCoverageData;
        coverageEntryDelete: RealTimeUpdateEvent<'insight', 'coverageEntryDelete'> & RealTimeUpdateInsightCoverageData;
        coverageEntryRestore: RealTimeUpdateEvent<'insight', 'coverageEntryRestore'> & RealTimeUpdateInsightCoverageData;
    };
    interviewCollection: {
        analysisComplete: RealTimeUpdateEvent<'interviewCollection', 'analysisComplete'> & RealTimeExperimentalInterviewCollectionEventData;
        interviewScriptAnalysisComplete: RealTimeUpdateEvent<'interviewCollection', 'interviewScriptAnalysisComplete'> &
            RealTimeExperimentalInterviewScriptAnalysisEventData;
    };
    chat: {
        messageAdd: RealTimeUpdateEvent<'chat', 'messageAdd'> & RealTimeChatMessageEventData;
        initialize: RealTimeUpdateEvent<'chat', 'initialize'> & RealTimeChatEventData;
        close: RealTimeUpdateEvent<'chat', 'close'> & RealTimeChatEventData;
    };
    document: {
        create: RealTimeUpdateEvent<'document', 'create'> & RealTimeUpdateDocumentEventData;
        update: RealTimeUpdateEvent<'document', 'update'> & RealTimeUpdateDocumentEventData;
    };
    interview3: {
        scriptAdd: RealTimeUpdateEvent<'interview3', 'scriptAdd'> & RealTimeUpdateInterviewScript2Data;
        scriptUpdate: RealTimeUpdateEvent<'interview3', 'scriptUpdate'> & RealTimeUpdateInterviewScript2UpdateData;
        scriptDelete: RealTimeUpdateEvent<'interview3', 'scriptDelete'> & RealTimeUpdateInterviewScript2Data;
        scriptRestore: RealTimeUpdateEvent<'interview3', 'scriptRestore'> & RealTimeUpdateInterviewScript2Data;
        add: RealTimeUpdateEvent<'interview3', 'add'> & RealTimeUpdateInterviewEventData;
        update: RealTimeUpdateEvent<'interview3', 'update'> & RealTimeUpdateInterviewEventData;
        delete: RealTimeUpdateEvent<'interview3', 'delete'> & RealTimeUpdateInterviewEventData;
        restore: RealTimeUpdateEvent<'interview3', 'restore'> & RealTimeUpdateInterviewEventData;
        analysisAdd: RealTimeUpdateEvent<'interview3', 'analysisAdd'> & RealTimeUpdateInterviewEventData;
        analysisUpdate: RealTimeUpdateEvent<'interview3', 'analysisUpdate'> & RealTimeUpdateInterviewEventData;
        referredContactUpdate: RealTimeUpdateEvent<'interview3', 'referredContactUpdate'> & RealTimeUpdateInterviewReferredContactEventData;
    };
    research2: {
        add: RealTimeUpdateEvent<'research2', 'add'> & RealTimeUpdateResearch2EventData;
        update: RealTimeUpdateEvent<'research2', 'update'> & RealTimeUpdateResearch2EventData;
        delete: RealTimeUpdateEvent<'research2', 'delete'> & RealTimeUpdateResearch2EventData;
        restore: RealTimeUpdateEvent<'research2', 'restore'> & RealTimeUpdateResearch2EventData;
        interviewsUpdate: RealTimeUpdateEvent<'research2', 'interviewsUpdate'> & RealTimeUpdateResearch2InterviewsUpdateEventData;
        analysisCreate: RealTimeUpdateEvent<'research2', 'analysisCreate'> & RealTimeUpdateResearch2EventData;
        analysisUpdate: RealTimeUpdateEvent<'research2', 'analysisUpdate'> & RealTimeUpdateResearch2AnalysisUpdateEventData;
    };
};

type RealTimeUpdateEvent<TCategory extends keyof RealTimeEventTypeMap, TType extends keyof RealTimeEventTypeMap[TCategory]> = {
    category: TCategory;
    type: TType;
};

export type RealTimeUpdateUserEventData = { id: string };
export type RealTimeUpdateIdeaEventData = { ideaId: string };
export type RealTimeUpdateMembershipEventData = RealTimeUpdateIdeaEventData & { userId: string };
export type RealTimeUpdateCanvasBoxEventData = RealTimeUpdateIdeaEventData & { box: BoxType };
export type RealTimeUpdateCanvasItemEventData = RealTimeUpdateCanvasBoxEventData & { itemId: number };
export type RealTimeUpdateConnectionEventData = { connectionId: string };
export type RealTimeUpdateTaskEventData = RealTimeUpdateIdeaEventData & { task: string; sequence: string; variation?: string };
export type RealTimeUpdateTaskStatusEventData = RealTimeUpdateTaskEventData & { status: JourneyTaskStatus };
export type RealTimeUpdateTaskMarkerEventData = RealTimeUpdateIdeaEventData & { user: string };
export type RealTimeUpdateTaskSequenceEventData = RealTimeUpdateIdeaEventData & { sequence: string; variation: string };
export type RealTimeUpdatePersonEventData = RealTimeUpdateIdeaEventData & { personId: number; companyId?: number };
export type RealTimeUpdatePersonUpdateEventData = RealTimeUpdatePersonEventData & { previousCompanyId?: number };
export type RealTimeUpdateCompanyEventData = RealTimeUpdateIdeaEventData & { companyId: number };
export type RealTimeUpdateContactTagEventData = RealTimeUpdateIdeaEventData & { tagId: number };
export type RealTimeUpdateContactNoteEventData = RealTimeUpdateIdeaEventData & { noteId: number; contactId: number };
export type RealTimeUpdateNoteEventData = RealTimeUpdateIdeaEventData & { noteId: number };
export type RealTimeUpdateNoteUpdateData = RealTimeUpdateNoteEventData & { lowPriority?: boolean };
export type RealTimeUpdateReachOutEventData = RealTimeUpdateIdeaEventData & { reachOutId: number; contactId: number };
export type RealTimeUpdateMeetingEventData = RealTimeUpdateIdeaEventData & {
    meetingId: number;
    userId: string;
    contactId: number;
    startTime: Date;
    endTime: Date;
};
export type RealTimeUpdateScheduleEventData = RealTimeUpdateIdeaEventData & { scheduleId: number; userId: string; actualStartTime: Date; actualEndTime: Date };
export type RealTimeUpdateHypothesisEventData = RealTimeUpdateIdeaEventData & {
    hypothesisId: number;
    hypothesisType: HypothesisType;
    hypothesisGroup: HypothesisGroup;
};
export type RealTimeUpdateHypothesisUpdateEventData = RealTimeUpdateHypothesisEventData & { researchUpdated: boolean };
export type RealTimeUpdateResearchEventData = RealTimeUpdateIdeaEventData & { researchId: number; researchType: ResearchType };
export type RealTimeUpdateResearchScheduleAddedEventData = RealTimeUpdateResearchEventData & { scheduleId: number };
export type RealTimeUpdateResearchContactEventData = RealTimeUpdateResearchEventData & { contactId: number };
export type RealTimeUpdateResearchReachOutEventData = RealTimeUpdateResearchEventData & { researchReachOutId: number; contactId: number };
export type RealTimeUpdateInterviewEventData = RealTimeUpdateIdeaEventData & { interviewId: number };
export type RealTimeUpdateTimerEventData = RealTimeUpdateIdeaEventData & { timerId: number };
export type RealTimeUpdateInterviewEntryEventData = RealTimeUpdateInterviewEventData & { interviewSectionId: number; interviewEntryId: number };
export type RealTimeUpdateInterviewHypothesisVerdictEventData = RealTimeUpdateInterviewEventData & { hypothesisId: number; researchId: number };
export type RealTimeUpdateInterviewScriptData = RealTimeUpdateIdeaEventData & { interviewScriptId: number; interviewType: InterviewType };
export type RealTimeUpdateInterviewScriptSectionEventData = RealTimeUpdateIdeaEventData & { interviewScriptSectionId: number; interviewScriptId: number };
export type RealTimeUpdateInterviewScriptEntryEventData = RealTimeUpdateInterviewScriptSectionEventData & { interviewScriptEntryId: number };
export type RealTimeUpdateInterviewScriptTipEventData = RealTimeUpdateInterviewScriptEntryEventData & { interviewScriptTipId: number };
export type RealTimeUpdateInterviewQuoteEventData = RealTimeUpdateInterviewEventData & {
    quoteId: number;
    hypothesisId?: number;
    insightId?: number;
    researchId: number;
};
export type RealTimeUpdateInsightEventData = RealTimeUpdateIdeaEventData & { insightId: number; insightType: InsightType };
export type RealTimeUpdateInsightCoverageData = RealTimeUpdateInterviewEventData & { entryId: number };
export type RealTimeExperimentalInterviewCollectionEventData = RealTimeUpdateIdeaEventData & { interviewCollectionId: number };
export type RealTimeExperimentalInterviewScriptAnalysisEventData = RealTimeExperimentalInterviewCollectionEventData & { interviewId: number };
export type RealTimeChatEventData = RealTimeUpdateIdeaEventData & { chatId: number };
export type RealTimeChatMessageEventData = RealTimeChatEventData & { chatMessageId: number };
export type RealTimeUpdateDocumentEventData = RealTimeUpdateIdeaEventData & { documentId: number };
export type RealTimeUpdateInterviewScript2Data = RealTimeUpdateIdeaEventData & { interviewScriptId: number };
export type RealTimeUpdateInterviewScript2UpdateData = RealTimeUpdateInterviewScript2Data & { lowPriority?: boolean };
export type RealTimeUpdateInterviewReferredContactEventData = RealTimeUpdateInterviewEventData & { referredContactId: number };
export type RealTimeUpdateResearch2EventData = RealTimeUpdateIdeaEventData & { researchId: number };
export type RealTimeUpdateResearch2InterviewsUpdateEventData = RealTimeUpdateResearch2EventData & {
    eventData: { interviewsAdded: number[]; interviewsRemoved: number[] };
};
export type RealTimeUpdateResearch2AnalysisUpdateEventData = RealTimeUpdateResearch2EventData & { replaced: boolean };
export type RealTimeUpdateNoteTagEventData = RealTimeUpdateIdeaEventData & { tagId: number };

function meetingMessageDateParser(message: RealTimeEventTypeMap['scheduling']['meetingAdd' | 'meetingUpdate' | 'meetingDelete' | 'meetingRestore']) {
    dateTimeService.ensureDateField(message, 'startTime');
    dateTimeService.ensureDateField(message, 'endTime');
}

function scheduleMessageDateParser(message: RealTimeEventTypeMap['scheduling']['scheduleAdd' | 'scheduleUpdate' | 'scheduleDelete' | 'scheduleRestore']) {
    dateTimeService.ensureDateField(message, 'actualStartTime');
    dateTimeService.ensureDateField(message, 'actualEndTime');
}

function getRealTimeUpdateMessageDateParser<TCategory extends keyof RealTimeEventTypeMap, TType extends keyof RealTimeEventTypeMap[TCategory]>(
    category: TCategory,
    type: TType & string
): ((message: RealTimeUpdateEvent<TCategory, TType>) => void) | undefined {
    if (category === 'scheduling' && type.startsWith('meeting')) {
        return meetingMessageDateParser as any;
    }

    if (category === 'scheduling' && type.startsWith('schedule')) {
        return scheduleMessageDateParser as any;
    }

    return undefined;
}

class RealTimeUpdatesEventHub {
    private eventEmitter = new EventEmitter();

    addCategoryListener<TCategory extends keyof RealTimeEventTypeMap>(
        category: TCategory,
        listener: (e: RealTimeEventTypeMap[TCategory][keyof RealTimeEventTypeMap[TCategory]]) => void
    ) {
        this.eventEmitter.on(category, listener);
    }

    removeCategoryListener<TCategory extends keyof RealTimeEventTypeMap>(
        category: TCategory,
        listener: (e: RealTimeEventTypeMap[TCategory][keyof RealTimeEventTypeMap[TCategory]]) => void
    ) {
        this.eventEmitter.off(category, listener);
    }

    addEventListener<TCategory extends keyof RealTimeEventTypeMap, TType extends keyof RealTimeEventTypeMap[TCategory] & string>(
        category: TCategory,
        type: TType,
        listener: (e: RealTimeEventTypeMap[TCategory][TType]) => void
    ) {
        this.eventEmitter.on(`${category}.${type}`, listener);
    }

    removeEventListener<TCategory extends keyof RealTimeEventTypeMap, TType extends keyof RealTimeEventTypeMap[TCategory] & string>(
        category: TCategory,
        type: TType,
        listener: (e: RealTimeEventTypeMap[TCategory][TType]) => void
    ) {
        this.eventEmitter.off(`${category}.${type}`, listener);
    }

    emitMessage(message: RealTimeUpdateEvent<any, any>) {
        const dateParser = getRealTimeUpdateMessageDateParser(message.category, message.type);
        if (dateParser) dateParser(message);

        this.eventEmitter.emit(message.category, message);
        this.eventEmitter.emit(`${message.category}.${message.type}`, message);
    }
}

export const realTimeUpdatesEventHub = new RealTimeUpdatesEventHub();

const connectionIdInterceptor = (() => {
    let instance: { currentConnectionId?: string } = {};

    realTimeUpdatesEventHub.addEventListener('connection', 'idChanged', e => {
        instance.currentConnectionId = e.connectionId;
    });

    return function(url: string, request: RequestInit) {
        if (!instance.currentConnectionId) return;
        if (
            request.method !== RequestMethod.POST &&
            request.method !== RequestMethod.PUT &&
            request.method !== RequestMethod.DELETE &&
            request.method !== RequestMethod.PATCH
        ) {
            return;
        }

        if (!request.headers) request.headers = {};
        (request.headers as Record<string, string>)['RTU-Connection-Id'] = instance.currentConnectionId;
    };
})();

HttpServiceBase.registerRequestInterceptor(connectionIdInterceptor);
