import { Reveal } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { ComponentType, ReactElement, ReactNode, useMemo, useState } from 'react';
import {
    AlternativeSolutionHypothesesGroup,
    HypothesesByProblemAndTypeGroup,
    HypothesesByProblemAndTypeList
} from '../../components/hypotheses/hypothesesByProblemAndTypeList';
import { HypothesisEditMode, HypothesisEditor } from '../../components/hypotheses/hypothesisEditor';
import { InterviewEmptyColumnView } from '../../components/interview/interviewModalLayout';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { SvgIconButtonContent } from '../../components/ui/svgIconButtonContent';
import { useGlobalCanvas } from '../../hooks/canvasHooks';
import { useResearchHypotheses, useResearchInterviewHypothesesVerdicts, useResearchInterviewQuotes, useResearchInterviews } from '../../hooks/researchHooks';
import { useResearchParams } from '../../hooks/routerHooks';
import { ReactComponent as TrueHypothesisIcon } from '../../icons/check-circle-closed.svg';
import { ReactComponent as ExpandIcon } from '../../icons/chevron-down.svg';
import { ReactComponent as CollapseIcon } from '../../icons/chevron-up.svg';
import { ReactComponent as CollapseAltIcon } from '../../icons/chevrons-up.svg';
import { ReactComponent as UnknownHypothesisIcon } from '../../icons/minus-circle.svg';
import { ReactComponent as FalseHypothesisIcon } from '../../icons/x-circle.svg';
import emptyHypothesesIllustrationUrl from '../../images/empty-hypotheses-illustration.svg';
import { BoxItem, BoxType } from '../../services/canvasService';
import { combineClassNames, groupBy, toRecord } from '../../services/common';
import { Hypothesis, HypothesisType } from '../../services/hypothesesService';
import { HypothesisVerdict, InterviewHypothesisVerdict } from '../../services/interviewsService';
import { InterviewHypothesisQuote, InterviewQuoteType } from '../../services/researchService';

import { Skeleton } from '@progress/kendo-react-indicators';
import { HypothesisVerdictChip } from '../../components/interview/hypotheses/interviewHypothesesEditList';
import { InterviewItemQuotesGroupedByInterview } from '../../components/interview/interviewItemQuotes';
import { ResearchInterviewLayout } from './researchDetailsPage';

/**
 * @obsolete This code is part of the old research functionality
 */
export function ResearchHypothesesPage() {
    const { ideaId, researchId } = useResearchParams();
    const { canvas } = useGlobalCanvas(undefined, true);
    const researchHypotheses = useResearchHypotheses(ideaId, researchId);
    const researchInterviews = useResearchInterviews(ideaId, researchId);
    const researchHypothesesVerdicts = useResearchInterviewHypothesesVerdicts(ideaId, researchId);
    const researchInterviewQuotes = useResearchInterviewQuotes(ideaId, researchId, InterviewQuoteType.Hypothesis);
    const [selection, setSelection] = useState<{ interviewId: number; quoteId: number; entryId: number }>();
    const selectedInterviewInterviewee = useMemo(
        () => (researchInterviews && selection ? researchInterviews.find(i => i.id === selection.interviewId)?.contact : undefined),
        [researchInterviews, selection]
    );

    const groups = useMemo<HypothesesByProblemAndTypeGroup[] | undefined>(
        () =>
            groupHypotheses(
                researchHypotheses,
                canvas.boxes?.find(b => b.type === BoxType.Problem)?.items,
                canvas.boxes?.find(b => b.type === BoxType.AlternativeSolutions)?.items
            ),
        [canvas.boxes, researchHypotheses]
    );

    function renderHypothesis(hypothesis: Hypothesis) {
        const hypothesisQuotes = researchInterviewQuotes?.filter(q => q.hypothesisId === hypothesis.id && !q.entry.hidden);
        const hypothesisVerdicts = researchHypothesesVerdicts?.filter(v => v.hypothesisId === hypothesis.id);

        return (
            <StackLayout key={hypothesis.id} orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-icp-panel k-gap-3 k-pt-4">
                <HypothesisEditor value={hypothesis} editMode={HypothesisEditMode.NoEdit} bordered className="k-mx-4" />
                <HypothesisDetailsPanel title={<HypothesisEvaluationStats verdicts={hypothesisVerdicts} interviewsCount={researchInterviews?.length} />}>
                    {!hypothesisQuotes || hypothesisQuotes.length > 0 ? (
                        <HypothesisQuotesByInterview
                            ideaId={ideaId}
                            quotes={hypothesisQuotes}
                            verdicts={hypothesisVerdicts}
                            onSelectedQuote={(interviewId, quoteId, entryId) => setSelection({ interviewId, quoteId, entryId })}
                            selectedQuoteId={selection?.quoteId}
                        />
                    ) : (
                        undefined
                    )}
                </HypothesisDetailsPanel>
            </StackLayout>
        );
    }

    if (!groups) return <LoadingIndicator size="big" className="k-display-block -block-center" />;
    if (!groups.length)
        return (
            <InterviewEmptyColumnView image={{ url: emptyHypothesesIllustrationUrl, width: 560, height: 109, description: 'No hypotheses in research' }}>
                No hypotheses associated with this research.
            </InterviewEmptyColumnView>
        );

    return (
        <ResearchInterviewLayout
            ideaId={ideaId}
            interviewId={selection?.interviewId}
            selectedEntryId={selection?.entryId}
            interviewee={selectedInterviewInterviewee}
        >
            <HypothesesByProblemAndTypeList
                groups={groups}
                renderPainLevelHypothesis={renderHypothesis}
                renderAlternativeSolutionHypothesis={renderHypothesis}
            />
        </ResearchInterviewLayout>
    );
}

function groupHypotheses(
    hypotheses?: Hypothesis[],
    customerProblems?: BoxItem[],
    alternativeSolutions?: BoxItem[]
): HypothesesByProblemAndTypeGroup[] | undefined {
    if (!hypotheses || !customerProblems) return undefined;

    const groups: HypothesesByProblemAndTypeGroup[] = [];
    const hypothesesByProblem = groupBy(hypotheses, h => h.customerProblemId);
    const alternativeSolutionsMap = alternativeSolutions && toRecord(alternativeSolutions, as => as.id);
    // Iterate the problems in order to preserve the problems order in the groups
    for (const customerProblem of customerProblems) {
        const hypothesesForCustomerProblem = hypothesesByProblem[customerProblem.id];
        if (!hypothesesForCustomerProblem || !hypothesesForCustomerProblem.length) continue;

        const group: HypothesesByProblemAndTypeGroup = {
            customerProblemId: customerProblem.id,
            customerProblem: customerProblem,
            painLevelHypotheses: [],
            alternativeSolutionHypothesesGroups: []
        };
        groups.push(group);

        const alternativeSolutionHypothesesMap = new Map<number, AlternativeSolutionHypothesesGroup>();
        for (const customerProblemHypothesis of hypothesesForCustomerProblem) {
            if (customerProblemHypothesis.type === HypothesisType.PainLevel) {
                group.painLevelHypotheses.push(customerProblemHypothesis);
                continue;
            }

            let alternativeSolutionHypothesesGroup = alternativeSolutionHypothesesMap.get(customerProblemHypothesis.alternativeSolutionId);
            if (!alternativeSolutionHypothesesGroup) {
                alternativeSolutionHypothesesGroup = {
                    alternativeSolutionId: customerProblemHypothesis.alternativeSolutionId,
                    alternativeSolution: alternativeSolutionsMap?.[customerProblemHypothesis.alternativeSolutionId],
                    alternativeSolutionHypotheses: [],
                    alternativeSolutionSatisfactionHypotheses: []
                };

                alternativeSolutionHypothesesMap.set(customerProblemHypothesis.alternativeSolutionId, alternativeSolutionHypothesesGroup);
            }

            if (customerProblemHypothesis.type === HypothesisType.AlternativeSolutionUsage)
                alternativeSolutionHypothesesGroup.alternativeSolutionHypotheses.push(customerProblemHypothesis);
            else if (customerProblemHypothesis.type === HypothesisType.AlternativeSolutionSatisfaction)
                alternativeSolutionHypothesesGroup.alternativeSolutionSatisfactionHypotheses.push(customerProblemHypothesis);
        }

        group.alternativeSolutionHypothesesGroups = Array.from(alternativeSolutionHypothesesMap.values());
    }

    return groups;
}

function HypothesisDetailsPanel({ title, children }: { title: ReactElement; children?: ReactNode }) {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-px-4 k-pb-2 k-gap-1">
                <div className="k-flex-1">{title}</div>
                {children && (
                    <Button type="button" size="small" fillMode="flat" className="k-icp-svg-icon-button" onClick={() => setIsExpanded(e => !e)}>
                        {isExpanded ? <CollapseIcon className="k-icp-icon" /> : <ExpandIcon className="k-icp-icon" />}
                    </Button>
                )}
            </StackLayout>
            <Reveal className="!k-d-block">
                {isExpanded && children ? (
                    <div className="k-icp-bordered-top k-icp-component-border k-icp-panel-base k-px-4 k-pt-4 k-pb-2">
                        {children}
                        <Button
                            type="button"
                            themeColor="secondary"
                            size="small"
                            fillMode="link"
                            className="k-mt-2 !k-d-flex k-mx-auto"
                            onClick={() => setIsExpanded(false)}
                        >
                            <SvgIconButtonContent icon={CollapseAltIcon} className="k-flex-row-reverse">
                                Collapse
                            </SvgIconButtonContent>
                        </Button>
                    </div>
                ) : null}
            </Reveal>
        </div>
    );
}

function HypothesisEvaluationStats({ verdicts, interviewsCount }: { verdicts?: InterviewHypothesisVerdict[]; interviewsCount?: number }) {
    const stats: Record<HypothesisVerdict, number> | undefined = verdicts
        ? { [HypothesisVerdict.True]: 0, [HypothesisVerdict.False]: 0, [HypothesisVerdict.Unknown]: 0 }
        : undefined;
    if (stats && verdicts) verdicts.forEach(v => stats[v.verdict]++);

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-10">
            <HypothesisEvaluationStat
                icon={TrueHypothesisIcon}
                iconBgClassName="k-icp-bg-success-8"
                metric="True"
                interviewsCount={interviewsCount}
                count={stats?.[HypothesisVerdict.True]}
            />
            <HypothesisEvaluationStat
                icon={FalseHypothesisIcon}
                iconBgClassName="k-icp-bg-error-8"
                metric="False"
                interviewsCount={interviewsCount}
                count={stats?.[HypothesisVerdict.False]}
            />
            <HypothesisEvaluationStat
                icon={UnknownHypothesisIcon}
                metric="Unknown"
                interviewsCount={interviewsCount}
                count={stats?.[HypothesisVerdict.Unknown]}
            />
        </StackLayout>
    );
}

function HypothesisEvaluationStat({
    icon: Icon,
    iconBgClassName,
    count,
    metric,
    interviewsCount
}: {
    icon: ComponentType<React.SVGProps<SVGSVGElement>>;
    iconBgClassName?: string;
    metric: string;
    count?: number;
    interviewsCount?: number;
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }}>
            <div className={combineClassNames('k-p-1 k-mr-1 k-rounded-full', iconBgClassName || 'k-icp-bg-dark-8')}>
                <Icon className="k-icp-icon k-icp-icon-size-6" />
            </div>
            <div className="k-mr-2 k-display-4" style={{ lineHeight: '42px' }}>
                {count === undefined ? <Skeleton shape="text" style={{ width: 24 }} /> : count}
            </div>
            <div className="k-fs-sm">
                <strong>{metric}</strong>
                <div>
                    in {interviewsCount === undefined ? <Skeleton shape="text" style={{ width: 12, display: 'inline-block' }} /> : interviewsCount} interviews
                </div>
            </div>
        </StackLayout>
    );
}

function HypothesisQuotesByInterview({
    ideaId,
    quotes,
    verdicts,
    onSelectedQuote,
    selectedQuoteId
}: {
    ideaId: string;
    quotes?: InterviewHypothesisQuote[];
    verdicts?: InterviewHypothesisVerdict[];
    onSelectedQuote?: (interviewId: number, quoteId: number, entryId: number) => void;
    selectedQuoteId?: number;
}) {
    return (
        <InterviewItemQuotesGroupedByInterview
            ideaId={ideaId}
            quotes={quotes}
            selectedQuoteId={selectedQuoteId}
            onSelectedQuote={onSelectedQuote}
            resolveHeader={interviewId => {
                const hypothesisVerdictInInterview = verdicts?.find(v => v.interviewId === interviewId);
                if (!hypothesisVerdictInInterview) return undefined;

                return <HypothesisVerdictChip verdict={hypothesisVerdictInInterview?.verdict} />;
            }}
        />
    );
}
