import { Fade } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AIAvatar } from '../../components/ai/aiAvatar';
import { BackLinkHeader } from '../../components/common/BackLinkHeader';
import { BoundDropDownButton, DropDownButtonItem } from '../../components/common/boundDropDownButton';
import { interviewStageToUrlPathMap, InterviewStatusIndicator } from '../../components/interview2/common';
import { researchV2StatusTextMap } from '../../components/research2/common';
import { IncludeExistingInterviewModal } from '../../components/research2/includeExistingInterviewModal';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { SvgIconButtonContent } from '../../components/ui/svgIconButtonContent';
import { TypeText } from '../../components/ui/typeText';
import { H1, H2 } from '../../components/ui/typography';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { useResearchParams } from '../../hooks/routerHooks';
import { ReactComponent as EditIcon } from '../../icons/edit-2.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import addInterviewIllustrationUrl from '../../images/add-interview-illustration.svg';
import aiCompletedItemIllustrationUrl from '../../images/ai-completed-item-illustration.svg';
import { appEventHub } from '../../services/appEvents';
import { debounce } from '../../services/common';
import { InterviewStageV2 } from '../../services/interviewsV2Service';
import {
    RealTimeUpdateResearch2AnalysisUpdateEventData,
    RealTimeUpdateResearch2EventData,
    realTimeUpdatesEventHub
} from '../../services/realTimeUpdatesService';
import {
    mapResearchTypeToInterviewType,
    ResearchAnalysisStatusV2,
    ResearchAnalysisV2,
    ResearchV2,
    researchV2Service,
    ResearchV2Status
} from '../../services/researchV2Service';
import { UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addNotification, removeNotification } from '../../state/notifications/platformNotificationsSlice';
import { CreateInterviewOptions } from '../interviews/createInterviewPage';
import { UploadInterviewOptions } from '../interviews/uploadInterviewModalPage';
import { UploadMultipleInterviewsOptions } from '../interviews/uploadMultipleInterviewsModalPage';
import { TabbedLayout, TabHeader } from '../layouts/tabbedLayout';

const tabs: TabHeader[] = [
    {
        title: 'Overview',
        to: 'overview'
    },
    {
        title: 'Interviews',
        to: 'interviews',
        end: false
    },
    {
        title: 'Insights',
        to: 'insights',
        end: false
    },
    {
        title: 'Quality',
        to: 'quality',
        end: false
    }
];

export function ResearchDetailsPage() {
    const { ideaId, researchId } = useResearchParams();
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canEdit = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;

    const [research, setResearch] = useState<ResearchV2>();
    const loading = !research;
    const navigate = useNavigate();
    const location = useLocation();
    const [showIncludeExistingInterviewModal, setShowIncludeExistingInterviewModal] = useState(false);
    const [analysis, setAnalysis] = useState<ResearchAnalysisV2 | null>();
    const { show: showConfirmDialog, element: confirmDialog } = useConfirmDialog();
    const dispatch = useAppDispatch();

    const onCreateNewInterview = () => {
        const createOptions: CreateInterviewOptions = {
            researchId: researchId,
            scriptType: interviewsType,
            scriptCustomerSegmentId: research?.customerSegmentId,
            defaultScriptId: research?.interviewScriptId,
            cancelReturnUrl: location.pathname,
            saveReturnUrl: `../../research-v2/${researchId}/interviews/${interviewStageToUrlPathMap[InterviewStageV2.NotStarted]}`
        };
        navigate('../../interviews-v2/create', { state: createOptions });
    };

    function onUploadInterview() {
        const uploadOptions: UploadInterviewOptions = {
            researchId: researchId,
            mandatoryType: interviewsType,
            cancelReturnUrl: location.pathname,
            saveReturnUrl: `../../research-v2/${researchId}/interviews/${interviewStageToUrlPathMap[InterviewStageV2.Completed]}`
        };
        navigate('../../interviews-v2/upload', { state: uploadOptions });
    }

    function onUploadInterviews() {
        const uploadOptions: UploadMultipleInterviewsOptions = {
            researchId: researchId,
            mandatoryType: interviewsType,
            cancelReturnUrl: location.pathname,
            saveReturnUrl: `../../research-v2/${researchId}/interviews/${interviewStageToUrlPathMap[InterviewStageV2.Completed]}`
        };
        navigate('../../interviews-v2/upload-multiple', { state: uploadOptions });
    }

    const noInterviewsPageDropdownItems: DropDownButtonItem[] = [
        {
            text: 'Include existing interview',
            action: () => setShowIncludeExistingInterviewModal(true)
        },
        {
            text: 'Upload an Interview',
            action: onUploadInterview
        },
        {
            text: 'Upload multiple interviews',
            action: onUploadInterviews
        }
    ];

    const addInterviewsButtonOptions: DropDownButtonItem[] = [
        {
            text: 'Create new interview',
            action: onCreateNewInterview
        },
        {
            text: 'Include existing interview',
            action: () => setShowIncludeExistingInterviewModal(true)
        },
        {
            text: 'Upload an Interview',
            action: onUploadInterview
        },
        {
            text: 'Upload multiple interviews',
            action: onUploadInterviews
        }
    ];

    const onEditResearch = () => {
        navigate('edit');
    };

    const loadResearch = useCallback(
        () =>
            researchV2Service.getResearch(ideaId, researchId).then(r => {
                setResearch(r);
                return r;
            }),
        [ideaId, researchId]
    );
    useEffect(() => {
        loadResearch();
    }, [loadResearch]);

    const loadAnalysis = useCallback(() => researchV2Service.getResearchOverviewAnalysis(ideaId, researchId).then(setAnalysis), [ideaId, researchId]);
    useEffect(() => {
        loadAnalysis();
    }, [loadAnalysis]);

    const analyzeResearch = useCallback(
        async function analyzeResearch() {
            const analysis = await researchV2Service.runAnalysis(ideaId, researchId);
            setAnalysis(analysis);
        },
        [ideaId, researchId]
    );

    const analysisFailed = analysis?.status === ResearchAnalysisStatusV2.Error;
    useEffect(() => {
        if (!analysisFailed) return;

        const notificationId = dispatch(
            addNotification(
                { content: 'Failed to analyze research.', timeout: 0, type: 'error', actionText: canEdit ? 'Run again' : undefined },
                analyzeResearch
            )
        );

        return () => dispatch(removeNotification(notificationId, false));
    }, [analysisFailed, analyzeResearch, canEdit, dispatch]);

    useEffect(() => {
        const refreshResearchDebounced = debounce(function(e: RealTimeUpdateResearch2EventData) {
            if (e.ideaId !== ideaId || e.researchId !== researchId) return;
            loadResearch();
        }, 300);

        realTimeUpdatesEventHub.addEventListener('research2', 'update', refreshResearchDebounced);
        realTimeUpdatesEventHub.addEventListener('research2', 'interviewsUpdate', refreshResearchDebounced);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('research2', 'update', refreshResearchDebounced);
            realTimeUpdatesEventHub.removeEventListener('research2', 'interviewsUpdate', refreshResearchDebounced);
        };
    }, [ideaId, loadResearch, researchId]);

    useEffect(() => {
        function onAnalysisCreatedOrUpdated(e: RealTimeUpdateResearch2EventData) {
            if (e.ideaId !== ideaId || e.researchId !== researchId) return;
            loadAnalysis();
        }

        realTimeUpdatesEventHub.addEventListener('research2', 'analysisCreate', onAnalysisCreatedOrUpdated);
        realTimeUpdatesEventHub.addEventListener('research2', 'analysisUpdate', onAnalysisCreatedOrUpdated);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('research2', 'analysisCreate', onAnalysisCreatedOrUpdated);
            realTimeUpdatesEventHub.removeEventListener('research2', 'analysisUpdate', onAnalysisCreatedOrUpdated);
        };
    }, [ideaId, loadAnalysis, researchId]);

    function excludeInterview(interviewId: number, interviewStage: InterviewStageV2, callback?: () => Promise<void>) {
        showConfirmDialog({
            title: 'Exclude interview from Research',
            content: 'Are you sure you want to exclude the interview from this research?',
            confirmButtonText: 'Exclude interview',
            callback: async () => {
                await researchV2Service.updateIncludedInterviewsInResearch(ideaId, researchId, { interviewsToRemove: [interviewId] });
                const research = await loadResearch();
                if (interviewStage === InterviewStageV2.Completed) await loadAnalysis();
                await callback?.();
                if (interviewStage === InterviewStageV2.Completed && research.completeCount)
                    dispatch(addNotification({ content: 'Research update in progress' }));
            }
        });
    }

    async function pinInsightsCategory(categoryName: string) {
        const pinnedInsightCategories = await researchV2Service.pinResearchInsightCategory(ideaId, researchId, categoryName);
        setAnalysis(a => a && { ...a, pinnedInsightCategories });
    }

    if (loading) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-h-full">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    const interviewsType = research && mapResearchTypeToInterviewType(research.type);

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-min-h-full">
            <BackLinkHeader to=".." text="Back to Research" />
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-flex-1">
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-justify-content-between">
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-min-w-0">
                        <H2 className="k-text-ellipsis" title={research.name}>
                            {research.name}
                        </H2>
                        <InterviewStatusIndicator
                            className="k-shrink-0"
                            text={researchV2StatusTextMap[research.status]}
                            type={research.status === ResearchV2Status.Complete ? 'success' : 'warning'}
                        />
                    </StackLayout>
                    {canEdit && (
                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
                            <BoundDropDownButton
                                text={<SvgIconButtonContent icon={PlusIcon}>Add interviews</SvgIconButtonContent>}
                                icon="arrow-60-down"
                                themeColor="primary"
                                items={addInterviewsButtonOptions}
                                buttonClass="k-flex-row-reverse"
                                popupSettings={{
                                    anchorAlign: { horizontal: 'left', vertical: 'bottom' },
                                    popupAlign: { horizontal: 'left', vertical: 'top' }
                                }}
                            />
                            <Button onClick={onEditResearch}>
                                <SvgIconButtonContent icon={EditIcon}>Edit research</SvgIconButtonContent>
                            </Button>
                        </StackLayout>
                    )}
                </StackLayout>

                {research.interviewCount > 0 ? (
                    <TabbedLayout
                        size="small"
                        headers={tabs}
                        data={{
                            research,
                            analysis,
                            pinInsightsCategory: canEdit ? pinInsightsCategory : undefined,
                            excludeInterview: canEdit ? excludeInterview : undefined
                        }}
                        theme="secondary"
                        separated
                    />
                ) : (
                    <NoInterviewsInResearchView
                        onCreateNewInterview={canEdit ? onCreateNewInterview : undefined}
                        dropdownItems={canEdit ? noInterviewsPageDropdownItems : undefined}
                    />
                )}
            </StackLayout>
            {showIncludeExistingInterviewModal && (
                <IncludeExistingInterviewModal
                    ideaId={ideaId}
                    researchId={researchId}
                    onCancel={() => setShowIncludeExistingInterviewModal(false)}
                    onIncludedInterviews={async stats => {
                        appEventHub.trigger('research2', 'existingInterviewsAdded', { researchId });
                        await loadResearch();
                        setShowIncludeExistingInterviewModal(false);
                        if (stats.Completed) {
                            dispatch(addNotification({ content: 'Research update in progress' }));
                            navigate(`interviews/${interviewStageToUrlPathMap[InterviewStageV2.Completed]}`);
                        } else if (stats.NotStarted) navigate(`interviews/${interviewStageToUrlPathMap[InterviewStageV2.NotStarted]}`);
                        else if (stats.InProgress) navigate(`interviews/${interviewStageToUrlPathMap[InterviewStageV2.InProgress]}`);
                    }}
                    customerSegmentId={research.customerSegmentId}
                    interviewsType={interviewsType}
                />
            )}
            {confirmDialog}
            <ResearchReAnalyzedDialog ideaId={ideaId} researchId={researchId} currentAnalysis={analysis} />
        </StackLayout>
    );
}

function NoInterviewsInResearchView({ onCreateNewInterview, dropdownItems }: { onCreateNewInterview?: () => void; dropdownItems?: DropDownButtonItem[] }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1 k-gap-8 page-content-middle -w2">
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6">
                <img src={addInterviewIllustrationUrl} width="240" height="121" alt="Add Research" />
                <div className="k-text-center">
                    It looks like you haven’t added any interviews to your research yet. Once you do, this page will display an overview of your interviews and
                    insights.
                </div>
            </StackLayout>
            <StackLayout className="k-gap-2.5">
                <Button themeColor="secondary" fillMode="outline" onClick={onCreateNewInterview} disabled={!onCreateNewInterview}>
                    Create new interview
                </Button>
                <BoundDropDownButton
                    themeColor="secondary"
                    fillMode="outline"
                    icon="arrow-60-down"
                    items={dropdownItems}
                    text="Add completed interview"
                    buttonClass="k-flex-row-reverse"
                    disabled={!dropdownItems}
                />
            </StackLayout>
        </StackLayout>
    );
}

const researchAnimationStages = [
    'Scanning latest interviews...',
    'Detecting emerging patterns...',
    'Refining insights for accuracy...',
    'Generating a smarter analysis...'
];
function ResearchReAnalyzedDialog({
    ideaId,
    researchId,
    currentAnalysis
}: {
    ideaId: string;
    researchId: number;
    currentAnalysis?: ResearchAnalysisV2 | null;
}) {
    const [showDialog, setShowDialog] = useState(false);
    const [waitForCompletedAnalysis, setWaitForCompletedAnalysis] = useState(false);
    const [currentAnimationStage, setCurrentAnimationStage] = useState(0);
    const [animationIsRunning, setAnimationIsRunning] = useState(false);

    const analysisIsComplete = currentAnalysis?.status === ResearchAnalysisStatusV2.Complete;
    useEffect(() => {
        if (!analysisIsComplete || showDialog) return;

        function onCompletedAnalysisUpdated(e: RealTimeUpdateResearch2AnalysisUpdateEventData) {
            if (e.ideaId !== ideaId || e.researchId !== researchId || !e.replaced) return;

            setShowDialog(true);
            setWaitForCompletedAnalysis(true);
            setCurrentAnimationStage(0);
            setAnimationIsRunning(true);
        }

        realTimeUpdatesEventHub.addEventListener('research2', 'analysisUpdate', onCompletedAnalysisUpdated);

        return () => realTimeUpdatesEventHub.removeEventListener('research2', 'analysisUpdate', onCompletedAnalysisUpdated);
    }, [analysisIsComplete, ideaId, researchId, showDialog]);

    useEffect(() => {
        if (currentAnalysis?.status === ResearchAnalysisStatusV2.Complete) setWaitForCompletedAnalysis(false);
    }, [currentAnalysis]);

    if (!showDialog) return null;

    const showFinalMessage = currentAnimationStage >= researchAnimationStages.length && !waitForCompletedAnalysis;

    return (
        <Dialog width={480} closeIcon={false}>
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-4 k-text-center k-my-4">
                <AIAvatar
                    borderSize="k-border"
                    iconSize={48}
                    size={80}
                    animate={animationIsRunning}
                    onAnimationIteration={() => currentAnimationStage >= researchAnimationStages.length + 1 && setAnimationIsRunning(false)}
                />
                <H1>IVA is uncovering new patterns</H1>
                <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-1">
                    {researchAnimationStages.map((animationStage, animationStageIndex) => (
                        <ResearchAnalysisStageAnimation
                            key={animationStageIndex}
                            paused={currentAnimationStage < animationStageIndex}
                            onEnd={() => setCurrentAnimationStage(s => ++s)}
                        >
                            {animationStage}
                        </ResearchAnalysisStageAnimation>
                    ))}
                </StackLayout>
                <Fade onEntered={() => setCurrentAnimationStage(s => ++s)}>
                    <strong key={showFinalMessage ? 'text' : 'placeholder'} className={showFinalMessage ? undefined : 'k-visibility-invisible'}>
                        Your Research is enriched with fresh insights!
                    </strong>
                </Fade>
            </StackLayout>

            <DialogActionsBar layout="center">
                <Button type="button" themeColor="primary" disabled={waitForCompletedAnalysis || animationIsRunning} onClick={() => setShowDialog(false)}>
                    View updated analysis
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}

function ResearchAnalysisStageAnimation({ children, paused, onEnd }: { children: string; paused?: boolean; onEnd?: () => void }) {
    const [showCompleteIcon, setShowCompleteIcon] = useState(false);

    return (
        <div>
            <span className="k-mr-2 k-align-middle">
                <TypeText reserveSpace mode="letter" paused={paused} interval={30} onTypeEnd={() => setShowCompleteIcon(true)}>
                    {children}
                </TypeText>
            </span>
            <Fade className="k-align-middle !k-display-inline" componentChildClassName="!k-display-inline" onEntered={onEnd}>
                {showCompleteIcon ? (
                    <img key="icon" width="16" height="17" src={aiCompletedItemIllustrationUrl} alt="" className="k-align-middle" />
                ) : (
                    <span key="placeholder" className="k-display-inline-block k-align-middle" style={{ width: 16, height: 17 }}></span>
                )}
            </Fade>
        </div>
    );
}
