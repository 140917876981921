import { Dialog } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { ReactComponent as FileUploadedIcon } from '../../icons/file-uploaded.svg';
import { interviewsV2Service } from '../../services/interviewsV2Service';
import LoadingIndicator from '../ui/loadingIndicator';
import { H2 } from '../ui/typography';
import { InterviewStatusIndicator } from './common';

export default function FileViewModal({
    ideaId,
    interviewId,
    name,
    type,
    onClose
}: {
    ideaId: string;
    interviewId: number;
    name: string;
    type: 'interview' | 'script';
    onClose: () => void;
}) {
    const [fileText, setFileText] = useState<string | undefined>();

    useEffect(() => {
        const fetchFileText = async () => {
            const fileText =
                type === 'interview'
                    ? await interviewsV2Service.getInterviewTranscriptText(ideaId, interviewId)
                    : await interviewsV2Service.getInterviewScriptText(ideaId, interviewId);
            setFileText(fileText);
        };
        fetchFileText();
    }, [ideaId, interviewId, type]);

    return (
        <Dialog
            className="k-icp-dialog-maximized k-icp-dialog-no-padding k-icp-dialog-with-title-shadow"
            title={type === 'interview' ? <CompletedInterviewHeading fileName={name} /> : <ScriptHeading fileName={name} />}
            onClose={onClose}
        >
            {fileText !== undefined ? (
                <div style={{ width: '720px' }} className="page-content-middle k-white-space-pre-wrap k-p-4">
                    {fileText}
                </div>
            ) : (
                <div className="k-d-flex k-align-items-center k-justify-content-center k-w-full k-h-full">
                    <LoadingIndicator size={'big'} />
                </div>
            )}
        </Dialog>
    );
}

function CompletedInterviewHeading({ fileName }: { fileName: string }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-overflow-hidden">
            <H2 className="k-text-ellipsis">{fileName}</H2>
            <InterviewStatusIndicator type="base" text="Uploaded transcript" />
        </StackLayout>
    );
}

function ScriptHeading({ fileName }: { fileName: string }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3 k-overflow-hidden">
            <FileUploadedIcon className="k-icp-icon k-icp-icon-size-6" />
            <span className="k-fs-lg k-font-medium k-text-ellipsis">{fileName}</span>
            <InterviewStatusIndicator type="base" text="Uploaded script" className="k-ml-1" />
        </StackLayout>
    );
}
