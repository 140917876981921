import { Button } from '@progress/kendo-react-buttons';
import { Skeleton } from '@progress/kendo-react-indicators';
import { Input } from '@progress/kendo-react-inputs';
import { GridLayout, GridLayoutItem, StackLayout } from '@progress/kendo-react-layout';
import React, { ComponentType, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AIAvatar } from '../../components/ai/aiAvatar';
import {
    AnalysisPanelSectionWithScore,
    AreasAndStrengthsSection,
    InsightView,
    InterviewSummaryPanel,
    MeetingInstructionsView,
    QualityNote,
    RecommendationView,
    Section,
    SectionsView,
    SkeletonSection,
    SummaryHeader
} from '../../components/common/analysisComponents';
import { BackLinkHeader } from '../../components/common/BackLinkHeader';
import { BoundDropDownButton, DropDownButtonItem } from '../../components/common/boundDropDownButton';
import { AILedInterviewViewModal } from '../../components/interview2/aiLedInterviewViewModal';
import {
    interviewStageLabelMap,
    interviewStageToUrlPathMap,
    InterviewStatusIndicator,
    IVA_USER,
    IVAUser,
    TextFileButton,
    UserDetailsText
} from '../../components/interview2/common';
import FileViewModal from '../../components/interview2/fileViewModal';
import { HostPickerDialog } from '../../components/interview2/hostPicker';
import { InterviewAnalysisContext, useInterviewAnalysis, useInterviewAnalysisContext } from '../../components/interview2/interviewAnalysisContext';
import { ManageInterviewResearchModal } from '../../components/interview2/manageInterviewResearchModal';
import { ReferralCard } from '../../components/interview2/referralsPanel';
import { ScriptPickerDialog } from '../../components/interviewScript/changeScriptModal';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { PresentAndCopyLink } from '../../components/ui/presentAndCopyLink';
import { SvgIconButtonContent } from '../../components/ui/svgIconButtonContent';
import { H2, H3 } from '../../components/ui/typography';
import UserAvatar from '../../components/user/userAvatar';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { useIdeaParams, useIntParam } from '../../hooks/routerHooks';
import { ReactComponent as ArrowRightCircleIcon } from '../../icons/arrow-right-circle.svg';
import { ReactComponent as AwardIcon } from '../../icons/award.svg';
import { ReactComponent as FileTranscriptIcon } from '../../icons/file-transcript.svg';
import { ReactComponent as FileUploadedIcon } from '../../icons/file-uploaded.svg';
import { ReactComponent as InsightIcon } from '../../icons/insight.svg';
import { ReactComponent as ListCheckIcon } from '../../icons/list-check.svg';
import { ReactComponent as PlayCircleIcon } from '../../icons/play-circle.svg';
import { ReactComponent as ScriptIcon } from '../../icons/script.svg';
import { ReactComponent as ReferralIcon } from '../../icons/user-referred.svg';
import { ReactComponent as EmptyInsightsIllustration } from '../../images/insight-in-circle-illustration.svg';
import { ReactComponent as EmptyQualityIllustration } from '../../images/quality-in-circle-illustration.svg';
import { chatsService } from '../../services/chatsService';
import { combineClassNames, debounce, generateInitials, getPersonFullName, getPreferredColorIndex, resolveAbsoluteUrl } from '../../services/common';
import { ReducedPerson } from '../../services/contactsService';
import { dateTimeService } from '../../services/dateTimeService';
import {
    ImportedInterviewDetailV2,
    InterviewAnalysisStatus,
    InterviewExecutionTypeV2,
    InterviewInsight,
    InterviewInsightCategory,
    InterviewNextStep,
    InterviewQualityAssessmentArea,
    InterviewStageV2,
    interviewsV2Service,
    InterviewV2,
    IvaChatInterviewDetailV2
} from '../../services/interviewsV2Service';
import {
    RealTimeChatEventData,
    RealTimeUpdateInterviewEventData,
    RealTimeUpdateResearch2InterviewsUpdateEventData,
    realTimeUpdatesEventHub
} from '../../services/realTimeUpdatesService';
import { ReducedResearchV2 } from '../../services/researchV2Service';
import { ReducedUser, UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addNotification, removeNotification } from '../../state/notifications/platformNotificationsSlice';
import { TabbedLayout, TabHeader, useTabbedLayoutData } from '../layouts/tabbedLayout';
import { InterviewScriptViewModal } from '../scripts/interviewScriptViewDialog';

type MeetingInstructions = {
    title: string;
    instructions: string[];
};

const MEETING_INSTRUCTIONS: Record<InterviewExecutionTypeV2, MeetingInstructions> = {
    [InterviewExecutionTypeV2.IvaChat]: {
        title: 'AI-led interview',
        instructions: [
            'The interviewee will engage in an online chat with IVA.',
            'IVA will gather the required information by following your prepared interview script and organize all findings for your review.',
            'Start your interview by sharing the following link:'
        ]
    },
    [InterviewExecutionTypeV2.OnlineMeeting]: {
        title: 'Online meeting',
        instructions: [
            'Online interviews can be conducted with Google Meet.',

            'IVA will join your meeting as a silent attendant, taking notes and giving you hints what to ask next, so you never miss crucial information around your most important questions.',
            'Input your meeting information, so IVA is ready to join as soon as you start the interview:'
        ]
    },
    [InterviewExecutionTypeV2.InPersonMeeting]: {
        title: 'In-person meeting',
        instructions: [
            'In-person interviews require both you and your interviewee to be in the same location, with your laptop or phone readily accessible.',

            'IVA will actively listen through your device, take detailed notes, and monitor the points outlined in your interview script. It will also provide helpful hints to guide your conversation seamlessly.',
            'Ensure your device is positioned to capture both you and your interviewee clearly, with the microphone enabled for optimal audio quality.'
        ]
    },
    [InterviewExecutionTypeV2.Import]: {
        title: '',
        instructions: []
    }
};

export type InterviewDetailsPageOptions = {
    backTo?: {
        item: ReducedResearchV2;
        params?: string;
    };
};
export default function InterviewDetailsPage() {
    const { ideaId } = useIdeaParams();
    const interviewId = useIntParam('interviewId');
    const { state: options } = useLocation() as { state?: InterviewDetailsPageOptions };

    const [details, setDetails] = useState<InterviewV2 | null>(null);
    const [showChangeHostDialog, setShowChangeHostDialog] = useState(false);
    const [showChangeScriptDialog, setShowChangeScriptDialog] = useState(false);
    const [showViewInterviewModal, setShowViewInterviewModal] = useState(false);
    const [showManageRelatedResearch, setShowManageRelatedResearch] = useState(false);
    const currentUserRole = useAppSelector(s => s.idea.role);
    const currentUserId = useAppSelector(s => s.user?.userId);
    const canExecuteActions = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const navigate = useNavigate();
    const { show: showConfirmDialog, element: confirmDialog } = useConfirmDialog();

    const fetchInterview = useCallback(() => interviewsV2Service.getInterview(ideaId, interviewId).then(setDetails), [ideaId, interviewId]);

    useEffect(() => {
        const handleInterviewUpdate = async (event: RealTimeUpdateInterviewEventData) => {
            if (event.interviewId !== interviewId || event.ideaId !== ideaId) return;

            await fetchInterview();
        };

        const fetchInterviewsDebounced = debounce(fetchInterview, 300);
        async function handleResearchInterviewsUpdated(e: RealTimeUpdateResearch2InterviewsUpdateEventData) {
            if (e.ideaId !== ideaId || (!e.eventData.interviewsAdded.includes(interviewId) && !e.eventData.interviewsRemoved.includes(interviewId))) return;

            await fetchInterviewsDebounced();
        }

        realTimeUpdatesEventHub.addEventListener('interview3', 'update', handleInterviewUpdate);
        realTimeUpdatesEventHub.addEventListener('research2', 'interviewsUpdate', handleResearchInterviewsUpdated);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview3', 'update', handleInterviewUpdate);
            realTimeUpdatesEventHub.removeEventListener('research2', 'interviewsUpdate', handleResearchInterviewsUpdated);
        };
    }, [fetchInterview, ideaId, interviewId]);

    const subscribeForChatInitializeWithId =
        details &&
        details.aiLed &&
        details.detail &&
        (details.detail as IvaChatInterviewDetailV2).chat &&
        !(details.detail as IvaChatInterviewDetailV2).chat!.initialized
            ? (details.detail as IvaChatInterviewDetailV2).chat!.id
            : undefined;
    useEffect(() => {
        if (subscribeForChatInitializeWithId === undefined) return;

        async function onChatInitialized(e: RealTimeChatEventData) {
            if (e.ideaId !== ideaId || e.chatId !== subscribeForChatInitializeWithId) return;

            await fetchInterview();
        }

        realTimeUpdatesEventHub.addEventListener('chat', 'initialize', onChatInitialized);

        return () => realTimeUpdatesEventHub.removeEventListener('chat', 'initialize', onChatInitialized);
    }, [fetchInterview, ideaId, interviewId, subscribeForChatInitializeWithId]);

    useEffect(() => {
        fetchInterview();
    }, [fetchInterview]);

    const handleChangeHost = async (host: ReducedUser | IVAUser) => {
        if (!interviewId) return;

        const isAiLed = host.userId === IVA_USER.userId;
        const updatedHost = isAiLed ? undefined : host;

        const updatedInterview = await interviewsV2Service.updateInterview(ideaId, interviewId, isAiLed, updatedHost?.userId ?? null);
        setDetails(updatedInterview);
        setShowChangeHostDialog(false);
    };

    const handleChangeScript = async (scriptId: number) => {
        if (!interviewId) return;

        const updatedInterview = await interviewsV2Service.updateInterview(ideaId, interviewId, undefined, undefined, scriptId);
        setDetails(updatedInterview);
        setShowChangeScriptDialog(false);
    };

    const handleDeleteInterview = async () => {
        if (!interviewId) return;

        showConfirmDialog({
            title: 'Delete interview',
            content: (
                <div>Are you sure you want to delete this interview? Deleting a complete interview makes all derivative insights unavailable for use.</div>
            ),
            confirmButtonText: 'Delete interview',
            callback: async () => {
                await interviewsV2Service.deleteInterview(ideaId, interviewId);
                navigate('..');
            }
        });
    };

    const viewInterview = useCallback(() => setShowViewInterviewModal(true), []);

    if (!details) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    const backToOption = options?.backTo;
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-min-h-full">
            {backToOption ? (
                <BackLinkHeader
                    to={`../../research-v2/${backToOption.item.id}${backToOption.params ?? ''}`}
                    text={`Back to Research: ${backToOption.item.name}`}
                />
            ) : (
                <BackLinkHeader to={`./../${interviewStageToUrlPathMap[details.stage]}`} text={`Back to ${interviewStageLabelMap[details.stage]} interviews`} />
            )}
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-flex-1">
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-justify-content-between">
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-min-w-0">
                        <H2 className="k-text-ellipsis" title={details.title}>
                            {details.title}
                        </H2>
                        <InterviewStatusIndicator
                            className="k-shrink-0"
                            text={details.imported ? 'Uploaded' : interviewStageLabelMap[details.stage]}
                            type={
                                details.stage === InterviewStageV2.NotStarted
                                    ? 'warning'
                                    : details.stage === InterviewStageV2.InProgress
                                    ? 'warning'
                                    : details.imported
                                    ? 'base'
                                    : 'success'
                            }
                        />
                    </StackLayout>
                    <InterviewDetailsActions
                        isViewer={!canExecuteActions}
                        actions={[
                            // details.stage === InterviewStageV2.NotStarted
                            //     ? {
                            //           text: details.aiLed ? 'Take over & Start interview' : 'Start Interview',
                            //           primary: !details.aiLed,
                            //           // TODO R4: Remove when online & in-person interviews are implemented
                            //           disabled: true,
                            //           icon: PlayIcon,
                            //           actions: [
                            //               {
                            //                   text: 'Start online interview',
                            //                   action: function() {}
                            //               },
                            //               {
                            //                   text: 'Start in-person interview',
                            //                   action: function() {}
                            //               }
                            //           ]
                            //       }
                            //     : undefined,
                            {
                                text: details.host?.userId === currentUserId ? 'Continue interview' : 'View interview in progress',
                                availableForViewer: details.host?.userId !== currentUserId,
                                primary: details.host?.userId === currentUserId,
                                icon: PlayCircleIcon,
                                // TODO R4: remove this
                                disabled: true,
                                action:
                                    details.stage === InterviewStageV2.InProgress && !details.aiLed && currentUserId !== undefined
                                        ? function() {
                                              // TODO: open person-led interview
                                          }
                                        : undefined
                            },
                            {
                                text: 'View interview in progress',
                                icon: ArrowRightCircleIcon,
                                availableForViewer: true,
                                action: details.stage === InterviewStageV2.InProgress && details.aiLed ? viewInterview : undefined
                            }
                        ]}
                        moreActions={[
                            // {
                            //     text: 'Change host',
                            //     action: details.stage === InterviewStageV2.NotStarted ? () => setShowChangeHostDialog(true) : undefined,
                            //     //TODO R4: Remove when real-time interview execution is implemented
                            //     disabled: true
                            // },
                            {
                                text: 'Change script',
                                action: details.stage === InterviewStageV2.NotStarted ? () => setShowChangeScriptDialog(true) : undefined
                            },
                            {
                                children: (
                                    <>
                                        Add/Remove from
                                        <br />
                                        Research
                                    </>
                                ),
                                separated: true,
                                action: () => setShowManageRelatedResearch(true)
                            },
                            {
                                text: 'Finish interview',
                                action:
                                    details.stage === InterviewStageV2.InProgress
                                        ? function() {
                                              showConfirmDialog({
                                                  title: 'Finish interview',
                                                  content: (
                                                      <div>
                                                          Are you sure you want to finish this interview?{' '}
                                                          {details.aiLed
                                                              ? 'IVA is still conducting the interview.'
                                                              : details.host?.userId === currentUserId
                                                              ? 'TODO R4: Finish interview current user is host'
                                                              : 'TODO R4: Finish interview current user is not host'}
                                                      </div>
                                                  ),
                                                  confirmButtonText: 'Finish interview',
                                                  callback: async () => {
                                                      if (details.aiLed) {
                                                          if (!details.detail) return;
                                                          const aiLedDetails = details.detail as IvaChatInterviewDetailV2;
                                                          if (!aiLedDetails.chat) return;
                                                          await chatsService.finishChat(ideaId, aiLedDetails.chat.tag);
                                                          await fetchInterview();
                                                      }
                                                  }
                                              });
                                          }
                                        : undefined
                            },
                            {
                                text: 'Delete interview',
                                danger: true,
                                separated: true,
                                action: handleDeleteInterview
                            }
                        ]}
                    />
                </StackLayout>

                {details.stage === InterviewStageV2.NotStarted || details.stage === InterviewStageV2.InProgress ? (
                    <NotCompletedInterviewPage ideaId={ideaId} details={details} />
                ) : (
                    <CompletedInterviewPage
                        ideaId={ideaId}
                        interviewId={details.id}
                        onViewInterview={viewInterview}
                        interviewDetailsPageOptions={options}
                        canExecuteActions={canExecuteActions}
                        interview={details}
                    />
                )}
            </StackLayout>
            {showChangeHostDialog && (
                <HostPickerDialog
                    ideaId={ideaId}
                    title="Change host"
                    currentHost={!details.aiLed ? details.host! : IVA_USER}
                    onSave={host => handleChangeHost(host)}
                    onCancel={() => setShowChangeHostDialog(false)}
                />
            )}

            {showChangeScriptDialog && details.scriptId && (
                <ScriptPickerDialog
                    ideaId={ideaId}
                    interviewId={interviewId}
                    title="Change interview script"
                    currentScriptId={details.scriptId}
                    onSave={scriptId => handleChangeScript(scriptId)}
                    onCancel={() => setShowChangeScriptDialog(false)}
                />
            )}

            {showViewInterviewModal &&
                (details.aiLed ? (
                    <AILedInterviewViewModal ideaId={ideaId} interview={details} onClose={() => setShowViewInterviewModal(false)} />
                ) : (
                    <>{/* TODO R4: open human-led interview modal */}</>
                ))}
            {showManageRelatedResearch && (
                <ManageInterviewResearchModal
                    ideaId={ideaId}
                    interviewId={details.id}
                    relatedResearchIds={details.relatedResearch.map(r => r.id)}
                    onSave={async () => {
                        await fetchInterview();
                        setShowManageRelatedResearch(false);
                    }}
                    onClose={() => setShowManageRelatedResearch(false)}
                />
            )}
            {confirmDialog}
        </StackLayout>
    );
}

type DetailsAction = {
    icon?: ComponentType<React.SVGProps<SVGSVGElement>>;
    text: string;
    action?: () => void;
    availableForViewer?: boolean;
    primary?: boolean;
    disabled?: boolean;
};

type DetailsActionGroup = {
    icon?: ComponentType<React.SVGProps<SVGSVGElement>>;
    text: string;
    actions: (DropDownButtonItem & { availableForViewer?: boolean })[];
    primary?: boolean;
    disabled?: boolean;
};

function InterviewDetailsActions({
    isViewer,
    actions,
    moreActions
}: {
    isViewer?: boolean;
    actions?: (DetailsAction | DetailsActionGroup | undefined)[];
    moreActions?: DropDownButtonItem[];
}) {
    if (isViewer === undefined) return null;

    const filteredActions = filterDetailsActions(isViewer, actions);
    const filteredMoreActions = filterDetailsActions(isViewer, moreActions);

    const hasActions = filteredActions !== undefined && filteredActions.length > 0;
    const hasMoreActions = filteredMoreActions !== undefined && filteredMoreActions.length > 0;
    if (!hasActions && !hasMoreActions) return null;

    return (
        <StackLayout orientation="horizontal" align={{ vertical: 'top', horizontal: 'start' }} className="k-gap-3">
            {hasActions &&
                filteredActions.map((actionOrGroup, index) =>
                    'actions' in actionOrGroup ? (
                        <BoundDropDownButton
                            key={index}
                            items={actionOrGroup.actions}
                            themeColor={actionOrGroup.primary ? 'primary' : undefined}
                            icon="arrow-60-down"
                            buttonClass="k-flex-row-reverse"
                            popupSettings={{
                                anchorAlign: { horizontal: 'left', vertical: 'bottom' },
                                popupAlign: { horizontal: 'left', vertical: 'top' }
                            }}
                            text={
                                actionOrGroup.icon ? (
                                    <SvgIconButtonContent icon={actionOrGroup.icon}>{actionOrGroup.text}</SvgIconButtonContent>
                                ) : (
                                    actionOrGroup.text
                                )
                            }
                            disabled={actionOrGroup.disabled}
                        />
                    ) : (
                        <Button
                            key={index}
                            themeColor={actionOrGroup.primary ? 'primary' : undefined}
                            onClick={actionOrGroup.action}
                            disabled={actionOrGroup.disabled}
                        >
                            {actionOrGroup.icon ? (
                                <SvgIconButtonContent icon={actionOrGroup.icon}>{actionOrGroup.text}</SvgIconButtonContent>
                            ) : (
                                actionOrGroup.text
                            )}
                        </Button>
                    )
                )}
            {hasMoreActions && (
                <BoundDropDownButton
                    popupSettings={{
                        anchorAlign: { horizontal: 'right', vertical: 'bottom' },
                        popupAlign: { horizontal: 'right', vertical: 'top' }
                    }}
                    items={filteredMoreActions}
                    text={hasActions ? 'More actions' : 'Actions'}
                    icon="arrow-60-down"
                    buttonClass="k-flex-row-reverse"
                />
            )}
        </StackLayout>
    );
}

function filterDetailsActions<TActions extends DetailsAction | DetailsActionGroup | DropDownButtonItem>(
    isViewer: boolean,
    actions: (TActions | undefined)[] | undefined
): TActions[] | undefined {
    if (!actions) return undefined;
    const filteredActions: TActions[] = [];
    for (const actionOrGroup of actions) {
        if (!actionOrGroup) continue;
        if ('actions' in actionOrGroup) {
            const childActions = actionOrGroup.actions.filter(a => a.action && (!isViewer || a.availableForViewer));
            childActions.length && filteredActions.push({ ...actionOrGroup, actions: childActions });
            continue;
        }

        if (!actionOrGroup.action || (isViewer && (!('availableForViewer' in actionOrGroup) || !actionOrGroup.availableForViewer))) continue;
        filteredActions.push(actionOrGroup);
    }

    return filteredActions;
}

export function NotCompletedInterviewPage({ ideaId, details }: { ideaId: string; details: InterviewV2 }) {
    const currentUserId = useAppSelector(state => state.user?.userId);
    const [showPreviewScriptDialog, setShowPreviewScriptDialog] = useState(false);

    if (!details || !currentUserId) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    return (
        <div className="page-content-middle page-content--xxl k-mt-6">
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-8">
                <IntervieweeDetailsSection interviewee={details.contact} intervieweeName={details.intervieweeName} />
                <HostAndDetailsSection
                    interviewDetails={details}
                    currentUserId={currentUserId}
                    fileButtons={
                        details.scriptId && details.scriptName ? (
                            <TextFileButton
                                title={details.scriptName}
                                icon={ScriptIcon}
                                onClick={() => {
                                    setShowPreviewScriptDialog(true);
                                }}
                            />
                        ) : null
                    }
                />

                <MeetingInstructionsPanel
                    meetingId={details.meetingCode}
                    fixedType={details.stage === InterviewStageV2.InProgress || details.stage === InterviewStageV2.Completed ? details.detail?.type : undefined}
                    publicCode={details.publicCode}
                    isAiLed={details.aiLed}
                />
            </StackLayout>

            {showPreviewScriptDialog && details.scriptId && details.scriptName && (
                <InterviewScriptViewModal
                    ideaId={ideaId}
                    scriptId={details.scriptId}
                    scriptName={details.scriptName}
                    onClose={() => setShowPreviewScriptDialog(false)}
                />
            )}
        </div>
    );
}

type CompletedInterviewActions = { viewInterview?: () => void };
export function CompletedInterviewPage({
    ideaId,
    interviewId,
    onViewInterview,
    interviewDetailsPageOptions,
    canExecuteActions,
    interview
}: {
    ideaId: string;
    interviewId: number;
    onViewInterview: () => void;
    interviewDetailsPageOptions?: InterviewDetailsPageOptions;
    canExecuteActions?: boolean;
    interview?: InterviewV2;
}) {
    const dispatch = useAppDispatch();
    const { analysis, runAnalysis, associateContactWithReferee } = useInterviewAnalysis(ideaId, interviewId);
    const childActions = useMemo<CompletedInterviewActions>(() => ({ viewInterview: onViewInterview }), [onViewInterview]);

    const analysisFailed = analysis?.status === InterviewAnalysisStatus.Error;
    useEffect(() => {
        if (!analysisFailed) return;

        const notificationId = dispatch(
            addNotification(
                { content: 'Failed to analyze the interview.', timeout: 0, type: 'error', actionText: canExecuteActions ? 'Run again' : undefined },
                runAnalysis
            )
        );

        return () => dispatch(removeNotification(notificationId, false));
    }, [analysisFailed, runAnalysis, dispatch, canExecuteActions]);

    if (!analysis) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    const interviewTabs: TabHeader[] = [
        { title: 'Overview', to: '', state: interviewDetailsPageOptions },
        { title: 'Insights', to: 'insights', state: interviewDetailsPageOptions },
        { title: 'Quality', to: 'quality', state: interviewDetailsPageOptions }
    ];

    return (
        <InterviewAnalysisContext.Provider value={{ analysis, associateContactWithReferee }}>
            <TabbedLayout size="small" headers={interviewTabs} theme="secondary" separated data={{ interview: interview, actions: childActions }} />
        </InterviewAnalysisContext.Provider>
    );
}

function MeetingInstructionsPanel({
    meetingId,
    publicCode,
    fixedType,
    isAiLed
}: {
    meetingId?: string | null;
    publicCode?: string | null;
    fixedType?: InterviewExecutionTypeV2;
    isAiLed?: boolean;
}) {
    const [meetingInstructionsType, setMeetingInstructionsType] = useState<InterviewExecutionTypeV2>(fixedType ?? InterviewExecutionTypeV2.OnlineMeeting);
    const { title, instructions } = MEETING_INSTRUCTIONS[isAiLed ? InterviewExecutionTypeV2.IvaChat : meetingInstructionsType];

    const handleSwitchMeetingInstructions = () => {
        setMeetingInstructionsType(type =>
            type === InterviewExecutionTypeV2.OnlineMeeting ? InterviewExecutionTypeV2.InPersonMeeting : InterviewExecutionTypeV2.OnlineMeeting
        );
    };

    if (meetingInstructionsType === InterviewExecutionTypeV2.Import) {
        return null;
    }

    return (
        <MeetingDetailsPanel
            title={title}
            headerRightElement={
                isAiLed || fixedType ? (
                    undefined
                ) : (
                    <Button onClick={handleSwitchMeetingInstructions} themeColor="secondary" fillMode="flat" size="small">
                        {meetingInstructionsType === InterviewExecutionTypeV2.OnlineMeeting
                            ? 'View instructions for in-person meeting'
                            : 'View instructions for online meeting'}
                    </Button>
                )
            }
        >
            <MeetingInstructionsView meetingInstructions={instructions} />
            {isAiLed && publicCode ? (
                <MeetingLinkComponent className="k-mt-4 k-ml-11" publicCode={publicCode} />
            ) : !isAiLed ? (
                <MeetingIDComponent className="k-mt-4 k-ml-11" meetingId={meetingId} />
            ) : null}
        </MeetingDetailsPanel>
    );
}

function getIVAInterviewLink(publicCode: string) {
    return `/interviews/${publicCode}/ai`;
}

function generateAbsoluteIVAInterviewLink(publicCode: string) {
    return resolveAbsoluteUrl(getIVAInterviewLink(publicCode));
}

//TODO R4 check this link
function generateGoogleMeetLink(meetingId: string) {
    return `https://meet.google.com/${meetingId}`;
}

function MeetingLinkComponent({ publicCode, className }: { publicCode: string; className?: string }) {
    const link = generateAbsoluteIVAInterviewLink(publicCode);

    return <PresentAndCopyLink link={link} name="Interview link" className={className} />;
}

function MeetingIDComponent({ meetingId, className }: { meetingId?: string | null; className?: string }) {
    const [meetingLinkValue, setMeetingLinkValue] = useState<string | undefined>(undefined);
    const [meetingLink, setMeetingLink] = useState<string | undefined>(meetingId ? generateGoogleMeetLink(meetingId) : undefined);
    const [inviteInProgress, inviteIvaWrapper] = useSingleClickButton();

    const handleInviteIVA = inviteIvaWrapper(async () => {
        //TODO R4 verify link to server
        await new Promise(resolve => setTimeout(resolve, 1000)); // Add 1 second delay

        setMeetingLink(meetingLinkValue);
    });

    const handleChangeMeetingLink = () => {
        setMeetingLink(undefined);
        setMeetingLinkValue(undefined);
    };

    return (
        <div className={combineClassNames('', className)}>
            {!meetingLink ? (
                <>
                    <label htmlFor="meeting-id" className="k-mb-1 k-display-block">
                        Meeting ID
                    </label>
                    <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                        <Input
                            id="meeting-id"
                            className="k-flex-1"
                            placeholder="e.g. meet.google.com/izp-ppmz-bqs"
                            value={meetingLinkValue}
                            disabled={inviteInProgress}
                            onChange={e => {
                                setMeetingLinkValue(e.value);
                            }}
                        />
                        <Button onClick={handleInviteIVA} disabled={!meetingLinkValue || inviteInProgress}>
                            Invite IVA
                        </Button>
                    </StackLayout>
                </>
            ) : (
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-icp-panel k-justify-content-between k-px-4 k-py-2">
                    <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                        <AIAvatar />
                        <div>IVA is invited.</div>
                    </StackLayout>
                    <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2.5">
                        <div className="k-fs-sm">{meetingLinkValue}</div>
                        <Button onClick={handleChangeMeetingLink}>Change meeting ID</Button>
                    </StackLayout>
                </StackLayout>
            )}
        </div>
    );
}

function MeetingDetailsPanel({ title, headerRightElement, children }: { title: string; headerRightElement?: ReactNode; children?: ReactNode }) {
    return (
        <>
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-icp-panel k-icp-panel-content k-gap-4">
                <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                    <H3 className="k-flex-1">{title}</H3>
                    {headerRightElement}
                </StackLayout>
                <div className="k-separator k-icp-component-border" />
                <div style={{ maxWidth: 640 }}>{children}</div>
            </StackLayout>
        </>
    );
}

export function OverviewTab() {
    const [viewScript, setViewScript] = useState(false);
    const [viewUploadedInterview, setViewUploadedInterview] = useState(false);
    const [viewUploadedScript, setViewUploadedScript] = useState(false);
    const navigate = useNavigate();
    const currentUserId = useAppSelector(state => state.user?.userId);
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canExecuteActions = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const { ideaId } = useIdeaParams();
    const { interview: details, actions: interviewActions } = useTabbedLayoutData<{ interview: InterviewV2 | undefined; actions: CompletedInterviewActions }>();
    const { analysis: overviewAnalysis, associateContactWithReferee } = useInterviewAnalysisContext();
    const viewInterview = interviewActions.viewInterview;

    if (!currentUserId || !details) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    return (
        <div className="page-content-middle page-content--xxl">
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-8">
                <IntervieweeDetailsSection
                    interviewee={details.contact}
                    intervieweeName={details.intervieweeName}
                    isAnalysisPending={overviewAnalysis.status === InterviewAnalysisStatus.Pending}
                />
                <HostAndDetailsSection
                    interviewBaseUrl="../"
                    interviewDetails={details}
                    currentUserId={currentUserId}
                    isAnalysisPending={overviewAnalysis.status === InterviewAnalysisStatus.Pending}
                    fileButtons={
                        <>
                            <TextFileButton
                                title={
                                    details.imported
                                        ? (details.detail as ImportedInterviewDetailV2).transcriptName
                                        : details.contact
                                        ? `Completed interview with ${details.contact.name}`
                                        : 'Completed interview'
                                }
                                icon={details.imported ? FileUploadedIcon : FileTranscriptIcon}
                                onClick={() => {
                                    if (details.imported) {
                                        setViewUploadedInterview(true);
                                    } else {
                                        viewInterview?.();
                                    }
                                }}
                            />
                            {details.scriptId && details.scriptName ? (
                                <TextFileButton title={details.scriptName} icon={ScriptIcon} onClick={() => setViewScript(true)} />
                            ) : details.imported && (details.detail as ImportedInterviewDetailV2).scriptName ? (
                                <TextFileButton
                                    title={(details.detail as ImportedInterviewDetailV2).scriptName!}
                                    icon={FileUploadedIcon}
                                    onClick={() => setViewUploadedScript(true)}
                                />
                            ) : null}
                        </>
                    }
                />

                <StackLayout orientation="horizontal" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                    <InterviewSummaryPanel icon={InsightIcon} title="Insights Summary">
                        {overviewAnalysis.insightSummaryItems.length > 0 ? (
                            <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                                {overviewAnalysis.insightSummaryItems.map(item => (
                                    <React.Fragment key={item.title}>
                                        <div>
                                            <div className="k-mb-2 k-font-semibold">{item.title}</div>
                                            <div>{item.content}</div>
                                        </div>
                                        <div className="k-separator k-icp-component-border" />
                                    </React.Fragment>
                                ))}
                                <Button themeColor="secondary" fillMode="flat" size="small" onClick={() => navigate('insights')}>
                                    View insights
                                </Button>
                            </StackLayout>
                        ) : overviewAnalysis.status === InterviewAnalysisStatus.Pending ? (
                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'middle' }} className="k-gap-4 ">
                                {[60, 50, 80].map((lastSkeletonWidthPercentage, idx) => {
                                    return (
                                        <React.Fragment key={idx}>
                                            <SkeletonSection lastSkeletonWidthPercentage={lastSkeletonWidthPercentage} />
                                            <div className="k-separator k-icp-component-border" />
                                        </React.Fragment>
                                    );
                                })}
                                <Skeleton shape="rectangle" style={{ width: '108px', height: '24px' }} className="k-rounded-sm" />
                            </StackLayout>
                        ) : (
                            <div className="k-icp-subtle-text">No significant insights were identified with this interview.</div>
                        )}
                    </InterviewSummaryPanel>

                    <InterviewSummaryPanel icon={AwardIcon} title="Quality and Suggestions">
                        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                            <AnalysisPanelSectionWithScore
                                isPending={!overviewAnalysis.qualitySummary && overviewAnalysis.status === InterviewAnalysisStatus.Pending}
                                title="Quality score"
                                score={overviewAnalysis.qualitySummary?.score}
                                text={overviewAnalysis.qualitySummary?.text}
                                emptyText="No quality score for this interview."
                                hasButton
                                buttonText="View more"
                                onButtonClick={() => navigate('quality')}
                            />
                            <div className="k-separator k-icp-component-border" />
                            <AnalysisPanelSectionWithScore
                                isPending={!overviewAnalysis.scriptCoverage && overviewAnalysis.status === InterviewAnalysisStatus.Pending}
                                title="Script coverage"
                                score={overviewAnalysis.scriptCoverage?.coverage}
                                showFullText
                                text={overviewAnalysis.scriptCoverage?.text}
                                emptyText="No interview script provided. Script coverage unavailable."
                            />
                        </StackLayout>
                    </InterviewSummaryPanel>
                </StackLayout>
                {overviewAnalysis.referrals.length > 0 && (
                    <InterviewSummaryPanel icon={ReferralIcon} title="Referrals">
                        <GridLayout cols={[{ width: '1fr' }, { width: '1fr' }, { width: '1fr' }]} gap={{ rows: 16, cols: 16 }} className="k-w-full">
                            {overviewAnalysis?.referrals.map((referral, index: number) => (
                                <GridLayoutItem key={index} className="k-min-w-0">
                                    <ReferralCard
                                        ideaId={ideaId}
                                        interviewId={details.id}
                                        refereeDetails={referral}
                                        readonly={!canExecuteActions}
                                        addedReferee={referral.contact ?? undefined}
                                        onAddReferee={associateContactWithReferee}
                                    />
                                </GridLayoutItem>
                            ))}
                        </GridLayout>
                    </InterviewSummaryPanel>
                )}
                {overviewAnalysis.nextSteps.length > 0 && <NextStepsPanel nextSteps={overviewAnalysis.nextSteps} />}
            </StackLayout>

            {viewUploadedInterview && details.imported && (
                <FileViewModal
                    ideaId={ideaId}
                    interviewId={details.id}
                    name={(details.detail as ImportedInterviewDetailV2).transcriptName}
                    type="interview"
                    onClose={() => setViewUploadedInterview(false)}
                />
            )}
            {viewUploadedScript && details.imported && (details.detail as ImportedInterviewDetailV2).scriptName && (
                <FileViewModal
                    ideaId={ideaId}
                    interviewId={details.id}
                    name={(details.detail as ImportedInterviewDetailV2).scriptName!}
                    type="script"
                    onClose={() => setViewUploadedScript(false)}
                />
            )}
            {viewScript && details.scriptId && details.scriptName && (
                <InterviewScriptViewModal ideaId={ideaId} scriptId={details.scriptId} scriptName={details.scriptName} onClose={() => setViewScript(false)} />
            )}
        </div>
    );
}

export function InsightsTab() {
    const [selectedSectionId, setSelectedSectionId] = useState<number>(0);
    const { ideaId } = useIdeaParams();
    const { interviewId: interviewIdParam } = useParams();
    const [insightCategories, setInsightCategories] = useState<InterviewInsightCategory[] | undefined>(undefined);
    const { analysis } = useInterviewAnalysisContext();

    useEffect(() => {
        const fetchInterviewInsights = async () => {
            if (!interviewIdParam || analysis.status === InterviewAnalysisStatus.Pending) return;

            const interviewId = parseInt(interviewIdParam);
            if (isNaN(interviewId)) return;

            const insightCategories = await interviewsV2Service.getInterviewInsights(ideaId, interviewId);
            setInsightCategories(insightCategories);
        };

        fetchInterviewInsights();
    }, [ideaId, interviewIdParam, analysis.status]);

    if (analysis.status === InterviewAnalysisStatus.Pending) {
        return (
            <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} style={{ maxWidth: 320 }} className="k-flex-1 k-gap-2">
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                </StackLayout>
                <div className="k-separator k-icp-component-border" />
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} style={{ maxWidth: 640 }} className="-flex-2 k-gap-4">
                    <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                        <Skeleton className="k-rounded-full k-shrink-0" shape="rectangle" style={{ width: '64px', height: '64px' }} />
                        <Skeleton shape="text" className="k-flex-1 k-fs-lg" />
                    </StackLayout>
                    <div>
                        <Skeleton shape="text" />
                        <Skeleton shape="text" />
                    </div>
                    <Skeleton shape="rectangle" className="k-rounded-md" style={{ height: '176px' }} />
                </StackLayout>
            </StackLayout>
        );
    }

    if (!insightCategories) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    if (insightCategories.length === 0) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <EmptyInsightsIllustration />
                <div className="k-mt-6">No insights found</div>
            </StackLayout>
        );
    }

    const sections = insightCategories.map((category, idx) => ({ id: idx, text: category.name, summary: category.description } as Section));
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6">
            <div style={{ maxWidth: 320 }} className="k-flex-1">
                <SectionsView sectionName="Categories" sections={sections} selectedSectionId={selectedSectionId} onSelectedSection={setSelectedSectionId} />
            </div>
            <div className="k-separator k-icp-component-border" />
            <InsightDetails className="-flex-2" category={insightCategories[selectedSectionId]} />
        </StackLayout>
    );
}

export function QualityTab() {
    const [selectedSectionId, setSelectedSectionId] = useState<number>(0);
    const { ideaId } = useIdeaParams();
    const { interviewId: interviewIdParam } = useParams();
    const [qualityAssessmentAreas, setQualityAssessmentAreas] = useState<InterviewQualityAssessmentArea[] | undefined>(undefined);
    const { analysis } = useInterviewAnalysisContext();

    useEffect(() => {
        const fetchQualityAssessment = async () => {
            if (!interviewIdParam || analysis.status === InterviewAnalysisStatus.Pending) return;

            const interviewId = parseInt(interviewIdParam);
            if (isNaN(interviewId)) return;

            const qualityAssessmentAreas = await interviewsV2Service.getInterviewQualityAssessment(ideaId, interviewId);
            setQualityAssessmentAreas(qualityAssessmentAreas);
        };

        fetchQualityAssessment();
    }, [ideaId, interviewIdParam, analysis.status]);

    if (analysis.status === InterviewAnalysisStatus.Pending) {
        return (
            <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} style={{ maxWidth: 320 }} className="k-flex-1 k-gap-2">
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                    <Skeleton className="k-rounded-md" shape="rectangle" style={{ height: '54px' }} />
                </StackLayout>
                <div className="k-separator k-icp-component-border" />
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} style={{ maxWidth: 640 }} className="-flex-2 k-gap-4">
                    <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                        <Skeleton className="k-rounded-full k-shrink-0" shape="rectangle" style={{ width: '64px', height: '64px' }} />
                        <Skeleton shape="text" className="k-flex-1 k-fs-lg" />
                    </StackLayout>
                    <div>
                        <Skeleton shape="text" />
                        <Skeleton shape="text" />
                    </div>
                    <Skeleton shape="rectangle" className="k-rounded-md" style={{ height: '176px' }} />
                </StackLayout>

                {analysis.qualitySummary && (
                    <>
                        <div className="k-separator k-icp-component-border" />
                        <QualityNote className="k-flex-1" text={analysis.qualitySummary.text} score={analysis.qualitySummary.score} maxWidth={296} />
                    </>
                )}
            </StackLayout>
        );
    }

    if (!qualityAssessmentAreas) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    if (qualityAssessmentAreas.length === 0) {
        return analysis.qualitySummary ? (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }}>
                <QualityNote text={analysis.qualitySummary.text} score={analysis.qualitySummary.score} maxWidth={540} />
            </StackLayout>
        ) : (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <EmptyQualityIllustration />
                <div className="k-mt-6">No areas for improvement found</div>
            </StackLayout>
        );
    }

    const sections = qualityAssessmentAreas.map(
        (area, idx) => ({ id: idx, text: area.title, summary: area.text, scorePercentage: area.percentageImprovement } as Section)
    );

    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6">
            <AreasAndStrengthsSection
                sections={sections}
                selectedSectionId={selectedSectionId}
                onSelectedSectionId={setSelectedSectionId}
                className="k-flex-1"
            />
            <div className="k-separator k-icp-component-border" />
            <ImprovementAreaView className="-flex-2" area={qualityAssessmentAreas[selectedSectionId]} />
            {analysis.qualitySummary && (
                <>
                    <div className="k-separator k-icp-component-border" />
                    <QualityNote className="k-flex-1" text={analysis.qualitySummary.text} score={analysis.qualitySummary.score} maxWidth={296} />
                </>
            )}
        </StackLayout>
    );
}

function NextStepsPanel({ nextSteps }: { nextSteps: InterviewNextStep[] }) {
    const firstColumnSteps = nextSteps.slice(0, Math.ceil(nextSteps.length / 2));
    const secondColumnSteps = nextSteps.slice(Math.ceil(nextSteps.length / 2));

    return (
        <InterviewSummaryPanel icon={ListCheckIcon} title="Next steps">
            <StackLayout orientation="horizontal" align={{ horizontal: 'stretch', vertical: 'stretch' }} className="k-gap-8">
                <NextStepsColumn nextSteps={firstColumnSteps} maxWidth={secondColumnSteps.length === 0 ? '50%' : undefined} />
                {secondColumnSteps.length > 0 && (
                    <>
                        <div className="k-separator k-icp-component-border" />
                        <NextStepsColumn nextSteps={secondColumnSteps} />
                    </>
                )}
            </StackLayout>
        </InterviewSummaryPanel>
    );
}

function NextStepsColumn({ nextSteps, maxWidth }: { nextSteps: InterviewNextStep[]; maxWidth?: string }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} style={{ maxWidth: maxWidth }} className="k-gap-4">
            {nextSteps.map((step, idx) => (
                <React.Fragment key={idx}>
                    {idx > 0 && <div className="k-separator k-icp-component-border" />}
                    <div key={idx}>
                        <div className="k-mb-2 k-font-weight-semibold">{step.title}</div>
                        <div className="formatted-links-area" dangerouslySetInnerHTML={{ __html: step.text }} />
                    </div>
                </React.Fragment>
            ))}
        </StackLayout>
    );
}

function IntervieweeDetailsSection({
    interviewee,
    intervieweeName,
    isAnalysisPending
}: {
    interviewee: ReducedPerson | null;
    intervieweeName?: string | null;
    isAnalysisPending?: boolean;
}) {
    return (
        <div>
            <H3 className="!k-mb-2">Interviewee</H3>
            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                {interviewee ? (
                    <>
                        <UserAvatar
                            picture={interviewee.picture || undefined}
                            colorIndex={getPreferredColorIndex(interviewee.id)}
                            initials={generateInitials(2, interviewee.firstName, interviewee.lastName)}
                        />
                        <UserDetailsText name={interviewee.name} jobTitle={interviewee.jobTitle} />
                    </>
                ) : (
                    <>
                        <UserAvatar empty={true} initials={''} className="k-icp-subtle-text" />
                        {isAnalysisPending && !intervieweeName ? (
                            <Skeleton shape="text" className="k-fs-lg" style={{ width: 320 }} />
                        ) : (
                            <strong className="k-fs-lg">{intervieweeName || 'Unknown interviewee'}</strong>
                        )}
                    </>
                )}
            </StackLayout>
        </div>
    );
}

function HostAndDetailsSection({
    interviewDetails,
    currentUserId,
    isAnalysisPending,
    fileButtons,
    interviewBaseUrl
}: {
    interviewDetails: InterviewV2;
    currentUserId: string;
    isAnalysisPending?: boolean;
    fileButtons?: ReactNode;
    interviewBaseUrl?: string;
}) {
    const { host, aiLed, date, imported, createdBy, createdOn, updatedBy, updatedOn, relatedResearch } = interviewDetails;

    return (
        <div className="k-icp-panel k-icp-panel-content">
            <H3 className="!k-mb-2">Host & Details</H3>
            <StackLayout orientation="horizontal" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-8">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                    <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                        {aiLed ? (
                            <>
                                <AIAvatar />
                                <span>IVA</span>
                            </>
                        ) : host ? (
                            <>
                                <UserAvatar
                                    picture={host.picture}
                                    colorIndex={getPreferredColorIndex(host.userId)}
                                    initials={generateInitials(2, host.firstName, host.lastName)}
                                />
                                <span>{getPersonFullName(host.firstName, host.lastName, host.userId === currentUserId)}</span>
                            </>
                        ) : (
                            <>
                                <UserAvatar empty={true} initials={''} className="k-icp-subtle-text" />
                                <span>Unknown Host</span>
                            </>
                        )}
                    </StackLayout>
                    {createdBy && (
                        <HostDetailsRow name="Created">
                            {dateTimeService.stringifyToDay(createdOn, false, true) +
                                ' by ' +
                                getPersonFullName(createdBy.firstName, createdBy.lastName, createdBy.userId === currentUserId)}
                        </HostDetailsRow>
                    )}

                    {date ? (
                        <HostDetailsRow name="Interview date">{dateTimeService.stringifyToDay(date, false, true)}</HostDetailsRow>
                    ) : (
                        imported && (
                            <HostDetailsRow name="Interview date" pending={isAnalysisPending}>
                                Unknown
                            </HostDetailsRow>
                        )
                    )}
                    {updatedOn && !imported && (
                        <HostDetailsRow name="Last modified">
                            {dateTimeService.stringifyToDay(updatedOn, false, true) +
                                (updatedBy ? ' by ' + getPersonFullName(updatedBy.firstName, updatedBy.lastName, updatedBy.userId === currentUserId) : '')}
                        </HostDetailsRow>
                    )}
                    {relatedResearch.length > 0 && (
                        <HostDetailsRow name="Research">
                            <ResearchList researchItems={relatedResearch} baseUrl={interviewBaseUrl} />
                        </HostDetailsRow>
                    )}
                </StackLayout>
                {fileButtons && (
                    <>
                        <div className="k-separator k-icp-component-border" />
                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2 k-align-self-center">
                            {fileButtons}
                        </StackLayout>
                    </>
                )}
            </StackLayout>
        </div>
    );
}

function HostDetailsRow({ name, children, pending }: { name: string; children: ReactNode; pending?: boolean }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-fs-sm k-gap-2">
            <span className="k-icp-subtle-text k-shrink-0">{name}:</span>
            {pending ? <Skeleton className="!k-d-inline-block" shape="text" /> : children}
        </StackLayout>
    );
}

function ResearchList({ researchItems, baseUrl }: { researchItems: ReducedResearchV2[]; baseUrl?: string }) {
    const [showAll, setShowAll] = useState<boolean>();
    if (!researchItems.length) return null;

    function renderResearch(research: ReducedResearchV2) {
        return (
            <Link key={research.id} to={`${baseUrl ?? ''}../../research-v2/${research.id}/overview`} className="k-button-link-secondary">
                {research.name}
            </Link>
        );
    }

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-flex-1 k-gap-thin">
            {showAll ? (
                <>
                    {researchItems.map(renderResearch)}
                    <span onClick={() => setShowAll(false)} className="k-cursor-pointer k-button-link-secondary">
                        Show less
                    </span>
                </>
            ) : (
                <>
                    {renderResearch(researchItems[0])}
                    {researchItems.length > 1 && (
                        <div>
                            and{' '}
                            <span onClick={() => setShowAll(true)} className="k-cursor-pointer k-button-link-secondary">
                                {researchItems.length - 1} more
                            </span>
                        </div>
                    )}
                </>
            )}
        </StackLayout>
    );
}

function InsightDetails({ category, className }: { category: InterviewInsightCategory; className?: string }) {
    const groupedInsights = useMemo(() => {
        const grouped = new Map<string | undefined, InterviewInsight[]>();

        category.insights?.forEach(insight => {
            const group = insight.group ?? undefined; // Convert null to undefined
            if (!grouped.has(group)) {
                grouped.set(group, []);
            }
            grouped.get(group)!.push(insight);
        });

        return grouped;
    }, [category.insights]);

    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'stretch', vertical: 'middle' }}
            className={combineClassNames('k-gap-4', className)}
            style={{ maxWidth: 640 }}
        >
            <SummaryHeader title={category.name} summary={category.description} icon={InsightIcon} />

            {/* Display ungrouped insights first */}
            {groupedInsights.get(undefined)?.map((insight, idx) => (
                <InsightView asPanel insight={insight} key={idx} />
            ))}

            {/* Display grouped insights */}
            {Array.from(groupedInsights.entries())
                .filter(([group]) => group !== undefined)
                .map(([group, insights]) => (
                    <StackLayout key={group} orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                        <div className="k-font-weight-semibold">{group}</div>
                        {insights.map((insight, idx) => (
                            <InsightView asPanel insight={insight} key={idx} />
                        ))}
                    </StackLayout>
                ))}
        </StackLayout>
    );
}

function ImprovementAreaView({ area, className }: { area: InterviewQualityAssessmentArea; className?: string }) {
    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'stretch', vertical: 'middle' }}
            className={combineClassNames('k-gap-4', className)}
            style={{ maxWidth: 640 }}
        >
            <SummaryHeader title={area.title} summary={area.text} icon={InsightIcon} />
            {area.recommendations.map((recommendation, idx) => (
                <RecommendationView recommendation={recommendation} key={idx} asPanel showPercentage />
            ))}
        </StackLayout>
    );
}
