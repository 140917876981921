import {
    changeTextBlock,
    formatBlockElements,
    htmlToFragment,
    InputRule,
    inputRules,
    pasteCleanup,
    PasteCleanupSettings,
    removeAttribute,
    replaceImageSourcesFromRtf,
    sanitize
} from '@progress/kendo-editor-common';
import { fragmentToHtml } from '@progress/kendo-editor-common/dist/npm/source';
import { Button } from '@progress/kendo-react-buttons';
import { Editor, EditorPasteEvent, EditorTools, EditorUtils } from '@progress/kendo-react-editor';
import { StackLayout } from '@progress/kendo-react-layout';
import { keymap } from 'prosemirror-keymap';
import { Node, Schema } from 'prosemirror-model';
import { EditorState, NodeSelection, Plugin, PluginKey, TextSelection } from 'prosemirror-state';
import { ReplaceStep } from 'prosemirror-transform';
import { Decoration, DecorationSet, DirectEditorProps, EditorView } from 'prosemirror-view';
import { ComponentType, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useAsRef } from '../../hooks/commonHooks';
import { ChangesStatus } from '../../hooks/textEditorSaveIndicator';
import { isMacOs } from '../../services/common';
import { EditorDocument } from '../../services/documentsService';
import { BoundDropDownButton, DropDownButtonItem } from '../common/boundDropDownButton';
import { PopupPropsNestedContextProvider } from '../common/popupPropsNestedContextProvider';
import { collab, useDocumentCollabBehavior } from '../ui/richTextEditor/collabPlugin';
import { collectTitlesPlugin } from '../ui/richTextEditor/collectTitlesPlugin';
import {
    BookmarkTitle,
    deleteNodeAtSelection,
    duplicateNodeAtSelection,
    scrollToHeading,
    switchHeadingAndQuestionAtSelection,
    usePdfExportTool
} from '../ui/richTextEditor/common';

const { Undo, Redo } = EditorTools;

const PLACEHOLDERS_INITIAL = {
    heading: 'New section title...',
    paragraph: 'Type a question here...'
};

const inputRule = () => {
    return inputRules({
        rules: [
            new InputRule(/^\/$/, (state, match, start, end) => {
                const tr = state.tr;
                const currentNode = state.selection.$anchor.parent;
                changeTextBlock(tr, currentNode, state.schema.nodes.heading, { ...currentNode.attrs, level: 2 });

                if (tr.docChanged) {
                    return tr;
                }
                return null;
            })
        ]
    });
};

function preventHardBreaksPlugin() {
    return new Plugin({
        key: new PluginKey('preventHardBreaks'),
        filterTransaction(transaction, editorState) {
            let containsHardBreak = false;
            transaction.steps.forEach(step => {
                if (step instanceof ReplaceStep) {
                    const slice = (step as any).slice;
                    if (slice.content.content.some((node: any) => node.type.name === 'hard_break')) {
                        containsHardBreak = true;
                    }
                }
            });

            if (containsHardBreak) {
                return false;
            }

            return true;
        }
    });
}

function getNodeAtCursor(state: EditorState) {
    const indexInDoc = state.selection.$head.index(0);
    return state.doc.maybeChild(indexInDoc);
}

function getNodeBeforeCursor(state: EditorState) {
    const indexInDoc = state.selection.$head.index(0);
    return state.doc.maybeChild(indexInDoc - 1);
}

function getNodeAfterCursor(state: EditorState) {
    const indexInDoc = state.selection.$head.index(0);
    return state.doc.maybeChild(indexInDoc + 1);
}

const handleBackspaceDeleteEnterPlugin = keymap({
    Backspace: (state, dispatch, view) => {
        if (!state.selection.empty) {
            return false;
        }

        const { $head } = state.selection;
        const currentNode = getNodeAtCursor(state);
        const previousNode = getNodeBeforeCursor(state);

        if (!currentNode || !previousNode) {
            return false;
        }

        // If we are at the end of an empty element, allow default behavior.
        if (currentNode.content.size === 0) {
            return false;
        }

        // When between heading and paragraph instead of merging (deleting), move the caret at the end of the previous element.
        if (currentNode.type.name !== previousNode.type.name && $head.parentOffset === 0) {
            const newPos = state.selection.from - 2;
            const $pos = state.doc.resolve(newPos);
            const tr = state.tr.setSelection(TextSelection.near($pos));
            dispatch!(tr);

            return true;
        }

        return false;
    },
    Delete: (state, dispatch, view) => {
        if (!state.selection.empty) {
            return false;
        }

        const { $head } = state.selection;
        const currentNode = getNodeAtCursor(state);
        const nextNode = getNodeAfterCursor(state);
        if (!currentNode || !nextNode) {
            return false;
        }

        // If we are at the end of an empty heading, allow default behavior UNLESS it is the first in the document.
        if (currentNode.type.name === 'heading' && currentNode.content.size === 0 && $head.index(0) !== 0) {
            return false;
        }

        // If we are at teh end of an empty paragraph, always allow to delete it.
        if (currentNode.type.name === 'paragraph' && currentNode.content.size === 0) {
            return false;
        }

        // When between different node types and the cursor is at the end of the current node,
        // move the caret to the beginning of the next element instead of merging (deleting) content.
        if (currentNode.type.name !== nextNode.type.name && $head.parentOffset === currentNode.nodeSize - 2) {
            const newPos = state.selection.from + 1;
            const $pos = state.doc.resolve(newPos);
            const tr = state.tr.setSelection(TextSelection.near($pos));
            dispatch!(tr);
            return true;
        }

        return false;
    },
    Enter: (state, dispatch, view) => {
        if (!state.selection.empty) {
            return false;
        }

        const { $head } = state.selection;

        const currentNode = getNodeAtCursor(state);
        const nextNode = getNodeAfterCursor(state);
        if (!currentNode || !nextNode) {
            return false;
        }

        // If we are at the end of a heading and the next element is an empty paragraph, move the caret in it instead of adding new line.
        if (
            currentNode.type.name === 'heading' &&
            $head.parentOffset === currentNode.nodeSize - 2 &&
            nextNode.type.name === 'paragraph' &&
            nextNode.content.size === 0
        ) {
            const newPos = state.selection.from + 1;
            const $pos = state.doc.resolve(newPos);
            const tr = state.tr.setSelection(TextSelection.near($pos));
            dispatch!(tr);

            return true;
        }

        return false;
    }
});

function generateSectionsAndQuestionsPlugin() {
    return new Plugin({
        key: new PluginKey('generateSectionsAndQuestions'),

        appendTransaction(transactions, oldState, newState) {
            // Check if document is empty
            const doc = newState.doc;
            const tr = newState.tr;
            const isEmpty = doc.childCount === 1 && doc.textContent === '';
            const firstChildIsHeading = doc.firstChild && doc.firstChild.type.name === 'heading';

            if (isEmpty || !firstChildIsHeading) {
                const heading = newState.schema.nodes.heading.create({ level: 2 });
                tr.insert(0, heading);
                tr.setSelection(TextSelection.create(tr.doc, 0));
            }

            // let lastInsertedNodePos: number | undefined;
            newState.doc.descendants((node, pos, parent, index) => {
                const afterSiblingNode = parent?.maybeChild(index + 1);
                if (node.type.name === 'heading' && (!afterSiblingNode || afterSiblingNode.type.name !== 'paragraph')) {
                    const paragraphNodeType = newState.schema.nodes.paragraph;
                    if (EditorUtils.canInsert(newState, paragraphNodeType)) {
                        const paragraph = paragraphNodeType.create(null);
                        tr.insert(tr.mapping.map(pos + node.nodeSize), paragraph);
                    }
                    // lastInsertedNodePos = pos + node.nodeSize;
                }
            });

            //TODO FIX autofocus. The problem might not be here.
            // if (lastInsertedNodePos) {
            //     tr.setSelection(TextSelection.create(tr.doc, tr.mapping.map(lastInsertedNodePos)));
            // }

            return tr;
        }
    });
}

function addPlaceholdersPlugin() {
    return new Plugin({
        key: new PluginKey('addPlaceholders'),

        props: {
            decorations: state => {
                const { doc } = state;
                const decorations: Array<any> = [];

                doc.descendants((node, pos, parent, index) => {
                    const beforeSiblingNode = parent?.maybeChild(index - 1);
                    const isNodeSelection = state.selection instanceof NodeSelection;

                    if (node.type.name === 'heading' && node.textContent === '') {
                        decorations.push(
                            Decoration.node(pos, pos + node.nodeSize, {
                                class: 'placeholder',
                                'data-placeholder': PLACEHOLDERS_INITIAL[node.type.name as keyof typeof PLACEHOLDERS_INITIAL]
                            })
                        );
                    } else if (
                        node.type.name === 'paragraph' &&
                        node.textContent === '' &&
                        beforeSiblingNode != null &&
                        beforeSiblingNode.type.name === 'heading'
                    ) {
                        decorations.push(
                            Decoration.node(pos, pos + node.nodeSize, {
                                class: 'placeholder',
                                'data-placeholder': PLACEHOLDERS_INITIAL[node.type.name as keyof typeof PLACEHOLDERS_INITIAL]
                            })
                        );
                    } else if (
                        node.textContent === '' &&
                        ((!isNodeSelection && state.selection.$head.pos === state.selection.$anchor.pos && state.selection.$anchor.pos - 1 === pos) ||
                            (isNodeSelection && state.selection.anchor === pos))
                    ) {
                        decorations.push(
                            Decoration.widget(pos, () => {
                                const placeholderContent = document.createElement('span');
                                placeholderContent.className = 'placeholder-q-or-s';
                                placeholderContent.innerHTML =
                                    "Type a question or press <span class='placeholder-q-or-s-slash'></span> to start typing section title...";
                                return placeholderContent;
                            })
                        );
                    }
                });

                return DecorationSet.create(doc, decorations);
            }
        }
    });
}

interface SideButton {
    nodeIdx: number;
    domElement?: HTMLElement;
}

export type EditorToolProps = { view?: EditorView; dir: string };
export type EditorTool = ComponentType<EditorToolProps>;

interface InterviewScriptDocumentEditorProps {
    ideaId: string;
    editorDocument: EditorDocument;
    handleNewTitles?: (titles: BookmarkTitle[]) => void;
    handleSectionIdxChanged?: (sectionIdx: number) => void;
    onSavingStatusChange?: (status: ChangesStatus) => void;
    exportTitle?: string;
    readonly?: boolean;
    additionalTools?: (EditorTool | EditorTool[])[];
}

export interface InterviewScriptDocumentEditorRef {
    selectSection: (sectionIdx: number) => void;
}

export const InterviewScriptDocumentEditor = forwardRef<InterviewScriptDocumentEditorRef, InterviewScriptDocumentEditorProps>((props, ref) => {
    const { ideaId, editorDocument, handleNewTitles, handleSectionIdxChanged, onSavingStatusChange, exportTitle, readonly, additionalTools } = props;
    const editorViewRef = useRef<EditorView | null>(null);
    const editorRef = useRef<Editor | null>(null);
    const { onDispatchTransaction: collabOnDispatchTransaction } = useDocumentCollabBehavior(editorViewRef, ideaId, editorDocument, onSavingStatusChange);

    useImperativeHandle(
        ref,
        () => ({
            selectSection: (sectionIdx: number) => {
                if (!editorViewRef.current) return;
                scrollToHeading(editorViewRef.current, sectionIdx);
            }
        }),
        []
    );

    const [sideButtons, setSideButtons] = useState<SideButton[] | undefined>(undefined);
    const [isMounted, setIsMounted] = useState(false);

    const pasteSettings: PasteCleanupSettings = {
        convertMsLists: true,
        attributes: {
            '*': removeAttribute
        }
    };

    useEffect(() => {
        if (!isMounted) return;

        const handleKeyDownEvent = (event: KeyboardEvent) => {
            const editor = editorRef.current;
            const view = editorViewRef.current;
            if (!view || readonly || !editor) return;

            if (
                (!isMacOs && event.ctrlKey && !event.shiftKey && event.code === 'Slash') ||
                (isMacOs && event.metaKey && !event.shiftKey && event.code === 'Slash')
            ) {
                event.preventDefault();
                switchHeadingAndQuestionAtSelection(true, view);
            } else if (
                (!isMacOs && event.ctrlKey && event.shiftKey && event.code === 'Slash') ||
                (isMacOs && event.metaKey && event.shiftKey && event.code === 'Slash')
            ) {
                event.preventDefault();
                switchHeadingAndQuestionAtSelection(false, view);
            } else if ((!isMacOs && event.ctrlKey && event.code === 'KeyD') || (isMacOs && event.metaKey && event.code === 'KeyD')) {
                event.preventDefault();
                duplicateNodeAtSelection(view);
            } else if (event.code === 'Delete') {
                deleteNodeAtSelection(view);
            }
        };

        const editorDocumentElement = editorRef.current?.element;
        if (!editorDocumentElement) return;

        editorDocumentElement.addEventListener('keydown', handleKeyDownEvent);

        return () => {
            editorDocumentElement.removeEventListener('keydown', handleKeyDownEvent);
        };
    }, [isMounted, readonly]);

    const readonlyRef = useAsRef(readonly);
    const onMount = (event: { viewProps: DirectEditorProps; dom: any }) => {
        const { viewProps } = event;
        const { plugins, schema } = viewProps.state;
        if (readonly) viewProps.editable = () => !readonlyRef.current;

        const filteredNodes = schema.spec.nodes
            .remove('div')
            .remove('blockquote')
            .remove('horizontal_rule')
            .remove('code_block')
            .remove('image')
            .remove('ordered_list')
            .remove('bullet_list')
            .remove('list_item')
            .remove('table')
            .remove('table_row')
            .remove('table_cell')
            .remove('table_header');

        const filteredMarks = schema.spec.marks
            .remove('link')
            // .remove('strong')
            .remove('b')
            // .remove('em')
            .remove('i')
            // .remove('u')
            .remove('del')
            .remove('sub')
            .remove('sup')
            .remove('code')
            .remove('style');

        const textSchema = new Schema({
            nodes: filteredNodes,
            marks: filteredMarks
        });

        const doc = Node.fromJSON(textSchema, JSON.parse(editorDocument.document));
        const customPlugins = [handleBackspaceDeleteEnterPlugin, ...plugins, inputRule(), preventHardBreaksPlugin()];
        if (!readonly) {
            customPlugins.push(generateSectionsAndQuestionsPlugin(), addPlaceholdersPlugin());
        }
        if (handleNewTitles) customPlugins.push(collectTitlesPlugin(handleNewTitles, handleSectionIdxChanged));
        if (!readonly) customPlugins.push(addSideButtonsPlugin());
        customPlugins.push(collab({ version: editorDocument.docVersion }));
        const editorView = new EditorView(
            {
                mount: event.dom
            },
            {
                ...event.viewProps,
                state: EditorState.create({
                    doc,
                    plugins: customPlugins
                }),

                dispatchTransaction(transaction) {
                    const view = editorViewRef.current;
                    if (!view) return;
                    let newState = view.state.apply(transaction);
                    view.updateState(newState);
                    collabOnDispatchTransaction(newState, view);
                }
            }
        );

        editorView.focus();
        editorViewRef.current = editorView;
        setIsMounted(true);
        return editorView;
    };

    function generateDecorations(sideButtons: SideButton[], doc: Node) {
        const decorations: Decoration[] = sideButtons.map(pos => {
            const nodePositionInDoc = nodePositionInDocByIndex(doc, pos.nodeIdx);
            if (nodePositionInDoc === null) throw new Error('Node position in doc not found');
            const decWidget = Decoration.widget(
                nodePositionInDoc + 1,
                () => {
                    const span = document.createElement('span');
                    span.className = `side-button-marker`;
                    span.dataset.nodeIndex = pos.nodeIdx.toString();

                    sideButtons.forEach(btn => {
                        if (btn.nodeIdx === pos.nodeIdx) {
                            btn.domElement = span;
                        }
                    });
                    setSideButtons(sideButtons);
                    return span;
                },
                { key: `side-button-${pos.nodeIdx}` }
            );

            return decWidget;
        });

        return decorations;
    }

    function addSideButtonsPlugin() {
        const pluginKey = new PluginKey('sideButtons');

        function collectSideButtons(state: EditorState) {
            const positions: SideButton[] = [];
            state.doc.content.forEach((node, offset, index) => {
                positions.push({
                    nodeIdx: index,
                    domElement: document.querySelector(`.side-button-marker[data-node-index="${index}"]`) as HTMLElement
                });
            });
            return positions;
        }

        return new Plugin({
            key: pluginKey,
            state: {
                init(config, instance) {
                    const state = instance;
                    if (!state) return DecorationSet.empty;
                    const sideBtnPositions = collectSideButtons(state);
                    const initialDecorations = generateDecorations(sideBtnPositions, state.doc);
                    return DecorationSet.create(state.doc, initialDecorations);
                },
                apply(tr, value, oldState, newState) {
                    if (tr.docChanged) {
                        const sideBtnPositions = collectSideButtons(newState);
                        const decorations = generateDecorations(sideBtnPositions, newState.doc);
                        return DecorationSet.create(newState.doc, decorations);
                    }
                    return value;
                }
            },

            props: {
                decorations: state => {
                    return pluginKey.getState(state) as DecorationSet;
                }
            }
        });
    }

    const PdfExportTool = usePdfExportTool(exportTitle);
    return (
        <>
            <Editor
                ref={editorRef}
                className="full-page-document-editor !k-border-0 k-h-full"
                tools={readonly ? additionalTools : [[Undo, Redo], [SectionTitleTool, QuestionTool], PdfExportTool, ...(additionalTools ?? [])]}
                onPasteHtml={(event: EditorPasteEvent) => {
                    let html = pasteCleanup(sanitize(event.pastedHtml), pasteSettings);
                    const fragment = htmlToFragment(html);

                    const headings = fragment.querySelectorAll('h1, h3, h4, h5, h6');
                    headings.forEach(heading => {
                        const h2 = document.createElement('h2');
                        h2.innerHTML = heading.innerHTML;
                        heading.parentNode?.replaceChild(h2, heading);
                    });

                    html = fragmentToHtml(fragment);

                    // If the pasted HTML contains images with sources pointing to the local file system,
                    // `replaceImageSourcesFromRtf` will extract the sources from the RTF and place them to images 'src' attribute in base64 format.
                    if (event.nativeEvent.clipboardData) {
                        html = replaceImageSourcesFromRtf(html, event.nativeEvent.clipboardData);
                    }

                    return html;
                }}
                defaultContent="<p>Select any text and Bold, Italic and Underline tools will appear above the selection.</p>"
                defaultEditMode="div"
                onMount={onMount}
            />

            {editorViewRef && <SideButtons viewRef={editorViewRef} sideButtons={sideButtons} />}
        </>
    );
});

const SideMenuButton = ({ viewRef, sideButton }: { viewRef: React.RefObject<EditorView>; sideButton: SideButton }) => {
    const view = viewRef.current;

    if (!view) return null;

    function createNodeSelection(view: EditorView) {
        const doc = view.state.doc;
        const nodeIdx = nodePositionInDocByIndex(doc, sideButton.nodeIdx);
        if (nodeIdx === null) return;
        const selection = NodeSelection.create(doc, nodeIdx);
        view.dispatch(view.state.tr.setSelection(selection));
    }

    const ctrlKey = isMacOs ? '⌘' : 'Ctrl';
    const shiftKey = isMacOs ? '⇧' : 'Shift';

    const actions: DropDownButtonItem[] = [
        {
            action: () => {
                switchHeadingAndQuestionAtSelection(true, view);
            },
            children: <SideMenuButtonContent text="Format as Section" sideText={`${ctrlKey}+/`} />
        },
        {
            action: () => {
                switchHeadingAndQuestionAtSelection(false, view);
            },
            children: <SideMenuButtonContent text="Format as Question" sideText={`${ctrlKey}+${shiftKey}+/`} />
        },
        { separated: true, action: () => duplicateNodeAtSelection(view), children: <SideMenuButtonContent text="Duplicate" sideText={`${ctrlKey}+D`} /> },
        {
            danger: true,
            separated: true,
            action: () => deleteNodeAtSelection(view),
            children: <SideMenuButtonContent text="Delete" sideText={`Del`} />
        }
    ];

    return (
        <BoundDropDownButton
            popupSettings={{
                popupClass: 'interview-script-document-side-popup',
                anchorAlign: { horizontal: 'left', vertical: 'center' },
                popupAlign: { horizontal: 'right', vertical: 'center' }
            }}
            items={actions}
            size="small"
            onOpen={() => {
                createNodeSelection(view);
            }}
            fillMode="flat"
            icon="more-horizontal"
            className="side-menu-button k-mr-0.5"
        />
    );
};

function nodePositionInDocByNode(doc: Node, node: Node): number | null {
    let nodePositionInDoc: number | null = null;
    doc.nodesBetween(0, doc.content.size, (curNode, pos, parent, index) => {
        if (curNode === node) {
            nodePositionInDoc = pos;
        }

        return false;
    });

    return nodePositionInDoc;
}

function nodePositionInDocByIndex(doc: Node, nodeIdx: number): number | null {
    const node = doc.content.child(nodeIdx);
    return nodePositionInDocByNode(doc, node);
}

const SideMenuButtonContent = ({ text, sideText }: { text: string; sideText?: string }) => {
    return (
        <StackLayout align={{ vertical: 'middle', horizontal: 'start' }} className="k-gap-1 k-justify-content-between k-w-full">
            <span>{text}</span>
            {sideText && (
                <div className="k-icp-subtle-text k-text-right" style={{ width: '56px', fontSize: '9px', lineHeight: '18px' }}>
                    {sideText}
                </div>
            )}
        </StackLayout>
    );
};

const SideButtons = ({ sideButtons, viewRef }: { sideButtons?: SideButton[]; viewRef: React.RefObject<EditorView> }) => {
    const mainPageContentSection = useMemo(() => document.querySelector('.k-window'), []);
    if (!sideButtons) return null;
    if (!mainPageContentSection) return null;
    return (
        <PopupPropsNestedContextProvider value={props => ({ ...props, collision: { horizontal: 'flip', vertical: 'fit' } })}>
            {sideButtons.map((sideBtn, index) => {
                if (!sideBtn.domElement) return null;

                return ReactDOM.createPortal(<SideMenuButton sideButton={sideBtn} viewRef={viewRef} />, sideBtn.domElement, `side-button-${index}`);
            })}
        </PopupPropsNestedContextProvider>
    );
};

const QuestionTool = (props: any) => {
    const { view }: { view: EditorView } = props;
    const onClick = () => {
        formatBlockElements('p', 'QuestionTool')(view.state, view.dispatch);
        view.focus();
    };
    return <Button onClick={onClick}>Question</Button>;
};

const SectionTitleTool = (props: any) => {
    const { view }: { view: EditorView } = props;

    const onClick = () => {
        formatBlockElements('h2', 'SectionTitleTool')(view.state, view.dispatch);
        view.focus();

        // EditorUtils.applyInlineStyle(view, { style: 'background-color', value: colorToApply });
    };

    return <Button onClick={onClick}>Section title</Button>;
};
