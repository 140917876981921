import { Slide } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AIAvatar } from '../../components/ai/aiAvatar';
import { canvasItemsZoneDependent } from '../../components/canvas/canvasItemsZone';
import { CanvasItemInContextSimpleView } from '../../components/canvas/customerSegmentInContextSimpleView';
import { interviewTypeToLabelMap } from '../../components/interview2/common';
import {
    EditorToolProps,
    InterviewScriptDocumentEditor,
    InterviewScriptDocumentEditorRef
} from '../../components/interviewScript/InterviewScriptDocumentEditor';
import { StartupMembershipList } from '../../components/startup/startupMembershipList';
import { ClickableStackLayout } from '../../components/ui/clickableStackLayout';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { TypingDots } from '../../components/ui/typingDots';
import { useAsRef } from '../../hooks/commonHooks';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { ChangesStatus, useTextEditorSaveIndicator } from '../../hooks/textEditorSaveIndicator';
import { DEFAULT_THROTTLE_TIMEOUT_MS, useThrottledFetch } from '../../hooks/useThrottledFetch';
import { ReactComponent as InterviewScriptIcon } from '../../icons/script.svg';
import failedInterviewScriptIllustrationUrl from '../../images/failed-interview-script-illustration.svg';
import { BoxType } from '../../services/canvasService';
import { documentsService, EditorDocument } from '../../services/documentsService';
import { domService } from '../../services/domService';
import { InterviewScript2, interviewScripts2Service } from '../../services/interviewScripts2Service';
import { Interview2Type } from '../../services/interviewsV2Service';
import { RealTimeUpdateInterviewScript2Data, RealTimeUpdateInterviewScript2UpdateData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { buildUserViewModel, ReducedUser, UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';
import {
    TextEditorBookmarksList,
    TextEditorInfo,
    TextEditorInfoSection,
    TextEditorModal,
    TextEditorNameEditor,
    TextEditorUserActionDate
} from '../common/textEditorModal';
import { BookmarkTitle } from '../ui/richTextEditor/common';

export const InterviewScriptModal = canvasItemsZoneDependent(function InterviewScriptModal({
    ideaId,
    scriptId,
    onClose
}: {
    ideaId: string;
    scriptId: number;
    onClose?: () => void;
}) {
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canEditInterviewScript = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const [bookmarkTitles, setBookmarkTitles] = useState<BookmarkTitle[] | undefined>(undefined);
    const userId = useAppSelector(state => state.user?.userId);
    const [editorDocument, setEditorDocument] = useState<EditorDocument | undefined>(undefined);
    const [selectedSectionIdx, setSelectedSectionIdx] = useState<number>(0);
    const [script, setScript] = useState<InterviewScript2>();
    const { indicator: interviewSaveIndicator, indicatorRef: interviewSaveIndicatorRef } = useTextEditorSaveIndicator();

    const dispatch = useAppDispatch();

    const interviewScriptDocumentEditorRef = useRef<InterviewScriptDocumentEditorRef>(null);

    const fetchScript = useCallback(() => {
        interviewScripts2Service.getInterviewScript(ideaId, scriptId).then(setScript);
    }, [ideaId, scriptId]);

    const { throttledFetch: throttledFetchScriptOnAutoSave } = useThrottledFetch(fetchScript);

    useEffect(() => {
        fetchScript();
    }, [fetchScript]);

    const documentIdToLoad = script ? (script.ready ? script.documentId : script.initialDocumentId) : undefined;
    useEffect(() => {
        if (typeof documentIdToLoad !== 'number') return;

        documentsService.getDocument(ideaId, documentIdToLoad).then(setEditorDocument);
    }, [documentIdToLoad, ideaId]);

    const registerContribution = useEnsureCurrentUserAsContributor(ideaId, userId, canEditInterviewScript ? script : undefined, contributors =>
        setScript(s => (s ? { ...s, contributors } : s))
    );

    useEffect(() => {
        let getScriptTimeoutId: NodeJS.Timer | undefined;

        function getScript(delayed?: boolean) {
            if (delayed) {
                if (getScriptTimeoutId) return;
                getScriptTimeoutId = setTimeout(() => getScript(false), DEFAULT_THROTTLE_TIMEOUT_MS);
            } else {
                if (getScriptTimeoutId) {
                    clearTimeout(getScriptTimeoutId);
                    getScriptTimeoutId = undefined;
                }

                fetchScript();
            }
        }

        function onUpdateScript(e: RealTimeUpdateInterviewScript2UpdateData) {
            if (e.ideaId !== ideaId || e.interviewScriptId !== scriptId) return;

            getScript(e.lowPriority);
        }

        function onDeleteScript(e: RealTimeUpdateInterviewScript2Data) {
            if (e.ideaId !== ideaId || e.interviewScriptId !== scriptId) return;

            dispatch(addNotification({ content: 'The script was deleted' }));
            onClose?.();
        }

        realTimeUpdatesEventHub.addEventListener('interview3', 'scriptUpdate', onUpdateScript);
        realTimeUpdatesEventHub.addEventListener('interview3', 'scriptDelete', onDeleteScript);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview3', 'scriptUpdate', onUpdateScript);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'scriptDelete', onDeleteScript);
            if (getScriptTimeoutId) {
                clearTimeout(getScriptTimeoutId);
                getScriptTimeoutId = undefined;
            }
        };
    }, [dispatch, ideaId, onClose, scriptId, fetchScript]);

    const handleDocumentNameUpdate = useCallback(
        async (name: string) => {
            if (!ideaId || scriptId === undefined) throw new Error('Invalid state');
            const script = await interviewScripts2Service.updateInterviewScriptName(ideaId, scriptId, name);
            setScript(script);
        },
        [ideaId, scriptId]
    );

    const hasScriptError = !!script && script.error;

    return (
        <TextEditorModal
            mainContent={
                <>
                    {hasScriptError ? (
                        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-2 k-h-full k-p-4">
                            <img src={failedInterviewScriptIllustrationUrl} width="64" height="65" alt="Failed interview script" className="k-mb-2" />
                            <strong className="k-fs-lg">Script not initialized</strong>
                            <div>We were unable to generate your interview script. Go back and try again.</div>
                        </StackLayout>
                    ) : editorDocument && currentUserRole ? (
                        <InterviewScriptDocumentEditor
                            key={editorDocument.id}
                            ref={interviewScriptDocumentEditorRef}
                            ideaId={ideaId}
                            editorDocument={editorDocument}
                            handleSectionIdxChanged={setSelectedSectionIdx}
                            handleNewTitles={setBookmarkTitles}
                            onSavingStatusChange={changesStatus => {
                                interviewSaveIndicatorRef.current?.onSaveStatusChange && interviewSaveIndicatorRef.current.onSaveStatusChange(changesStatus);
                                if (changesStatus === ChangesStatus.ChangesSaved) {
                                    registerContribution();
                                    throttledFetchScriptOnAutoSave();
                                }
                            }}
                            exportTitle={script?.name}
                            readonly={!canEditInterviewScript || editorDocument.id === script?.initialDocumentId}
                            additionalTools={editorDocument.id === script?.initialDocumentId ? [AIDocumentGenerationTool] : undefined}
                        />
                    ) : (
                        <LoadingIndicator size="big" className="k-centered" />
                    )}
                </>
            }
            leftSideContent={
                <>
                    {!hasScriptError && (
                        <TextEditorInfoSection title="Script sections" relaxed>
                            <TextEditorBookmarksList
                                bookmarks={bookmarkTitles}
                                selectedSectionIdx={selectedSectionIdx}
                                onBookmarkSelected={interviewScriptDocumentEditorRef.current?.selectSection}
                            />
                        </TextEditorInfoSection>
                    )}
                </>
            }
            rightSideContent={
                <div>
                    <StackLayout
                        orientation="vertical"
                        align={{ horizontal: 'stretch', vertical: 'top' }}
                        className="k-p-4 k-pl-6 k-gap-6 k-icp-component-border -maxw1"
                    >
                        <TextEditorInfoSection title="Script details">
                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                                <TextEditorInfo title="Interview duration">
                                    {script ? (
                                        script.durationInMinutes ? (
                                            `${script.durationInMinutes} min`
                                        ) : (
                                            'Not available'
                                        )
                                    ) : (
                                        <Skeleton shape="text" style={{ width: 40 }} />
                                    )}
                                </TextEditorInfo>
                                <TextEditorInfo title="Interview type">
                                    {script ? (
                                        `${interviewTypeToLabelMap[script.interviewType]} Interviews`
                                    ) : (
                                        <Skeleton shape="text" style={{ width: '100%' }} />
                                    )}
                                </TextEditorInfo>
                            </StackLayout>
                        </TextEditorInfoSection>
                        <div className="k-separator" />
                        <TextEditorInfoSection title="Customer Segment">
                            <CanvasItemInContextSimpleView box={BoxType.CustomerSegments} itemId={script?.customerSegmentId} showLoading size="mediumsmall" />
                        </TextEditorInfoSection>
                        {script && typeof script.initialDocumentId === 'number' && script.ready && (
                            <>
                                <div className="k-separator" />
                                <TextEditorInfoSection title="Initial script">
                                    <InitialInterviewScriptModalViewer
                                        ideaId={ideaId}
                                        scriptId={scriptId}
                                        interviewType={script.interviewType}
                                        initialDocumentId={script.initialDocumentId}
                                        onScriptReset={canEditInterviewScript ? setScript : undefined}
                                    />
                                </TextEditorInfoSection>
                            </>
                        )}
                        <div className="k-separator" />
                        <TextEditorInfoSection title="Contributors">
                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                                {script ? (
                                    <StartupMembershipList
                                        users={script.contributors.map(contributor => buildUserViewModel(contributor))}
                                        className="k-align-self-start"
                                    />
                                ) : (
                                    <Skeleton shape="circle" style={{ width: 40, height: 40 }} />
                                )}
                                <TextEditorInfo title="Created">
                                    <TextEditorUserActionDate date={script?.createdOn} user={script?.createdBy} />
                                </TextEditorInfo>
                                {script && script.updatedOn && (
                                    <TextEditorInfo title="Last modified">
                                        <TextEditorUserActionDate date={script.updatedOn} user={script?.createdBy} />
                                    </TextEditorInfo>
                                )}
                            </StackLayout>
                        </TextEditorInfoSection>
                    </StackLayout>
                </div>
            }
            textDocumentId={documentIdToLoad}
            title={script?.name}
            titleIcon={InterviewScriptIcon}
            canEdit={canEditInterviewScript}
            saveIndicator={interviewSaveIndicator}
            handleDocumentNameUpdate={handleDocumentNameUpdate}
            onClose={() => {
                (!interviewSaveIndicatorRef.current?.hasPendingChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) &&
                    onClose?.();
            }}
        />
    );
});

function useEnsureCurrentUserAsContributor(
    ideaId: string,
    currentUserId?: string,
    script?: InterviewScript2,
    onUpdatedContributors?: (contributors: ReducedUser[]) => void
) {
    const shouldAppendContributor = currentUserId !== undefined && script !== undefined && !script.contributors.some(c => c.userId === currentUserId);
    const currentUserAppendedRef = useRef(false);
    const hasContributionRef = useRef(false);

    const onUpdatedContributorsRef = useAsRef(onUpdatedContributors);
    const ensureCurrentUserAsContributor = useCallback(
        async function ensureCurrentUserAsContributor() {
            if (currentUserAppendedRef.current || !shouldAppendContributor) return;

            currentUserAppendedRef.current = true;
            try {
                const contributors = await interviewScripts2Service.appendCurrentUserAsContributor(ideaId, script.id);
                onUpdatedContributorsRef.current?.(contributors);
            } catch (e) {
                currentUserAppendedRef.current = false;
                throw e;
            }
        },
        [ideaId, onUpdatedContributorsRef, script?.id, shouldAppendContributor]
    );

    useEffect(() => {
        if (!shouldAppendContributor || !hasContributionRef.current) return;

        ensureCurrentUserAsContributor();
    }, [ensureCurrentUserAsContributor, shouldAppendContributor]);

    return function onContribution() {
        hasContributionRef.current = true;
        if (shouldAppendContributor) ensureCurrentUserAsContributor();
    };
}

function InitialInterviewScriptModalViewer({
    ideaId,
    scriptId,
    interviewType,
    initialDocumentId,
    onScriptReset
}: {
    ideaId: string;
    scriptId: number;
    interviewType: Interview2Type;
    initialDocumentId: number;
    onScriptReset?: (script: InterviewScript2) => void;
}) {
    const [viewInitialDocument, setViewInitialDocument] = useState<boolean>();
    const title = `Script for ${interviewTypeToLabelMap[interviewType]} interviews`;

    return (
        <>
            <ClickableStackLayout
                align={{ horizontal: 'start', vertical: 'middle' }}
                className="k-gap-2 k-p-1 k-border k-border-solid k-icp-component-border k-cursor-pointer k-rounded k-fs-sm k-icp-hover-bg-base-4"
                onClick={() => setViewInitialDocument(true)}
            >
                <InterviewScriptIcon className="k-icp-icon k-w-8 k-h-8 k-shrink-0" />
                <span>{title}</span>
            </ClickableStackLayout>
            {viewInitialDocument && (
                <InitialInterviewScriptDocumentModal
                    ideaId={ideaId}
                    title={title}
                    documentId={initialDocumentId}
                    onClose={() => setViewInitialDocument(undefined)}
                    onReset={
                        onScriptReset &&
                        (async () => {
                            const resetScript = await interviewScripts2Service.resetInterviewScript(ideaId, scriptId);
                            setViewInitialDocument(undefined);
                            onScriptReset(resetScript);
                        })
                    }
                />
            )}
        </>
    );
}

function InitialInterviewScriptDocumentModal({
    ideaId,
    title,
    documentId,
    onReset,
    onClose
}: {
    ideaId: string;
    title: string;
    documentId: number;
    onReset?: () => Promise<void>;
    onClose: () => void;
}) {
    const [document, setDocument] = useState<EditorDocument>();
    const { show: showResetDialog, element: resetDialog } = useConfirmDialog();
    const [hideModal, setHideModal] = useState<boolean>();

    useEffect(() => {
        documentsService.getDocument(ideaId, documentId).then(setDocument);
    }, [documentId, ideaId]);

    useEffect(() => {
        function closeOnEscape(e: KeyboardEvent) {
            if (e.key !== 'Escape') return;
            e.stopPropagation();
            setHideModal(true);
        }

        window.addEventListener('keydown', closeOnEscape, { capture: true });

        return () => window.removeEventListener('keydown', closeOnEscape, { capture: true });
    }, []);

    return (
        <Slide
            direction="left"
            appear
            className="k-pos-fixed k-top-0 k-bottom-0 k-right-0 k-w-1/2 !k-overflow-visible"
            componentChildClassName="k-w-full k-h-full"
            onExited={onClose}
        >
            {!hideModal && (
                <StackLayout
                    orientation="vertical"
                    align={{ horizontal: 'stretch', vertical: 'top' }}
                    className="k-content k-icp-shadow-md k-icp-dialog-with-title-shadow k-w-full k-h-full"
                >
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-window-titlebar k-justify-content-between">
                        <TextEditorNameEditor icon={InterviewScriptIcon} name={title} />

                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                            {onReset && (
                                <Button
                                    onClick={() =>
                                        showResetDialog({
                                            title: 'Reset script',
                                            content: 'Are you sure you want to reset this script to its initial version?',
                                            confirmButtonText: 'Reset script',
                                            callback: onReset
                                        })
                                    }
                                >
                                    <span className="k-text-error">Reset to this script</span>
                                </Button>
                            )}
                            <Button type="button" icon="x" fillMode="flat" onClick={() => setHideModal(true)} />
                        </StackLayout>
                    </StackLayout>

                    {document ? (
                        <InterviewScriptDocumentEditor ideaId={ideaId} editorDocument={document} readonly />
                    ) : (
                        <div className="k-px-8 k-py-7 ProseMirror k-flex-1 k-overflow-auto">
                            <InterviewScriptSectionLoader />
                            <InterviewScriptSectionLoader />
                        </div>
                    )}
                    {resetDialog}
                </StackLayout>
            )}
        </Slide>
    );
}

export function InterviewScriptSectionLoader() {
    return (
        <>
            <h2>
                <Skeleton shape="text" />
            </h2>
            <p>
                <Skeleton shape="text" />
                <Skeleton shape="text" />
                <Skeleton shape="text" style={{ width: '60%' }} />
            </p>
        </>
    );
}

function AIDocumentGenerationTool({ view }: EditorToolProps) {
    const shouldScrollToBottom = !!view && view.dom.scrollHeight > view.dom.clientHeight && view.dom.scrollTop < domService.getMaxScrollTop(view.dom);
    if (shouldScrollToBottom) view.dom.scrollTop = domService.getMaxScrollTop(view.dom);

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
            <AIAvatar animate />
            <strong>
                Generating interview script
                <TypingDots alwaysUseSpace />
            </strong>
        </StackLayout>
    );
}
