import { Editor } from '@progress/kendo-react-editor';
import { Node } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';
import { DirectEditorProps, EditorView } from 'prosemirror-view';
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { ResponsiveGroup, useAsRef, useResponsiveLayout } from '../../hooks/commonHooks';
import { ChangesStatus } from '../../hooks/textEditorSaveIndicator';
import { EditorDocument } from '../../services/documentsService';
import { collab, useDocumentCollabBehavior } from '../ui/richTextEditor/collabPlugin';
import { useCollapseToolbarTool } from '../ui/richTextEditor/collapseToolbarTool';
import { collectTitlesPlugin } from '../ui/richTextEditor/collectTitlesPlugin';
import {
    AlignCenterEnhanced,
    AlignJustifyEnhanced,
    AlignLeftEnhanced,
    AlignRightEnhanced,
    BoldEnhanced,
    BookmarkTitle,
    FontSizeEnhanced,
    FormatBlockEnhanced,
    InsertTableEnhanced,
    ItalicEnhanced,
    LinkEnhanced,
    OrderedListEnhanced,
    RedoEnhanced,
    scrollToHeading,
    StrikethroughEnhanced,
    UnderlineEnhanced,
    UndoEnhanced,
    UnlinkEnhanced,
    UnorderedListEnhanced,
    usePdfExportTool
} from '../ui/richTextEditor/common';
import useToolbarOptionsPlugin from '../ui/richTextEditor/useToolbarOptionsPlugin';

export interface NotesDocumentEditorRef {
    selectSection: (sectionIdx: number) => void;
}

interface NotesDocumentEditorProps {
    ideaId: string;
    editorDocument: EditorDocument;
    handleNewTitles?: (titles: BookmarkTitle[]) => void;
    handleSectionIdxChanged?: (sectionIdx: number) => void;
    onSavingStatusChange?: (status: ChangesStatus) => void;
    exportTitle?: string;
    readonly?: boolean;
}

export const NotesDocumentEditor = forwardRef<NotesDocumentEditorRef, NotesDocumentEditorProps>((props, ref) => {
    const { ideaId, editorDocument, handleNewTitles, handleSectionIdxChanged, onSavingStatusChange, exportTitle, readonly } = props;
    const editorViewRef = useRef<EditorView | null>(null);
    const { onDispatchTransaction: collabOnDispatchTransaction } = useDocumentCollabBehavior(editorViewRef, ideaId, editorDocument, onSavingStatusChange);
    const readonlyRef = useAsRef(readonly);
    const PdfExportTool = usePdfExportTool(exportTitle);
    const editorRef = useRef<Editor>(null);
    const { toolbarOptionsPlugin, renderTools } = useToolbarOptionsPlugin(editorRef);
    const indentingTools = useMemo(() => [AlignLeftEnhanced, AlignCenterEnhanced, AlignRightEnhanced, AlignJustifyEnhanced], []);
    const linksTools = useMemo(() => [LinkEnhanced, UnlinkEnhanced], []);
    const insertListTools = useMemo(() => [OrderedListEnhanced, UnorderedListEnhanced], []);
    const insertTableAndPDFTools = useMemo(() => [InsertTableEnhanced, PdfExportTool], [PdfExportTool]);
    const responsiveGroup = useResponsiveLayout();
    const moveThirdLargestItemsInShrinkedToolbar =
        responsiveGroup !== ResponsiveGroup.xxl && responsiveGroup !== ResponsiveGroup.xl && responsiveGroup !== ResponsiveGroup.lg;
    const moveSecondLargestItemsInShrinkedToolbar = responsiveGroup !== ResponsiveGroup.xxl && responsiveGroup !== ResponsiveGroup.xl;
    const moveXXLItemsInShrinkedToolbar = responsiveGroup !== ResponsiveGroup.xxl;

    useImperativeHandle(
        ref,
        () => ({
            selectSection: (sectionIdx: number) => {
                if (!editorViewRef.current) return;
                scrollToHeading(editorViewRef.current, sectionIdx);
            }
        }),
        []
    );

    const shrinkedToolbarTools = useMemo(() => {
        const shrinkedToolbarTools = [];
        if (moveThirdLargestItemsInShrinkedToolbar) shrinkedToolbarTools.push(indentingTools);
        if (moveSecondLargestItemsInShrinkedToolbar) shrinkedToolbarTools.push(insertListTools);
        if (moveXXLItemsInShrinkedToolbar) shrinkedToolbarTools.push(linksTools, ...insertTableAndPDFTools);
        return shrinkedToolbarTools;
    }, [
        indentingTools,
        insertListTools,
        insertTableAndPDFTools,
        linksTools,
        moveSecondLargestItemsInShrinkedToolbar,
        moveThirdLargestItemsInShrinkedToolbar,
        moveXXLItemsInShrinkedToolbar
    ]);

    const { CollapseToolbarTool } = useCollapseToolbarTool(editorRef, shrinkedToolbarTools);

    const mainToolbarTools = useMemo(() => {
        if (readonlyRef.current) {
            return [];
        }

        const toolbarTools: any[] = [
            [UndoEnhanced, RedoEnhanced],
            FontSizeEnhanced,
            FormatBlockEnhanced,
            [BoldEnhanced, ItalicEnhanced, UnderlineEnhanced, StrikethroughEnhanced]
        ];

        if (!moveThirdLargestItemsInShrinkedToolbar) toolbarTools.push(indentingTools);
        if (!moveSecondLargestItemsInShrinkedToolbar) toolbarTools.push(insertListTools);
        if (!moveXXLItemsInShrinkedToolbar) toolbarTools.push(linksTools, ...insertTableAndPDFTools);
        if (moveXXLItemsInShrinkedToolbar || moveSecondLargestItemsInShrinkedToolbar) toolbarTools.push(CollapseToolbarTool);
        return toolbarTools;
    }, [
        CollapseToolbarTool,
        indentingTools,
        insertListTools,
        insertTableAndPDFTools,
        linksTools,
        moveSecondLargestItemsInShrinkedToolbar,
        moveThirdLargestItemsInShrinkedToolbar,
        moveXXLItemsInShrinkedToolbar,
        readonlyRef
    ]);

    const onMount = (event: { viewProps: DirectEditorProps; dom: any }) => {
        const { viewProps } = event;
        const { plugins, schema } = viewProps.state;
        if (readonly) viewProps.editable = () => !readonlyRef.current;
        const parsedJson = JSON.parse(editorDocument.document);
        const doc = Node.fromJSON(schema, parsedJson);
        const customPlugins = [...plugins, toolbarOptionsPlugin()];
        if (handleNewTitles) customPlugins.push(collectTitlesPlugin(handleNewTitles, handleSectionIdxChanged, true));
        customPlugins.push(collab({ version: editorDocument.docVersion }));
        const editorView = new EditorView(
            {
                mount: event.dom
            },
            {
                ...event.viewProps,
                state: EditorState.create({
                    doc,
                    plugins: customPlugins
                }),

                dispatchTransaction(transaction) {
                    const view = editorViewRef.current;
                    if (!view) return;
                    let newState = view.state.apply(transaction);
                    view.updateState(newState);
                    collabOnDispatchTransaction(newState, view);
                }
            }
        );

        editorView.focus();
        editorViewRef.current = editorView;

        return editorView;
    };

    return (
        <>
            <Editor ref={editorRef} className="full-page-notes-editor !k-border-0 k-h-full" tools={mainToolbarTools} defaultEditMode="div" onMount={onMount} />
            {renderTools()}
        </>
    );
});
