import { StackLayout } from '@progress/kendo-react-layout';
import { combineClassNames, GraphicData } from '../../services/common';
import { FormFieldProps } from './inputs';

export type CardsPickerItem<TValue> = {
    value: TValue;
    label: string;
    image: GraphicData;
};

export function CardsPicker<TValue>({ id, value, onChange, disabled, cards }: FormFieldProps<TValue> & { cards: CardsPickerItem<TValue>[] }) {
    return (
        <StackLayout id={id} align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
            {cards.map((card, index) => {
                const isSelected = card.value === value;
                const canSelectValue = !disabled && Boolean(onChange);
                return (
                    <div key={typeof card.value === 'number' || typeof card.value === 'string' ? card.value : index} className="k-text-center">
                        <div
                            className={combineClassNames(
                                'k-p-4 k-icp-panel k-mb-1',
                                disabled ? 'k-disabled' : undefined,
                                canSelectValue ? 'k-cursor-pointer k-icp-interactive-card-xs' : undefined,
                                isSelected ? '!k-border-secondary' : undefined
                            )}
                            onClick={canSelectValue && !isSelected ? () => onChange!({ value: card.value }) : undefined}
                        >
                            <img src={card.image.url} alt={card.image.description} width={card.image.width} height={card.image.height} />
                        </div>
                        <div className="k-fs-sm">{card.label}</div>
                    </div>
                );
            })}
        </StackLayout>
    );
}
