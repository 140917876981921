import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    TextEditorBookmarksList,
    TextEditorInfo,
    TextEditorInfoSection,
    TextEditorModal,
    TextEditorUserActionDate
} from '../../components/common/textEditorModal';
import { NotesDocumentEditor, NotesDocumentEditorRef } from '../../components/notes/notesDocumentEditor';
import { ManageNoteTagsButton, NoteTagsPicker } from '../../components/notes/noteTags';
import { StartupMembershipList } from '../../components/startup/startupMembershipList';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { BookmarkTitle } from '../../components/ui/richTextEditor/common';
import { useAsRef } from '../../hooks/commonHooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { ChangesStatus, useTextEditorSaveIndicator } from '../../hooks/textEditorSaveIndicator';
import { DEFAULT_THROTTLE_TIMEOUT_MS, useThrottledFetch } from '../../hooks/useThrottledFetch';
import { ReactComponent as NoteIcon } from '../../icons/notes.svg';
import { documentsService, EditorDocument } from '../../services/documentsService';
import { Note, notesService, NoteTag } from '../../services/notesService';
import { RealTimeUpdateNoteEventData, RealTimeUpdateNoteUpdateData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { buildUserViewModel, ReducedUser, UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';

export function NoteEditorModalPage() {
    const navigate = useNavigate();
    const { noteId } = useParams();
    const { ideaId } = useIdeaParams();
    const parsedNoteId = useMemo(() => parseInt(noteId!), [noteId]);
    const currentUserRole = useAppSelector(s => s.idea.role);
    const currentUserId = useAppSelector(s => s.user?.userId);
    const { indicator: notesSaveIndicator, indicatorRef: notesSaveIndicatorRef } = useTextEditorSaveIndicator();
    const canEditNotes = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const [note, setNote] = useState<Note | undefined>(undefined);
    const [editorDocument, setEditorDocument] = useState<EditorDocument | undefined>(undefined);
    const notesDocumentEditorRef = useRef<NotesDocumentEditorRef>(null);
    const [bookmarkTitles, setBookmarkTitles] = useState<BookmarkTitle[] | undefined>(undefined);
    const [selectedSectionIdx, setSelectedSectionIdx] = useState<number>(0);

    const registerContribution = useEnsureCurrentUserAsContributor(ideaId, currentUserId, canEditNotes ? note : undefined, contributors =>
        setNote(n => (n ? { ...n, contributors } : n))
    );

    const fetchNote = useCallback(() => {
        notesService.getNote(ideaId, parsedNoteId).then(setNote);
    }, [ideaId, parsedNoteId]);

    const { throttledFetch: throttledFetchNoteOnAutoSave } = useThrottledFetch(fetchNote);

    const orderedTags = useMemo(() => {
        if (!note?.tagIdsOrder || !note?.tags) return note?.tags;
        return note.tags.sort((a, b) => note.tagIdsOrder.indexOf(a.id) - note.tagIdsOrder.indexOf(b.id));
    }, [note?.tagIdsOrder, note?.tags]);

    useEffect(() => {
        if (!noteId) return;

        fetchNote();
    }, [noteId, fetchNote]);

    useEffect(() => {
        if (typeof note?.documentId !== 'number') return;
        const documentIdToLoad = note.documentId;

        documentsService.getDocument(ideaId, documentIdToLoad).then(setEditorDocument);
    }, [note?.documentId, ideaId]);

    useEffect(() => {
        let getNoteTimeoutId: NodeJS.Timer | undefined;

        function onNoteUpdated(e: RealTimeUpdateNoteUpdateData) {
            if (e.ideaId !== ideaId || e.noteId !== parsedNoteId) return;
            if (e.lowPriority) {
                if (getNoteTimeoutId) return;
                getNoteTimeoutId = setTimeout(() => {
                    fetchNote();
                    getNoteTimeoutId = undefined;
                }, DEFAULT_THROTTLE_TIMEOUT_MS);
            }
            if (e.lowPriority) return;
            fetchNote();
        }

        function onNoteDeleted(e: RealTimeUpdateNoteEventData) {
            if (e.ideaId !== ideaId || e.noteId !== parsedNoteId) return;
            navigate(-1);
        }

        realTimeUpdatesEventHub.addEventListener('notes', 'noteUpdate', onNoteUpdated);
        realTimeUpdatesEventHub.addEventListener('notes', 'noteDelete', onNoteDeleted);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('notes', 'noteUpdate', onNoteUpdated);
            realTimeUpdatesEventHub.removeEventListener('notes', 'noteDelete', onNoteDeleted);
        };
    }, [ideaId, parsedNoteId, navigate, fetchNote]);

    const handleDocumentNameUpdate = async (documentName: string) => {
        const updatedNote = await notesService.updateNote(ideaId, parsedNoteId, { title: documentName });
        setNote(updatedNote);
    };

    const handleTagsChange = async (e: { value: NoteTag[] }) => {
        notesSaveIndicatorRef.current?.onSaveStatusChange?.(ChangesStatus.PendingChanges);
        const updatedNote = await notesService.updateNote(ideaId, parsedNoteId, { tagIds: e.value.map(t => t.id) });
        setNote(updatedNote);
        notesSaveIndicatorRef.current?.onSaveStatusChange?.(ChangesStatus.ChangesSaved);
    };

    return (
        <TextEditorModal
            mainContent={
                editorDocument && currentUserRole ? (
                    <NotesDocumentEditor
                        ref={notesDocumentEditorRef}
                        ideaId={ideaId}
                        editorDocument={editorDocument}
                        handleSectionIdxChanged={setSelectedSectionIdx}
                        handleNewTitles={setBookmarkTitles}
                        onSavingStatusChange={changesStatus => {
                            notesSaveIndicatorRef.current?.onSaveStatusChange && notesSaveIndicatorRef.current.onSaveStatusChange(changesStatus);
                            if (changesStatus === ChangesStatus.ChangesSaved) {
                                registerContribution();
                                throttledFetchNoteOnAutoSave();
                            }
                        }}
                        exportTitle={note?.name}
                        readonly={!canEditNotes}
                    />
                ) : (
                    <LoadingIndicator size="big" className="k-centered" />
                )
            }
            title={note?.name}
            saveIndicator={notesSaveIndicator}
            textDocumentId={note?.documentId}
            handleDocumentNameUpdate={handleDocumentNameUpdate}
            canEdit={canEditNotes}
            leftSideContent={
                <>
                    <TextEditorInfoSection title="Sections" relaxed>
                        <TextEditorBookmarksList
                            bookmarks={bookmarkTitles}
                            selectedSectionIdx={selectedSectionIdx}
                            removeEmptyBookmarks={true}
                            onBookmarkSelected={notesDocumentEditorRef.current?.selectSection}
                        />
                    </TextEditorInfoSection>
                </>
            }
            rightSideContent={
                <div>
                    <StackLayout
                        orientation="vertical"
                        align={{ horizontal: 'stretch', vertical: 'top' }}
                        className="k-p-4 k-pl-6 k-gap-6 k-icp-component-border -maxw1"
                    >
                        <TextEditorInfoSection title="Contributors">
                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                                {note ? (
                                    <StartupMembershipList
                                        users={note.contributors.map(contributor => buildUserViewModel(contributor))}
                                        className="k-align-self-start"
                                    />
                                ) : (
                                    <Skeleton shape="circle" style={{ width: 40, height: 40 }} />
                                )}
                                <TextEditorInfo title="Created">
                                    <TextEditorUserActionDate date={note?.createdOn} user={note?.createdBy} />
                                </TextEditorInfo>
                                {note && note.updatedOn && (
                                    <TextEditorInfo title="Last modified">
                                        <TextEditorUserActionDate date={note.updatedOn} user={note?.createdBy} />
                                    </TextEditorInfo>
                                )}
                            </StackLayout>
                        </TextEditorInfoSection>
                        <TextEditorInfoSection title="Tags">
                            {note ? (
                                <>
                                    <NoteTagsPicker
                                        ideaId={ideaId}
                                        value={orderedTags}
                                        onChange={handleTagsChange}
                                        allowCreate
                                        placeholder="Type to select or create tag..."
                                        disabled={!canEditNotes}
                                    />
                                    <ManageNoteTagsButton ideaId={ideaId} disabled={!canEditNotes} className="k-mt-1" />
                                </>
                            ) : (
                                <>
                                    <Skeleton shape="rectangle" style={{ width: '100%', height: 30 }} />
                                    <Skeleton shape="text" style={{ width: '100px' }} />
                                </>
                            )}
                        </TextEditorInfoSection>
                    </StackLayout>
                </div>
            }
            titleIcon={NoteIcon}
            onClose={() => {
                (!notesSaveIndicatorRef.current?.hasPendingChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) &&
                    navigate('./..');
            }}
        />
    );
}

function useEnsureCurrentUserAsContributor(ideaId: string, currentUserId?: string, note?: Note, onUpdatedContributors?: (contributors: ReducedUser[]) => void) {
    const shouldAppendContributor = currentUserId !== undefined && note !== undefined && !note.contributors.some(c => c.userId === currentUserId);
    const currentUserAppendedRef = useRef(false);
    const hasContributionRef = useRef(false);

    const onUpdatedContributorsRef = useAsRef(onUpdatedContributors);
    const ensureCurrentUserAsContributor = useCallback(
        async function ensureCurrentUserAsContributor() {
            if (currentUserAppendedRef.current || !shouldAppendContributor) return;

            currentUserAppendedRef.current = true;
            try {
                const contributors = await notesService.appendCurrentUserAsContributor(ideaId, note.id);
                onUpdatedContributorsRef.current?.(contributors);
            } catch (e) {
                currentUserAppendedRef.current = false;
                throw e;
            }
        },
        [ideaId, onUpdatedContributorsRef, note?.id, shouldAppendContributor]
    );

    useEffect(() => {
        if (!shouldAppendContributor || !hasContributionRef.current) return;

        ensureCurrentUserAsContributor();
    }, [ensureCurrentUserAsContributor, shouldAppendContributor]);

    return function onContribution() {
        hasContributionRef.current = true;
        if (shouldAppendContributor) ensureCurrentUserAsContributor();
    };
}
