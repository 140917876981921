import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BoundDropDownButton, DropDownButtonItem } from '../../components/common/boundDropDownButton';
import { InterviewsLayout, interviewStageLabelMap, interviewStageToUrlPathMap, RouteToInterviewsDefaultTab } from '../../components/interview2/common';
import { InterviewsGrid } from '../../components/interview2/interviewsGridComponents';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { Pager } from '../../components/ui/pager';
import { TextMarker } from '../../components/ui/textMarker';
import { H1 } from '../../components/ui/typography';
import { InterviewsListProvider, useInterviews, useInterviewsCounts, useInterviewsListPreferencesContext } from '../../hooks/interviews2Hooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { InterviewsCounts, InterviewStageV2 } from '../../services/interviewsV2Service';
import { UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { TabbedLayout, TabHeader, useTabbedLayoutData } from '../layouts/tabbedLayout';

export function InterviewsPage() {
    const { ideaId } = useIdeaParams();
    const navigate = useNavigate();
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canExecuteActions = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const interviewsCounts = useInterviewsCounts(ideaId);

    const tabs: TabHeader[] = [
        {
            title: interviewStageLabelMap[InterviewStageV2.NotStarted],
            to: interviewStageToUrlPathMap[InterviewStageV2.NotStarted],
            count: interviewsCounts?.notStartedCount
        },
        {
            title: interviewStageLabelMap[InterviewStageV2.InProgress],
            to: interviewStageToUrlPathMap[InterviewStageV2.InProgress],
            count: interviewsCounts?.inProgressCount
        },
        {
            title: interviewStageLabelMap[InterviewStageV2.Completed],
            to: interviewStageToUrlPathMap[InterviewStageV2.Completed],
            count: interviewsCounts?.completeCount
        }
    ];

    const dropdownItems: DropDownButtonItem[] = [
        { text: 'Upload an interview', action: () => navigate('upload') },
        { text: 'Upload multiple interviews', action: () => navigate('upload-multiple') }
    ];

    return (
        <InterviewsListProvider>
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="-minh100">
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-8 heading-row">
                    <TextMarker text="Early Preview">
                        <H1>Interviews</H1>
                    </TextMarker>
                    {canExecuteActions && (
                        <>
                            <div className="vertical-separator" />
                            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                                <Button themeColor="primary" size="large" onClick={() => navigate('create')}>
                                    <StackLayout className="k-gap-1">
                                        <PlusIcon className="k-icp-icon" />
                                        New interview
                                    </StackLayout>
                                </Button>
                                <BoundDropDownButton
                                    size="large"
                                    popupSettings={{
                                        popupAlign: { horizontal: 'left', vertical: 'top' },
                                        anchorAlign: { horizontal: 'left', vertical: 'bottom' }
                                    }}
                                    text={
                                        <StackLayout className="k-gap-1">
                                            <PlusIcon className="k-icp-icon" />
                                            Upload interview
                                        </StackLayout>
                                    }
                                    items={dropdownItems}
                                />
                            </StackLayout>
                        </>
                    )}
                </StackLayout>
                <TabbedLayout headers={tabs} data={interviewsCounts} />
            </StackLayout>
        </InterviewsListProvider>
    );
}

export function NotStartedInterviewsTabPage() {
    const { ideaId } = useIdeaParams();
    const { listPreferences, updateListPreferences } = useInterviewsListPreferencesContext();
    const [skip, setSkip] = useState(0);
    const [notStartedInterviews] = useInterviews(ideaId, skip, listPreferences.pageSize, InterviewStageV2.NotStarted);

    if (!notStartedInterviews) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    return (
        <InterviewsLayout
            emptyMessage="Start a new interview or upload a transcript from a past one to get insights."
            empty={notStartedInterviews.totalCount <= 0}
        >
            <InterviewsGrid
                interviews={notStartedInterviews.interviews}
                showHost
                showType
                showViewInterview
                pagerComponent={
                    <Pager
                        total={notStartedInterviews.totalCount}
                        skip={skip}
                        take={listPreferences.pageSize}
                        onPageChange={(skip, take) => {
                            setSkip(skip);
                            updateListPreferences({ pageSize: take });
                        }}
                    />
                }
            />
        </InterviewsLayout>
    );
}

export function InProgressInterviewsPage() {
    const { ideaId } = useIdeaParams();
    const { listPreferences, updateListPreferences } = useInterviewsListPreferencesContext();
    const [skip, setSkip] = useState(0);
    const [inProgressInterviews] = useInterviews(ideaId, skip, listPreferences.pageSize, InterviewStageV2.InProgress);

    if (!inProgressInterviews) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    return (
        <InterviewsLayout emptyMessage="Your ongoing interviews will appear here as they happen." empty={inProgressInterviews.totalCount <= 0}>
            <InterviewsGrid
                interviews={inProgressInterviews.interviews}
                showHost
                showType
                showProgress
                showDate
                showViewInterview
                pagerComponent={
                    <Pager
                        total={inProgressInterviews.totalCount}
                        skip={skip}
                        take={listPreferences.pageSize}
                        onPageChange={(skip, take) => {
                            setSkip(skip);
                            updateListPreferences({ pageSize: take });
                        }}
                    />
                }
            />
        </InterviewsLayout>
    );
}

export function CompletedInterviewsPage() {
    const { ideaId } = useIdeaParams();
    const { listPreferences, updateListPreferences } = useInterviewsListPreferencesContext();
    const [skip, setSkip] = useState(0);
    const [completedInterviews] = useInterviews(ideaId, skip, listPreferences.pageSize, InterviewStageV2.Completed);

    if (!completedInterviews) {
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );
    }

    return (
        <InterviewsLayout
            emptyMessage="Once an interview is finished, you’ll find it here, ready for review with all key insights extracted."
            empty={completedInterviews.totalCount <= 0}
        >
            <InterviewsGrid
                interviews={completedInterviews.interviews}
                showType
                showDate
                showScore
                showViewInterview
                pagerComponent={
                    <Pager
                        total={completedInterviews.totalCount}
                        skip={skip}
                        take={listPreferences.pageSize}
                        onPageChange={(skip, take) => {
                            setSkip(skip);
                            updateListPreferences({ pageSize: take });
                        }}
                    />
                }
            />
        </InterviewsLayout>
    );
}

export function RouteToInterviewsDefaultTabPage() {
    const interviewsCounts = useTabbedLayoutData<InterviewsCounts | undefined>();
    return <RouteToInterviewsDefaultTab interviewsCounts={interviewsCounts} />;
}
