import { Card, CardBody, CardSubtitle, StackLayout } from '@progress/kendo-react-layout';
import { PopupPropsContext } from '@progress/kendo-react-popup';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { combineClassNames } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import { buildUserViewModel, ReducedUser } from '../../services/usersService';
import { CustomerSegmentCircleView } from '../canvas/customerSegmentInContextSimpleView';
import { BoundDropDownButton, DropDownButtonItem } from '../common/boundDropDownButton';
import { StartupMembershipList } from '../startup/startupMembershipList';

export function ResearchCard({
    icon,
    statusText,
    title,
    subtitle,
    customerSegmentId,
    lastEdited,
    linkTo,
    actions,
    contributors,
    disabled
}: {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    statusText: string;
    title: string;
    subtitle: ReactNode;
    linkTo: string;
    customerSegmentId: number;
    lastEdited: Date;
    actions: DropDownButtonItem[];
    contributors: ReducedUser[];
    disabled?: boolean;
}) {
    const Icon = icon;
    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const clickedElement = e.target as HTMLElement;
        if (!clickedElement) return;
        if (!e.currentTarget.contains(clickedElement) || clickedElement.closest('.card-actions-area')) e.preventDefault();
    };

    return (
        <Link className={combineClassNames('k-link', disabled ? 'k-disabled' : undefined)} to={linkTo} onClick={disabled ? undefined : onClick}>
            <Card className="k-icp-interactive-card">
                <CardBody>
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-mb-4">
                        <div className="k-rounded-full k-p-2 k-icp-bg-primary-30">
                            <Icon className="k-icp-icon k-icp-icon-size-4" />
                        </div>
                        <div className="k-flex-1 k-fs-xs k-text-uppercase">{statusText}</div>
                        <CustomerSegmentCircleView customerSegmentId={customerSegmentId} />
                    </StackLayout>
                    <div className="k-card-title max-lines-2 !k-mb-1" title={title}>
                        {title}
                    </div>
                    <CardSubtitle className="!k-mb-4">{subtitle} </CardSubtitle>
                    <StackLayout align={{ vertical: 'middle', horizontal: 'start' }} className="k-justify-content-between k-gap-2 k-flex-nowrap">
                        {contributors.length ? (
                            <PopupPropsContext.Provider value={p => ({ ...p, appendTo: document.body })}>
                                <StartupMembershipList
                                    users={contributors.map(contributor => buildUserViewModel(contributor))}
                                    className="k-icp-avatar-compact-list-sm"
                                    avatarSize="small"
                                    maxCount={8}
                                />
                            </PopupPropsContext.Provider>
                        ) : (
                            <div />
                        )}

                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                            <span className="k-icp-text-smaller k-icp-subtle-text">Edited {dateTimeService.stringifyDuration(new Date(), lastEdited)}</span>
                            <div className="k-separator" />
                            <BoundDropDownButton
                                fillMode="flat"
                                size="small"
                                icon="more-horizontal"
                                popupSettings={{
                                    anchorAlign: { horizontal: 'right', vertical: 'bottom' },
                                    popupAlign: { horizontal: 'right', vertical: 'top' }
                                }}
                                items={actions}
                                className="card-actions-area"
                                disabled={disabled}
                            />
                        </StackLayout>
                    </StackLayout>
                </CardBody>
            </Card>
        </Link>
    );
}
