import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSingleClickCallback } from '../../hooks/commonHooks';
import { ReactComponent as InterviewAddIcon } from '../../icons/interview-add.svg';
import { Interview2Type, InterviewStageV2, interviewsV2Service, MinimalInterviewV2 } from '../../services/interviewsV2Service';
import { researchV2Service } from '../../services/researchV2Service';
import { preventCloseOfParentDialog } from '../common/modal';
import { InterviewsGridPicker } from '../interview2/interviewsGridPicker';
import { FullScreenModalWithIcon } from '../ui/appModal';

export type IncludedInterviewsStats = Record<InterviewStageV2, number>;
export function IncludeExistingInterviewModal({
    ideaId,
    researchId,
    customerSegmentId,
    interviewsType,
    onIncludedInterviews,
    onCancel
}: {
    ideaId: string;
    customerSegmentId?: number;
    researchId: number;
    interviewsType?: Interview2Type;
    onIncludedInterviews?: (stats: IncludedInterviewsStats) => void | Promise<void>;
    onCancel?: () => void;
}) {
    const getInterviewsPromiseRef = useRef<Promise<MinimalInterviewV2[]>>();

    const [selectedInterviews, setSelectedInterviews] = useState<number[]>([]);
    const [interviews, setInterviews] = useState<MinimalInterviewV2[] | undefined>(undefined);

    const [isIncludingInterviews, handleIncludeInterviews] = useSingleClickCallback(async function handleIncludeInterviews() {
        await researchV2Service.updateIncludedInterviewsInResearch(ideaId, researchId, { interviewsToAdd: selectedInterviews });
        const stats: IncludedInterviewsStats = {
            [InterviewStageV2.NotStarted]: 0,
            [InterviewStageV2.InProgress]: 0,
            [InterviewStageV2.Completed]: 0
        };
        interviews?.filter(i => selectedInterviews.includes(i.id)).forEach(i => stats[i.stage]++);
        await onIncludedInterviews?.(stats);
    });

    const fetchInterviews = useCallback(
        async (text?: string) => {
            if (!getInterviewsPromiseRef.current)
                getInterviewsPromiseRef.current = interviewsV2Service
                    .getInterviews(ideaId, undefined, undefined, undefined, undefined, undefined, researchId, customerSegmentId, undefined, interviewsType)
                    .then(r => r.interviews);

            const normalizedSearchText = text?.toLowerCase();
            const getInterviewsPromise = normalizedSearchText
                ? getInterviewsPromiseRef.current.then(interviews =>
                      interviews.filter(interview => {
                          const searchableFields = [
                              interview.title,
                              interview.intervieweeName,
                              interview.contact?.firstName,
                              interview.contact?.lastName,
                              interview.contact?.jobTitle,
                              interview.host?.firstName,
                              interview.host?.lastName
                          ]
                              .filter(Boolean)
                              .map(field => field!.toLowerCase());

                          return searchableFields.some(field => field.includes(normalizedSearchText));
                      })
                  )
                : getInterviewsPromiseRef.current;

            return getInterviewsPromise.then(setInterviews);
        },
        [ideaId, researchId, customerSegmentId, interviewsType]
    );

    useEffect(() => {
        fetchInterviews();
    }, [fetchInterviews]);

    return (
        <FullScreenModalWithIcon
            autoFocus
            title="Include existing interview to Research"
            icon={InterviewAddIcon}
            onClose={e => {
                preventCloseOfParentDialog(e);
                onCancel?.();
            }}
        >
            <StackLayout
                orientation="vertical"
                align={{ horizontal: 'stretch', vertical: 'top' }}
                style={{ minHeight: '70vh' }}
                className="page-content-middle page-content--xxl k-p-4 k-gap-10 k-max-h-full"
            >
                <InterviewsGridPicker
                    interviews={interviews}
                    selectedInterviews={selectedInterviews}
                    handleSearchInterviews={fetchInterviews}
                    onSelectionChange={setSelectedInterviews}
                />
                <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-4">
                    <Button
                        size="large"
                        fillMode="solid"
                        disabled={selectedInterviews.length === 0 || isIncludingInterviews}
                        themeColor="primary"
                        type="submit"
                        style={{ minWidth: 200 }}
                        onClick={handleIncludeInterviews}
                    >
                        Include {selectedInterviews.length > 0 ? selectedInterviews.length : ''} interview{selectedInterviews.length === 1 ? '' : 's'}
                    </Button>
                    <Button size="large" fillMode="solid" themeColor="base" onClick={onCancel}>
                        Cancel
                    </Button>
                </StackLayout>
            </StackLayout>
        </FullScreenModalWithIcon>
    );
}
