import { StackLayout } from '@progress/kendo-react-layout';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { TypingDots } from '../components/ui/typingDots';
import { ReactComponent as SavedIcon } from '../icons/check-circle-closed.svg';
import { ReactComponent as SavingIcon } from '../icons/refresh-cw.svg';
import { debounceWithCancel } from '../services/common';

export enum ChangesStatus {
    PendingChanges = 'PendingChanges',
    SendingChanges = 'SavingChanges',
    ChangesSaved = 'ChangesSaved'
}

type TextEditorSaveIndicatorHandle = {
    onSaveStatusChange?: (status: ChangesStatus) => void;
    readonly hasPendingChanges: boolean;
};
enum TextEditorSaveIndicatorStatus {
    Hidden = 1,
    Saving = 2,
    Saved = 3
}

export function useTextEditorSaveIndicator() {
    const textEditorSaveIndicatorRef = useRef<TextEditorSaveIndicatorHandle>(null);

    useEffect(() => {
        function onLeaving(e: BeforeUnloadEvent) {
            if (textEditorSaveIndicatorRef.current?.hasPendingChanges) e.preventDefault();
        }

        window.addEventListener('beforeunload', onLeaving);

        return () => window.removeEventListener('beforeunload', onLeaving);
    });

    const indicator = <TextEditorSaveIndicator ref={textEditorSaveIndicatorRef} />;

    return { indicator, indicatorRef: textEditorSaveIndicatorRef };
}

const TextEditorSaveIndicator = forwardRef<TextEditorSaveIndicatorHandle>(function TextEditorSaveIndicator(_, ref) {
    const [hideDebounced, cancelHideDebounced] = useMemo(() => debounceWithCancel(() => setStatus(TextEditorSaveIndicatorStatus.Hidden), 2000), []);
    const [showSavedDebounced, cancelShowSavedDebounced] = useMemo(
        () =>
            debounceWithCancel(() => {
                setStatus(TextEditorSaveIndicatorStatus.Saved);
                hideDebounced();
            }, 1000),
        [hideDebounced]
    );
    const [status, setStatus] = useState<TextEditorSaveIndicatorStatus>(TextEditorSaveIndicatorStatus.Hidden);
    const savingChangesCountRef = useRef(0);
    const hasPendingChangesRef = useRef(false);

    useImperativeHandle(
        ref,
        () => ({
            onSaveStatusChange(changesStatus: ChangesStatus) {
                if (changesStatus === ChangesStatus.SendingChanges) savingChangesCountRef.current += 1;
                else if (changesStatus === ChangesStatus.ChangesSaved) savingChangesCountRef.current -= 1;

                if (savingChangesCountRef.current < 0) savingChangesCountRef.current = 0;

                if (changesStatus === ChangesStatus.PendingChanges || savingChangesCountRef.current) {
                    hasPendingChangesRef.current = true;
                    setStatus(TextEditorSaveIndicatorStatus.Saving);
                    cancelShowSavedDebounced();
                    cancelHideDebounced();
                } else {
                    hasPendingChangesRef.current = false;
                    showSavedDebounced();
                    cancelHideDebounced();
                }
            },
            get hasPendingChanges() {
                return hasPendingChangesRef.current;
            }
        }),
        [cancelHideDebounced, cancelShowSavedDebounced, showSavedDebounced]
    );

    if (status === TextEditorSaveIndicatorStatus.Hidden) return null;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
            {status === TextEditorSaveIndicatorStatus.Saving ? (
                <SavingIcon className="k-icp-icon k-icp-icon-size-4 rotating-element" />
            ) : (
                <SavedIcon className="k-icp-icon k-icp-icon-size-4" />
            )}
            <span className="k-fs-sm k-font-normal">
                {status === TextEditorSaveIndicatorStatus.Saving ? (
                    <>
                        Saving
                        <TypingDots alwaysUseSpace />
                    </>
                ) : (
                    status === TextEditorSaveIndicatorStatus.Saved && 'Saved'
                )}
            </span>
        </StackLayout>
    );
});
