const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
];

class DateTimeService {
    stringifyDuration(from: Date, to: Date, startWithCapitalLetter = false) {
        let seconds = Math.floor((to.getTime() - from.getTime()) / 1000);
        const isFuture = seconds > 0;
        seconds = Math.abs(seconds);
        if (seconds < 5) return startWithCapitalLetter ? 'Just now' : 'just now';
        const interval = intervals.find(i => i.seconds < seconds) || intervals[intervals.length - 1];
        const count = Math.floor(seconds / interval.seconds);
        const durationString = `${count} ${interval.label}${count !== 1 ? 's' : ''}`;

        return isFuture ? (startWithCapitalLetter ? `In ${durationString}` : `in ${durationString}`) : `${durationString} ago`;
    }

    stringifyMinutesToHours(durationInMinutes: number) {
        const hours = Math.floor(durationInMinutes / 60);

        if (hours === 0) return `${durationInMinutes} min${durationInMinutes === 1 ? '' : 's'}`;

        const minutes = durationInMinutes - 60 * hours;
        return `${hours} hour${hours === 1 ? '' : 's'}${minutes === 0 ? '' : ` ${minutes} min${minutes === 1 ? '' : 's'}`}`;
    }

    ensureDateType<TEntity>(dateParentSelector: (entity: TEntity) => any, dateFieldName: string, ...entities: TEntity[]) {
        if (!entities) return;

        entities.forEach(e => {
            const dateParent = dateParentSelector(e);
            if (!dateParent) return;
            this.ensureDateField(dateParent, dateFieldName);
        });
    }

    ensureDateField<TDateField extends keyof TItem, TItem extends { [Property in TDateField]?: Date | null | undefined }>(
        item: TItem,
        dateFieldName: TDateField
    ) {
        const dateFieldValue = item[dateFieldName];
        if (!dateFieldValue || dateFieldName instanceof Date) return;
        (item as any)[dateFieldName] = new Date(dateFieldValue);
    }

    addMonths(date: Date, numberOfMonths: number) {
        date.setMonth(date.getMonth() + numberOfMonths);
    }

    addDays(date: Date, numberOfDays: number) {
        date.setDate(date.getDate() + numberOfDays);
    }

    addUTCDays(date: Date, numberOfDays: number) {
        date.setUTCDate(date.getUTCDate() + numberOfDays);
    }

    addHours(date: Date, numberOfHours: number) {
        date.setTime(date.getTime() + numberOfHours * 60 * 60 * 1000);
    }

    addMinutes(date: Date, numberOfMinutes: number): Date {
        date.setTime(date.getTime() + numberOfMinutes * 60 * 1000);
        return date;
    }

    stringifyToDay(date: Date, includeWeekDay?: boolean, short?: boolean) {
        return date.toLocaleDateString(undefined, {
            year: 'numeric',
            day: 'numeric',
            month: short ? 'short' : 'long',
            weekday: includeWeekDay ? 'long' : undefined
        });
    }

    stringifyToTime(date: Date) {
        return date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' });
    }

    stringifyToDateAndTime(date: Date, short?: boolean) {
        return date.toLocaleDateString(undefined, { year: 'numeric', day: 'numeric', month: short ? 'numeric' : 'long', hour: 'numeric', minute: 'numeric' });
    }

    getCurrentTimeZone() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    getGenericTimeZoneTitle(timeZoneName: string) {
        return this.getTimeZoneTitle(timeZoneName, 'longGeneric');
    }

    getShortTimeZoneTitle(timeZoneName: string) {
        return this.getTimeZoneTitle(timeZoneName, 'short');
    }

    private getTimeZoneTitle(timeZoneName: string, timeZoneFormat: Intl.DateTimeFormatOptions['timeZoneName']) {
        return new Date().toLocaleDateString('en-US', { day: '2-digit', timeZone: timeZoneName, timeZoneName: timeZoneFormat }).substring(4);
    }

    isToday(date: Date) {
        const now = new Date();

        return date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();
    }

    isSameDay(firstDate: Date, secondDate: Date) {
        return (
            firstDate.getFullYear() === secondDate.getFullYear() &&
            firstDate.getMonth() === secondDate.getMonth() &&
            firstDate.getDate() === secondDate.getDate()
        );
    }

    getWeekDayName(weekDay: number) {
        if (weekDay > 6) weekDay = weekDay % 7;
        const date = new Date();
        const dateWeekDay = date.getDay();
        if (dateWeekDay !== weekDay) date.setDate(date.getDate() + (weekDay - dateWeekDay));
        return date.toLocaleDateString('en-us', { weekday: 'long' });
    }

    getAllIanaTimeZones(): Promise<string[]> {
        const intl = Intl as any; // Workaround for "Property 'supportedValuesOf' does not exist on type 'typeof Intl'" - the definition is shipped with TS 5.1
        if (typeof intl.supportedValuesOf === 'undefined') return this.getAllIanaTimeZonesFallback();

        try {
            return Promise.resolve(intl.supportedValuesOf('timeZone'));
        } catch (e) {
            if (!(e instanceof RangeError)) throw e;

            return this.getAllIanaTimeZonesFallback();
        }
    }

    private async getAllIanaTimeZonesFallback() {
        if (!timeZonesListModule) timeZonesListModule = await import('timezones-list');

        return timeZonesListModule.default.map(tz => tz.tzCode);
    }

    getDurationInWeeks(startDate: Date, endDate: Date, snapDays = true) {
        const from = snapDays ? new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0) : startDate;
        const to = snapDays ? new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999) : endDate;

        return Math.round(Math.abs(to.getTime() - from.getTime()) / (7 * 24 * 60 * 60 * 1000));
    }

    getDurationInDays(startDate: Date, endDate: Date) {
        return Math.round((endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000));
    }

    getMaxDate(...dates: Date[]): Date;
    getMaxDate(...dates: (Date | null | undefined)[]): Date | null | undefined;
    getMaxDate(...dates: (Date | null | undefined)[]): Date | null | undefined {
        return dates.reduce((a, b) => (!a ? b : !b ? a : a > b ? a : b));
    }

    stringifySecondsToMinutesAndSeconds(totalSeconds: number) {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.round(totalSeconds % 60);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
}
let timeZonesListModule: typeof import('timezones-list') | undefined;

export const dateTimeService = new DateTimeService();
