import { Button } from '@progress/kendo-react-buttons';
import { GridColumn } from '@progress/kendo-react-grid';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useMemo, useState } from 'react';
import { BoundDropDownButton } from '../../components/common/boundDropDownButton';
import { CellTemplateProps, createCellTemplateFromComponent, triggerGridCommand } from '../../components/common/grid';
import { InterviewsLayout, interviewStageLabelMap, interviewStageToUrlPathMap, RouteToInterviewsDefaultTab } from '../../components/interview2/common';
import { InterviewsGrid, InterviewsGridViewProps } from '../../components/interview2/interviewsGridComponents';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { Pager } from '../../components/ui/pager';
import { InterviewsListProvider, useInterviews, useInterviewsListPreferencesContext } from '../../hooks/interviews2Hooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { appEventHub, Research2ExistingInterviewsAddedEventData } from '../../services/appEvents';
import { InterviewsCounts, InterviewStageV2, MinimalInterviewV2 } from '../../services/interviewsV2Service';
import { ResearchV2 } from '../../services/researchV2Service';
import { InterviewDetailsPageOptions } from '../interviews/interviewDetailsPage';
import { TabbedLayout, useTabbedLayoutData } from '../layouts/tabbedLayout';

export function ResearchInterviewsTab() {
    const { research, excludeInterview } = useTabbedLayoutData<{
        research: ResearchV2;
        excludeInterview?: (interviewId: number, interviewStage: InterviewStageV2, callback?: () => Promise<void>) => void;
    }>();

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="page-content-middle page-content--xxl k-flex-1">
            <InterviewsListProvider>
                <TabbedLayout
                    headers={[
                        {
                            title: interviewStageLabelMap[InterviewStageV2.NotStarted],
                            count: research.notStartedCount,
                            to: interviewStageToUrlPathMap[InterviewStageV2.NotStarted]
                        },
                        {
                            title: interviewStageLabelMap[InterviewStageV2.InProgress],
                            count: research.inProgressCount,
                            to: interviewStageToUrlPathMap[InterviewStageV2.InProgress]
                        },
                        {
                            title: interviewStageLabelMap[InterviewStageV2.Completed],
                            count: research.completeCount,
                            to: interviewStageToUrlPathMap[InterviewStageV2.Completed]
                        }
                    ]}
                    presentation="outline"
                    data={{ research, excludeInterview }}
                />
            </InterviewsListProvider>
        </StackLayout>
    );
}

function ResearchInterviewsInStageTab({
    stage,
    emptyMessage,
    gridViewParams
}: {
    stage: InterviewStageV2;
    emptyMessage: string;
    gridViewParams?: Partial<InterviewsGridViewProps>;
}) {
    const { ideaId } = useIdeaParams();
    const { research, excludeInterview } = useTabbedLayoutData<{
        research: ResearchV2;
        excludeInterview?: (interviewId: number, interviewStage: InterviewStageV2, callback?: () => Promise<void>) => void;
    }>();
    const { listPreferences, updateListPreferences } = useInterviewsListPreferencesContext();
    const [skip, setSkip] = useState(0);
    const [interviewsData, fetchInterviews] = useInterviews(ideaId, skip, listPreferences.pageSize, stage, research.id);

    useEffect(() => {
        function onExistingInterviewsAdded(e: Research2ExistingInterviewsAddedEventData) {
            if (e.researchId !== research.id) return;

            fetchInterviews();
        }

        appEventHub.addEventListener('research2', 'existingInterviewsAdded', onExistingInterviewsAdded);

        return () => appEventHub.removeEventListener('research2', 'existingInterviewsAdded', onExistingInterviewsAdded);
    }, [fetchInterviews, research.id]);

    if (!interviewsData)
        return (
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                <LoadingIndicator size="big" />
            </StackLayout>
        );

    const viewInterviewOptions: InterviewDetailsPageOptions = {
        backTo: {
            item: research,
            params: `/interviews/${interviewStageToUrlPathMap[stage]}`
        }
    };
    return (
        <InterviewsLayout emptyMessage={emptyMessage} empty={interviewsData.totalCount <= 0}>
            <InterviewsGrid
                interviews={interviewsData.interviews}
                viewInterviewOptions={{ state: viewInterviewOptions }}
                pagerComponent={
                    <Pager
                        total={interviewsData.totalCount}
                        skip={skip}
                        take={listPreferences.pageSize}
                        onPageChange={(skip, take) => {
                            setSkip(skip);
                            updateListPreferences({ pageSize: take });
                        }}
                    />
                }
                onCommand={async (interview, command) => {
                    if (command === 'exclude-from-research') excludeInterview?.(interview.id, interview.stage, fetchInterviews);
                }}
                {...gridViewParams}
            >
                <GridColumn cell={InterviewInResearchActionsCell} width={160} className={!excludeInterview ? 'k-icp-no-edit' : undefined} />
            </InterviewsGrid>
        </InterviewsLayout>
    );
}

export function ResearchNotStartedInterviewsTabPage() {
    return (
        <ResearchInterviewsInStageTab
            stage={InterviewStageV2.NotStarted}
            emptyMessage="Start a new interview or upload a transcript from a past one to get insights."
            gridViewParams={{ showHost: true }}
        />
    );
}

export function ResearchInProgressInterviewsPage() {
    return (
        <ResearchInterviewsInStageTab
            stage={InterviewStageV2.InProgress}
            emptyMessage="Your ongoing interviews will appear here as they happen."
            gridViewParams={{
                showHost: true,
                showProgress: true,
                showDate: true
            }}
        />
    );
}

export function ResearchCompletedInterviewsPage() {
    return (
        <ResearchInterviewsInStageTab
            stage={InterviewStageV2.Completed}
            emptyMessage="Once an interview is finished, you’ll find it here, ready for review with all key insights extracted."
            gridViewParams={{
                showDate: true,
                showScore: true
            }}
        />
    );
}

const InterviewInResearchActionsCell = createCellTemplateFromComponent(function({ cellProps }: CellTemplateProps<MinimalInterviewV2>) {
    const noEdit = cellProps.className !== undefined && cellProps.className.includes('k-icp-no-edit');
    return (
        <StackLayout align={{ horizontal: 'end', vertical: 'middle' }} className="k-gap-2.5">
            <Button type="button" fillMode="flat" themeColor="secondary" size="small" onClick={e => triggerGridCommand(cellProps, e, 'view')}>
                View interview
            </Button>
            <BoundDropDownButton
                fillMode="flat"
                size="small"
                icon="more-horizontal"
                popupSettings={{
                    anchorAlign: { horizontal: 'right', vertical: 'bottom' },
                    popupAlign: { horizontal: 'right', vertical: 'top' }
                }}
                items={[
                    {
                        children: (
                            <>
                                Exclude from
                                <br />
                                this Research
                            </>
                        ),
                        disabled: noEdit,
                        action: e => triggerGridCommand(cellProps, e.syntheticEvent, 'exclude-from-research')
                    }
                ]}
            />
        </StackLayout>
    );
});

export function RouteToResearchInterviewsDefaultTabPage() {
    const { research } = useTabbedLayoutData<{ research: ResearchV2 }>();
    const interviewsCounts = useMemo<Omit<InterviewsCounts, 'totalCount'>>(
        () => ({ notStartedCount: research.notStartedCount, inProgressCount: research.inProgressCount, completeCount: research.completeCount }),
        [research.notStartedCount, research.inProgressCount, research.completeCount]
    );
    return <RouteToInterviewsDefaultTab interviewsCounts={interviewsCounts} />;
}
