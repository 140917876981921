import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCopyToClipboard } from '../../hooks/commonHooks';
import { ReactComponent as LinkIcon } from '../../icons/link.svg';
import { combineClassNames } from '../../services/common';

export function PresentAndCopyLink({ link, name, className, disabled }: { link: string; name: string; className?: string; disabled?: boolean }) {
    const copyToClipboard = useCopyToClipboard();
    function copyLink() {
        copyToClipboard(link, name);
    }

    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className={combineClassNames('k-gap-2', className)}>
            <div
                className={combineClassNames('k-button-link-secondary -bw', disabled ? 'k-disabled' : 'k-cursor-pointer')}
                onClick={disabled ? undefined : copyLink}
            >
                {link}
            </div>
            <Button type="button" className="k-icp-svg-icon-button k-shrink-0" onClick={copyLink} disabled={disabled}>
                <LinkIcon className="k-icp-icon" />
            </Button>
        </StackLayout>
    );
}
