import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Person } from '../../services/contactsService';
import { InterviewAnalysis, InterviewReferredContact, interviewsV2Service } from '../../services/interviewsV2Service';
import {
    RealTimeUpdateInterviewEventData,
    RealTimeUpdateInterviewReferredContactEventData,
    RealTimeUpdatePersonEventData,
    realTimeUpdatesEventHub
} from '../../services/realTimeUpdatesService';

export type InterviewAnalysisContextType = {
    analysis: InterviewAnalysis;
    associateContactWithReferee: (refereeId: number, contact: Person) => Promise<void>;
};

export const InterviewAnalysisContext = createContext<InterviewAnalysisContextType | undefined>(undefined);

export function useInterviewAnalysis(ideaId: string, interviewId: number) {
    const [analysis, setAnalysis] = useState<InterviewAnalysis | undefined>(undefined);

    const runAnalysis = useCallback(
        async function() {
            const analysis = await interviewsV2Service.runAnalysis(ideaId, interviewId);
            setAnalysis(analysis);
        },
        [ideaId, interviewId]
    );

    const updateReferral = useCallback(async (referral: InterviewReferredContact) => {
        setAnalysis(prevAnalysis => {
            if (!prevAnalysis) return prevAnalysis;
            return { ...prevAnalysis, referrals: prevAnalysis.referrals.map(ref => (ref.id === referral.id ? referral : ref)) };
        });
    }, []);

    const updateReferrals = useCallback(
        async (referralIds: number[]) => {
            const updatedReferrals = await Promise.all(referralIds.map(id => interviewsV2Service.getReferredContact(ideaId, interviewId, id)));
            setAnalysis(prevAnalysis => {
                if (!prevAnalysis) return prevAnalysis;
                return {
                    ...prevAnalysis,
                    referrals: prevAnalysis.referrals.map(ref => {
                        const updatedReferral = updatedReferrals.find(updated => updated.id === ref.id);
                        return updatedReferral || ref;
                    })
                };
            });
        },
        [ideaId, interviewId]
    );

    const associateContactWithReferee = useCallback(
        async (refereeId: number, contact: Person) => {
            const updatedReferral = await interviewsV2Service.associateContactWithReferral(ideaId, interviewId, refereeId, contact.id);
            updateReferral(updatedReferral);
        },
        [ideaId, interviewId, updateReferral]
    );

    useEffect(() => {
        const fetchOverviewDetails = async () => {
            const overviewDetails = await interviewsV2Service.getInterviewAnalysisOverview(ideaId, interviewId);
            setAnalysis(overviewDetails);
        };
        fetchOverviewDetails();
    }, [ideaId, interviewId]);

    useEffect(() => {
        const handleAnalysisCreateOrUpdate = async (event: RealTimeUpdateInterviewEventData) => {
            if (event.ideaId !== ideaId || event.interviewId !== interviewId) return;

            const analysisOverview = await interviewsV2Service.getInterviewAnalysisOverview(ideaId, interviewId);
            setAnalysis(analysisOverview);
        };

        const handleReferralUpdate = async (event: RealTimeUpdateInterviewReferredContactEventData) => {
            if (event.ideaId !== ideaId || event.interviewId !== interviewId) return;
            await updateReferrals([event.referredContactId]);
        };

        const handleContactUpdate = async (event: RealTimeUpdatePersonEventData) => {
            if (event.ideaId !== ideaId || !analysis?.referrals.some(ref => ref.contact?.id === event.personId)) return;
            const referralIds = analysis.referrals.filter(ref => ref.contact?.id === event.personId).map(ref => ref.id);
            await updateReferrals(referralIds);
        };

        const handleContactDelete = async (event: RealTimeUpdatePersonEventData) => {
            if (event.ideaId !== ideaId || !analysis?.referrals.some(ref => ref.contact?.id === event.personId)) return;
            const referralIds = analysis.referrals.filter(ref => ref.contact?.id === event.personId).map(ref => ref.id);
            await updateReferrals(referralIds);
        };

        realTimeUpdatesEventHub.addEventListener('interview3', 'analysisAdd', handleAnalysisCreateOrUpdate);
        realTimeUpdatesEventHub.addEventListener('interview3', 'analysisUpdate', handleAnalysisCreateOrUpdate);
        realTimeUpdatesEventHub.addEventListener('interview3', 'referredContactUpdate', handleReferralUpdate);
        realTimeUpdatesEventHub.addEventListener('contact', 'personUpdate', handleContactUpdate);
        realTimeUpdatesEventHub.addEventListener('contact', 'personDelete', handleContactDelete);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview3', 'analysisAdd', handleAnalysisCreateOrUpdate);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'analysisUpdate', handleAnalysisCreateOrUpdate);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'referredContactUpdate', handleReferralUpdate);
            realTimeUpdatesEventHub.removeEventListener('contact', 'personUpdate', handleContactUpdate);
            realTimeUpdatesEventHub.removeEventListener('contact', 'personDelete', handleContactDelete);
        };
    }, [analysis?.referrals, ideaId, interviewId, updateReferrals]);

    return { analysis, runAnalysis, associateContactWithReferee };
}

export function useInterviewAnalysisContext() {
    const context = useContext(InterviewAnalysisContext);
    if (context === undefined) {
        throw new Error('useInterviewAnalysis must be used within an InterviewAnalysisProvider');
    }
    return context;
}
