import { useNavigate } from 'react-router-dom';
import { canvasItemsZoneDependent } from '../../components/canvas/canvasItemsZone';
import { CreateOrUpdateResearchV2Modal } from '../../components/research2/createOrUpdateResearchV2Modal';
import { useIdeaParams } from '../../hooks/routerHooks';

export const CreateResearchV2Page = canvasItemsZoneDependent(function CreateResearchV2Page() {
    const navigate = useNavigate();
    const { ideaId } = useIdeaParams();

    return (
        <CreateOrUpdateResearchV2Modal ideaId={ideaId} onClose={createdResearch => navigate(createdResearch ? `../${createdResearch.id}/overview` : '..')} />
    );
});
