import { useEffect, useState } from 'react';
import { interviewsV2Service, InterviewV2 } from '../../services/interviewsV2Service';
import { AILedInterviewViewModal } from '../interview2/aiLedInterviewViewModal';
import FileViewModal from '../interview2/fileViewModal';
import { HumanLedInterviewViewModal } from '../interview2/humanLedInterviewViewModal';

export function ViewInterviewTranscriptDialog({ ideaId, interviewId, onClose }: { ideaId: string; interviewId: number; onClose: () => void }) {
    const [interview, setInterview] = useState<InterviewV2>();

    useEffect(() => {
        interviewsV2Service.getInterview(ideaId, interviewId).then(setInterview);
    }, [ideaId, interviewId]);

    if (!interview || interview.aiLed) {
        return <AILedInterviewViewModal ideaId={ideaId} interview={interview} onClose={onClose} />;
    }

    if (interview.imported) {
        return <FileViewModal ideaId={ideaId} interviewId={interview.id} name={interview.title || 'Interview Transcript'} type="interview" onClose={onClose} />;
    }

    // TODO R4
    return <HumanLedInterviewViewModal onClose={onClose} />;
}
