import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useRef, useState } from 'react';
import { useSingleClickCallback } from '../../hooks/commonHooks';
import { ReactComponent as TestIcon } from '../../icons/test.svg';
import { InterviewScriptSelector } from '../../pages/scripts/interviewScriptSelector';
import { MissingCustomerSegmentsWarning } from '../../pages/scripts/interviewScriptsPage';
import { BoxType } from '../../services/canvasService';
import { combineClassNames, resolveAbsoluteUrl } from '../../services/common';
import { InterviewScript2, interviewScripts2Service } from '../../services/interviewScripts2Service';
import {
    CreateResearchV2Data,
    ResearchTypeV2,
    ResearchV2,
    UpdateResearchV2Data,
    mapResearchTypeToInterviewType,
    researchV2Service
} from '../../services/researchV2Service';
import { CanvasItemFromBoxPicker } from '../canvas/canvasItemFromBoxPicker';
import { useRequireCanvasBoxItemsInZone } from '../canvas/canvasItemsZone';
import { scriptValidator } from '../interview2/common';
import { CreateNewScriptOptions, useCreateNewScriptWithOptions } from '../interviewScript2/createNewScriptOptionsComponent';
import { FullScreenModalWithIcon } from '../ui/appModal';
import { FormFieldProps, ValidatedInput, ValidatedInputLabelProps, requiredValidator } from '../ui/inputs';
import LoadingIndicator from '../ui/loadingIndicator';
import { PresentAndCopyLink } from '../ui/presentAndCopyLink';
import { ResearchTypePicker } from './researchTypePicker';

export function CreateOrUpdateResearchV2Modal({
    ideaId,
    researchId,
    onClose
}: {
    ideaId: string;
    researchId?: number;
    onClose?: (savedResearch?: ResearchV2) => void;
}) {
    const isEditing = typeof researchId === 'number';
    const [researchToEditData, setResearchToEditData] = useState<{ research: ResearchV2; script: InterviewScript2 }>();
    const customerSegments = useRequireCanvasBoxItemsInZone(BoxType.CustomerSegments);
    const { handleChosenAction, createScriptModal, editScriptModal } = useCreateNewScriptWithOptions({
        ideaId,
        onScriptEditorClose: scriptId => {
            prefillScript(scriptId);
        }
    });
    const [isCreatingNewScript, handleNewScriptCallback] = useSingleClickCallback(handleChosenAction) as [boolean, typeof handleChosenAction];
    const formRef = useRef<Form>(null);

    const [isSubmitInProgress, handleSubmit] = useSingleClickCallback(async function handleSubmit(data: Record<string, any>) {
        const selectedScript: InterviewScript2 = data.script;
        if (isEditing) {
            const updateResearchData: UpdateResearchV2Data = {
                name: data.name,
                interviewScriptId: selectedScript.id,
                aiLedInterviewsEnabled: data.aiLedInterviewsEnabled
            };
            const updatedResearch = await researchV2Service.partiallyUpdateResearch(ideaId, researchId, updateResearchData);
            onClose?.(updatedResearch);
        } else {
            const selectedScript: InterviewScript2 = data.script;
            const newResearchData: CreateResearchV2Data = {
                name: data.name,
                type: data.researchType,
                customerSegmentId: data.customerSegmentId,
                interviewScriptId: selectedScript.id
            };
            const createdResearch = await researchV2Service.createResearch(ideaId, newResearchData);
            onClose?.(createdResearch);
        }
    });

    const prefillScript = async (scriptId: number) => {
        const script = await interviewScripts2Service.getInterviewScript(ideaId, scriptId);
        formRef.current?.valueSetter('script', script);
        formRef.current?.forceUpdate();
    };

    useEffect(() => {
        if (!isEditing) return;

        (async function() {
            const researchToEdit = await researchV2Service.getResearch(ideaId, researchId);
            const researchScript = await interviewScripts2Service.getInterviewScript(ideaId, researchToEdit.interviewScriptId);

            setResearchToEditData({ research: researchToEdit, script: researchScript });
        })();
    }, [ideaId, isEditing, researchId]);

    return (
        <FullScreenModalWithIcon title={isEditing ? 'Edit research' : 'New research'} icon={TestIcon} onClose={onClose && (() => onClose())}>
            {isEditing && !researchToEditData ? (
                <LoadingIndicator size="big" className="k-centered" />
            ) : (
                <Form
                    key={researchId}
                    ignoreModified
                    initialValues={
                        isEditing
                            ? {
                                  name: researchToEditData!.research.name,
                                  researchType: researchToEditData!.research.type,
                                  customerSegmentId: researchToEditData!.research.customerSegmentId,
                                  script: researchToEditData!.script,
                                  aiLedInterviewsEnabled: researchToEditData!.research.aiLedInterviewsEnabled
                              }
                            : undefined
                    }
                    ref={formRef}
                    onSubmit={handleSubmit}
                    render={({ allowSubmit, onChange: onFormChange, valueGetter }) => {
                        const selectedResearchType: ResearchTypeV2 | undefined = valueGetter('researchType');
                        const selectedCustomerSegmentId: number | undefined = valueGetter('customerSegmentId');
                        const canSelectScript = Boolean(selectedResearchType) && typeof selectedCustomerSegmentId === 'number';
                        const interviewType = selectedResearchType ? mapResearchTypeToInterviewType(selectedResearchType) : undefined;

                        return (
                            <FormElement className="k-p-4 ">
                                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-10 page-content-middle">
                                    <Field
                                        name="name"
                                        component={ValidatedInput}
                                        label="Research name"
                                        placeholder="Type research name..."
                                        labelClassName="!k-mb-4 k-h3"
                                        validator={requiredValidator('Research name')}
                                    />
                                    <Field
                                        name="researchType"
                                        component={ValidatedInput}
                                        inputType={ResearchTypePicker}
                                        label="Research type"
                                        labelClassName="!k-mb-4 k-h3"
                                        validator={requiredValidator('Research type')}
                                        onChange={() => onFormChange('script', { value: null })}
                                        disabled={isEditing}
                                    />
                                    <Field
                                        name="customerSegmentId"
                                        component={ValidatedInput}
                                        inputType={CanvasItemFromBoxPicker}
                                        label="Relate to Customer Segment"
                                        labelClassName="!k-mb-4 k-h3"
                                        validator={requiredValidator('Customer segment')}
                                        items={customerSegments}
                                        emptyView={
                                            <MissingCustomerSegmentsWarning
                                                ideaBaseUrl="../.."
                                                instructionText="Defining a specific customer segment is essential for effective research. Identify your target audience before proceeding."
                                            />
                                        }
                                        onChange={() => onFormChange('script', { value: null })}
                                        disabled={isEditing}
                                    />
                                    <div>
                                        <Field
                                            name="script"
                                            label="Script"
                                            labelClassName="k-mb-4"
                                            component={ValidatedInput}
                                            inputType={InterviewScriptSelector}
                                            labelType={isEditing ? InterviewScriptWithResearchHintLabel : InterviewScriptSelectorLabel}
                                            ideaId={ideaId}
                                            customerSegmentId={selectedCustomerSegmentId}
                                            interviewType={interviewType}
                                            validator={scriptValidator}
                                            placeholder="Select from existing scripts..."
                                            disabled={!canSelectScript}
                                        />
                                        <div className={combineClassNames('k-mt-6', canSelectScript ? undefined : 'k-disabled')}>Or create new script</div>
                                        <CreateNewScriptOptions
                                            disabled={!!isSubmitInProgress || isCreatingNewScript || !canSelectScript}
                                            onAction={type =>
                                                type === 'blank' || type === 'file'
                                                    ? handleNewScriptCallback(type, selectedCustomerSegmentId, interviewType)
                                                    : handleNewScriptCallback(type, selectedCustomerSegmentId)
                                            }
                                            interviewTypes={interviewType ? [interviewType] : undefined}
                                        />
                                    </div>
                                    {isEditing && (
                                        <Field
                                            name="aiLedInterviewsEnabled"
                                            component={ValidatedInput}
                                            label="AI-Led Interviews"
                                            labelClassName="!k-mb-1 k-h3"
                                            inputType={AILedInterviewsEnabledCheckbox}
                                            researchPublicCode={researchToEditData!.research.publicCode}
                                        />
                                    )}
                                    <StackLayout orientation="horizontal" align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-4">
                                        <Button size="large" fillMode="solid" themeColor="primary" type="submit" disabled={!allowSubmit || isSubmitInProgress}>
                                            {isEditing ? 'Save changes' : 'Create research'}
                                        </Button>
                                        <Button
                                            size="large"
                                            fillMode="solid"
                                            themeColor="base"
                                            disabled={isSubmitInProgress}
                                            onClick={onClose && (() => onClose())}
                                        >
                                            {isEditing ? 'Discard' : 'Cancel'}
                                        </Button>
                                    </StackLayout>
                                </StackLayout>
                            </FormElement>
                        );
                    }}
                ></Form>
            )}
            {createScriptModal}
            {editScriptModal}
        </FullScreenModalWithIcon>
    );
}

function InterviewScriptSelectorLabel(props: ValidatedInputLabelProps) {
    return (
        <div className={props.className}>
            <Label editorId={props.editorId} editorValid={props.isValid} className="k-font-bold !k-display-inline k-align-middle">
                {props.text}
            </Label>
            {props.disabled && <span className="k-text-italic k-fs-sm k-ml-1.5">(Select a Research Type and Customer Segment to enable script selection)</span>}
        </div>
    );
}

function InterviewScriptWithResearchHintLabel(props: ValidatedInputLabelProps) {
    return (
        <div className={props.className}>
            <Label editorId={props.editorId} editorValid={props.isValid} className="k-font-bold !k-mb-1">
                {props.text}
            </Label>
            <div className="k-fs-sm">Updating the default script for this research will not impact any existing interviews.</div>
        </div>
    );
}

function AILedInterviewsEnabledCheckbox({
    id,
    disabled,
    onChange,
    onBlur,
    onFocus,
    valid,
    value,
    researchPublicCode
}: FormFieldProps<boolean> & { researchPublicCode?: string }) {
    const publicStartInterviewLink = researchPublicCode && resolveAbsoluteUrl(researchV2Service.getResearchInterviewPublicUrl(researchPublicCode));

    return (
        <div>
            <div className="k-fs-sm k-mb-4">
                Invite your customers to engage with IVA for a personalized interview experience based on the research parameters you've set.
            </div>
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-3">
                <Checkbox id={id} disabled={disabled} valid={valid} checked={value} onChange={onChange} onBlur={onBlur} onFocus={onFocus} />
                <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-1">
                    <label className="k-checkbox-label" htmlFor={id}>
                        Enable AI-led interview link
                    </label>
                    {publicStartInterviewLink && <PresentAndCopyLink link={publicStartInterviewLink} name="Interview link" />}
                </StackLayout>
            </StackLayout>
        </div>
    );
}
