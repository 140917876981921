import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { canvasItemsZoneDependent } from '../../components/canvas/canvasItemsZone';
import { DropDownButtonItem } from '../../components/common/boundDropDownButton';
import { researchV2StatusTextMap, researchV2TypeTextMap } from '../../components/research2/common';
import { ResearchCard } from '../../components/research2/ResearchCard';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { SvgIconButtonContent } from '../../components/ui/svgIconButtonContent';
import { TextMarker } from '../../components/ui/textMarker';
import { H1 } from '../../components/ui/typography';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as TestIcon } from '../../icons/test.svg';
import researchIllustrationUrl from '../../images/research-illustration.svg';
import { immutableRemove } from '../../services/common/common.immutable';
import { RealTimeUpdateResearch2EventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { ResearchV2, researchV2Service } from '../../services/researchV2Service';
import { UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';

export const ResearchV2Page = canvasItemsZoneDependent(function ResearchV2Page() {
    const { ideaId } = useIdeaParams();
    const currentUserRole = useAppSelector(s => s.idea.role);
    const navigate = useNavigate();
    const canEdit = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const [researchItems, setResearchItems] = useState<ResearchV2[]>();
    const { show: showDeleteDialog, element: deleteDialog } = useConfirmDialog();

    const loadResearchItems = useCallback(
        function loadResearchItems() {
            researchV2Service.getFullResearchList(ideaId).then(setResearchItems);
        },
        [ideaId]
    );

    const onDelete = (researchId: number) => {
        const researchToDelete = researchItems?.find(r => r.id === researchId);
        if (!researchToDelete) return;

        showDeleteDialog({
            title: 'Delete research',
            content: (
                <>
                    Are you sure you want to delete <strong>“{researchToDelete.name}”</strong>?
                </>
            ),
            confirmCheckboxText: 'I acknowledge that this cannot be undone',
            confirmButtonText: 'Delete research',
            callback: async () => {
                await researchV2Service.deleteResearch(ideaId, researchId);
                setResearchItems(researchItems => immutableRemove(researchItems, r => r.id !== researchId));
            }
        });
    };

    useEffect(() => {
        loadResearchItems();
    }, [loadResearchItems]);

    useEffect(() => {
        function handleReloadInterviewsEvent(e: RealTimeUpdateResearch2EventData) {
            if (e.ideaId !== ideaId) return;

            return loadResearchItems();
        }

        function handleDeleteResearchEvent(e: RealTimeUpdateResearch2EventData) {
            if (e.ideaId !== ideaId) return;

            setResearchItems(researchItems => immutableRemove(researchItems, r => r.id !== e.researchId));
        }

        realTimeUpdatesEventHub.addEventListener('research2', 'add', handleReloadInterviewsEvent);
        realTimeUpdatesEventHub.addEventListener('research2', 'update', handleReloadInterviewsEvent);
        realTimeUpdatesEventHub.addEventListener('research2', 'restore', handleReloadInterviewsEvent);
        realTimeUpdatesEventHub.addEventListener('research2', 'delete', handleDeleteResearchEvent);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('research2', 'add', handleReloadInterviewsEvent);
            realTimeUpdatesEventHub.removeEventListener('research2', 'update', handleReloadInterviewsEvent);
            realTimeUpdatesEventHub.removeEventListener('research2', 'restore', handleReloadInterviewsEvent);
            realTimeUpdatesEventHub.removeEventListener('research2', 'delete', handleDeleteResearchEvent);
        };
    }, [ideaId, loadResearchItems]);

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="-minh100">
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-8 heading-row">
                <TextMarker text="Early Preview">
                    <H1>Research</H1>
                </TextMarker>
                {canEdit && (
                    <>
                        <div className="vertical-separator" />
                        <Button themeColor="primary" size="large" onClick={() => navigate('create')}>
                            <SvgIconButtonContent icon={PlusIcon}>New research</SvgIconButtonContent>
                        </Button>
                    </>
                )}
            </StackLayout>

            {!researchItems ? (
                <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
                    <LoadingIndicator size="big" />
                </StackLayout>
            ) : !researchItems.length ? (
                <ResearchListEmptyView />
            ) : (
                <div className="cards-relaxed-grid">
                    {researchItems.map(research => {
                        const actions: DropDownButtonItem[] = [
                            { text: 'Open', action: () => navigate(research.id.toString()) },
                            { text: 'Delete', danger: true, separated: true, action: canEdit ? () => onDelete(research.id) : undefined, disabled: !canEdit }
                        ];
                        const researchType = researchV2TypeTextMap[research.type] + ' interviews';

                        return (
                            <ResearchCard
                                key={research.id}
                                icon={TestIcon}
                                statusText={researchV2StatusTextMap[research.status]}
                                title={research.name}
                                subtitle={
                                    <div className="k-text-ellipsis">
                                        {research.interviewCount} interviews
                                        <span className="dot-separator k-display-inline-block k-mx-1.5 k-align-middle" />
                                        <span title={researchType}>{researchType}</span>
                                    </div>
                                }
                                customerSegmentId={research.customerSegmentId}
                                lastEdited={research.lastActivity}
                                actions={actions}
                                linkTo={`${research.id}/overview`}
                                contributors={research.contributors}
                            />
                        );
                    })}
                </div>
            )}
            {deleteDialog}
        </StackLayout>
    );
});

function ResearchListEmptyView() {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1 k-text-center page-content-middle -w2">
            <img src={researchIllustrationUrl} width="240" height="120" alt="No research available" className="k-mb-6 responsive-image" />
            <div>Start a new research. Conduct interviews and validate your assumptions or gather valuable feedback from your customers.</div>
        </StackLayout>
    );
}
