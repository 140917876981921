import { Button } from '@progress/kendo-react-buttons';
import { Card, CardBody, CardTitle, StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { researchTypeTextMap } from '.';
import { canvasItemsZoneDependent } from '../../components/canvas/canvasItemsZone';
import { CanvasItemInContextSimpleView } from '../../components/canvas/customerSegmentInContextSimpleView';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { Pager } from '../../components/ui/pager';
import { SvgIconButtonContent } from '../../components/ui/svgIconButtonContent';
import { H1, H2 } from '../../components/ui/typography';
import { useAsRef } from '../../hooks/commonHooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import interviewIllustrationUrl from '../../images/interview-illustration.svg';
import { BoxType } from '../../services/canvasService';
import { dateTimeService } from '../../services/dateTimeService';
import { ResearchListPreferences, listPreferencesService } from '../../services/listPreferencesService';
import { RealTimeUpdateCanvasItemEventData, RealTimeUpdateResearchEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { MinimalProblemValidationResearch, ResearchList, ResearchType, researchService } from '../../services/researchService';
import { UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';

/**
 * @obsolete This code is part of the old research functionality
 */
export const ResearchPage = canvasItemsZoneDependent(function ResearchPage() {
    const { ideaId } = useIdeaParams();
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canCreateResearch = (currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator) && false; // Until this is implemented - hardcode it to false
    const [researchItems, skip, take, updatePage] = useResearchList(ideaId);

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="-minh100">
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-8 heading-row">
                <H1>Research</H1>
                {canCreateResearch && (
                    <>
                        <div className="vertical-separator" />
                        <Button themeColor="primary" size="large">
                            <SvgIconButtonContent icon={PlusIcon}>New research</SvgIconButtonContent>
                        </Button>
                    </>
                )}
            </StackLayout>

            {!researchItems ? (
                <ResearchLoadingView />
            ) : !researchItems.totalCount ? (
                <ResearchListEmptyView />
            ) : (
                <div className="k-flex-1">
                    <div className="k-d-grid cards-grid k-align-items-start k-gap-4">
                        {researchItems.research.map(research => (
                            <Link key={research.id} className="k-link" to={research.id.toString()}>
                                <ResearchCard research={research} />
                            </Link>
                        ))}
                    </div>
                    <Pager total={researchItems.totalCount} skip={skip} take={take} onPageChange={updatePage} />
                </div>
            )}
        </StackLayout>
    );
});

function useResearchList(ideaId: string) {
    const [researchItems, setResearchItems] = useState<ResearchList>();
    const [skip, setSkip] = useState(0);
    const [listPreferences, setListPreferences] = useState(() => listPreferencesService.getResearchListPreferences());

    const loadResearchItems = useCallback(
        function loadResearchItems(skip: number, take: number) {
            researchService.getProblemValidationResearchItems(ideaId, skip, take).then(setResearchItems);
        },
        [ideaId]
    );

    function updatePage(skip: number, take: number) {
        setSkip(skip);
        if (listPreferences.pageSize !== take) {
            const updatedPreferences: ResearchListPreferences = { ...listPreferences, pageSize: take };
            listPreferencesService.saveResearchListPreferences(updatedPreferences);
            setListPreferences(updatedPreferences);
        }
        loadResearchItems(skip, take);
    }

    const skipRef = useAsRef(skip);
    const listPreferencesRef = useAsRef(listPreferences);
    useEffect(() => {
        function loadItems() {
            return loadResearchItems(skipRef.current, listPreferencesRef.current.pageSize);
        }

        loadItems();

        function handleResearchEvent(e: RealTimeUpdateResearchEventData) {
            if (e.ideaId !== ideaId || e.researchType !== ResearchType.ProblemValidation) return;

            loadItems();
        }

        function onCanvasItemDeleted(e: RealTimeUpdateCanvasItemEventData) {
            if (e.ideaId !== ideaId || e.box !== BoxType.CustomerSegments) return;

            loadItems();
        }

        function onCanvasItemRestored(e: RealTimeUpdateCanvasItemEventData) {
            if (e.ideaId !== ideaId || e.box !== BoxType.CustomerSegments) return;

            loadItems();
        }

        realTimeUpdatesEventHub.addEventListener('research', 'add', handleResearchEvent);
        realTimeUpdatesEventHub.addEventListener('research', 'update', handleResearchEvent);
        realTimeUpdatesEventHub.addEventListener('research', 'delete', handleResearchEvent);
        realTimeUpdatesEventHub.addEventListener('research', 'restore', handleResearchEvent);

        realTimeUpdatesEventHub.addEventListener('idea', 'itemDelete', onCanvasItemDeleted);
        realTimeUpdatesEventHub.addEventListener('idea', 'itemRestore', onCanvasItemRestored);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('research', 'add', handleResearchEvent);
            realTimeUpdatesEventHub.removeEventListener('research', 'update', handleResearchEvent);
            realTimeUpdatesEventHub.removeEventListener('research', 'delete', handleResearchEvent);
            realTimeUpdatesEventHub.removeEventListener('research', 'restore', handleResearchEvent);

            realTimeUpdatesEventHub.removeEventListener('idea', 'itemDelete', onCanvasItemDeleted);
            realTimeUpdatesEventHub.removeEventListener('idea', 'itemRestore', onCanvasItemRestored);
        };
    }, [ideaId, listPreferencesRef, loadResearchItems, skipRef]);

    return [researchItems, skip, listPreferences.pageSize, updatePage] as const;
}

function ResearchListEmptyView() {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1 k-gap-8">
            <img src={interviewIllustrationUrl} width="420" height="300" alt="Empty research list" />
            <div className="page-content-middle -w2 k-text-center">
                <H2 className="!k-mb-4">No research started</H2>
                <div className="k-fs-lg">As you progress through your Journey, you will find all the interview data and insights right here.</div>
            </div>
        </StackLayout>
    );
}

function ResearchLoadingView() {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
            <LoadingIndicator size="big" />
        </StackLayout>
    );
}

function ResearchCard({ research }: { research: MinimalProblemValidationResearch }) {
    return (
        <Card className="k-icp-interactive-card">
            <CardBody className="">
                <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                    <CardTitle className="max-lines-2 !k-mb-0">{research.title}</CardTitle>
                    <div className="k-icp-subtle-text">{researchTypeTextMap[research.type]}</div>
                    <ResearchRangeView className="k-icp-subtle-text" start={research.startDate} end={research.endDate} />
                    <CanvasItemInContextSimpleView
                        box={BoxType.CustomerSegments}
                        itemId={research.customerSegmentId}
                        singleLine
                        showLoading
                        className="k-mt-1"
                    />
                </StackLayout>
            </CardBody>
        </Card>
    );
}

function ResearchRangeView({ start, end, className }: { start?: Date; end?: Date; className?: string }) {
    if (!start && !end) return null;

    return (
        <div className={className}>
            {start && dateTimeService.stringifyToDay(start)}
            {start && end && ' - '}
            {end && dateTimeService.stringifyToDay(end)}
        </div>
    );
}
