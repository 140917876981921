import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridRowClickEvent } from '@progress/kendo-react-grid';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode } from 'react';
import { NavigateOptions } from 'react-router-dom';
import { generateInitials, getPersonFullName, getPreferredColorIndex } from '../../services/common';
import { ReducedPerson } from '../../services/contactsService';
import { dateTimeService } from '../../services/dateTimeService';
import { InterviewAnalysisStatus, InterviewStageV2, MinimalInterviewV2 } from '../../services/interviewsV2Service';
import { ReducedUser } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { AIAvatar } from '../ai/aiAvatar';
import { CellTemplateProps, createCellTemplateFromComponent, GridCommandHandler, gridCommandHandler, triggerGridCommand } from '../common/grid';
import { useIdeaNavigate } from '../startup/ideaZone';
import UserAvatar from '../user/userAvatar';
import { InterviewProgress, interviewStageLabelMap, InterviewStatusIndicator, interviewTypeToLabelMap } from './common';

export type InterviewsGridViewProps = {
    showHost?: boolean;
    showScore?: boolean;
    showType?: boolean;
    showProgress?: boolean;
    showDate?: boolean;
    showViewInterview?: boolean;
};
export type InterviewsGridProps = InterviewsGridViewProps & {
    interviews: MinimalInterviewV2[];
    pagerComponent?: ReactNode;
    children?: ReactNode;
    onCommand?: GridCommandHandler<MinimalInterviewV2>;
    viewInterviewOptions?: NavigateOptions;
};

export function InterviewsGrid({
    interviews,
    showHost,
    showScore,
    showType,
    showProgress,
    showDate,
    pagerComponent,
    showViewInterview,
    children,
    onCommand,
    viewInterviewOptions
}: InterviewsGridProps) {
    const ideaNavigate = useIdeaNavigate();
    function navigateToInterview(interview: MinimalInterviewV2) {
        ideaNavigate(`interviews-v2/${interview.id}`, viewInterviewOptions);
    }
    function handleRowClick(e: GridRowClickEvent) {
        navigateToInterview(e.dataItem as MinimalInterviewV2);
    }

    return (
        <div>
            <Grid
                data={interviews}
                onRowClick={handleRowClick}
                className="k-grid-no-scrollbar k-icp-grid-navigatable k-icp-grid-light-header"
                onItemChange={gridCommandHandler<MinimalInterviewV2>(function(dataItem, commandName, commandValue) {
                    if (commandName === 'view') return navigateToInterview(dataItem);
                    onCommand?.(dataItem, commandName, commandValue);
                })}
            >
                <GridColumn title="Interviewee" cell={IntervieweeInfoGridCellTemplate} />
                {showHost && <GridColumn title="Interview Host" cell={HostInfoGridCellTemplate} />}
                {showScore && <GridColumn cell={InterviewScoreGridCellTemplate} title="Interview Score" width={172} />}
                {showType && <GridColumn title="Interview type" cell={InterviewTypeGridCellTemplate} className="k-fs-sm" width={172} />}
                {showProgress && <GridColumn cell={InterviewProgressGridCellTemplate} title="Completeness" width={110} />}
                {showDate && <GridColumn cell={InterviewDateGridCellTemplate} title="Interview Date" className="k-fs-sm" width={110} />}
                {showViewInterview && <GridColumn cell={ViewInterviewCellTemplate} width={80} />}
                {children}
            </Grid>
            {pagerComponent}
        </div>
    );
}

export function IntervieweeInfoComponent({
    person,
    guessedName,
    fileName,
    isPendingAnalysis
}: {
    person: ReducedPerson | null;
    guessedName: string | null;
    fileName?: string;
    isPendingAnalysis?: boolean;
}) {
    return (
        <>
            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-no-click">
                {person ? (
                    <UserAvatar
                        empty={!person}
                        picture={person?.picture}
                        initials={generateInitials(2, person?.firstName, person?.lastName)}
                        colorIndex={person && getPreferredColorIndex(person.id)}
                    />
                ) : (
                    <UserAvatar empty />
                )}

                <div className="k-flex-1">
                    {!isPendingAnalysis ? (
                        <div className="k-fs-lg k-font-semibold">{person?.name || guessedName || 'Unknown interviewee'}</div>
                    ) : (
                        <Skeleton className="k-fs-lg k-w-full" shape="text" />
                    )}
                    {person?.jobTitle && <div className="k-fs-sm k-icp-subtle-text">{person.jobTitle}</div>}
                </div>
            </StackLayout>
            {fileName && <div className="k-mt-0.5 k-fs-sm k-ml-14 k-no-click">{fileName}</div>}
        </>
    );
}

export const IntervieweeInfoGridCellTemplate = createCellTemplateFromComponent(function({ dataItem }: CellTemplateProps<MinimalInterviewV2>) {
    const interviewee = dataItem.contact;
    const fileName = interviewee ? undefined : dataItem.title;
    const isPendingAnalysis = dataItem.analysis?.status === InterviewAnalysisStatus.Pending;
    return (
        <IntervieweeInfoComponent
            person={interviewee}
            guessedName={dataItem.intervieweeName}
            fileName={fileName}
            isPendingAnalysis={isPendingAnalysis && !dataItem.intervieweeName && !interviewee?.name}
        />
    );
});

export const InterviewStatusGridCellTemplate = createCellTemplateFromComponent(function({ dataItem }: CellTemplateProps<MinimalInterviewV2>) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-no-click">
            <InterviewStatusIndicator
                text={interviewStageLabelMap[dataItem.stage]}
                type={dataItem.stage === InterviewStageV2.NotStarted ? 'warning' : dataItem.stage === InterviewStageV2.InProgress ? 'warning' : 'success'}
            />
        </StackLayout>
    );
});

const InterviewTypeGridCellTemplate = createCellTemplateFromComponent(function({ dataItem }: CellTemplateProps<MinimalInterviewV2>) {
    return <div className="k-no-click">{interviewTypeToLabelMap[dataItem.type]}</div>;
});

const InterviewScoreGridCellTemplate = createCellTemplateFromComponent(function({ dataItem }: CellTemplateProps<MinimalInterviewV2>) {
    const analysis = dataItem.analysis;
    const isPending = analysis?.status === InterviewAnalysisStatus.Pending;
    const qualityScore = analysis?.qualityScore ?? undefined;
    const scriptCoverage = analysis?.scriptCoverage ?? undefined;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-0.5 k-no-click">
            <InterviewScoreRowComponent score={qualityScore} isPending={isPending && !qualityScore} label="Quality" />
            <InterviewScoreRowComponent score={scriptCoverage} isPending={isPending && !scriptCoverage} label="Script coverage" />
        </StackLayout>
    );
});

const InterviewScoreRowComponent = ({ score, isPending, label }: { score?: number; isPending: boolean; label: string }) => {
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
            {isPending ? (
                <Skeleton shape="text" className="k-w-9" />
            ) : (
                <span className="k-w-9">
                    {score !== undefined ? <span className="k-font-semibold ">{score}%</span> : <span className="k-font-normal k-icp-subtle-text">-</span>}
                </span>
            )}

            <span className="k-fs-sm">{label}</span>
        </StackLayout>
    );
};

const HostInfoGridCellTemplate = createCellTemplateFromComponent(function({ dataItem }: CellTemplateProps<MinimalInterviewV2>) {
    const user = dataItem.host;
    const currentUser = useAppSelector(s => s.user);
    return <HostInfoComponent user={user} isCurrentUser={user?.userId === currentUser?.userId} isAI={dataItem.aiLed} />;
});

function HostInfoComponent({ user, isCurrentUser, isAI }: { user: ReducedUser | null; isCurrentUser: boolean; isAI: boolean }) {
    const name = isAI ? 'IVA' : user === null ? 'Unknown host' : getPersonFullName(user.firstName, user.lastName, isCurrentUser);
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-no-click">
            {isAI ? (
                <AIAvatar />
            ) : user === null ? (
                <UserAvatar empty />
            ) : (
                <UserAvatar
                    picture={user.picture}
                    initials={generateInitials(2, user.firstName, user.lastName)}
                    colorIndex={getPreferredColorIndex(user.userId)}
                />
            )}
            <span className="k-fs-md k-icp-hoverable">{name}</span>
        </StackLayout>
    );
}

export const InterviewProgressGridCellTemplate = createCellTemplateFromComponent(function({ dataItem: interview }: CellTemplateProps<MinimalInterviewV2>) {
    if (typeof interview.percentCompleted !== 'number') return null;

    return <InterviewProgress progressPercentage={interview.percentCompleted} hideLabel disableAnimation />;
});

export const InterviewDateGridCellTemplate = createCellTemplateFromComponent(function({ dataItem }: CellTemplateProps<MinimalInterviewV2>) {
    const analysis = dataItem.analysis;
    const isPending = analysis?.status === InterviewAnalysisStatus.Pending && !dataItem.date;
    const date = dataItem.date ? dateTimeService.stringifyToDay(dataItem.date, false, true) : 'Unknown';
    return <div className="k-no-click">{isPending ? <Skeleton shape="text" /> : date}</div>;
});

const ViewInterviewCellTemplate = createCellTemplateFromComponent(function({ dataItem: interview, cellProps }: CellTemplateProps<MinimalInterviewV2>) {
    return (
        <div className="k-display-flex k-justify-content-end">
            <Button
                type="button"
                fillMode="flat"
                themeColor="secondary"
                size="small"
                className="!k-min-w-0"
                onClick={e => triggerGridCommand(cellProps, e, 'view')}
            >
                View
            </Button>
        </div>
    );
});
