import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { AILedChatComponent } from '../../components/ai/aiLedChat';
import { useChatActions } from '../../hooks/chatHooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { useHiddenStartupSidebar, useStartupLayout } from '../../hooks/startupHooks';
import { Chat, ChatMessage, chatsService, NewChatMessage } from '../../services/chatsService';
import { immutableAddOrUpdate } from '../../services/common';
import { RealTimeChatEventData, RealTimeChatMessageEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { reloadCurrentIdea } from '../../state/idea/ideaSlice';

const INTRO_CHAT_TAG = 'intro';

export function StartupGetStartedPage() {
    useHiddenStartupSidebar();

    const currentRole = useAppSelector(s => s.idea.role);
    const { ideaId } = useIdeaParams();
    const { setPageContainerClassName } = useStartupLayout();
    const dispatch = useAppDispatch();

    const [chat, setChat] = useState<Chat>();
    const [messages, setMessages] = useState<ChatMessage[]>();

    useEffect(() => {
        chatsService.getChat(ideaId, INTRO_CHAT_TAG).then(setChat);
    }, [ideaId]);

    const initializeChat = useCallback(() => chatsService.initializeChat(ideaId, INTRO_CHAT_TAG), [ideaId]);
    const getAllChatMessages = useCallback(() => chatsService.getAllChatMessages(ideaId, INTRO_CHAT_TAG), [ideaId]);
    const sendMessage = useCallback(
        async function* sendMessage(message: NewChatMessage) {
            const messageStream = chatsService.sendMessage(ideaId, INTRO_CHAT_TAG, message);
            for await (const message of messageStream) {
                yield message;
                // When the intro chat is closed the idea is updated (title, description, flags) so we are refreshing it
                if (message.type === 'chat' && message.data.closed) await dispatch(reloadCurrentIdea());
            }
        },
        [dispatch, ideaId]
    );
    const fixChat = useCallback(() => chatsService.fixChat(ideaId, INTRO_CHAT_TAG), [ideaId]);

    const [handleSendMessage, handleMessageComplete, generateFixMessage] = useChatActions(
        chat,
        setChat,
        setMessages,
        currentRole !== undefined && currentRole !== UserRole.Viewer ? initializeChat : undefined,
        getAllChatMessages,
        sendMessage,
        fixChat
    );

    const chatId = chat?.id;
    useEffect(() => {
        if (chatId === undefined) return;

        async function handleNewChatMessage(e: RealTimeChatMessageEventData) {
            if (e.ideaId !== ideaId || e.chatId !== chatId) return;

            const newMessage = await chatsService.getChatMessage(ideaId, INTRO_CHAT_TAG, e.chatMessageId);
            setMessages(messages => immutableAddOrUpdate(messages, newMessage, i => i.id === newMessage.id));
        }

        async function handleChatClosed(e: RealTimeChatEventData) {
            if (e.ideaId !== ideaId || e.chatId !== chatId) return;

            setChat(prev => prev && { ...prev, closed: true });
        }

        realTimeUpdatesEventHub.addEventListener('chat', 'messageAdd', handleNewChatMessage);
        realTimeUpdatesEventHub.addEventListener('chat', 'close', handleChatClosed);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('chat', 'messageAdd', handleNewChatMessage);
            realTimeUpdatesEventHub.removeEventListener('chat', 'close', handleChatClosed);
        };
    }, [chatId, ideaId, setChat, setMessages]);

    useLayoutEffect(() => {
        setPageContainerClassName('k-p-0');

        return () => setPageContainerClassName(undefined);
    }, [setPageContainerClassName]);

    return (
        <AILedChatComponent
            addTalking
            addListening
            animateIntro
            readonly={!currentRole || currentRole === UserRole.Viewer || chat?.closed}
            messages={messages}
            onSendMessage={handleSendMessage}
            onMessageComplete={handleMessageComplete}
            generateFixMessage={generateFixMessage}
        />
    );
}
