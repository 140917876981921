import { Dialog, DialogHandle } from '@progress/kendo-react-dialogs';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { PopupPropsContext } from '@progress/kendo-react-popup';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useSingleClickCallback } from '../../hooks/commonHooks';
import { useRealTimeUpdatesPipe } from '../../hooks/realTimeUpdatesHooks';
import { combineClassNames } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import { buildUserViewModel, ReducedUser } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { StartupMembershipList } from '../startup/startupMembershipList';
import { BookmarkTitle } from '../ui/richTextEditor/common';

export function TextEditorModal({
    mainContent,
    leftSideContent,
    rightSideContent,
    textDocumentId,
    canEdit,
    titleIcon,
    title,
    saveIndicator,
    handleDocumentNameUpdate,
    onClose
}: {
    mainContent: ReactNode;
    leftSideContent?: ReactNode;
    rightSideContent?: ReactNode;
    textDocumentId?: number | null;
    canEdit?: boolean;
    title?: string;
    titleIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> & { title?: string };
    saveIndicator?: ReactNode;
    handleDocumentNameUpdate?: (name: string) => Promise<void>;
    onClose?: () => void;
}) {
    const dialogRef = useRef<DialogHandle>(null);

    return (
        <PopupPropsContext.Provider value={p => (dialogRef.current?.element && !p.appendTo ? { ...p, appendTo: dialogRef.current?.element } : p)}>
            <Dialog
                ref={dialogRef}
                className="k-icp-dialog-maximized k-icp-dialog-no-padding k-icp-dialog-with-title-shadow"
                contentStyle={{ overflow: 'visible' }}
                title={
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-flex-1 k-mr-4 k-py-1 k-min-w-0">
                        <TextEditorNameEditor icon={titleIcon} name={title} canEditName={canEdit} handleNameUpdate={handleDocumentNameUpdate} />
                        {saveIndicator}
                        <DocumentViewers documentId={textDocumentId} className="k-ml-auto" />
                    </StackLayout>
                }
                onClose={onClose}
            >
                <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }} className="k-icp-panel-base k-h-full">
                    <div className="k-flex-1 k-overflow-auto" style={{ flexBasis: '18.75%' }}>
                        <div className="k-p-4 k-pr-6 -maxw1">{leftSideContent}</div>
                    </div>
                    <div className="k-flex-1 k-content k-border-r k-border-r-solid k-border-l k-border-l-solid" style={{ flexBasis: '62.5%', maxWidth: 900 }}>
                        {mainContent}
                    </div>
                    <div className="k-flex-1 k-overflow-auto" style={{ flexBasis: '18.75%' }}>
                        {rightSideContent}
                    </div>
                </StackLayout>
            </Dialog>
        </PopupPropsContext.Provider>
    );
}

export function TextEditorNameEditor({
    icon,
    name,
    canEditName,
    handleNameUpdate
}: {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    name?: string;
    canEditName?: boolean;
    handleNameUpdate?: (name: string) => Promise<void>;
}) {
    const [isEditingName, setIsEditingName] = useState(false);
    const [updatedName, setUpdatedName] = useState<string>();
    const nameInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!isEditingName || !nameInputRef.current) return;
        nameInputRef.current.focus();
        nameInputRef.current.select();
    }, [isEditingName]);

    function startEditingName() {
        setUpdatedName(name);
        setIsEditingName(true);
    }

    function cancelEditingName() {
        setUpdatedName(undefined);
        setIsEditingName(false);
    }

    const [isUpdatingName, updateName] = useSingleClickCallback(async () => {
        if ((nameInputRef.current && !nameInputRef.current.validity.valid) || !updatedName || updatedName === name) {
            cancelEditingName();
            return;
        }

        await handleNameUpdate?.(updatedName);
        setIsEditingName(false);
    });

    const Icon = icon;

    return (
        <StackLayout
            align={{ horizontal: 'start', vertical: 'middle' }}
            className={combineClassNames('k-min-w-0', isEditingName ? 'k-flex-grow top-nav-editor--in-edit ' : undefined)}
        >
            <div className="top-nav-editor-breadcrumb top-nav-editor-breadcrumb--small">
                <Icon className="k-icp-icon k-icp-icon-size-6" />
            </div>
            {isEditingName && canEditName ? (
                <input
                    required={true}
                    maxLength={150}
                    ref={nameInputRef}
                    type="text"
                    value={updatedName}
                    onChange={e => setUpdatedName(e.target.value)}
                    onBlur={updateName}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            updateName();
                            e.stopPropagation();
                        } else if (e.key === 'Escape') {
                            cancelEditingName();
                            e.stopPropagation();
                        }
                    }}
                    className="k-input k-input-lg !k-border-none !k-py-0 !k-px-thin !k-font-weight-medium"
                    disabled={isUpdatingName}
                />
            ) : (
                <span
                    className={combineClassNames(
                        'k-text-ellipsis k-flex-shrink-0 k-fs-lg k-font-weight-medium k-px-thin',
                        canEditName && name !== undefined ? 'k-cursor-pointer k-icp-hover-bg-base-8' : undefined
                    )}
                    onClick={canEditName && name !== undefined ? startEditingName : undefined}
                >
                    {name === undefined ? <Skeleton shape="text" style={{ width: 250 }} /> : name}
                </span>
            )}
        </StackLayout>
    );
}

export function DocumentViewers({ documentId, className }: { documentId?: number | null; className?: string }) {
    const realTimeUpdatesPipe = useRealTimeUpdatesPipe();
    const ideaConnectedMembers = useAppSelector(s => s.ideaMembers.membersActivity);
    const usersViewingDocument =
        typeof documentId === 'number' && ideaConnectedMembers
            ? ideaConnectedMembers
                  .filter(m => m.pendingActions.some(action => action.type === 'document' && action.operation === 'View' && action.documentId === documentId))
                  .map(m => m.user)
            : undefined;

    useEffect(() => {
        if (!realTimeUpdatesPipe || typeof documentId !== 'number') return;

        realTimeUpdatesPipe.setPendingAction({ type: 'document', documentId: documentId, operation: 'View' });

        function clearDocumentPendingAction() {
            if (!realTimeUpdatesPipe) return;
            realTimeUpdatesPipe.setPendingAction(undefined);
        }

        window.addEventListener('beforeunload', clearDocumentPendingAction);

        return () => {
            clearDocumentPendingAction();
            window.removeEventListener('beforeunload', clearDocumentPendingAction);
        };
    }, [documentId, realTimeUpdatesPipe]);

    return <StartupMembershipList users={usersViewingDocument?.map(u => buildUserViewModel(u))} className={combineClassNames('k-my--2', className)} />;
}

export function TextEditorInfoSection({ title, children, relaxed }: { title: string; children?: ReactNode; relaxed?: boolean }) {
    return (
        <div>
            <div className={combineClassNames('k-font-weight-bold', relaxed ? 'k-mb-4' : 'k-mb-2')}>{title}</div>
            {children}
        </div>
    );
}

export function TextEditorInfo({ title, children }: { title: string; children?: ReactNode }) {
    return (
        <div className="k-fs-sm">
            <div className="k-icp-subtle-text k-mb-thin">{title}</div>
            {children}
        </div>
    );
}

export function TextEditorBookmarksList({
    bookmarks,
    selectedSectionIdx,
    onBookmarkSelected,
    removeEmptyBookmarks = false
}: {
    bookmarks?: BookmarkTitle[];
    selectedSectionIdx?: number;
    onBookmarkSelected?: (sectionIdx: number) => void;
    removeEmptyBookmarks?: boolean;
}) {
    if (bookmarks && !bookmarks.length) return <div className="k-fs-sm k-icp-subtle-text">No sections yet</div>;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4 k-fs-sm">
            {bookmarks ? (
                bookmarks.map((bookmark, index) =>
                    removeEmptyBookmarks && !bookmark.title ? null : (
                        <div
                            key={index}
                            className={combineClassNames(
                                'k-cursor-pointer k-text-ellipsis k-max-w-full',
                                selectedSectionIdx === index ? 'k-font-weight-semibold' : undefined
                            )}
                            style={bookmark.indentation ? { marginLeft: `${bookmark.indentation * 4}px` } : undefined}
                            onClick={onBookmarkSelected && (() => onBookmarkSelected(index))}
                        >
                            {bookmark.title || <span className="k-icp-subtle-text">New section title...</span>}
                        </div>
                    )
                )
            ) : (
                <>
                    <Skeleton shape="text" style={{ width: '100%' }} />
                    <Skeleton shape="text" style={{ width: '100%' }} />
                </>
            )}
        </StackLayout>
    );
}

export function TextEditorUserActionDate({ date, user }: { date?: Date | null; user?: ReducedUser | null }) {
    if (date === null) return null;
    if (date === undefined) return <Skeleton shape="text" style={{ width: '100%' }} />;

    return (
        <>
            {dateTimeService.isToday(date) ? dateTimeService.stringifyDuration(new Date(), date, true) : dateTimeService.stringifyToDay(date, false, true)}
            {user && (
                <>
                    {' '}
                    by {user.firstName} {user.lastName}
                </>
            )}
        </>
    );
}
