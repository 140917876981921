import { appConfig } from '../config';
import { ErrorWithOperationDisplayName } from './common';
import { dateTimeService } from './dateTimeService';
import { HttpServiceBase, PagedResponse, RequestMethod } from './httpServiceBase';
import { ReducedUser } from './usersService';

export enum NoteTemplateType {
    DailyPlanning = 'daily-planning',
    MeetingNotes = 'meeting-notes',
    ReleaseNotes = 'release-notes'
}

export interface NoteTag {
    id: number;
    name: string;
    colorCode: string;
    noteCount?: number;
}

export interface ReducedNote {
    id: number;
    name: string;
    contributors: ReducedUser[];
    tags?: NoteTag[];
    updatedOn: Date;
    ideaId: number;
    version: number;
    tagIdsOrder: number[];
}

export interface Note extends ReducedNote {
    documentId?: number;
    createdBy: ReducedUser;
    createdOn: Date;
    updatedBy: ReducedUser;
}

export type Notes = PagedResponse<'notes', ReducedNote>;

export type Tags = PagedResponse<'tags', NoteTag>;

export enum NoteSortOrder {
    TitleAsc = 'TitleAsc',
    TitleDesc = 'TitleDesc',
    DateAsc = 'DateAsc',
    DateDesc = 'DateDesc'
}

export interface FileInfo {
    name: string;
    key: string;
}

export interface CreateNoteDto {
    title?: string;
    file?: FileInfo;
    tagIds?: number[];
}

export interface UpdateNoteDto {
    title?: string;
    tagIds?: number[];
}

export interface TagDto {
    name: string;
    colorCode?: string;
}

// Service implementation
class NotesService extends HttpServiceBase {
    constructor() {
        super('/api/notes');
    }

    private static ensureNoteDateFields(note: ReducedNote): ReducedNote {
        dateTimeService.ensureDateField(note, 'updatedOn');
        return note;
    }

    private static ensureFullNoteDateFields(note: Note): Note {
        dateTimeService.ensureDateField(note, 'updatedOn');
        dateTimeService.ensureDateField(note, 'createdOn');
        return note;
    }

    @ErrorWithOperationDisplayName('Get notes')
    getNotes(ideaId: string, orderBy?: NoteSortOrder, afterId?: number, skip?: number, take?: number, tag?: string, filter?: string): Promise<Notes> {
        const queryParams: URLSearchParams = new URLSearchParams();
        this.addQueryParamIfPresent(queryParams, 'orderBy', orderBy);
        this.addQueryParamIfPresent(queryParams, 'afterId', afterId?.toString());
        this.addQueryParamIfPresent(queryParams, 'skip', skip?.toString());
        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());
        this.addQueryParamIfPresent(queryParams, 'tag', tag);
        this.addQueryParamIfPresent(queryParams, 'filter', filter);

        return this.performRequest<Notes>({
            path: `/${ideaId}/notes`,
            queryParams
        }).then(response => {
            response.notes.forEach(NotesService.ensureNoteDateFields);
            return response;
        });
    }

    @ErrorWithOperationDisplayName('Get note')
    getNote(ideaId: string, noteId: number): Promise<Note> {
        return this.performRequest<Note>({
            path: `/${ideaId}/notes/${noteId}`
        }).then(NotesService.ensureFullNoteDateFields);
    }

    @ErrorWithOperationDisplayName('Create note')
    createNote(ideaId: string, noteTemplateType?: NoteTemplateType): Promise<Note> {
        return this.performRequest<Note>({
            path: `/${ideaId}/notes`,
            method: RequestMethod.POST,
            body: {
                templateType: noteTemplateType
            }
        }).then(NotesService.ensureFullNoteDateFields);
    }

    @ErrorWithOperationDisplayName('Update note')
    updateNote(ideaId: string, noteId: number, dto: UpdateNoteDto): Promise<Note> {
        return this.performRequest<Note>({
            path: `/${ideaId}/notes/${noteId}`,
            method: RequestMethod.PATCH,
            body: dto
        }).then(NotesService.ensureFullNoteDateFields);
    }

    @ErrorWithOperationDisplayName('Append current user as contributor')
    appendCurrentUserAsContributor(ideaId: string, noteId: number): Promise<ReducedUser[]> {
        return this.performRequest<ReducedUser[]>({
            path: `/${ideaId}/notes/${noteId}/contributors`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Delete note')
    async deleteNote(ideaId: string, noteId: number): Promise<void> {
        await this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/notes/${noteId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Restore note')
    restoreNote(ideaId: string, noteId: number): Promise<Note> {
        return this.performRequest<Note>({
            path: `/${ideaId}/notes/${noteId}/restore`,
            method: RequestMethod.POST
        }).then(NotesService.ensureFullNoteDateFields);
    }

    @ErrorWithOperationDisplayName('Duplicate note')
    duplicateNote(ideaId: string, noteId: number): Promise<Note> {
        return this.performRequest<Note>({
            path: `/${ideaId}/notes/${noteId}/duplicate`,
            method: RequestMethod.POST
        }).then(NotesService.ensureFullNoteDateFields);
    }

    @ErrorWithOperationDisplayName('Get tags')
    getTags(ideaId: string, filter?: string, includeCounts: boolean = false, afterId?: number, skip?: number, take?: number): Promise<Tags> {
        const queryParams: URLSearchParams = new URLSearchParams();
        this.addQueryParamIfPresent(queryParams, 'search', filter);
        this.addQueryParamIfPresent(queryParams, 'includeCounts', includeCounts.toString());
        this.addQueryParamIfPresent(queryParams, 'afterId', afterId?.toString());
        this.addQueryParamIfPresent(queryParams, 'skip', skip?.toString());
        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());

        return this.performRequest<Tags>({
            path: `/${ideaId}/tags`,
            queryParams
        });
    }

    @ErrorWithOperationDisplayName('Get tag by ID')
    getTagById(ideaId: string, tagId: number): Promise<NoteTag> {
        return this.performRequest<NoteTag>({
            path: `/${ideaId}/tags/${tagId}`
        });
    }

    @ErrorWithOperationDisplayName('Create tag')
    async createTag(ideaId: string, name: string, colorCode?: string): Promise<NoteTag> {
        if (!colorCode) {
            const tags = await this.getTags(ideaId);
            colorCode = this.getLeastUsedColor(tags.tags);
        }

        return this.performRequest<NoteTag>({
            path: `/${ideaId}/tags`,
            method: RequestMethod.POST,
            body: { name, colorCode }
        });
    }

    @ErrorWithOperationDisplayName('Ensure tag')
    async ensureTag(ideaId: string, name: string, colorCode?: string): Promise<NoteTag> {
        if (!colorCode) {
            const tags = await this.getTags(ideaId);
            colorCode = this.getLeastUsedColor(tags.tags);
        }

        return this.performRequest<NoteTag>({
            path: `/${ideaId}/tags/ensure`,
            method: RequestMethod.POST,
            body: { name, colorCode }
        });
    }

    @ErrorWithOperationDisplayName('Update tag')
    updateTag(ideaId: string, tagId: number, name?: string, colorCode?: string): Promise<NoteTag> {
        return this.performRequest<NoteTag>({
            path: `/${ideaId}/tags/${tagId}`,
            method: RequestMethod.PATCH,
            body: { name, colorCode }
        });
    }

    @ErrorWithOperationDisplayName('Delete tag')
    async deleteTag(ideaId: string, tagId: number): Promise<void> {
        await this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/tags/${tagId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Restore tag')
    restoreTag(ideaId: string, tagId: number): Promise<NoteTag> {
        return this.performRequest<NoteTag>({
            path: `/${ideaId}/tags/${tagId}/restore`,
            method: RequestMethod.POST
        });
    }

    getLeastUsedColor(tags: NoteTag[]): string {
        if (!tags.length) {
            return appConfig.notes.tagsPalette[0];
        }

        const colorOccurrences: Record<string, number> = {};
        tags.forEach(tag => {
            colorOccurrences[tag.colorCode] = (colorOccurrences[tag.colorCode] ?? 0) + 1;
        });

        let leastUsedColorIndex = 0;
        let minOccurrenceCount = tags.length + 1;
        appConfig.notes.tagsPalette.forEach((colorCode, index) => {
            const occurrenceCount = colorOccurrences[colorCode] ?? 0;
            if (occurrenceCount < minOccurrenceCount) {
                minOccurrenceCount = occurrenceCount;
                leastUsedColorIndex = index;
            }
        });

        return appConfig.notes.tagsPalette[leastUsedColorIndex];
    }

    getLeastUsedColorForAllTags(ideaId: string): Promise<string> {
        return this.getTags(ideaId).then(tags => this.getLeastUsedColor(tags.tags));
    }
}

export const notesService = new NotesService();
