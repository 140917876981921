import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCacheBust } from '../../hooks/commonHooks';
import rocketImageUrl from '../../images/rocket-sketch.svg';
import { googleTagManager } from '../../scripts/googleTagManager';
import { Account, accountsService } from '../../services/accountsService';
import { getPersonFullName } from '../../services/common';
import { ideasService } from '../../services/ideasService';
import { useAppSelector } from '../../state/hooks';
import { ValidatedInput, defaultValidators, requiredValidator } from '../ui/inputs';
import { H1 } from '../ui/typography';

const startupOwnerValidators = [requiredValidator('Owner')];
export const CreateStartupModal = ({ onClose }: { onClose: () => void }) => {
    const navigate = useNavigate();
    const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);
    const constAnimationCacheBust = useCacheBust();
    const [associatedAccounts, setAssociatedAccounts] = useState<Account[] | undefined>();
    const currentUser = useAppSelector(s => s.user);

    useEffect(() => {
        accountsService.getAssociatedAccounts().then(setAssociatedAccounts);
    }, []);

    const associatedAccountsDropdownData = associatedAccounts?.map(acc => ({
        text: getPersonFullName(acc.owner.firstName, acc.owner.lastName) + (currentUser && currentUser.userId === acc.owner.userId ? ' (You)' : ''),
        uniqueId: acc.uniqueId
    }));

    const onSubmitCreateStartupForm = async (data: { [name: string]: any }) => {
        setSubmitButtonEnabled(false);
        try {
            const createdStartup = await ideasService.create({ title: data.title, accountUniqueId: data.startupOwner.uniqueId });
            googleTagManager.reportStartupCreatedEvent();
            navigate(ideasService.getStartupRedirectUrlById(false, createdStartup.uniqueId));
        } finally {
            setSubmitButtonEnabled(true);
        }
    };

    const isLoading = !associatedAccountsDropdownData;

    return (
        <Dialog onClose={onClose} closeIcon={true} title=" " className="k-icp-dialog-no-title">
            <StackLayout className="k-gap-16 create-startup-modal-content" align={{ horizontal: 'center', vertical: 'bottom' }}>
                <Form
                    key={isLoading ? 'loading' : 'form'}
                    onSubmit={onSubmitCreateStartupForm}
                    initialValues={{ startupOwner: associatedAccountsDropdownData?.[0] }}
                    render={formRenderProps => (
                        <FormElement className="k-flex-1">
                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-10">
                                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-3">
                                    <H1>What will you call your startup?</H1>
                                    <span className="k-icp-subtle-text">Don't overthink it - you can always change it as you make progress.</span>
                                </StackLayout>
                                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-8">
                                    <Field
                                        name="title"
                                        component={ValidatedInput}
                                        label="Startup name"
                                        maxLength={150}
                                        validator={defaultValidators.ideaTitleValidators}
                                        autoFocus={true}
                                        disabled={isLoading}
                                    />
                                    <Field
                                        name="startupOwner"
                                        component={ValidatedInput}
                                        label="Startup owner"
                                        inputType={DropDownList}
                                        validator={startupOwnerValidators}
                                        data={associatedAccountsDropdownData}
                                        textField="text"
                                        disabled={!associatedAccountsDropdownData || associatedAccountsDropdownData.length <= 1}
                                        dataItemKey="uniqueId"
                                        loading={isLoading}
                                    />
                                </StackLayout>
                                <Button type="submit" themeColor="primary" size="large" disabled={!submitButtonEnabled || !formRenderProps.allowSubmit}>
                                    Take me to my Journey
                                </Button>
                            </StackLayout>
                        </FormElement>
                    )}
                ></Form>

                <div className="k-flex-1">
                    <img
                        src={`${rocketImageUrl}?t=${constAnimationCacheBust}`}
                        className="responsive-image k-display-block"
                        width="400"
                        height="382"
                        alt="Create your startup"
                    />
                </div>
            </StackLayout>
        </Dialog>
    );
};
