import { useEffect, useState } from 'react';
import { ChatMessage, chatsService } from '../../services/chatsService';
import { immutableAddOrUpdate } from '../../services/common';
import { RealTimeChatMessageEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { AILedChatComponent } from './aiLedChat';

export function ChatView({ ideaId, chatTag, chatId }: { ideaId: string; chatTag: string; chatId: number }) {
    const [messages, setMessages] = useState<ChatMessage[]>();

    useEffect(() => {
        chatsService.getAllChatMessages(ideaId, chatTag).then(setMessages);
    }, [chatTag, ideaId]);

    useEffect(() => {
        async function handleNewChatMessage(e: RealTimeChatMessageEventData) {
            if (e.ideaId !== ideaId || e.chatId !== chatId) return;

            const newMessage = await chatsService.getChatMessage(ideaId, chatTag, e.chatMessageId);
            setMessages(messages => immutableAddOrUpdate(messages, newMessage, i => i.id === newMessage.id));
        }

        realTimeUpdatesEventHub.addEventListener('chat', 'messageAdd', handleNewChatMessage);

        return () => realTimeUpdatesEventHub.removeEventListener('chat', 'messageAdd', handleNewChatMessage);
    }, [chatId, chatTag, ideaId]);

    return <AILedChatComponent messages={messages} readonly hideMessageInput />;
}
