import { Input, InputChangeEvent, InputHandle } from '@progress/kendo-react-inputs';
import { useRef, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { combineClassNames } from '../../services/common';

export function SearchBar({
    placeholder,
    disabled,
    className,
    isLoading,
    initialValue,
    onChange
}: {
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    isLoading?: boolean;
    initialValue?: string;
    onChange: (text?: string) => void;
}) {
    const [searchText, setSearchText] = useState(initialValue);
    const searchBoxRef = useRef<InputHandle>(null);

    const handleOnChange = (e: InputChangeEvent) => {
        setSearchText(e.value);
        onChange(e.value);
    };

    return (
        <div className={combineClassNames('k-pos-relative', className)}>
            <Input ref={searchBoxRef} disabled={disabled} className="!k-pr-8" placeholder={placeholder} value={searchText ?? ''} onChange={handleOnChange} />
            <div className="k-pos-absolute k-d-flex k-align-items-center k-top-0 k-right-0 k-mr-2 k-h-8">
                {isLoading ? <div className="k-icon k-i-loading k-input-loading-icon" /> : <SearchIcon className="k-icp-icon k-icp-icon-size-4" />}
            </div>
        </div>
    );
}
