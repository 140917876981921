import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForceRender } from '../../hooks/commonHooks';
import { combineClassNames } from '../../services/common';
import { RealTimeUpdateTimerEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { Timer, timersService } from '../../services/timersService';

export type InlineTimerHandle = {
    pauseIfRunning: () => Promise<void>;
    startIfNotRunning: () => Promise<void>;
};

const maxElapsedTimeInMinutes = 1000;
export const InlineTimer = forwardRef<
    InlineTimerHandle,
    { ideaId: string; timerId: number; onStart?: () => void; readonly?: boolean; hideSeconds?: boolean; hideActions?: boolean; hideLabel?: boolean }
>(function InlineTimer({ ideaId, timerId, onStart, readonly, hideSeconds, hideActions, hideLabel }, ref) {
    const [timer, setTimer] = useState<Timer>();
    const forceRender = useForceRender();
    const [disableActions, setDisableActions] = useState(false);

    useImperativeHandle(
        ref,
        () => ({
            async pauseIfRunning() {
                if (!timer?.isRunning) return;
                setDisableActions(true);
                const pausedTimer = await timersService.pauseTimer(ideaId, timerId).finally(() => setDisableActions(false));
                setTimer(pausedTimer);
            },
            async startIfNotRunning() {
                if (timer?.isRunning) return;
                setDisableActions(true);
                const startedTimer = await timersService.startTimer(ideaId, timerId).finally(() => setDisableActions(false));
                setTimer(startedTimer);
            }
        }),
        [ideaId, timerId, timer?.isRunning]
    );

    useEffect(() => {
        function loadTimer() {
            timersService.getTimer(ideaId, timerId).then(setTimer);
        }

        loadTimer();

        function onTimerUpdated(e: RealTimeUpdateTimerEventData) {
            if (e.ideaId !== ideaId || e.timerId !== timerId) return;

            loadTimer();
        }

        realTimeUpdatesEventHub.addEventListener('timer', 'update', onTimerUpdated);

        return () => realTimeUpdatesEventHub.removeEventListener('timer', 'update', onTimerUpdated);
    }, [ideaId, timerId]);

    useEffect(() => {
        if (!timer?.isRunning) return;

        const updateTimerInterval = setInterval(forceRender, 1000);

        return () => clearInterval(updateTimerInterval);
    }, [forceRender, timer?.isRunning]);

    const elapsedSecondsSinceStart = timer && timer.isRunning && timer.started ? Math.floor(Math.max(Date.now() - timer.started.getTime(), 0) / 1000) : 0;
    const totalElapsedSeconds = timer ? timer.elapsedSeconds + elapsedSecondsSinceStart : 0;
    const totalElapsedMinutes = Math.floor(totalElapsedSeconds / 60);
    const currentMinuteElapsedSeconds = totalElapsedSeconds % 60;
    const isOverdue = timer ? totalElapsedSeconds >= timer.alarmSeconds : false;
    const maxTimeExceeded = totalElapsedMinutes > maxElapsedTimeInMinutes;

    async function executeMainTimerAction() {
        if (!timer) return;

        if (!timer.isRunning) onStart?.();

        setDisableActions(true);
        const timerActionPromise = timer.isRunning ? timersService.pauseTimer(ideaId, timerId) : timersService.startTimer(ideaId, timerId);
        const updatedTimer = await timerActionPromise.finally(() => setDisableActions(false));
        setTimer(updatedTimer);
    }

    function resetTimer() {
        setDisableActions(true);
        timersService
            .resetTimer(ideaId, timerId)
            .then(setTimer)
            .finally(() => setDisableActions(false));
    }

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
            {!hideLabel && <span className="k-icp-subtle-text">Time:</span>}
            <div style={{ minWidth: !hideSeconds ? 100 : undefined }}>
                <StackLayout
                    align={{ horizontal: 'start', vertical: 'middle' }}
                    className={combineClassNames('k-gap-1 k-w-fit', isOverdue ? 'k-icp-bg-error-8 k-px-1 k-mx--1 k-py-hair k-my--1px k-rounded-sm' : undefined)}
                >
                    <strong>{maxTimeExceeded ? `${maxElapsedTimeInMinutes}+` : totalElapsedMinutes}</strong>
                    <span className="k-icp-subtle-text">min</span>
                    {!maxTimeExceeded && !hideSeconds && (
                        <>
                            <strong>{currentMinuteElapsedSeconds}</strong>
                            <span className="k-icp-subtle-text">sec</span>
                        </>
                    )}
                </StackLayout>
            </div>
            {!hideActions && (
                <>
                    <Button
                        type="button"
                        icon={timer?.isRunning ? 'pause-sm' : 'play-sm'}
                        onClick={executeMainTimerAction}
                        disabled={!timer || disableActions || readonly}
                        className="k-ml-2"
                    />
                    <Button
                        type="button"
                        icon="reset-sm"
                        onClick={resetTimer}
                        disabled={(!timer?.isRunning && !totalElapsedMinutes) || disableActions || readonly}
                    />
                </>
            )}
        </StackLayout>
    );
});
