import React from 'react';
import { combineClassNames } from '../../services/common';

export type RoundedIconBackgroundSize = 'large' | 'medium';
export type RoundedIconBackgroundTheme = 'base' | 'secondary';

export interface RoundedIconBackgroundProps {
    /**
     * The SVG icon component to render
     */
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

    /**
     * Size of the background container
     * - large: 64x64px with 40x40px icon
     * - medium: 32x32px with 16x16px icon
     * @default 'large'
     */
    size?: RoundedIconBackgroundSize;

    /**
     * Background color theme
     * - base: Uses k-icp-panel-base
     * - secondary: Uses k-icp-bg-secondary-8
     * @default 'secondary'
     */
    theme?: RoundedIconBackgroundTheme;

    /**
     * Additional CSS class names
     */
    className?: string;
}

export function RoundedIconBackground({ icon, size = 'medium', theme = 'base', className }: RoundedIconBackgroundProps) {
    const Icon = icon;

    const containerSize = size === 'large' ? '64px' : '32px';
    const iconSize = size === 'large' ? 10 : 4;

    // Determine background color based on theme
    const bgColorClass = theme === 'secondary' ? 'k-icp-bg-secondary-8' : 'k-icp-panel-base';

    return (
        <div
            style={{ width: containerSize, height: containerSize }}
            className={combineClassNames('k-d-flex k-align-items-center k-justify-content-center k-rounded-full', bgColorClass, className)}
        >
            <Icon className={`k-icp-icon k-icp-icon-size-${iconSize}`} />
        </div>
    );
}
