import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { Popover } from '@progress/kendo-react-tooltip';
import { CSSProperties, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { appConfig } from '../../config';
import { useFittingPopoverCallout, useReRenderOn, useScheduledReRender } from '../../hooks/commonHooks';
import { useSoleToggle } from '../../hooks/toggleHooks';
import { ReactComponent as TimerIcon } from '../../icons/trial-timer.svg';
import emailImageUrl from '../../images/email-illustration.svg';
import { combineClassNames } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import { LicenseType, User, UserStatus } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { getButtonClassName } from '../common/DivButton';

const trialNoticeMaxDays = appConfig.subscription.trialDurationInDays;
const autoShowOnDayPriorExpiration = 3;
export function TrialNoticeNavItem() {
    const toggleRef = useRef<Button>(null);
    const [show, toggleShow] = useSoleToggle(false);
    const noticeIconRef = useRef<SVGSVGElement>(null);
    const [popoverId, onPopoverPosition] = useFittingPopoverCallout('horizontal', noticeIconRef.current);

    const currentUser = useAppSelector(s => s.user);
    const trialNoticeData = resolveTrialNoticeData(currentUser);
    useScheduledReRender(trialNoticeData ? 1000 * 60 * 60 : undefined);
    useReRenderOn(trialNoticeData?.hideUntil);

    useAutoShowPriorExpiration(currentUser?.userId, autoShowOnDayPriorExpiration, trialNoticeData, show, toggleShow);

    if (!trialNoticeData || trialNoticeData.hideUntil) return null;
    const { trialExpirationInDays, trialExpired, needsEmailConfirmation } = trialNoticeData;

    const iconPercentageProgress = trialExpired ? 100 : ((trialNoticeMaxDays - trialExpirationInDays) * 100) / trialNoticeMaxDays;
    const iconProgressStyle = { '--progress-percentage': iconPercentageProgress } as CSSProperties;

    return (
        <div>
            <Button
                ref={toggleRef}
                onClick={e => {
                    e.stopPropagation();
                    toggleShow();
                }}
                fillMode="flat"
                size="large"
                title={
                    trialExpired
                        ? 'Free trial expired'
                        : trialExpirationInDays === 0
                        ? 'Free trial expires today'
                        : trialExpirationInDays === 1
                        ? 'Free trial expires tomorrow'
                        : `Free trial expires in ${trialExpirationInDays} days`
                }
                className="k-icp-tooltip-trigger k-icp-svg-icon-button !k-font-normal"
            >
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                    <TimerIcon
                        className={combineClassNames('trial-timer-icon k-icp-icon', iconPercentageProgress === 100 ? 'trial-timer-over' : undefined)}
                        ref={noticeIconRef}
                        style={{ ...iconProgressStyle, '--icon-scale': 24 / 16 } as CSSProperties}
                    />
                    {Math.max(trialExpirationInDays, 0)}
                </StackLayout>
            </Button>
            <Popover
                id={popoverId}
                show={show}
                anchor={toggleRef.current?.element}
                collision={{ horizontal: 'fit', vertical: 'flip' }}
                position="bottom"
                className="!k-pr-3 top-nav-popover"
                margin={{ vertical: 10, horizontal: 0 }}
                positionMode="fixed"
                onPosition={onPopoverPosition}
            >
                <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6 k-my-2">
                    {needsEmailConfirmation ? (
                        <img src={emailImageUrl} width="64" height="64" alt="Check your email" />
                    ) : (
                        <TimerIcon
                            className={combineClassNames(
                                'trial-timer-icon k-icp-icon k-text-dark k-icp-icon-padded',
                                iconPercentageProgress === 100 ? 'trial-timer-over' : undefined
                            )}
                            width="64"
                            height="64"
                            strokeWidth={2}
                            style={{ ...iconProgressStyle, '--icon-scale': 64 / 16 } as CSSProperties}
                        />
                    )}

                    <div className="k-text-center">
                        <strong className="k-display-block k-mb-2">
                            {needsEmailConfirmation ? (
                                'Verify your email'
                            ) : (
                                <>
                                    Your free trial period{' '}
                                    {trialExpired
                                        ? 'has ended'
                                        : trialExpirationInDays === 0
                                        ? 'ends today'
                                        : trialExpirationInDays === 1
                                        ? 'ends tomorrow'
                                        : `ends in ${trialExpirationInDays} days`}
                                </>
                            )}
                        </strong>
                        <div>
                            {needsEmailConfirmation
                                ? "Check your inbox for a message with a confirmation link. If you don't see it, you may need to check your spam folder."
                                : trialExpired
                                ? 'Subscribe now to regain access to Icanpreneur.'
                                : 'Upgrade now to ensure uninterrupted access to Icanpreneur.'}
                        </div>
                    </div>

                    {!needsEmailConfirmation && (
                        <Link to="/account/subscribe" className={getButtonClassName({ themeColor: 'primary' })}>
                            {trialExpired ? 'Subscribe now' : 'Upgrade now'}
                        </Link>
                    )}
                </StackLayout>
            </Popover>
        </div>
    );
}

function useAutoShowPriorExpiration(
    userId: string | undefined,
    daysPriorExpiration: number,
    trialNoticeData: TrialNoticeData,
    show: boolean,
    toggleShow: () => void
) {
    const autoShowConditionsMet =
        userId !== undefined &&
        trialNoticeData !== undefined &&
        !trialNoticeData.hideUntil &&
        !trialNoticeData.trialExpired &&
        trialNoticeData.trialExpirationInDays <= daysPriorExpiration;

    useEffect(() => {
        if (!autoShowConditionsMet) return;
        if (isTrialNoticeAutoShown(userId, daysPriorExpiration)) return;

        if (!show) toggleShow();
    }, [autoShowConditionsMet, daysPriorExpiration, show, toggleShow, userId]);

    // Marking the notice as shown is in a separate effect (not in the previous one) because on dev due to the strict mode, the notice is marked as shown but the component is immediately unmounted and mounted again and the notice is not actually shown
    useEffect(() => {
        if (!autoShowConditionsMet || isTrialNoticeAutoShown(userId, daysPriorExpiration) || !show) return;

        markTrialNoticeAutoShown(userId, daysPriorExpiration);
    }, [autoShowConditionsMet, daysPriorExpiration, show, userId]);
}

function getTrialNoticeAutoShowStorageKey(daysPriorExpiration: number) {
    return `icp.tn-${daysPriorExpiration}`;
}

function isTrialNoticeAutoShown(userId: string, daysPriorExpiration: number) {
    const key = getTrialNoticeAutoShowStorageKey(daysPriorExpiration);
    const value = localStorage.getItem(key);
    if (!value) return false;

    return value === userId;
}

function markTrialNoticeAutoShown(userId: string, daysPriorExpiration: number) {
    const key = getTrialNoticeAutoShowStorageKey(daysPriorExpiration);
    localStorage.setItem(key, userId);
}

type TrialNoticeData = ReturnType<typeof resolveTrialNoticeData>;
function resolveTrialNoticeData(currentUser: User | null) {
    if (!currentUser) return undefined;

    const currentLicense = currentUser.license;
    if (currentLicense.type !== LicenseType.Trial) return undefined;

    const currentLicenseExpiration = new Date(currentLicense.expires);
    const currentTime = new Date();
    const maxTrialExpiration = new Date(currentTime);
    dateTimeService.addUTCDays(maxTrialExpiration, trialNoticeMaxDays);

    const trialExpirationInDays = dateTimeService.getDurationInDays(currentTime, currentLicenseExpiration);
    const trialExpired = currentTime > currentLicenseExpiration;
    const needsEmailConfirmation = currentUser.status === UserStatus.New;

    let hideUntil: Date | undefined;
    if (currentLicenseExpiration > maxTrialExpiration) {
        hideUntil = new Date(currentLicenseExpiration);
        dateTimeService.addUTCDays(hideUntil, -trialNoticeMaxDays);
    }

    return {
        trialExpirationInDays,
        trialExpired,
        needsEmailConfirmation,
        hideUntil
    };
}
