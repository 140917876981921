import { StackLayout } from '@progress/kendo-react-layout';
import { RemoteDataComboBox } from '../../components/common/remoteDataComboBox';
import { FormFieldProps } from '../../components/ui/inputs';
import { ReactComponent as ScriptIcon } from '../../icons/script.svg';
import { InterviewScript2, interviewScripts2Service } from '../../services/interviewScripts2Service';
import { Interview2Type } from '../../services/interviewsV2Service';

export function InterviewScriptSelector({
    id,
    disabled,
    ideaId,
    value,
    onChange,
    placeholder,
    valid,
    customerSegmentId,
    interviewType
}: {
    ideaId: string;
    placeholder?: string;
    customerSegmentId?: number;
    interviewType?: Interview2Type;
} & FormFieldProps<InterviewScript2 | null | undefined>) {
    return (
        <RemoteDataComboBox
            id={id}
            dataItemKey="id"
            textField="name"
            placeholder={placeholder ?? 'Add details...'}
            disabled={disabled}
            value={value}
            onChange={onChange}
            emptyListText="No script found."
            renderItem={script => <ScriptSimpleView script={script} />}
            fetchItems={_ =>
                interviewScripts2Service.getScripts(
                    ideaId,
                    interviewType || typeof customerSegmentId === 'number' ? { interviewType, customerSegmentId } : undefined
                )
            }
            valid={valid}
        />
    );
}

function ScriptSimpleView({ script }: { script: InterviewScript2 }) {
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-fs-sm">
            <ScriptIcon className="k-icp-icon k-icp-icon-size-8" />
            {script.name}
        </StackLayout>
    );
}
