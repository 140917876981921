import { Button } from '@progress/kendo-react-buttons';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode } from 'react';
import { ReactComponent as ArrowRightCircleIcon } from '../../icons/arrow-right-circle.svg';
import { ReactComponent as ArrowRightIcon } from '../../icons/arrow-right.svg';
import { ReactComponent as FileTranscriptIcon } from '../../icons/file-transcript.svg';
import { ReactComponent as QuoteIcon } from '../../icons/quote-3.svg';
import { ReactComponent as ThumbsUpIcon } from '../../icons/thumbs-up.svg';
import { combineClassNames } from '../../services/common';
import { InterviewInsight, InterviewQualityAssessmentRecommendation } from '../../services/interviewsV2Service';
import { ScoreView } from '../interview2/common';
import { ClickableStackLayout } from '../ui/clickableStackLayout';
import { RoundedIconBackground } from '../ui/roundedIconBackground';

export interface Section {
    id: number;
    text: string;
    scorePercentage?: number;
}

interface SectionProps {
    sectionName: string;
    sections: Section[];
    selectedSectionId?: number;
    onSelectedSection: (id: number) => void;
    className?: string;
}

export function SectionsView({ sectionName, sections, selectedSectionId, onSelectedSection, className }: SectionProps) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className={combineClassNames('k-gap-2', className)}>
            <div className="k-icp-text-smaller k-text-uppercase">{sectionName}</div>
            {sections.map(section => (
                <SectionButton
                    key={section.id}
                    scorePercentage={section.scorePercentage}
                    id={section.id}
                    text={section.text}
                    selected={selectedSectionId === section.id}
                    onClick={onSelectedSection}
                />
            ))}
        </StackLayout>
    );
}

export function AreasAndStrengthsSection({
    sections,
    selectedSectionId,
    className,
    onSelectedSectionId
}: {
    sections: Section[];
    selectedSectionId?: number;
    onSelectedSectionId: (id: number) => void;
    className?: string;
}) {
    const areasForImprovement = sections.filter(section => section.scorePercentage !== undefined && section.scorePercentage > 0);
    const areasOfStrength = sections.filter(section => section.scorePercentage !== undefined && section.scorePercentage <= 0);
    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'stretch', vertical: 'top' }}
            className={combineClassNames('k-gap-6', className)}
            style={{ maxWidth: 320 }}
        >
            {areasForImprovement.length > 0 && (
                <SectionsView
                    sectionName="Areas for improvement"
                    className="k-flex-1"
                    sections={areasForImprovement}
                    selectedSectionId={selectedSectionId}
                    onSelectedSection={onSelectedSectionId}
                />
            )}
            {areasOfStrength.length > 0 && (
                <SectionsView
                    sectionName="Strengths"
                    className="k-flex-1"
                    sections={areasOfStrength}
                    selectedSectionId={selectedSectionId}
                    onSelectedSection={onSelectedSectionId}
                />
            )}
        </StackLayout>
    );
}

interface SectionButtonProps {
    id: number;
    text: string;
    selected: boolean;
    onClick: (id: number) => void;
    scorePercentage?: number;
}

function SectionButton({ id, text, selected, onClick, scorePercentage }: SectionButtonProps) {
    const isPositive = scorePercentage === 0;
    const hasScore = typeof scorePercentage === 'number';
    return (
        <ClickableStackLayout
            orientation="horizontal"
            onClick={() => {
                onClick?.(id);
            }}
            align={{ horizontal: 'start', vertical: 'middle' }}
            className={`k-justify-content-between k-w-full k-border k-border-solid k-rounded-md k-p-4 k-cursor-pointer  k-gap-2 ${
                selected ? 'k-border-secondary k-icp-hover-bg-secondary-8' : 'k-icp-component-border k-icp-hover-bg-base-4'
            }`}
        >
            <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                {!isPositive && hasScore && <SectionImpactLabel scorePercentage={scorePercentage} />}
                <div className={selected ? 'k-font-semibold k-text-secondary' : undefined}>
                    <span className="k-mr-2 k-align-middle">{text}</span>
                    {hasScore && (
                        <AnalysisChip
                            className={combineClassNames('k-font-normal k-align-middle', isPositive ? 'k-px-2' : 'k-px-1.5')}
                            theme={selected ? 'secondary' : 'base'}
                        >
                            {isPositive ? <ThumbsUpIcon className="k-icp-icon k-icp-icon-size-4" /> : `+${scorePercentage}%`}
                        </AnalysisChip>
                    )}
                </div>
            </StackLayout>
            <ArrowRightIcon className={combineClassNames('k-shrink-0 k-icp-icon', selected ? 'k-text-secondary' : undefined)} />
        </ClickableStackLayout>
    );
}

function SectionImpactLabel({ scorePercentage }: { scorePercentage: number }) {
    const viewData: { colorClassName: string; impactLabel: string } =
        scorePercentage >= 15
            ? {
                  colorClassName: 'k-bg-error',
                  impactLabel: 'High'
              }
            : scorePercentage < 7
            ? {
                  colorClassName: 'k-bg-success',
                  impactLabel: 'Low'
              }
            : {
                  colorClassName: 'k-bg-warning',
                  impactLabel: 'Medium'
              };

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
            <div className={combineClassNames('k-w-2 k-h-2 k-rounded-sm', viewData.colorClassName)} />
            <span className="k-icp-text-xs">{viewData.impactLabel} impact</span>
        </StackLayout>
    );
}

export function SummaryHeader({
    title,
    subtitle,
    summary,
    icon
}: {
    title: ReactNode;
    subtitle?: string;
    summary: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}) {
    return (
        <>
            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
                <RoundedIconBackground className="k-shrink-0" icon={icon} theme="secondary" size="large" />
                <div>
                    <div className="k-fs-lg k-font-semibold">{title}</div>
                    {subtitle && <div className="k-mt-0.5">{subtitle}</div>}
                </div>
            </StackLayout>
            <div>{summary}</div>
        </>
    );
}

export function hasQuotes(text: string) {
    const trimmed = text.trim();
    return (
        (trimmed.startsWith('"') || trimmed.startsWith('“') || trimmed.startsWith("'")) &&
        (trimmed.endsWith('"') || trimmed.endsWith('”') || trimmed.endsWith("'"))
    );
}

export function QuotePanel({
    text,
    icon,
    addQuotes,
    panelClassName
}: {
    text: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    addQuotes?: boolean;
    panelClassName?: string;
}) {
    const Icon = icon;
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
            <Icon className="k-shrink-0 k-icp-icon k-icp-icon-size-4 k-mt-1.5" />
            <div className={`k-px-2 k-py-1.5 k-rounded-md ${panelClassName}`}>{addQuotes ? `“${text}”` : text}</div>
        </StackLayout>
    );
}

export function AnalysisChip({ children, theme, className }: { children?: React.ReactNode; theme?: 'base' | 'secondary' | 'ai'; className?: string }) {
    const borderColorClass = theme === 'secondary' ? 'k-border-secondary' : theme === 'base' ? 'k-border-base' : 'k-border-transparent';
    const textColorClass = theme === 'secondary' ? 'k-text-secondary' : theme === 'ai' ? 'ai-text-color' : 'k-text-base';
    return (
        <span
            style={{ borderRadius: '20px' }}
            className={combineClassNames(
                'k-display-inline-block k-fs-sm k-py-0.5 k-border k-border-solid k-pos-relative',
                borderColorClass,
                textColorClass,
                className
            )}
        >
            {theme === 'ai' && <div className="k-border k-border-solid ai-border ai-border--purple k-rounded-full k-border-transparent" />}
            {children}
        </span>
    );
}

export function QualityNote({ text, score, className, maxWidth }: { text?: string; score?: number; className?: string; maxWidth?: number }) {
    return (
        <div className={combineClassNames('k-px-6 k-py-4 k-rounded-lg k-icp-bg-secondary-8', className)} style={{ maxWidth: maxWidth }}>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-justify-content-between k-gap-2 k-mb-4">
                <div className="k-fs-lg k-font-semibold">Quality score</div>
                <ScoreView percentage={score} showLoaders />
            </StackLayout>
            <div>
                {text ?? (
                    <>
                        <Skeleton shape="text" className="k-w-full" />
                        <Skeleton shape="text" className="k-w-full" />
                        <Skeleton shape="text" className="k-w-3/5" />
                    </>
                )}
            </div>
        </div>
    );
}

export function InterviewSummaryPanel({
    title,
    subtitle,
    headerContent,
    icon,
    children,
    className,
    style
}: {
    title?: string;
    subtitle?: string;
    headerContent?: ReactNode;
    icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string;
        }
    >;
    children: ReactNode;
    className?: string;
    style?: React.CSSProperties;
}) {
    return (
        <div className={combineClassNames('k-icp-panel k-icp-panel-content', className)} style={style}>
            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3 k-mb-8">
                <RoundedIconBackground className="k-shrink-0" icon={icon} theme="secondary" size="large" />
                {title && (
                    <div>
                        <div className="k-fs-lg k-font-semibold">{title}</div>
                        {subtitle && <div className="k-mt-0.5">{subtitle}</div>}
                    </div>
                )}
                {headerContent}
            </StackLayout>
            {children}
        </div>
    );
}

export function AnalysisPanelSectionWithScore({
    isPending,
    title,
    score,
    text,
    hasButton,
    buttonText,
    emptyText,
    onButtonClick,
    showFullText
}: {
    isPending: boolean;
    title: string;
    score?: number;
    text?: string;
    emptyText: string;
    hasButton?: boolean;
    buttonText?: string;
    onButtonClick?: () => void;
    showFullText?: boolean;
}) {
    return !isPending ? (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-w-full">
            <ScoreRow name={title} score={score} />
            {text ? (
                <>
                    <div className={showFullText ? undefined : 'max-lines-3'}>{text}</div>
                    {hasButton && (
                        <Button themeColor="secondary" fillMode="flat" size="small" onClick={onButtonClick}>
                            {buttonText}
                        </Button>
                    )}
                </>
            ) : (
                <div className="k-icp-subtle-text">{emptyText}</div>
            )}
        </StackLayout>
    ) : (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'middle' }} className="k-gap-2 k-w-full">
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-justify-content-between">
                <Skeleton shape="text" style={{ width: '40%' }} />
                <Skeleton shape="rectangle" className="k-rounded-sm" style={{ width: 74, height: 32 }} />
            </StackLayout>
            <SkeletonSectionTextGroup lastSkeletonWidthPercentage={70} />
            {hasButton && <Skeleton shape="rectangle" style={{ width: '108px', height: '24px' }} className="k-rounded-sm" />}
        </StackLayout>
    );
}

function ScoreRow({ name, score }: { name: string; score?: number }) {
    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-w-full">
            <div className="k-flex-1 k-font-semibold">{name}</div>
            <ScoreView percentage={score} />
        </StackLayout>
    );
}

export function SkeletonSection({ lastSkeletonWidthPercentage }: { lastSkeletonWidthPercentage?: number }) {
    return (
        <div>
            <Skeleton shape="text" className="k-mb-2" style={{ width: '80%' }} />
            <SkeletonSectionTextGroup lastSkeletonWidthPercentage={lastSkeletonWidthPercentage} />
        </div>
    );
}

export function SkeletonSectionTextGroup({ lastSkeletonWidthPercentage }: { lastSkeletonWidthPercentage?: number }) {
    return (
        <div>
            <Skeleton shape="text" />
            <Skeleton shape="text" />
            <Skeleton shape="text" style={{ width: `${lastSkeletonWidthPercentage}%` }} />
        </div>
    );
}

type SegmentType = 'text' | 'said' | 'rec';
type Segment = {
    type: SegmentType;
    content: string;
};

export function InterviewAnalysisAggregateWithViewDetails({
    title,
    onButtonClick,
    buttonText,
    children
}: {
    title: string;
    onButtonClick?: () => void;
    buttonText?: string;
    children: ReactNode;
}) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-icp-panel k-p-4 !k-rounded-md k-gap-2">
            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                <div className="k-flex-1 k-font-semibold">{title}</div>
                {onButtonClick && (
                    <Button fillMode="flat" themeColor="secondary" size="small" onClick={onButtonClick}>
                        {buttonText || 'View details'}
                    </Button>
                )}
            </StackLayout>
            {children}
        </StackLayout>
    );
}

export function InterviewAnalysisAggregatePanel({ title, onButtonClick, children }: { title: string; onButtonClick: () => void; children: ReactNode }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-icp-panel k-p-4 !k-rounded-md k-gap-2">
            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                <div className="k-flex-1 k-font-semibold">{title}</div>
                <Button fillMode="flat" onClick={onButtonClick} className="k-icp-svg-icon-button !k-p-1 !k-border-none">
                    <FileTranscriptIcon className="k-icp-icon k-icp-icon-size-4" />
                </Button>
            </StackLayout>
            <div className="k-separator k-icp-component-border" />
            {children}
        </StackLayout>
    );
}

export function InsightView({ insight, asPanel }: { insight: InterviewInsight; asPanel?: boolean }) {
    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'start', vertical: 'top' }}
            className={combineClassNames('k-gap-2', asPanel ? 'k-rounded-md k-p-4 k-border k-border-solid k-icp-component-border' : undefined)}
        >
            <div>{insight.insight}</div>
            {insight.quote && <QuotePanel addQuotes={!hasQuotes(insight.quote)} text={insight.quote} icon={QuoteIcon} panelClassName="k-icp-panel-base" />}
        </StackLayout>
    );
}

export function RecommendationView({
    recommendation,
    asPanel,
    showPercentage
}: {
    recommendation: InterviewQualityAssessmentRecommendation;
    asPanel?: boolean;
    showPercentage?: boolean;
}) {
    const segments = parseSegments(recommendation.recommendation);
    const isPositive = recommendation.percentageImprovement === 0;
    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'stretch', vertical: 'top' }}
            className={combineClassNames('k-gap-2', asPanel ? 'k-rounded-md k-p-4 k-border k-border-solid k-icp-component-border' : undefined)}
        >
            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
                <div className="k-fs-sm k-icp-subtle-text k-text-uppercase">{isPositive ? 'Feedback' : 'Recommendation'}</div>
                {!isPositive && <SectionImpactLabel scorePercentage={recommendation.percentageImprovement} />}
                {showPercentage && (
                    <AnalysisChip className={combineClassNames('k-icp-bg-secondary-8 k-ml-auto', isPositive ? 'k-px-2' : 'k-px-1.5')}>
                        {isPositive ? <ThumbsUpIcon className="k-icp-icon k-icp-icon-size-4 k-text-secondary" /> : `+${recommendation.percentageImprovement}%`}
                    </AnalysisChip>
                )}
            </StackLayout>

            {segments.map((segment, index) => {
                if (segment.type === 'text') {
                    return <div key={index}>{segment.content}</div>;
                } else if (segment.type === 'said') {
                    return (
                        <QuotePanel
                            key={index}
                            text={segment.content}
                            icon={QuoteIcon}
                            panelClassName="k-icp-panel-base"
                            addQuotes={!hasQuotes(segment.content)}
                        />
                    );
                } else {
                    return (
                        <QuotePanel
                            key={index}
                            text={segment.content}
                            icon={ThumbsUpIcon}
                            panelClassName="k-icp-bg-secondary-8"
                            addQuotes={!hasQuotes(segment.content)}
                        />
                    );
                }
            })}
        </StackLayout>
    );
}

const parseSegments = (text: string): Segment[] => {
    const segments: Segment[] = [];
    const oldRegex = /{(said|rec)}([\s\S]*?){\/\1}/g;
    const newRegex = /\|(said|rec)Start\|([\s\S]*?)\|\1End\|/g;
    let lastIndex = 0;
    let match: RegExpExecArray | null;
    const trimText = (s: string) => s.replace(/^[,\s]+|[,\s]+$/g, '');

    try {
        // Choose regex based on the format present in the text
        const regex = text.includes('{said}') || text.includes('{rec}') ? oldRegex : newRegex;

        while ((match = regex.exec(text)) !== null) {
            // Handle text before the tag
            const textBefore = text.slice(lastIndex, match.index);
            const trimmedTextBefore = trimText(textBefore);
            if (trimmedTextBefore !== '') {
                segments.push({ type: 'text', content: trimmedTextBefore });
            }

            segments.push({ type: match[1] as SegmentType, content: match[2].trim() });
            lastIndex = regex.lastIndex;
        }

        // Add any remaining text as a trimmed text segment if not empty
        const remainingText = trimText(text.slice(lastIndex));
        if (remainingText !== '') {
            segments.push({ type: 'text', content: remainingText });
        }
    } catch (error) {
        return [{ type: 'text', content: text }];
    }

    return segments;
};

export function MeetingInstructionsView({ meetingInstructions }: { meetingInstructions: ReactNode[] }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
            {meetingInstructions.map((instruction, index) => (
                <StackLayout key={index} orientation="horizontal" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-3">
                    <ArrowRightCircleIcon className="k-m-1 k-icp-icon k-icp-icon-size-6 k-flex-shrink-0" />
                    <div className="k-mt-1.5">{instruction}</div>
                </StackLayout>
            ))}
        </StackLayout>
    );
}
