import { StackLayout } from '@progress/kendo-react-layout';
import { ComponentType, forwardRef, ReactNode, useRef } from 'react';
import { To } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ReactComponent as GlossaryIcon } from '../../icons/book-open.svg';
import { ReactComponent as CanvasIcon } from '../../icons/canvas.svg';
import { ReactComponent as ContactsIcon } from '../../icons/contacts.svg';
import { ReactComponent as InterviewsIcon } from '../../icons/interview.svg';
import { ReactComponent as JourneyIcon } from '../../icons/map.svg';
import { ReactComponent as NotesIcon } from '../../icons/notes.svg';
import { ReactComponent as CalendarIcon } from '../../icons/schedule.svg';
import { ReactComponent as ScriptsIcon } from '../../icons/script.svg';
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg';
import { ReactComponent as TestsIcon } from '../../icons/test.svg';
import { JourneyTaskNavigationHighlight } from '../../services/journeyService';
import { UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { InfoTip } from '../idea/infoTip';

export default function Sidebar() {
    const currentRole = useAppSelector(s => s.idea.role);
    const journeyLinkElementRef = useRef<HTMLAnchorElement>(null);

    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'stretch', vertical: 'top' }}
            className="side-nav k-px-2 k-pt-6 k-pb-4 k-gap-2 k-border-r-solid k-border-r k-icp-component-border"
        >
            <NavLinksList>
                <SidebarNavLink ref={journeyLinkElementRef} icon={JourneyIcon} to="./journey">
                    Journey
                </SidebarNavLink>
                <InfoTip name={JourneyTaskNavigationHighlight.TaskUnlockedNotice} anchor={journeyLinkElementRef.current}>
                    Start your next task
                </InfoTip>
                <SidebarNavLink icon={CanvasIcon} to="./canvas">
                    Canvas
                </SidebarNavLink>
                <SidebarNavLink icon={TestsIcon} to="./research-v2">
                    Research
                </SidebarNavLink>
                <SidebarNavLink icon={InterviewsIcon} to="./interviews-v2">
                    Interviews
                </SidebarNavLink>
                <SidebarNavLink icon={ScriptsIcon} to="./scripts">
                    Scripts
                </SidebarNavLink>
                <SidebarNavLink icon={CalendarIcon} to="./calendar">
                    Calendar
                </SidebarNavLink>
                <SidebarNavLink icon={ContactsIcon} to="./contacts">
                    Contacts
                </SidebarNavLink>
            </NavLinksList>
            <div className="k-separator" />
            <NavLinksList>
                <SidebarNavLink icon={NotesIcon} to="./notes">
                    Notes
                </SidebarNavLink>
                <SidebarNavLink icon={GlossaryIcon} to="./glossary">
                    Glossary
                </SidebarNavLink>
                {currentRole && currentRole !== UserRole.Viewer && (
                    <SidebarNavLink icon={SettingsIcon} to="./settings">
                        Settings
                    </SidebarNavLink>
                )}
            </NavLinksList>
        </StackLayout>
    );
}

function NavLinksList({ children }: { children?: ReactNode }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }}>
            {children}
        </StackLayout>
    );
}

type SidebarNavLinkProps = {
    icon: ComponentType<React.SVGProps<SVGSVGElement>>;
    children: string;
    to: To;
};
const SidebarNavLink = forwardRef<HTMLAnchorElement, SidebarNavLinkProps>(function SidebarNavLink({ icon: Icon, children: text, to }, ref) {
    return (
        <NavLink
            to={to}
            className="k-button k-button-sm k-button-flat k-button-flat-base k-rounded-md !k-font-normal !k-justify-content-start !k-p-2 !k-pl-3 !k-border-0 !k-white-space-normal !k-text-left"
            ref={ref}
        >
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-icp-svg-icon-button-content k-gap-2">
                <Icon className="k-icp-icon k-mt-1px k-shrink-0" />
                {text}
            </StackLayout>
        </NavLink>
    );
});
