import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRealTimeUpdatesPipe } from '../../hooks/realTimeUpdatesHooks';
import { ReactComponent as CheckCircleIcon } from '../../icons/check-circle-closed.svg';
import { ReactComponent as DefaultItemCardIcon } from '../../icons/pause-circle.svg';
import { ReactComponent as XCircleIcon } from '../../icons/x-circle.svg';
import buyerIllustrationUrl from '../../images/buyer-illustration.svg';
import customerProblemIllustrationUrl from '../../images/customer-problem-illustration.svg';
import diceIllustrationUrl from '../../images/dice-illustration.svg';
import { ReactComponent as NoInterviewsIllustration } from '../../images/interviews-illustration.svg';
import { combineClassNames, getPersonFullName } from '../../services/common';
import { Interview2Type, InterviewsCounts, InterviewStageV2 } from '../../services/interviewsV2Service';
import { buildUserViewModel } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { StartupMembershipList } from '../startup/startupMembershipList';
import { CardsPicker, CardsPickerItem } from '../ui/cardsPicker';
import { ClickableStackLayout } from '../ui/clickableStackLayout';
import { DonutProgress } from '../ui/donutProgress';
import { FormFieldProps, requiredValidator } from '../ui/inputs';
import LoadingIndicator from '../ui/loadingIndicator';

export const interviewStageLabelMap: Record<InterviewStageV2, string> = {
    [InterviewStageV2.NotStarted]: 'Not started',
    [InterviewStageV2.InProgress]: 'In progress',
    [InterviewStageV2.Completed]: 'Completed'
};

export const interviewStageToUrlPathMap: Record<InterviewStageV2, string> = {
    [InterviewStageV2.NotStarted]: 'not-started',
    [InterviewStageV2.InProgress]: 'in-progress',
    [InterviewStageV2.Completed]: 'completed'
};

export const interviewTypeToLabelMap: Record<Interview2Type, string> = {
    [Interview2Type.ProblemDiscoveryAndValidation]: 'Problem Discovery and Validation',
    [Interview2Type.WinLoss]: 'Win/Loss',
    [Interview2Type.BuyerPersona]: 'Buyer Persona',
    [Interview2Type.Other]: 'Other'
};

export const interviewTypeToIllustrationUrlMap: Record<Interview2Type, string> = {
    [Interview2Type.ProblemDiscoveryAndValidation]: customerProblemIllustrationUrl,
    [Interview2Type.WinLoss]: diceIllustrationUrl,
    [Interview2Type.BuyerPersona]: buyerIllustrationUrl,
    [Interview2Type.Other]: customerProblemIllustrationUrl
};

export const allowedTextFileExtensions = ['.pdf', '.docx', '.doc', '.rtf', '.txt'];
//TODO R4: support video extension files
// export const allowedInterviewTranscriptExtensions = [...allowedTextFileExtensions, '.mov', '.mp4', '.avi'];
export const maxInterviewFileSizeInBytes = 10 * 1024 * 1024; // 10 MB

export const intervieweeValidator = requiredValidator('Interviewee');
export const transcriptValidator = requiredValidator('Transcript');
export const scriptValidator = requiredValidator('Script');
export const hostValidator = requiredValidator('Host');

export function InterviewStatusIndicator({ text, type, className }: { text: string; type: 'warning' | 'success' | 'alert' | 'base'; className?: string }) {
    const bgClassName =
        type === 'warning' ? 'k-icp-bg-warning-8' : type === 'success' ? 'k-icp-bg-success-8' : type === 'alert' ? 'k-icp-bg-error-4' : 'k-icp-bg-dark-8';
    return <div className={`k-fs-sm k-font-normal k-px-2 -py-0.75 k-rounded-md ${bgClassName}${className ? ` ${className}` : ''}`}>{text}</div>;
}

export const ScoreView = ({ percentage, showLoaders }: { percentage?: number; showLoaders?: boolean }) => {
    return (
        <StackLayout
            align={{ horizontal: 'start', vertical: 'middle' }}
            className={combineClassNames('k-gap-2', typeof percentage !== 'number' ? 'k-disabled' : undefined)}
            orientation="horizontal"
        >
            {typeof percentage === 'number' || !showLoaders ? (
                <DonutProgress progress={percentage ?? 0} />
            ) : (
                <Skeleton shape="circle" className="k-w-8 k-h-8" />
            )}
            <div className={combineClassNames(typeof percentage === 'number' ? 'k-text-secondary' : undefined)}>
                {typeof percentage === 'number' ? `${percentage}%` : showLoaders ? <Skeleton shape="text" style={{ width: 30 }} /> : 'N/A'}
            </div>
        </StackLayout>
    );
};

export const TextFileButton = ({
    title,
    icon,
    onClick
}: {
    title: string;
    icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string;
        }
    >;
    onClick: () => void;
}) => {
    const Icon = icon;
    return (
        <ClickableStackLayout
            align={{ horizontal: 'start', vertical: 'middle' }}
            className="k-gap-2 k-p-1 k-border k-border-solid k-icp-component-border k-cursor-pointer k-rounded k-fs-sm k-icp-hover-bg-base-4"
            onClick={onClick}
        >
            <Icon className="k-icp-icon k-w-8 k-h-8 k-shrink-0" />
            <span>{title}</span>
        </ClickableStackLayout>
    );
};

const interviewTypePickerCards = [Interview2Type.ProblemDiscoveryAndValidation, Interview2Type.BuyerPersona, Interview2Type.WinLoss].map<
    CardsPickerItem<Interview2Type>
>(interviewType => ({
    value: interviewType,
    label: `${interviewTypeToLabelMap[interviewType]} Interviews`,
    image: {
        width: 48,
        height: 48,
        description: interviewTypeToLabelMap[interviewType],
        url: interviewTypeToIllustrationUrlMap[interviewType]
    }
}));
export function InterviewTypePicker(formFieldProps: FormFieldProps<Interview2Type>) {
    return <CardsPicker {...formFieldProps} cards={interviewTypePickerCards} />;
}

export type IVAUser = typeof IVA_USER;
export type UnknownUser = typeof UNKNOWN_HOST_USER;

export const IVA_USER = {
    userId: 'iva' as const,
    name: 'IVA' as const
} as const;

export const UNKNOWN_HOST_USER = {
    userId: 'unknown' as const,
    name: 'Unknown host' as const
} as const;

export type ScriptQuestion = {
    status: 'default' | 'answered' | 'unanswered';
    text: string;
};

export type ScriptSection = {
    title: string;
    answeredQuestions: number;
    totalQuestions: number;
    questions: ScriptQuestion[];
};

export function StickyStamp({ text }: { text: string }) {
    return (
        <div className="k-pos-sticky k-top-0 k-pt-2 k-pb-2.5 k-z-10 k-text-center">
            <div className="k-icp-message-stamp k-display-inline-block">{text}</div>
        </div>
    );
}

export function ScriptQuestionsSection({ section, children }: { section: ScriptSection; children: React.ReactNode }) {
    const { title, answeredQuestions, totalQuestions } = section;
    return (
        <StackLayout orientation="vertical" className="k-gap-2">
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2 k-justify-content-between">
                <strong className="k-text-secondary">{title}</strong>
                <span className="k-fs-sm -mt-0.75">
                    {answeredQuestions}/{totalQuestions}
                </span>
            </StackLayout>

            {children}
        </StackLayout>
    );
}

type InterviewScriptQuestionHandle = {
    readonly element: HTMLDivElement | null;
};

export type ScriptQuestionCardProps = {
    status: 'default' | 'answered' | 'unanswered';
    text: string;
    scratchAnswered?: boolean;
};

export const ScriptQuestionCard = forwardRef<InterviewScriptQuestionHandle, ScriptQuestionCardProps>(function ScriptQuestionCard(
    { status, text, scratchAnswered },
    ref
) {
    const elementRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
        ref,
        () => ({
            get element() {
                return elementRef.current;
            }
        }),
        []
    );

    const backgroundClass = status === 'unanswered' || (status === 'answered' && scratchAnswered) ? 'k-icp-bg-light-16' : undefined;
    const iconClass = status === 'answered' ? 'k-text-success' : status === 'unanswered' ? 'k-text-warning' : 'k-text-secondary';
    const scratched = scratchAnswered && status === 'answered';
    const Icon = status === 'answered' ? CheckCircleIcon : status === 'unanswered' ? XCircleIcon : DefaultItemCardIcon;
    return (
        <div ref={elementRef} className={combineClassNames('k-panel k-p-2', backgroundClass)}>
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                <Icon className={`k-icp-icon k-icp-icon-size-4 ${iconClass}`} />
                <div className={combineClassNames('k-flex-1', scratched ? 'k-text-line-through' : undefined)}>{text}</div>
            </StackLayout>
        </div>
    );
});

export function UserDetailsText({ name, firstName, lastName, jobTitle }: { name?: string; firstName?: string; lastName?: string; jobTitle?: string | null }) {
    const fullName = name || getPersonFullName(firstName, lastName);
    return (
        <div>
            <strong className="k-fs-lg">{fullName}</strong>
            {jobTitle && <div className="k-fs-sm k-icp-subtle-text">{jobTitle}</div>}
        </div>
    );
}

export function InterviewProgress({
    progressPercentage,
    hideLabel,
    size = 'medium',
    disableAnimation
}: {
    progressPercentage: number;
    hideLabel?: boolean;
    size?: 'medium' | 'small';
    disableAnimation?: boolean;
}) {
    const donutSize = size === 'medium' ? '8' : '6';
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-fs-md k-font-normal">
            {!hideLabel && <span className="k-icp-subtle-text">Completeness:</span>}
            <DonutProgress progress={progressPercentage} padBorderClassName="k-icp-component-border" animate={!disableAnimation} size={donutSize} />
            <span className="k-text-secondary" style={{ minWidth: 33 }}>
                {progressPercentage.toFixed()}%
            </span>
        </StackLayout>
    );
}

export function InterviewViewers({ interviewId, addSeparator }: { interviewId?: number; addSeparator?: boolean }) {
    const realTimeUpdatesPipe = useRealTimeUpdatesPipe();
    const ideaConnectedMembers = useAppSelector(s => s.ideaMembers.membersActivity);
    const usersViewingInterview =
        typeof interviewId === 'number' && ideaConnectedMembers
            ? ideaConnectedMembers
                  .filter(m =>
                      m.pendingActions.some(action => action.type === 'interview' && action.operation === 'View' && action.interviewId === interviewId)
                  )
                  .map(m => m.user)
            : undefined;

    useEffect(() => {
        if (!realTimeUpdatesPipe || typeof interviewId !== 'number') return;

        realTimeUpdatesPipe.setPendingAction({ type: 'interview', interviewId: interviewId, operation: 'View' });

        function clearDocumentPendingAction() {
            if (!realTimeUpdatesPipe) return;
            realTimeUpdatesPipe.setPendingAction(undefined);
        }

        return () => clearDocumentPendingAction();
    }, [interviewId, realTimeUpdatesPipe]);

    const interviewViewersListElement = <StartupMembershipList users={usersViewingInterview?.map(u => buildUserViewModel(u))} className="k-my--2" />;
    if (addSeparator && usersViewingInterview?.length)
        return (
            <>
                <div className="k-separator" />
                {interviewViewersListElement}
            </>
        );

    return interviewViewersListElement;
}

export function InterviewsLayout({ empty, children, emptyMessage }: { empty: boolean; children: ReactNode; emptyMessage: string }) {
    return (
        <StackLayout className="k-flex-1 page-content-middle page-content--xxl">
            {!empty ? (
                children
            ) : (
                <div className="k-display-flex k-justify-content-center k-items-center">
                    <EmptyInterviewsComponent emptyMessage={emptyMessage} />
                </div>
            )}
        </StackLayout>
    );
}

export function EmptyInterviewsComponent({ emptyMessage }: { emptyMessage: string }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="page-content-middle -w2 k-gap-6">
            <NoInterviewsIllustration />
            <div className="k-text-center">{emptyMessage}</div>
        </StackLayout>
    );
}

export function RouteToInterviewsDefaultTab({ interviewsCounts }: { interviewsCounts?: Omit<InterviewsCounts, 'totalCount'> }) {
    const navigate = useNavigate();
    useEffect(() => {
        if (!interviewsCounts) return;
        if (interviewsCounts.notStartedCount) navigate(`../${interviewStageToUrlPathMap[InterviewStageV2.NotStarted]}`, { replace: true });
        else if (interviewsCounts.completeCount) navigate(`../${interviewStageToUrlPathMap[InterviewStageV2.Completed]}`, { replace: true });
        else if (interviewsCounts.inProgressCount) navigate(`../${interviewStageToUrlPathMap[InterviewStageV2.InProgress]}`, { replace: true });
        else navigate(`../${interviewStageToUrlPathMap[InterviewStageV2.NotStarted]}`, { replace: true });
    }, [interviewsCounts, navigate]);

    return (
        <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className="k-flex-1">
            <LoadingIndicator size="big" />
        </StackLayout>
    );
}
