import { Node } from 'prosemirror-model';
import { Plugin, PluginKey } from 'prosemirror-state';
import { BookmarkTitle, getDocLevelSectionIdxOfSelection } from './common';

export function collectTitlesPlugin(
    onTitlesChanged: (titles: BookmarkTitle[]) => void,
    onSelectionInSectionChanged?: (sectionIdx: number) => void,
    enableIndentation?: boolean
) {
    return new Plugin({
        key: new PluginKey('collectTitles'),
        state: {
            init(config, instance) {
                const state = instance;
                if (!state) return;
                const titles = getTitles(state.doc, !!enableIndentation);
                onTitlesChanged(titles);
            },
            apply(tr, value, oldState, newState) {
                if (tr.docChanged) {
                    const titles = getTitles(newState.doc, !!enableIndentation);
                    onTitlesChanged(titles);
                } else {
                    if (!onSelectionInSectionChanged) return;
                    const selection = newState.selection;
                    const sectionIdx = getDocLevelSectionIdxOfSelection(newState.doc, selection);
                    onSelectionInSectionChanged(sectionIdx);
                }
            }
        }
    });
}

function getTitles(doc: Node, enableIndentation: boolean) {
    const titles: BookmarkTitle[] = [];
    doc.descendants((node, pos, parent, index) => {
        if (node.type.name !== 'heading' || parent?.type.name !== 'doc') return;
        const indentation = enableIndentation ? node.attrs.level - 1 : undefined;
        titles.push({ title: node.textContent, indentation });
    });
    return titles;
}
