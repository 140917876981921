import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useAsRef } from '../../hooks/commonHooks';
import { TypingDots } from './typingDots';

type TypeComponentsProps = {
    children?: string | ReactElement | (string | ReactElement)[];
    WrapperComponent?: React.ComponentType<{ children: React.ReactNode }>;
    onTypeEnd?: () => void;
    initialDelayMs?: number;
    waitForMore?: boolean;
};

function DefaultWrapperComponent({ children }: { children: React.ReactNode }) {
    return <>{children}</>;
}

export function TypeComponents({ children, WrapperComponent = DefaultWrapperComponent, onTypeEnd, waitForMore, initialDelayMs }: TypeComponentsProps) {
    const [revealToIndex, setRevealToIndex] = useState(0);
    const TYPING_SPEED_MS = 25;
    const revealToIndexRef = useAsRef(revealToIndex);
    const onTypedEndRef = useAsRef(onTypeEnd);
    const [typingInProgress, setTypingInProgress] = useState(false);

    const processedItems = useMemo(
        () =>
            children &&
            (Array.isArray(children) ? children : [children]).reduce<
                Array<{
                    type: 'text' | 'element';
                    content: string[] | ReactElement;
                    startIndex: number;
                }>
            >((acc, child) => {
                const startIndex = acc.length
                    ? acc[acc.length - 1].startIndex + (acc[acc.length - 1].type === 'text' ? (acc[acc.length - 1].content as string[]).length : 1)
                    : 0;

                if (typeof child === 'string') {
                    acc.push({
                        type: 'text',
                        content: child.split(/(\s+)/).filter(Boolean),
                        startIndex
                    });
                } else {
                    acc.push({
                        type: 'element',
                        content: child,
                        startIndex
                    });
                }
                return acc;
            }, []),
        [children]
    );

    useEffect(() => {
        if (!processedItems) return;
        if (!processedItems.length) {
            if (!waitForMore) onTypedEndRef.current?.();

            return;
        }

        setTypingInProgress(true);

        const totalItems = processedItems.reduce((sum, item) => sum + (item.type === 'text' ? (item.content as string[]).length : 1), 0);

        if (initialDelayMs && revealToIndexRef.current === 0) {
            const initialDelayTimeout = setTimeout(() => {
                startTyping();
            }, initialDelayMs);
            return () => clearTimeout(initialDelayTimeout);
        }
        return startTyping();

        function startTyping() {
            const typingIntervalId = setInterval(() => {
                if (revealToIndexRef.current >= totalItems) {
                    setTypingInProgress(false);
                    if (waitForMore) return;
                    clearInterval(typingIntervalId);
                    onTypedEndRef.current?.();
                    return;
                }

                setRevealToIndex(prev => prev + 1);
            }, TYPING_SPEED_MS);

            return () => clearInterval(typingIntervalId);
        }
    }, [processedItems, onTypedEndRef, revealToIndexRef, waitForMore, initialDelayMs]);

    return (
        <>
            {processedItems &&
                processedItems.map((item, idx) => {
                    if (item.type === 'element') {
                        return revealToIndex > item.startIndex ? <React.Fragment key={idx}>{item.content}</React.Fragment> : null;
                    }

                    const words = item.content as string[];
                    const visibleWords = words.filter((_, wordIdx) => item.startIndex + wordIdx < revealToIndex);

                    return visibleWords.length > 0 ? <WrapperComponent key={idx}>{visibleWords.join('')}</WrapperComponent> : null;
                })}
            {!typingInProgress && waitForMore && <TypingDots alwaysUseSpace />}
        </>
    );
}
