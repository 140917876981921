import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AILabel } from '../../components/ai/aiLabel';
import { CanvasItemFromBoxPicker } from '../../components/canvas/canvasItemFromBoxPicker';
import { canvasItemsZoneDependent, useRequireCanvasBoxItemsInZone } from '../../components/canvas/canvasItemsZone';
import { DropDownButtonItem } from '../../components/common/boundDropDownButton';
import { DeletionRelatedEntriesNotice } from '../../components/common/deletionRelatedEntriesNotice';
import { DurationPicker } from '../../components/common/durationPicker';
import { preventCloseOfParentDialog } from '../../components/common/modal';
import {
    allowedTextFileExtensions,
    InterviewTypePicker,
    interviewTypeToIllustrationUrlMap,
    interviewTypeToLabelMap,
    maxInterviewFileSizeInBytes
} from '../../components/interview2/common';
import { ResearchCard } from '../../components/research2/ResearchCard';
import { FileSelector, minLengthValidator, requiredValidator, useFileSelectorValidator, ValidatedInput } from '../../components/ui/inputs';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { PredefinedAction, PredefinedActionsList } from '../../components/ui/predefinedActions';
import { SvgIconButtonContent } from '../../components/ui/svgIconButtonContent';
import { H1 } from '../../components/ui/typography';
import { appConfig } from '../../config';
import { useSingleClickCallback } from '../../hooks/commonHooks';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { ReactComponent as AddIcon, ReactComponent as CreateScriptIcon } from '../../icons/plus.svg';
import { ReactComponent as InterviewScriptIcon } from '../../icons/script.svg';
import { ReactComponent as ImportScriptIcon } from '../../icons/upload.svg';
import emptyInterviewScriptsIllustrationUrl from '../../images/scripts-illustration.svg';
import { BoxType } from '../../services/canvasService';
import { combineClassNames, immutableRemove } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import { InterviewScript2, InterviewScript2Stats, interviewScripts2Service, NewInterviewScript2Data } from '../../services/interviewScripts2Service';
import { Interview2Type } from '../../services/interviewsV2Service';
import { RealTimeUpdateInterviewScript2Data, RealTimeUpdateInterviewScript2UpdateData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';

export const InterviewScriptsPage = canvasItemsZoneDependent(function InterviewScriptsPage() {
    const { ideaId } = useIdeaParams();
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canEditInterviewCollections = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;

    const [scripts, setScripts] = useState<InterviewScript2[]>();
    const { show: showDeleteDialog, element: deleteDialog } = useConfirmDialog();
    const [deletingScriptId, setDeletingScriptId] = useState<number>();
    const [unableToDeleteScriptStats, setUnableToDeleteScriptStats] = useState<InterviewScript2Stats>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [createScriptConfig, setCreateScriptConfig] = useState<CreateInterviewScriptModalConfigProps>();

    const loadScripts = useCallback(() => interviewScripts2Service.getScripts(ideaId).then(setScripts), [ideaId]);

    useEffect(() => {
        loadScripts();
    }, [loadScripts]);

    useEffect(() => {
        function onAddOrRestoreScript(e: RealTimeUpdateInterviewScript2Data) {
            if (e.ideaId !== ideaId) return;
            loadScripts();
        }

        function onUpdateScript(e: RealTimeUpdateInterviewScript2UpdateData) {
            if (e.ideaId !== ideaId || e.lowPriority) return;
            loadScripts();
        }

        function onDeleteScript(e: RealTimeUpdateInterviewScript2Data) {
            if (e.ideaId !== ideaId) return;

            setScripts(s => immutableRemove(s, s => s.id !== e.interviewScriptId));
        }

        realTimeUpdatesEventHub.addEventListener('interview3', 'scriptAdd', onAddOrRestoreScript);
        realTimeUpdatesEventHub.addEventListener('interview3', 'scriptUpdate', onUpdateScript);
        realTimeUpdatesEventHub.addEventListener('interview3', 'scriptDelete', onDeleteScript);
        realTimeUpdatesEventHub.addEventListener('interview3', 'scriptRestore', onAddOrRestoreScript);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview3', 'scriptAdd', onAddOrRestoreScript);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'scriptUpdate', onUpdateScript);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'scriptDelete', onDeleteScript);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'scriptRestore', onAddOrRestoreScript);
        };
    }, [ideaId, loadScripts]);

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-6 k-min-h-full">
            <H1>Interview Scripts</H1>
            <PredefinedActionsList className="k-shrink-0">
                {[Interview2Type.ProblemDiscoveryAndValidation, Interview2Type.BuyerPersona, Interview2Type.WinLoss].map(interviewType => {
                    const interviewTypeLabel = interviewTypeToLabelMap[interviewType];
                    return (
                        <PredefinedAction
                            key={interviewType}
                            text={`Get a ${interviewTypeLabel} interview script`}
                            cardClassName="k-pos-relative"
                            action={() => setCreateScriptConfig({ automaticGenerationType: interviewType })}
                            disabled={!canEditInterviewCollections}
                        >
                            <img
                                src={interviewTypeToIllustrationUrlMap[interviewType]}
                                alt={interviewTypeLabel}
                                width="64"
                                height="64"
                                className="k-display-block k-mx-auto"
                            />
                            <AILabel className="k-pos-absolute k-top-1 k-left-1">Create with IVA</AILabel>
                        </PredefinedAction>
                    );
                })}
                <PredefinedAction text="Create a new Interview Script" action={() => setCreateScriptConfig({})} disabled={!canEditInterviewCollections}>
                    <CreateScriptIcon width="48" height="48" className="k-icp-icon k-my-2 k-mx-auto" />
                </PredefinedAction>
                <PredefinedAction
                    text="Import an Interview Script"
                    action={() => setCreateScriptConfig({ import: true })}
                    disabled={!canEditInterviewCollections}
                >
                    <ImportScriptIcon width="48" height="48" className="k-icp-icon k-my-2 k-mx-auto" />
                </PredefinedAction>
            </PredefinedActionsList>
            {scripts ? (
                scripts.length ? (
                    <>
                        <InterviewScriptsList
                            scripts={scripts}
                            onOpen={scriptId => navigate(String(scriptId))}
                            onDuplicate={
                                canEditInterviewCollections
                                    ? async scriptId => {
                                          const duplicatedScript = await interviewScripts2Service.duplicateInterviewScript(ideaId, scriptId);
                                          setScripts(s => (s ? [duplicatedScript, ...s] : [duplicatedScript]));
                                          dispatch(addNotification({ content: 'Interview script duplicated' }));
                                      }
                                    : undefined
                            }
                            disabledScriptId={deletingScriptId}
                            onDelete={
                                canEditInterviewCollections
                                    ? async scriptId => {
                                          const scriptToDelete = scripts.find(s => s.id === scriptId);
                                          if (!scriptToDelete) return;

                                          setDeletingScriptId(scriptId);
                                          const scriptStats = await interviewScripts2Service
                                              .getInterviewScriptStats(ideaId, scriptId)
                                              .finally(() => setDeletingScriptId(undefined));
                                          if (scriptStats.interviewCount || scriptStats.researchCount) setUnableToDeleteScriptStats(scriptStats);
                                          else
                                              showDeleteDialog({
                                                  title: 'Delete interview script',
                                                  content: (
                                                      <>
                                                          Are you sure you want to delete <strong>“{scriptToDelete.name}”</strong>?
                                                      </>
                                                  ),
                                                  confirmCheckboxText: 'I acknowledge that this cannot be undone',
                                                  confirmButtonText: 'Delete interview script',
                                                  callback: async () => {
                                                      await interviewScripts2Service.deleteInterviewScript(ideaId, scriptId);
                                                      setScripts(scripts => immutableRemove(scripts, i => i.id !== scriptId));
                                                  }
                                              });
                                      }
                                    : undefined
                            }
                        />
                        {deleteDialog}
                        {unableToDeleteScriptStats && (
                            <ScripCannotBeDeletedModal stats={unableToDeleteScriptStats} onClose={() => setUnableToDeleteScriptStats(undefined)} />
                        )}
                    </>
                ) : (
                    <InterviewScriptsEmptyView className="k-flex-1" />
                )
            ) : (
                <LoadingIndicator size="big" className="k-align-self-center" />
            )}
            {createScriptConfig && (
                <CreateInterviewScriptModal
                    ideaId={ideaId}
                    ideaBaseUrl=".."
                    {...createScriptConfig}
                    onCancel={() => setCreateScriptConfig(undefined)}
                    onCreated={s => navigate(String(s.id))}
                />
            )}
        </StackLayout>
    );
});

function InterviewScriptsEmptyView({ className }: { className?: string }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className={combineClassNames(className, 'k-gap-6')}>
            <img src={emptyInterviewScriptsIllustrationUrl} alt="No interview scripts list" width="240" height="120" />
            <div>Get an IVA generated script or craft one yourself</div>
        </StackLayout>
    );
}

function InterviewScriptsList({
    scripts,
    onOpen,
    onDuplicate,
    onDelete,
    disabledScriptId
}: {
    scripts: InterviewScript2[];
    onOpen: (scriptId: number) => void;
    onDuplicate?: (scriptId: number) => void;
    onDelete?: (scriptId: number) => void;
    disabledScriptId?: number;
}) {
    return (
        <div className="cards-relaxed-grid">
            {scripts.map(script => (
                <InterviewScriptCard
                    key={script.id}
                    script={script}
                    onOpen={() => onOpen(script.id)}
                    onDuplicate={onDuplicate && script.ready && !script.error ? () => onDuplicate(script.id) : undefined}
                    onDelete={onDelete && (() => onDelete(script.id))}
                    disabled={disabledScriptId === script.id}
                />
            ))}
        </div>
    );
}

function InterviewScriptCard({
    script,
    onOpen,
    onDuplicate,
    onDelete,
    disabled
}: {
    script: InterviewScript2;
    onOpen: () => void;
    onDuplicate?: () => void;
    onDelete?: () => void;
    disabled?: boolean;
}) {
    const actions: DropDownButtonItem[] = [];
    actions.push({ text: 'Open', action: onOpen });
    actions.push({ text: 'Duplicate', action: onDuplicate, disabled: !onDuplicate });
    actions.push({ text: 'Delete', danger: true, separated: true, action: onDelete, disabled: !onDelete });
    const interviewTypeLabel = interviewTypeToLabelMap[script.interviewType] + ' script';

    return (
        <ResearchCard
            actions={actions}
            linkTo={`${script.id}`}
            contributors={script.contributors}
            customerSegmentId={script.customerSegmentId}
            lastEdited={dateTimeService.getMaxDate(script.createdOn, script.updatedOn)!}
            statusText={`Used in ${script.interviewsCount} interview${script.interviewsCount === 1 ? '' : 's'}`}
            title={script.name}
            subtitle={
                <div className="k-text-ellipsis">
                    {script.durationInMinutes ? (
                        <>
                            {script.durationInMinutes} min
                            <span className="dot-separator k-display-inline-block k-mx-1.5 k-align-middle" />
                        </>
                    ) : (
                        undefined
                    )}
                    <span title={interviewTypeLabel}>{interviewTypeLabel}</span>
                </div>
            }
            icon={InterviewScriptIcon}
            disabled={disabled}
        />
    );
}

const customerSegmentIdValidator = requiredValidator('Customer Segment');
const durationValidator = requiredValidator('Duration');
const interviewTypeValidator = requiredValidator('Type');
const requiredInterviewScriptFileErrorMessage = 'Script file is required';

export type CreateInterviewScriptModalConfigProps = { customerSegmentId?: number } & (
    | { automaticGenerationType: Interview2Type }
    | { import?: boolean; interviewType?: Interview2Type }
);
type CreateInterviewScriptModalProps = {
    ideaId: string;
    ideaBaseUrl: string;
    onCancel?: () => void;
    onCreated?: (interviewScript: InterviewScript2) => void;
} & CreateInterviewScriptModalConfigProps;
export function CreateInterviewScriptModal(props: CreateInterviewScriptModalProps) {
    const automaticGenerationType = 'automaticGenerationType' in props ? props.automaticGenerationType : undefined;
    const isImporting = 'import' in props ? props.import : undefined;

    const customerSegments = useRequireCanvasBoxItemsInZone(BoxType.CustomerSegments);

    const [interviewScriptFileValidator, interviewFileErrors] = useFileSelectorValidator(allowedTextFileExtensions, maxInterviewFileSizeInBytes);
    const interviewScriptFileValidators = useMemo(
        () => [
            interviewScriptFileValidator,
            requiredValidator(requiredInterviewScriptFileErrorMessage, false),
            minLengthValidator(requiredInterviewScriptFileErrorMessage, 1, false)
        ],
        [interviewScriptFileValidator]
    );

    const [createDisabled, createInterviewScriptCallback] = useSingleClickCallback(async (data: Record<string, any>) => {
        const newScriptData: NewInterviewScript2Data = {
            customerSegmentId: data.customerSegmentId,
            interviewType: automaticGenerationType ?? data.interviewType,
            durationInMinutes: automaticGenerationType ? data.durationInMinutes : undefined,
            useTemplate: Boolean(automaticGenerationType),
            file: isImporting ? data.file[0] : undefined
        };

        const newInterviewScript = await interviewScripts2Service.createInterviewScript(props.ideaId, newScriptData);
        props.onCreated?.(newInterviewScript);
    });

    const prefilledInterviewType = 'interviewType' in props ? props.interviewType : undefined;
    const prefillData =
        typeof props.customerSegmentId || prefilledInterviewType
            ? { customerSegmentId: props.customerSegmentId, interviewType: prefilledInterviewType }
            : undefined;

    return (
        <Dialog
            onClose={e => {
                preventCloseOfParentDialog(e);
                props.onCancel?.();
            }}
            autoFocus
            width={640}
            title={
                automaticGenerationType
                    ? `Generate a ${interviewTypeToLabelMap[automaticGenerationType]} Interview Script`
                    : isImporting
                    ? 'Import an Interview Script'
                    : 'Create an Interview Script'
            }
            className="k-icp-dialog-no-padding k-icp-dialog-with-form"
            closeIcon={false}
        >
            <Form
                ignoreModified
                onSubmit={createInterviewScriptCallback}
                initialValues={automaticGenerationType ? { durationInMinutes: 30, ...prefillData } : prefillData}
                render={formRenderProps => (
                    <FormElement className="k-icp-component-border">
                        <div className="k-window-content">
                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-8">
                                {isImporting && (
                                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                                        <Field
                                            name="file"
                                            component={ValidatedInput}
                                            inputType={FileSelector}
                                            label="Script file"
                                            labelClassName="!k-mb-4 k-h3"
                                            acceptFileExtensions={allowedTextFileExtensions.join(', ')}
                                            validator={interviewScriptFileValidators}
                                            fileErrors={interviewFileErrors}
                                            hideErrorMessage={Boolean(interviewFileErrors)}
                                            className="!k-min-h-0 !k-p-4"
                                        />
                                    </StackLayout>
                                )}
                                {automaticGenerationType ? (
                                    <Field
                                        name="durationInMinutes"
                                        component={ValidatedInput}
                                        inputType={DurationPicker}
                                        label="Choose interview duration"
                                        labelClassName="!k-mb-4 k-h3"
                                        validator={durationValidator}
                                        predefinedValues={[10, 15, 30, 45, 60]}
                                    />
                                ) : (
                                    <Field
                                        name="interviewType"
                                        component={ValidatedInput}
                                        inputType={InterviewTypePicker}
                                        label="Interview type"
                                        labelClassName="!k-mb-4 k-h3"
                                        validator={interviewTypeValidator}
                                        disabled={Boolean(prefilledInterviewType)}
                                    />
                                )}
                                <Field
                                    name="customerSegmentId"
                                    component={ValidatedInput}
                                    inputType={CanvasItemFromBoxPicker}
                                    label="Relate to Customer Segment"
                                    labelClassName="!k-mb-4 k-h3"
                                    validator={customerSegmentIdValidator}
                                    items={customerSegments}
                                    emptyView={<MissingCustomerSegmentsWarning ideaBaseUrl={props.ideaBaseUrl} />}
                                    disabled={typeof props.customerSegmentId === 'number'}
                                />
                            </StackLayout>
                        </div>
                        <DialogActionsBar layout="center">
                            <Button disabled={!formRenderProps.allowSubmit || createDisabled} type="submit" themeColor="primary">
                                {automaticGenerationType ? 'Generate script' : 'Create script'}
                            </Button>
                            <Button type="reset" onClick={props.onCancel}>
                                Cancel
                            </Button>
                        </DialogActionsBar>
                    </FormElement>
                )}
            />
        </Dialog>
    );
}

export function MissingCustomerSegmentsWarning({ ideaBaseUrl, instructionText }: { ideaBaseUrl: string; instructionText?: string }) {
    return (
        <div className="k-px-8 k-py-4 k-icp-bg-warning-8 k-rounded k-text-center">
            <div className="k-mb-4 k-text-left">
                {instructionText || 'Focusing on a specific Customer Segment is essential for conducting any type of interview. Define one before proceeding.'}
            </div>
            <Link
                className="k-button k-button-md k-button-outline k-button-outline-base k-rounded-md"
                to={`${ideaBaseUrl}/canvas?highlight=${encodeURIComponent(appConfig.canvas.boxes.CustomerSegments.highlight)}`}
            >
                <SvgIconButtonContent icon={AddIcon}>Add Customer Segment</SvgIconButtonContent>
            </Link>
        </div>
    );
}

function ScripCannotBeDeletedModal({ stats, onClose }: { stats: InterviewScript2Stats; onClose?: () => void }) {
    return (
        <Dialog title="Delete script" onClose={onClose} width={480}>
            <div>This interview script cannot be deleted.</div>

            <DeletionRelatedEntriesNotice
                parentEntriesTitle="Related entities blocking deletion:"
                relationsData={[
                    {
                        count: stats.interviewCount,
                        entryName: 'interview',
                        isParent: true
                    },
                    {
                        count: stats.researchCount,
                        entryName: 'research',
                        pluralEntryName: 'research',
                        isParent: true
                    }
                ]}
            />

            <DialogActionsBar layout="end">
                <Button onClick={onClose}>Close</Button>
            </DialogActionsBar>
        </Dialog>
    );
}
