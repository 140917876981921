import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { combineClassNames, generateInitials, getPreferredColorIndex } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import { interviewsV2Service, InterviewTranscriptEntry, InterviewTranscriptEntryType } from '../../services/interviewsV2Service';
import { InterviewMessageBox } from '../interview/entries/interviewMessageLog';
import LoadingIndicator from '../ui/loadingIndicator';
import UserAvatar from '../user/userAvatar';
import { AIAvatar } from './aiAvatar';

export function InterviewTranscriptView({ ideaId, interviewId }: { ideaId: string; interviewId: number }) {
    const [transcriptEntries, setTranscriptEntries] = useState<InterviewTranscriptEntry[]>();

    useEffect(() => {
        interviewsV2Service.getInterviewTranscript(ideaId, interviewId).then(setTranscriptEntries);
    }, [ideaId, interviewId]);

    if (!transcriptEntries) return <LoadingIndicator size="big" className="k-centered" />;

    return <InterviewTranscriptList transcriptEntries={transcriptEntries} />;
}

export function InterviewTranscriptList({
    transcriptEntries,
    chatLike,
    className
}: {
    transcriptEntries: InterviewTranscriptEntry[];
    chatLike?: boolean;
    className?: string;
}) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className={combineClassNames('k-gap-4', className)}>
            {transcriptEntries.map(transcriptEntry => {
                const isFromAgent = transcriptEntry.type === InterviewTranscriptEntryType.Agent;
                const transcriptAuthor =
                    transcriptEntry.type === InterviewTranscriptEntryType.Contact
                        ? transcriptEntry.contact
                        : transcriptEntry.type === InterviewTranscriptEntryType.User
                        ? transcriptEntry.user
                        : undefined;
                return (
                    <InterviewMessageBox
                        key={transcriptEntry.id}
                        hideShadow
                        right={chatLike && transcriptEntry.type === InterviewTranscriptEntryType.Contact}
                        rightAdditionalComponent={
                            <span className="k-align-self-end k-ml-1 k-mb-2 k-icp-subtle-text k-fs-sm">
                                {dateTimeService.stringifySecondsToMinutesAndSeconds(transcriptEntry.millisecondsSinceStart / 1000)}
                            </span>
                        }
                        messageBoxClassName={isFromAgent ? undefined : 'k-icp-panel-base !k-icp-component-border'}
                        avatar={
                            isFromAgent ? (
                                <AIAvatar className="k-shrink-0" />
                            ) : transcriptAuthor ? (
                                <UserAvatar
                                    className="k-shrink-0"
                                    initials={generateInitials(2, transcriptAuthor.firstName, transcriptAuthor.lastName)}
                                    picture={transcriptAuthor.picture}
                                    colorIndex={getPreferredColorIndex('userId' in transcriptAuthor ? transcriptAuthor.userId : transcriptAuthor.id)}
                                />
                            ) : (
                                undefined
                            )
                        }
                    >
                        {transcriptEntry.content}
                    </InterviewMessageBox>
                );
            })}
        </StackLayout>
    );
}
