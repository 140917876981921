import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useMemo, useState } from 'react';
import { useSingleClickCallback } from '../../hooks/commonHooks';
import { ReactComponent as ResearchIcon } from '../../icons/test.svg';
import researchEmptyIllustrationUrl from '../../images/research-in-circle-illustration.svg'; // TODO: change this to be for research
import { debounce } from '../../services/common';
import { interviewsV2Service } from '../../services/interviewsV2Service';
import { ResearchV2, researchV2Service } from '../../services/researchV2Service';
import { SearchBar } from '../common/searchbar';
import { researchV2TypeTextMap } from '../research2/common';
import LoadingIndicator from '../ui/loadingIndicator';
import { RadioOption } from '../ui/radioOption';

export function ManageInterviewResearchModal({
    ideaId,
    interviewId,
    relatedResearchIds,
    onSave,
    onClose
}: {
    ideaId: string;
    interviewId: number;
    relatedResearchIds: number[];
    onSave?: () => void | Promise<void>;
    onClose?: () => void;
}) {
    const [compatibleResearch, setCompatibleResearch] = useState<ResearchV2[]>();
    const [selectedResearchIds, setSelectedResearchIds] = useState(relatedResearchIds);
    const [searchingForResearch, setSearchingForResearch] = useState<boolean>();

    useEffect(() => {
        researchV2Service.getInterviewCompatibleResearch(ideaId, interviewId).then(setCompatibleResearch);
    }, [ideaId, interviewId]);

    const [isUpdatingRelatedResearch, updateRelatedResearch] = useSingleClickCallback(async function updateRelatedResearch() {
        const researchToAdd = selectedResearchIds.filter(r => !relatedResearchIds.includes(r));
        const researchToRemove = relatedResearchIds.filter(r => !selectedResearchIds.includes(r));
        if (!researchToAdd.length && !researchToRemove.length) {
            onClose?.();
            return;
        }

        await interviewsV2Service.updateInterviewRelatedResearch(ideaId, interviewId, {
            researchToAdd: researchToAdd.length ? researchToAdd : undefined,
            researchToRemove: researchToRemove.length ? researchToRemove : undefined
        });

        await onSave?.();
    });

    const searchForResearchDebounced = useMemo(
        () =>
            debounce(async function(text: string | undefined) {
                const foundResearch = await researchV2Service
                    .getInterviewCompatibleResearch(ideaId, interviewId, text)
                    .finally(() => setSearchingForResearch(undefined));
                setCompatibleResearch(foundResearch);
            }, 300),
        [ideaId, interviewId]
    );

    function toggleResearchSelection(researchId: number) {
        setSelectedResearchIds(selectedResearchIds =>
            selectedResearchIds.includes(researchId)
                ? selectedResearchIds.filter(selectedResearchId => selectedResearchId !== researchId)
                : [...selectedResearchIds, researchId]
        );
    }

    return (
        <Dialog
            title="Add/Remove from Research"
            width={640}
            onClose={onClose}
            className="k-icp-dialog-fixed-height k-icp-dialog-no-padding k-icp-dialog-with-form"
        >
            <div className="k-icp-panel-base k-p-4 k-border-b k-border-b-solid k-icp-component-border">
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-justify-content-between k-gap-4 k-mb-2">
                    <div className="k-font-weight-bold">Search for Research</div>
                    <div className="k-fs-sm">{selectedResearchIds.length} selected</div>
                </StackLayout>

                <SearchBar
                    disabled={!compatibleResearch}
                    isLoading={searchingForResearch}
                    placeholder="Type a research name..."
                    onChange={text => {
                        setSearchingForResearch(true);
                        searchForResearchDebounced(text);
                    }}
                />
            </div>
            <StackLayout
                orientation="vertical"
                align={{ horizontal: 'stretch', vertical: 'top' }}
                className="k-gap-3 k-overflow-auto k-flex-1 k-window-content"
            >
                {compatibleResearch ? (
                    compatibleResearch.length ? (
                        compatibleResearch.map(r => (
                            <RadioOption key={r.id} isSelected={selectedResearchIds.includes(r.id)} onClick={() => toggleResearchSelection(r.id)} deselectable>
                                <ResearchIcon className="k-shrink-0 k-icp-icon k-icp-icon-size-8 -mt-0.75" />
                                <div className="k-flex-1">
                                    <div>{r.name}</div>
                                    <div className="k-fs-sm k-icp-subtle-text">{researchV2TypeTextMap[r.type]} interviews</div>
                                </div>
                            </RadioOption>
                        ))
                    ) : (
                        <div className="k-m-auto k-text-center">
                            <img src={researchEmptyIllustrationUrl} width="80" height="81" alt="No research found" className="k-mb-6" />
                            <div>No research found</div>
                        </div>
                    )
                ) : (
                    <LoadingIndicator size="big" className="k-m-auto" />
                )}
            </StackLayout>
            {compatibleResearch && compatibleResearch.length > 0 && (
                <div className="k-icp-panel-base k-fs-sm k-px-4 k-py-2 k-border-t k-border-t-solid k-icp-component-border">
                    Updates to interview and research connections will automatically initiate analysis, ensuring all research insights remain current and
                    relevant.
                </div>
            )}
            <DialogActionsBar layout="center">
                <Button disabled={isUpdatingRelatedResearch} themeColor="primary" onClick={updateRelatedResearch}>
                    Save changes
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActionsBar>
        </Dialog>
    );
}
