import { useNavigate } from 'react-router-dom';
import { canvasItemsZoneDependent } from '../../components/canvas/canvasItemsZone';
import { CreateOrUpdateResearchV2Modal } from '../../components/research2/createOrUpdateResearchV2Modal';
import { useIdeaParams, useIntParam } from '../../hooks/routerHooks';

export const EditResearchV2Page = canvasItemsZoneDependent(function EditResearchV2Page() {
    const navigate = useNavigate();
    const { ideaId } = useIdeaParams();
    const researchId = useIntParam('researchId');

    return <CreateOrUpdateResearchV2Modal ideaId={ideaId} researchId={researchId} onClose={() => navigate('./../overview')} />;
});
